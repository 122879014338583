import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../modal/Modal';
import { Body2Regular, Body3Regular, Subtitle1Bold } from '../../lib/font';
import { Col, Row } from '../../lib/utils';
import { CodeValueType } from '../../types/types';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import NewColorSquareButton from '../Button/NewColorSquareButton';
import NewWhiteSquareButton from '../Button/NewWhiteSquareButton';
import NewTextInputDropDown from '../DropDown/NewTextInputDropDown';
type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    dropDownCoachList?: CodeValueType[];
    selectCoach?: CodeValueType;
    onClickChangeCoach: (newCoachId: string) => void;
};

const ModalMemberProfileComponentCoachChange = ({
    setIsVisible,
    dropDownCoachList,
    selectCoach: _selectCoach,
    onClickChangeCoach,
}: Props) => {
    const [selectCoach, setSelectCoach] = React.useState<CodeValueType | undefined>(_selectCoach);
    return (
        <Modal
            setIsVisible={setIsVisible}
            whiteViewWidth={1000}
            whiteViewHeight={460}
            isCloseButton
            onClose={() => setIsVisible(false)}
            isCloseOutside
        >
            <Col className="py-[36px] px-[48px]">
                <Subtitle1Bold className=" text-LAVEL_4 mb-[16px]">담당 강사 변경</Subtitle1Bold>
                <Body3Regular className="text-LAVEL_3 mb-[32px]">
                    변경할 강사님을 선택하시고 주의사항을 읽은 후, ‘변경하기'를 눌러주세요!
                </Body3Regular>
                <NewTextInputDropDown
                    selectData={selectCoach}
                    datas={dropDownCoachList ?? []}
                    onClickSort={(data) => {
                        setSelectCoach(data);
                    }}
                    width={300}
                    fontType={'Body2Regular'}
                    mb={40}
                />

                <Row className=" items-center mb-[8px]">
                    <SvgIcon name={'SvgError'} size={18} fill={colors.red} className="mr-[12px]" />
                    <Body2Regular className=" text-LAVEL_4">강사님이 변경되면 바뀌는 것들을 확인해주세요!</Body2Regular>
                </Row>
                <Col className="pl-[32px]">
                    <Body3Regular className="text-LAVEL_2">
                        1. 이전 강사님과 잡혀있던 미래의 수업일정들이 취소됩니다.
                    </Body3Regular>
                    <Body3Regular className="text-LAVEL_2">
                        2. 과거 수업일정, 운동일지, 컨디션 정보 등의 데이터는 회원님께서 그대로 확인하실 수 있습니다.
                    </Body3Regular>
                    <Body3Regular className="text-LAVEL_2">
                        3. 활성화되어있던 수강권들이 새로운 담당강사님에게 인계되어 양도 수강권으로 새로 생성됩니다.
                    </Body3Regular>
                </Col>
                <Row className=" absolute bottom-[24px] right-[32px]">
                    <NewWhiteSquareButton
                        text="취소"
                        width={148}
                        height={48}
                        mr={16}
                        onClick={() => {
                            setIsVisible(false);
                        }}
                    />
                    <NewColorSquareButton
                        text="변경하기"
                        width={148}
                        height={48}
                        bgColor={'primary'}
                        textColor={'white'}
                        onClick={() => onClickChangeCoach(selectCoach?.code ?? '')}
                    />
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalMemberProfileComponentCoachChange;
