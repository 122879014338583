import dayjs, { Dayjs } from 'dayjs';

export const getWeekOfMonth = (date: Dayjs) => {
    // 현재 월의 첫 번째 날
    const startOfMonth = date.startOf('month');

    // 현재 날짜가 속한 주의 시작일 (월요일 기준)
    const currentWeekStart = date.startOf('week');

    // 현재 월의 첫 번째 주의 시작일 (월요일 기준)
    const firstWeekStart = startOfMonth.startOf('week');

    // 현재 주차 계산 (첫 번째 주를 포함한 주차 계산)
    const weekNumber = currentWeekStart.diff(firstWeekStart, 'week') + 1;

    // 현재 주가 시작하는 날짜가 이번 달이 아니면 주차를 조정
    if (currentWeekStart.isBefore(startOfMonth)) {
        return 1; // 첫 주가 현재 월에 속하지 않으면 1주차로 처리
    }

    return weekNumber;
};
// 이번달 달력 데이터 생성
export const generateMonthlyData = (month: number, events: any[]) => {
    const startOfMonth = dayjs()
        .month(month - 1)
        .startOf('month')
        .startOf('week');
    const endOfMonth = dayjs()
        .month(month - 1)
        .endOf('month')
        .endOf('week');

    const days = [];
    let current = startOfMonth;

    // 달의 모든 날짜 생성
    while (current.isBefore(endOfMonth) || current.isSame(endOfMonth, 'day')) {
        const currentDate = current.format('YYYY-MM-DD');

        // 이벤트에서 해당 날짜와 일치하는 항목 찾기
        const matchingEvent = events.find((event) => dayjs(event.start).isSame(currentDate, 'day'));

        if (matchingEvent) {
            // 이벤트 데이터가 있는 경우
            days.push({
                start: matchingEvent.start,
                end: matchingEvent.end,
                money: matchingEvent.money,
                count1: matchingEvent.count1,
                count2: matchingEvent.count2,
            });
        } else {
            // 이벤트 데이터가 없는 경우 기본값
            days.push({
                start: currentDate,
                end: currentDate,
                money: 0,
                count1: 0,
                count2: 0,
            });
        }

        current = current.add(1, 'day');
    }

    return days;
};

// 이번달이 아닌 날짜인지 확인
export const isOffRange = (month: Dayjs, date: Dayjs) => {
    return !dayjs(date).isSame(dayjs(month), 'month');
};
