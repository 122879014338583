import React, { useRef, useState, useEffect, useMemo } from 'react';
import chunk from 'lodash/chunk';
import { navigate } from '../utils/constants';
import { notify } from '../utils/helpers';
// import DateContentRow from './DateContentRow';
import { NavigateAction } from '../types/types';
import DateContentRow from './DateContentRow';
import dayjs from 'dayjs';
import { inRange, sortWeekEvents } from '../utils/eventLevel';
import SvgIcon from '../../SvgIcon';
import { WeeklyTotal } from '../../../services/useCenterQueryService';
import { DemiLightText } from '../../../lib/Text';

interface MonthViewProps {
    date: Date;
    events: any[];
    weeklyEvents?: any[];
    components: any;
    selectable?: boolean;
    getNow: () => Date;
    selected?: any;
    longPressThreshold?: number;
    accessors?: any;
    getters?: any;
    showAllEvents?: boolean;
    popup?: boolean;
    onDrillDown?: (date: Date, view: string) => void;
    onShowMore?: (events: any[], date: Date, slot: number) => void;
    onSelectSlot?: (slotInfo: { slots: any[]; start: Date; end: Date; action: string; bounds: any; box: any }) => void;
    onSelectEvent?: (event: any) => void;
    onDoubleClickEvent?: (event: any) => void;
    onKeyPressEvent?: (event: any) => void;
    popupOffset?: number;
    className?: string;
    monthCustomEventComponent?: (event: any) => React.ReactNode;
    monthCellHeaderCustomComponent?: (date: Date) => React.ReactNode;
    monthWeeklyCustomComponent?: (event: WeeklyTotal, isLastWeek: boolean) => React.ReactNode;
}

const MonthView: React.FC<MonthViewProps> & {
    range: (date: Date) => { start: Date; end: Date };
    navigate: (date: Date, action: NavigateAction) => Date;
    title: (date: Date) => string;
} = ({
    date,
    events,
    weeklyEvents,
    components,
    selectable,
    getNow,
    selected,
    longPressThreshold,
    accessors,
    getters,
    showAllEvents = false,
    popup,
    onDrillDown,
    onShowMore,
    onSelectSlot,
    onSelectEvent,
    onDoubleClickEvent,
    onKeyPressEvent,
    popupOffset,
    className,
    monthCustomEventComponent,
    monthCellHeaderCustomComponent,
    monthWeeklyCustomComponent,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const slotRowRef = useRef<any>(null);
    const [pendingSelection, setPendingSelection] = useState<Date[]>([]);
    const [rowLimit, setRowLimit] = useState(5);
    const [needLimitMeasure, setNeedLimitMeasure] = useState(true);

    const monthCustomProps = {
        // Cell 헤더 커스텀
        monthCellHeaderCustomComponent: monthCellHeaderCustomComponent,
        // Cell 커스텀
        monthCustomEventComponent: monthCustomEventComponent,
        // 합산 주간 이벤트
        weeklyEvents: weeklyEvents,
        // 주간 커스텀
        monthWeeklyCustomComponent: monthWeeklyCustomComponent,
    };

    const measureRowLimit = () => {
        if (slotRowRef.current) {
            setRowLimit(slotRowRef.current.getRowLimit());
            setNeedLimitMeasure(false);
        }
    };

    const handleResize = () => {
        setNeedLimitMeasure(true);
    };

    useEffect(() => {
        if (needLimitMeasure) measureRowLimit();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [needLimitMeasure]);

    const clearSelection = () => {
        setPendingSelection([]);
    };

    const selectDates = (slotInfo: any) => {
        const sortedSlots = [...pendingSelection].sort((a, b) => +a - +b);
        const start = new Date(sortedSlots[0]);
        const end = new Date(sortedSlots[sortedSlots.length - 1]);
        end.setDate(end.getDate() + 1);

        // notify(onSelectSlot, {
        //     slots: sortedSlots as any[],
        //     start,
        //     end,
        //     action: slotInfo.action,
        //     bounds: slotInfo.bounds,
        //     box: slotInfo.box,
        // });
        clearSelection();
    };

    const handleSelectSlot = (range: Date[], slotInfo: any) => {
        setPendingSelection((prev) => [...prev, ...range]);
        setTimeout(() => selectDates(slotInfo), 0);
    };

    const renderHeaders = (row: Date[]) => {
        const first = row[0];
        const headerClass = 'flex relative w-full h-full text-ellipsis overflow-hidden pl-[12px pt-[16px] pb-[20px] ';
        const totalClass = ' bg-[#F2F4F6] rounded-tl-[12px] rounded-tr-[12px] ';
        return Array.from({ length: 8 }, (_, idx) => {
            // 마지막 헤더를 "합산"으로 표시
            if (idx === 7) {
                return (
                    <div key={`header_${idx}`} className={`${headerClass} ${totalClass} flex-row items-center`}>
                        <SvgIcon name={'SvgPlusInSquare'} size={16} fill={'#595D60'} className="ml-[14px]" />
                        <DemiLightText fontSize={15} lineHeight={21} className="     ml-[8px]" color="#595D60">
                            주간 합산
                        </DemiLightText>
                    </div>
                );
            }
            return (
                <div key={`header_${idx}`} className={headerClass}>
                    <DemiLightText fontSize={15} lineHeight={21} className="ml-[8px]" color="#595D60">
                        {dayjs(first).add(idx, 'day').format('ddd')}
                    </DemiLightText>
                </div>
            );
        });
    };

    const renderWeek = (week: Date[], weekIdx: number) => {
        const weeksEvents = events.filter((event) => inRange(event, week[0], week[6]));
        const sortedEvents = sortWeekEvents(weeksEvents, accessors);
        // 마지막 주인지
        const isLastWeek = weekIdx === weeks.length - 1;

        return (
            <DateContentRow
                key={weekIdx}
                // ref={weekIdx === 0 ? slotRowRef : undefined}
                container={() => containerRef.current || document.createElement('div')}
                getNow={getNow}
                date={date}
                range={week}
                events={sortedEvents}
                // maxRows={showAllEvents ? Infinity : rowLimit}
                selected={selected}
                selectable={selectable}
                components={components}
                accessors={accessors}
                getters={getters}
                onSelectSlot={handleSelectSlot}
                isLastWeek={isLastWeek}
                {...monthCustomProps}
            />
        );
    };

    // 11월 1일, 11월 30일
    const { start, end } = useMemo(() => {
        const startOfMonth = dayjs(date).startOf('month');
        const endOfMonth = dayjs(date).endOf('month');
        const start = startOfMonth.startOf('week').toDate(); // 포함할 첫 번째 주의 첫 날
        const end = endOfMonth.endOf('week').toDate(); // 포함할 마지막 주의 마지막 날
        return { start, end };
    }, [date]);

    const weeks = useMemo(() => {
        const days = [];
        let current = dayjs(start);
        while (current.isBefore(end, 'day') || current.isSame(end, 'day')) {
            days.push(current.toDate());
            current = current.add(1, 'day');
        }
        return chunk(days, 7);
    }, [start, end]);

    return (
        <div className=" flex flex-col  w-full h-full" ref={containerRef}>
            <div className="flex flex-row relative  w-full ">{renderHeaders(weeks[0])}</div>
            {weeks.map(renderWeek)}
        </div>
    );
};

MonthView.range = (date: Date) => {
    const start = dayjs(date).startOf('month').toDate(); // First day of the month
    const end = dayjs(date).endOf('month').toDate(); // Last day of the month
    return { start, end };
};

MonthView.navigate = (date: Date, action: NavigateAction) => {
    switch (action) {
        case navigate.PREVIOUS:
            return new Date(date.getFullYear(), date.getMonth() - 1, 1);
        case navigate.NEXT:
            return new Date(date.getFullYear(), date.getMonth() + 1, 1);
        default:
            return date;
    }
};

MonthView.title = (date: Date) => {
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
};

export default MonthView;
