import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { MediumText, RegularText } from '../../lib/Text';
import { colors } from '../../lib/colors';
import { ToggleSwitch } from './ModalCommission';
import { CommissionType } from '../../api/salesCommision';
import NewColorSquareButton from '../../components/Button/NewColorSquareButton';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onClickConfirm: (incentive: string, type: CommissionType) => void;
    incentive?: string;
    type?: CommissionType;
};

const ModalSalaryCalIncentive = ({ setIsVisible, onClickConfirm, incentive: _incentive, type: _type }: Props) => {
    const [incentive, setIncentive] = useState<string>(_incentive || '');
    const [type, setType] = useState<CommissionType>(_type || 'percentage');
    const inputRef = React.useRef<HTMLInputElement>(null);
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && incentive !== '') {
                setIsVisible(false);
                onClickConfirm(incentive, type);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [incentive, type, setIsVisible, onClickConfirm]);

    // input 포커싱
    useEffect(() => {
        inputRef.current?.focus();
    }, []);
    return (
        <Modal setIsVisible={setIsVisible} whiteViewWidth={550} borderRadius={15}>
            <Col className="py-[36px] px-[32px]">
                <MediumText
                    fontSize={22}
                    lineHeight={38}
                    letterSpacing={-0.32}
                    color={colors.Text_1}
                    className="mb-[27px]"
                >
                    매출 인센티브 설정
                </MediumText>
                <Row className="w-full bg-white border border-solid border-[#D9D9D9B2] rounded-[9px] py-[17.5px] px-[23px] justify-between mr-[20px] mb-[65px]">
                    <input
                        ref={inputRef}
                        value={type === 'percentage' ? incentive : convertToKRW(Number.parseInt(incentive) ?? 0)}
                        onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(/,/g, '');

                            if (value === '') {
                                value = '0';
                            }

                            // 숫자가 아니거나 빈 문자열일 경우 처리
                            if (/^\d+$/.test(value)) {
                                value = value.replace(/[^0-9]/g, '');
                            }

                            // 입력 값이 "0"일 때 새로운 숫자를 입력하면 "0"을 대체
                            if (value.startsWith('0') && value.length > 1) {
                                value = value.slice(1); // "0"을 제거
                            }

                            // 퍼센트일 경우 값이 100을 넘지 않도록 제한
                            if (type === 'percentage' && Number(value) > 100) {
                                value = '100';
                            }

                            setIncentive(value);
                        }}
                        className="font-noto-demilight text-[17px] leading-[24px] text-[#3D4244] bg-trans w-[280px]"
                        maxLength={type === 'percentage' ? 3 : 12}
                        placeholder="매출액에 대한 인센티브를 입력해주세요"
                        style={{
                            letterSpacing: '-0.32px',
                        }}
                    />
                    <ToggleSwitch
                        value={type}
                        onToggle={(value) => {
                            setType(value);
                            setIncentive('0');
                        }}
                    />
                </Row>
                <Row className="items-center self-end">
                    <Col
                        className="bg-GREY w-[110px] h-[41px] justify-center items-center rounded-[9px] mr-[9px] cursor-pointer"
                        onClick={() => setIsVisible(false)}
                    >
                        <RegularText fontSize={16} lineHeight={24} letterSpacing={-0.32} color={colors.Text_1}>
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className={` w-[110px] h-[41px] justify-center items-center rounded-[9px] mr-[9px]  bg-primary 
                            ${incentive === '' ? ' opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                            `}
                        onClick={() => {
                            setIsVisible(false);
                            onClickConfirm(incentive, type);
                        }}
                    >
                        <RegularText fontSize={16} lineHeight={24} letterSpacing={-0.32} color={colors.white}>
                            적용하기
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalSalaryCalIncentive;
