// apiService.js

import { QueryClient } from '@tanstack/react-query';
import { getSalesList, GetSalesListParams, getSalesManagers } from '../api/membershipPayments';

const queryClient = new QueryClient();

export const fetchGetSalesList = (params: GetSalesListParams, authorization: string) => ({
    queryKey: ['getSalesListQuery', params.centerId, params.startDate, params.userId, params.role],
    queryFn: () =>
        getSalesList({
            centerId: params.centerId,
            startDate: params.startDate,
            endDate: params.endDate,
            userId: params.userId,
            role: params.role,
            accessToken: authorization,
        }),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data ?? [];
    },
});

export const fetchGetSalesManagers = (params: { centerId: string }, authorization: string) => ({
    queryKey: ['getSalesManagersQuery', params.centerId],
    queryFn: () => getSalesManagers(params.centerId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.salesManagers ?? [];
    },
});

export const refreshGetSaleManagers = () => {
    queryClient.invalidateQueries({
        queryKey: ['getSalesManagersQuery'],
    });
};

// 판매 상태 타입
export type SalesStatus = 'COMPLETED' | 'UPGRADED' | 'PARTIAL_REFUND' | 'FULL_REFUND' | 'PARTIAL_PAYMENT';

// 판매 담당자 필터 타입
type SalesManagerNames = SalesManager[];

// 요약 데이터 타입
export interface SalesListSummaryData {
    totalSales: number; // 총 매출액
    totalSalesCount: number; // 총 매출 건수
    totalSalesMemberCount: number; // 총 매출 명수
    newPaymentAmount: number; // 신규 결제액
    newPaymentCount: number; // 신규 결제 건수
    newPaymentMemberCount: number; // 신규 결제 명수
    reRegistrationPaymentAmount: number; // 재등록 결제액
    reRegistrationPaymentCount: number; // 재등록 결제 건수
    reRegistrationPaymentMemberCount: number; // 재등록 결제 명수
    cancelSales: number; // 환불 금액
    cancelSalesCount: number; // 환불 건수
    cancelSalesMemberCount: number; // 환불 명수
    partialPaymentAmount: number; // 부분 결제 금액
    partialPaymentCount: number; // 부분 결제 건수
    partialPaymentMemberCount: number; // 부분 결제 명수
}

// 테이블 데이터 타입
export interface MembershipPaymentData {
    membershipId: string; // 회원권 ID
    saleStatus: SalesStatus; // 판매 상태
    paymentDate: string; // 결제 일시
    salesManager: SalesManager;
    coachName: string; // 담당 강사 이름
    memberName: string; // 회원 이름
    memberId: string; // 회원 ID
    membershipTemplateId: string; // 수강권 필터를 위한 수강권 메뉴 ID
    isNewSales: boolean; // 신규 여부
    totalPrice: number; // 판매 금액
    paymentAmount: number; // 결제 금액
    cancelAmount: number; // 취소 금액
    totalSession: number; // 총 회차
    totalSessionOffset: number; // 총 회차 변화량
    membershipName: string; // 수강권 이름
    activationDate: string; // 이용기간 시작일
    expirationDate: string; // 이용기간 종료일
}

// 필터 - 회원 리스트 타입
interface MemberFilter {
    memberId: string;
    memberName: string;
}

// 필터 - 수강권 리스트 타입
interface MembershipTemplateFilter {
    membershipTemplateId: string;
    membershipTemplateName: string;
}

// 필터 데이터 타입
interface Filters {
    memberList: MemberFilter[]; // 회원 이름 검색 필터
    membershiptemplateList: MembershipTemplateFilter[]; // 수강권 이름 필터
}

// 전체 데이터 타입
export interface SalesDataResponse {
    salesManagers: SalesManager[]; // 판매 담당자 필터
    summaryData: SalesListSummaryData; // 요약 데이터
    tableDataByMembershipPayment: MembershipPaymentData[]; // 테이블 데이터
    filters: Filters; // 필터
}

export type SalesManager = {
    role: 'coach' | 'etc' | 'all';
    userId: string;
    userName: string;
};
