import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import { Body2Bold, Body3Regular, CaptionLight, CaptionRegular, Subtitle1Bold } from '../../lib/font';
import useMembershipService from '../../services/useMembershipService';
import CloseButton from '../Button/CloseButton';
import NewColorRoundButton from '../Button/NewColorRoundButton';
import RadiusTextInput from '../TextInput/RadiusTextInput';
import MembershipTemplate from '../../scenes/ClassPass/ClassPassList/MembershipTemplate';
import dayjs from 'dayjs';
import ColTitleAndTextInput from '../ColTitleAndTextInput';
import MemoTextInput from '../TextInput/MemoTextInput';
import NewWhiteSquareButton from '../Button/NewWhiteSquareButton';
import NewColorSquareButton from '../Button/NewColorSquareButton';
import ModalUpdateMembershipName from './ModalUpdateMembershipName';
import { useQueryClient } from '@tanstack/react-query';
import { MembershipTemplateDataType } from '../../services/useMembershipTemplateQueryService';
import { MemberShipApiType } from '../../api/memberShip';
import Dropdown from '../DropDown/DropDown';
import { colors } from '../../lib/colors';
import useSingleQuery from '../../hook/useSingleQuery';
import useMembershipPaymentsMutationService from '../../services/useMembershipPaymentsMutationService';
import { fetchGetSalesManagers, SalesManager } from '../../services/useMembershipPaymentsQueryService';
import { CodeValueType } from '../../types/types';
import ModalSalesManagerName from './ModalSalesManagerName';
import NewCalendar from '../Calendar/NewCalendar';
import { fetchGetIsNewSales } from '../../services/useMembershipQueryService';
import SvgIcon from '../SvgIcon';
import { RegularText } from '../../lib/Text';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    // 새로운 수강권 만들기 버튼 클릭 시
    onClickCreateNewMembership: () => void;

    memberName?: string;
    coachName?: string;
    coachId?: string;
    memberId?: string;
    type?: string;
    createAlert?: any;

    // 수강권 등록 완료 시
    onMembershipCreateSuccess?: (membership: MemberShipApiType) => void;
};

const ModalMembershipCreate = ({
    setIsVisible,
    onClickCreateNewMembership,
    memberName,
    coachName,
    coachId,
    memberId,
    type = 'memberProfile',
    createAlert,
    onMembershipCreateSuccess = undefined,
}: Props) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const queryClient = useQueryClient();

    const { allMembershipTemplatesListData, createMemberShipMutation } = useMembershipService({
        fetchAllMembershipTemplatesList: true,
        onCreateMemberShipSuccess() {
            setIsVisible(false);
            createAlert('수강권 등록 완료', '새로운 수강권이 성공적으로 등록되어 회원님께 발급되었습니다!', false);
            if (type === 'memberProfile') {
                queryClient.invalidateQueries({
                    queryKey: ['getAllMembershipForMemberQuery'],
                });
            } else if (type === 'schedule') {
                // 수강권 데이터 다시 불러와야함
                queryClient.invalidateQueries({
                    queryKey: ['getMemberListForCenterAPI'],
                });
            }
        },
    });

    const { postSalesManagerMutation } = useMembershipPaymentsMutationService({
        onPostSalesManagerSuccess(data) {
            setSalesManager(resfineSalesManagers(data));
        },
    });
    const { refinedResult, isLoading, isFetching } = useSingleQuery({
        fetchFn: fetchGetSalesManagers,
        params: { centerId: authObject.centerId },
        enabled: !!authorization,
        suspense: false,
    });
    const { refinedResult: _result } = useSingleQuery({
        fetchFn: fetchGetIsNewSales,
        params: { memberId: memberId, coachId: coachId },
        enabled: !!authorization,
        suspense: false,
    });

    // userId, userName -> code, value
    // 마지막에 직접입력 추가
    const resfineSalesManagers = useCallback(
        (data: any): Array<{ code: string; value: string; onClick?: () => void }> => {
            // userId, userName -> code, value 변환
            const _salesManagers = Array.isArray(data)
                ? data.map((item: any) => ({
                      code: item.userId,
                      value: item.userName,
                      object: item,
                  }))
                : [];

            // 직접입력 추가
            return [
                ..._salesManagers,
                {
                    code: 'direct',
                    value: '직접입력',
                    onClick: () => setIsSalesManagerModalVisible(true),
                },
            ];
        },
        []
    );
    const [salesManager, setSalesManager] = useState<CodeValueType[] | undefined>(undefined);

    useEffect(() => {
        if (!isLoading && !isFetching) {
            setSalesManager(resfineSalesManagers(refinedResult.data));
        }
    }, [isLoading, isFetching]);

    // 판매담당자 추가 모달
    const [isSalesManagerModalVisible, setIsSalesManagerModalVisible] = useState(false);
    // 결제일자 캘린더
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const [filterText, setFilterText] = useState<string>('');

    // filterText 가 있을 때만 필터링
    const filteredData = allMembershipTemplatesListData.filter((item: any) => item.name.includes(filterText));

    // 수강권 이름 수정 모달
    const [isMembershipNameModalVisible, setIsMembershipNameModalVisible] = useState(false);

    // 1,2 단계
    const [step, setStep] = useState<number>(1);
    // 수강권 템플릿 Id
    const [selectMemberShipTemplateId, setSelectMemberShipTemplateId] = useState<string>('');

    // 회당 가격
    const [pricePerClass, setPricePerClass] = useState<number>(0);
    // 판매 금액
    const [totalPrice, setTotalPrice] = useState<number>(0);

    // 시작 날짜
    const [startDate, setStartDate] = useState<string>('');
    // 만료 날짜 expirationDate
    const [expirationDate, setExpirationDate] = useState<string>('');
    // 수강권 기간
    const [membershipPeriod, setMembershipPeriod] = useState<number>(0);
    // 시작 회차 startSession
    const [startSession, setStartSession] = useState<number>(1);
    // 총 회차 totalSession
    const [totalSession, setTotalSession] = useState<number>(0);
    // 수업 시간
    const [classTime, setClassTime] = useState<number>(50);
    // 메모
    const [memo, setMemo] = useState<string>('');
    // BGColor
    const [BGColor, setBGColor] = useState<string[]>([]);
    // 수강권 이름
    const [membershipName, setMembershipName] = useState<string>('');
    // 결제일자
    const [paymentDate, setPaymentDate] = useState<string>(dayjs().toISOString());
    // 판매담당자
    const [selectedSalesManager, setSelectedSalesManager] = useState<SalesManager | undefined>(undefined);
    // 회원 구분
    const [memberType, setMemberType] = useState<string>('new');

    // 분할 결제 여부
    const [isSplitPayment, setIsSplitPayment] = useState<boolean>(false);
    // 결제 금액
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    // 미수금액
    const [unpaidAmount, setUnpaidAmount] = useState<number>(0);

    // 담당자 초기값 , coach id
    const initialSalesManager = salesManager?.find((item) => item.code === coachId);

    // 수강권 선택
    const onClickMembershipTemplate = (item: MembershipTemplateDataType) => {
        setStep(2);
        setPricePerClass(item.pricePerSchedule);
        setTotalPrice(item.totalPrice);
        setStartDate(dayjs().toISOString());
        setExpirationDate(dayjs().add(item.validDays, 'day').toISOString());
        setMembershipPeriod(item.validDays);
        setTotalSession(item.totalSession);
        setBGColor(item.BGColor);
        setMembershipName(item.name);
        setSelectMemberShipTemplateId(item._id);
        setClassTime(item.period);
    };

    // 등록완료 버튼 클릭 시
    const onClickCreateMembership = () => {
        if (classTime === null || classTime.toString().trim().length < 1) {
            createAlert('필수 입력', '수업 시간을 입력하여 주세요', true);
            return;
        }
        if (classTime % 10 > 0 || classTime.toString().trim().length < 1) {
            createAlert('올바르지 않은 입력', '수업 시간을 10분단위로 입력하여 주세요', true);
            return;
        }
        if (onMembershipCreateSuccess) {
            if (!selectedSalesManager) return;
            const membership: MemberShipApiType = {
                coachId: coachId,
                membershipTemplateId: selectMemberShipTemplateId,
                name: membershipName,
                period: classTime,
                activationDate: startDate,
                expirationDate: expirationDate,
                startSessionValue: startSession,
                totalSession: totalSession,
                memo: memo,
                pricePerSchedule: pricePerClass,
                status: 'active',
                totalPrice: totalPrice,
                BGColor: BGColor,
                issuer: { issuerId: authObject.adminId, issuerRole: 'admin' },
                salesManager: selectedSalesManager,
                isNewSales: memberType === 'new' ? true : false,
                isSplitPayment: isSplitPayment,
                totalPaymentAmount: paymentAmount,
                initialPaymentDate: dayjs(paymentDate).toISOString(),
            };

            onMembershipCreateSuccess && onMembershipCreateSuccess(membership);
        } else {
            if (!selectedSalesManager) return;
            createMemberShipMutation.mutate({
                coachId: coachId,
                memberId: memberId,
                membershipTemplateId: selectMemberShipTemplateId,
                name: membershipName,
                period: classTime,
                activationDate: startDate,
                expirationDate: expirationDate,
                startSessionValue: startSession,
                totalSession: totalSession,
                memo: memo,
                pricePerSchedule: pricePerClass,
                status: 'active', // [ ] active 의 기준?
                totalPrice: totalPrice,
                issuer: { issuerId: authObject.adminId, issuerRole: 'admin' },
                BGColor: BGColor,
                salesManager: selectedSalesManager,
                isNewSales: memberType === 'new' ? true : false,
                initialPaymentDate: dayjs(paymentDate).toISOString(),
                isSplitPayment: isSplitPayment,
                totalPaymentAmount: paymentAmount,
                accessToken: authorization,
            });
        }
    };

    // 수강권 기간 계산, 만료날짜 - 시작날짜
    useEffect(() => {
        if (startDate && expirationDate) {
            const start = dayjs(startDate);
            const end = dayjs(expirationDate);
            const diff = end.diff(start, 'days');
            setMembershipPeriod(diff);
        }
    }, [startDate, expirationDate]);

    // 미수금액 = 판매금액 - 결제금액
    useEffect(() => {
        if (isSplitPayment) {
            setUnpaidAmount(totalPrice - paymentAmount);
        }
    }, [paymentAmount, totalPrice, isSplitPayment]);

    return (
        <Modal whiteViewHeight={700} whiteViewWidth={1080} setIsVisible={setIsVisible} isCloseOutside={false}>
            <CloseButton size={24} className=" absolute right-[25px] top-[25px]" onClick={() => setIsVisible(false)} />
            <Col className="flex py-[36px] px-[48px]">
                <Row className="mb-[24px]">
                    <Subtitle1Bold>수강권 등록하기</Subtitle1Bold>
                </Row>
                {step === 1 ? (
                    <>
                        <Row className="flex justify-between mb-[40px]">
                            <Row className="flex flex-row  items-center">
                                <Col className=" justify-center items-center bg-primary w-[24px] h-[24px] rounded-full">
                                    <CaptionRegular className="text-white">1</CaptionRegular>
                                </Col>
                                <Col className="w-[36px] h-[1px] bg-BLUE_200" />

                                <Col className=" justify-center items-center bg-BLUE_200 w-[24px] h-[24px] rounded-full">
                                    <CaptionRegular className="text-white">2</CaptionRegular>
                                </Col>
                            </Row>
                            <Row>
                                <NewColorRoundButton
                                    bgColor="primary"
                                    text="새로운 수강권 만들기"
                                    onClick={onClickCreateNewMembership}
                                    textColor="white"
                                    fontType="Body3Regular"
                                    width={188}
                                    height={38}
                                    className="mr-[16px]"
                                />
                                <RadiusTextInput
                                    textInputHeight={36}
                                    value={filterText}
                                    placeholder="수강권 검색하기"
                                    width={310}
                                    onChange={(value) => setFilterText(value)}
                                    onClear={() => setFilterText('')}
                                    isRightIcon={false}
                                    isLeftIcon
                                    isEnterChange
                                />
                            </Row>
                        </Row>
                        <div className="grid grid-cols-3 gap-6 w-full px-[56px]">
                            {filteredData.map((item: { _id: any }, index: any) => (
                                <MembershipTemplate
                                    key={item._id}
                                    index={index}
                                    item={item}
                                    onClickDetailPage={onClickMembershipTemplate}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <Row className="flex flex-row  items-center mb-[40px]">
                            <Col className=" justify-center items-center bg-BLUE_200 w-[24px] h-[24px] rounded-full">
                                <CaptionRegular className="text-white">1</CaptionRegular>
                            </Col>
                            <Col className="w-[36px] h-[1px] bg-BLUE_200" />

                            <Col className=" justify-center items-center bg-primary w-[24px] h-[24px] rounded-full">
                                <CaptionRegular className="text-white">2</CaptionRegular>
                            </Col>
                        </Row>
                        <Row className="mb-[32px]">
                            <Col className="mr-[40px]">
                                <Col
                                    style={{
                                        width: 264,
                                        height: 120,
                                        background: `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`,
                                        borderRadius: 8,
                                        justifyContent: 'center',
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        marginBottom: 24,
                                    }}
                                >
                                    <Body2Bold className="text-white">{`${membershipName}`}</Body2Bold>
                                </Col>
                                {/* <NewWhiteRoundButton
                                    text="수강권 이름 수정하기"
                                    onClick={() => setIsMembershipNameModalVisible(true)}
                                    fontType="CaptionRegular"
                                    width={136}
                                    height={24}
                                    mb={52}
                                /> */}
                                {coachName && (
                                    <ColTitleAndTextInput
                                        title="담당 강사"
                                        value={coachName}
                                        onChange={() => {}}
                                        width={240}
                                        height={36}
                                        textInputRightIcon={false}
                                        textInputLeftIcon={false}
                                        disabled
                                        mb={40}
                                    />
                                )}
                                {memberName && (
                                    <ColTitleAndTextInput
                                        title="회원"
                                        value={memberName}
                                        onChange={() => {}}
                                        width={240}
                                        height={36}
                                        textInputRightIcon={false}
                                        textInputLeftIcon={false}
                                        disabled
                                    />
                                )}
                            </Col>
                            <Col className="w-[644px] px-[32px] pb-[32px]">
                                <Row className=" items-center mb-[17px]">
                                    <Col
                                        onClick={() => setIsSplitPayment(!isSplitPayment)}
                                        className={` justify-center items-center w-[24px] h-[24px] rounded-[3px] cursor-pointer mr-[7px] ${
                                            isSplitPayment
                                                ? 'bg-primary'
                                                : 'white border-solid border-[#D4DBE8] border-[2px]'
                                        }`}
                                    >
                                        {isSplitPayment && (
                                            <SvgIcon
                                                name="SvgCheck"
                                                size={13}
                                                fill={colors.trans}
                                                stroke="white"
                                                strokeWidth={2}
                                            />
                                        )}
                                    </Col>
                                    <RegularText fontSize={14} lineHeight={22} color="#1A305D">
                                        분할 결제
                                    </RegularText>
                                </Row>
                                <Col className="w-full h-[1px] bg-[#D9D9D9] mb-[29px]" />
                                {isSplitPayment && (
                                    <Row className="mb-[34px]">
                                        <ColTitleAndTextInput
                                            title="결제 금액"
                                            value={paymentAmount.toString()}
                                            onChange={(value) => {
                                                if (value === '') {
                                                    value = '0';
                                                }
                                                setPaymentAmount(parseInt(value));
                                            }}
                                            width={240}
                                            height={36}
                                            onClear={() => {
                                                setPaymentAmount(0);
                                            }}
                                            textInputRightIcon={false}
                                            isClearIcon={false}
                                            mr={60}
                                        />
                                        <ColTitleAndTextInput
                                            title="미수금액"
                                            value={unpaidAmount.toString()}
                                            onChange={(value) => {}}
                                            width={240}
                                            height={36}
                                            onClear={() => {}}
                                            textInputRightIcon={false}
                                            isClearIcon={false}
                                            mr={60}
                                            disabled
                                        />
                                    </Row>
                                )}
                                <Row className="mb-[34px]">
                                    <ColTitleAndTextInput
                                        title="회당 가격 (원)"
                                        value={pricePerClass}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setPricePerClass(parseInt(value));
                                        }}
                                        width={240}
                                        height={36}
                                        onClear={() => {
                                            setPricePerClass(0);
                                        }}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                        mr={60}
                                    />
                                    <ColTitleAndTextInput
                                        title="판매 금액 (원)"
                                        value={totalPrice}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setTotalPrice(parseInt(value));
                                        }}
                                        width={240}
                                        height={36}
                                        onClear={() => {
                                            setTotalPrice(0);
                                        }}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                    />
                                </Row>
                                <Row className="mb-[34px]">
                                    <ColTitleAndTextInput
                                        title="시작 날짜"
                                        value={startDate}
                                        isCalendar
                                        onClickDate={(date) => {
                                            setStartDate(dayjs(date).toISOString());
                                            setMembershipPeriod(dayjs(expirationDate).diff(dayjs(date), 'day'));
                                        }}
                                        width={240}
                                        height={36}
                                        mr={60}
                                    />
                                    <ColTitleAndTextInput
                                        title="만료 날짜"
                                        value={expirationDate}
                                        isCalendar
                                        onClickDate={(date) => {
                                            setExpirationDate(dayjs(date).toISOString());
                                            setMembershipPeriod(dayjs(date).diff(dayjs(startDate), 'day'));
                                        }}
                                        width={240}
                                        height={36}
                                    />
                                </Row>
                                <Row className=" items-end mb-[34px]">
                                    <ColTitleAndTextInput
                                        title="수강권 기간"
                                        value={membershipPeriod.toString()}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setMembershipPeriod(parseInt(value));
                                            setExpirationDate(
                                                dayjs(startDate).add(parseInt(value), 'day').toISOString()
                                            );
                                        }}
                                        width={140}
                                        height={36}
                                        mr={32}
                                        isClearIcon={false}
                                    />
                                    <Col>
                                        <CaptionLight className="text-WHITE_900">
                                            시작, 만료날짜를 입력하시면 유효기간이 자동으로 계산 되어요!
                                        </CaptionLight>
                                        <CaptionLight className="text-WHITE_900">
                                            만료 날짜가 지나도 수업 회차가 남으면 만료되지 않아요!
                                        </CaptionLight>
                                    </Col>
                                </Row>
                                <Row className="mb-[34px]">
                                    <ColTitleAndTextInput
                                        title="시작 회차"
                                        value={startSession.toString()}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setStartSession(parseInt(value));
                                        }}
                                        width={140}
                                        height={36}
                                        mr={60}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                    />
                                    <ColTitleAndTextInput
                                        title="총 회차"
                                        value={totalSession.toString()}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setTotalSession(parseInt(value));
                                        }}
                                        width={140}
                                        height={36}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                        mr={60}
                                    />
                                    <ColTitleAndTextInput
                                        title="수업 시간"
                                        value={classTime}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setClassTime(parseInt(value));
                                        }}
                                        width={140}
                                        height={36}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                    />
                                </Row>
                                <Row className="mb-[48px]">
                                    <Col className="mr-[60px]">
                                        <Body3Regular className="mb-[8px]">판매담당자</Body3Regular>
                                        {!isLoading && salesManager && (
                                            <Dropdown
                                                options={salesManager}
                                                bgColor="white"
                                                className="w-[140px] h-[36px] border border-solid border-[#D4DBE8] justify-between"
                                                borderRadius={50}
                                                arrowColor={colors.primary}
                                                onSelect={(item) => {
                                                    setSelectedSalesManager(item.object ?? undefined);
                                                }}
                                                initialSelected={initialSalesManager?.code ?? salesManager[0].code}
                                                isAllOption={false}
                                            />
                                        )}
                                    </Col>
                                    <Col className="mr-[60px]">
                                        <Body3Regular className="mb-[8px]">회원 구분</Body3Regular>
                                        <Dropdown
                                            options={[
                                                { code: 'new', value: '신규' },
                                                { code: 're', value: '재등록' },
                                            ]}
                                            bgColor="white"
                                            className="w-[140px] h-[36px] border border-solid border-[#D4DBE8] justify-between"
                                            borderRadius={50}
                                            arrowColor={colors.primary}
                                            isAllOption={false}
                                            onSelect={(item) => {
                                                setMemberType(item.code);
                                            }}
                                        />
                                    </Col>
                                    <Col onClick={() => setIsCalendarOpen(true)} className="relative">
                                        <Body3Regular color={colors.LAVEL_4} className="mb-[8px]">
                                            결제일자
                                        </Body3Regular>
                                        <Col
                                            className={`w-[150px] h-[36px] border border-solid border-[#D4DBE8] pl-[16px] justify-center  rounded-full cursor-pointer`}
                                        >
                                            <Body3Regular color={colors.LAVEL_4}>
                                                {dayjs(paymentDate).format('YYYY-MM-DD')}
                                            </Body3Regular>
                                        </Col>
                                    </Col>
                                </Row>
                                <Col>
                                    <Body3Regular className="mb-[6px]">메모</Body3Regular>
                                    <CaptionLight className="mb-[8px]">
                                        수강권의 메모는 관리자님과 강사님만 확인할 수 있어요!
                                    </CaptionLight>
                                    <MemoTextInput
                                        value={memo}
                                        onChange={(e) => setMemo(e.target.value)}
                                        height={136}
                                        placeholder="기억해야 할 회원님의 상태를 메모해보세요"
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="w-full justify-end">
                            <NewWhiteSquareButton
                                text="이전"
                                fontType="Body2Regular"
                                width={148}
                                height={48}
                                mr={16}
                                onClick={() => setStep(1)}
                            />
                            <NewColorSquareButton
                                text="등록 완료"
                                fontType="Body2Regular"
                                width={148}
                                height={48}
                                onClick={onClickCreateMembership}
                            />
                        </Row>
                        {isCalendarOpen && (
                            <NewCalendar
                                date={paymentDate || dayjs(paymentDate)}
                                setIsCalendar={setIsCalendarOpen}
                                onClickDate={(day) => {
                                    setPaymentDate(dayjs(day).toISOString());
                                }}
                                isAllDate={false}
                                top={200}
                                left={400}
                                selectedDay={dayjs(paymentDate)}
                                isNextMonth
                                isPrevMonth
                                titleFormat="YYYY년 MM월"
                            />
                        )}
                    </>
                )}
            </Col>
            {isMembershipNameModalVisible && (
                <ModalUpdateMembershipName
                    onUpdateComplete={(value: string) => {
                        setMembershipName(value);
                        setIsMembershipNameModalVisible(false);
                    }}
                    setIsVisible={setIsMembershipNameModalVisible}
                    value={membershipName}
                />
            )}
            {isSalesManagerModalVisible && (
                <ModalSalesManagerName
                    setIsVisible={setIsSalesManagerModalVisible}
                    onClickConfirm={(name: string) => {
                        postSalesManagerMutation.mutate({
                            membershipId: authObject.centerId,
                            managerName: name,
                        });
                    }}
                />
            )}
        </Modal>
    );
};

export default ModalMembershipCreate;
