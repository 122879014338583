import React, { TextareaHTMLAttributes, useState } from 'react';
import { Body2Regular } from '../lib/font';
import RadiusTextInput from './TextInput/RadiusTextInput';
import RoundDropDown from './DropDown/RoundDropDown';
import NewRoundedDropDown from './DropDown/NewRoundedDropDown';
import { CodeValueType } from '../types/types';
import MemoTextInput from './TextInput/MemoTextInput';
import Calendar from './Calendar/Calendar';
import dayjs from 'dayjs';
import { colors } from '../lib/colors';
import { Col } from '../lib/utils';
import TextSelector, { FontsType } from './TextSelector';

interface Props {
    title?: string;
    disabled?: boolean;
    value?: string | number;
    onChange?: (value: string) => void;
    onMemoChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    width?: number;
    height?: number;
    // -------------------- Dropdown  --------------------
    isDrowDown?: boolean;
    isMemo?: boolean;
    dropDownSelectData?: CodeValueType<string>;
    dropDownDatas?: CodeValueType<string>[];
    onClickDropDown?: (item: CodeValueType<string>) => void;
    placeholder?: string;
    maxLength?: number;
    mr?: number;
    mb?: number;
    onClear?: () => void;
    textInputLeftIcon?: boolean;
    textInputRightIcon?: boolean;
    unit?: string;
    isCalendar?: boolean;
    fontType?: FontsType;
    onClickDate?: (date: Date) => void;
    isClearIcon?: boolean;
    isEnterChange?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
}

const ColTitleAndTextInput = ({
    title,
    disabled,
    value = '',
    onChange,
    width,
    height,
    // -------------------- Dropdown  --------------------
    isDrowDown,
    isMemo,
    onMemoChange,
    dropDownSelectData,
    dropDownDatas,
    onClickDropDown,
    placeholder,
    maxLength,
    mr,
    mb,
    onClear,
    textInputLeftIcon = false,
    textInputRightIcon = true,
    unit,
    isCalendar,
    fontType = 'Body2Regular',
    onClickDate,
    isClearIcon,
    isEnterChange = false,
    onFocus,
    onBlur,
}: Props) => {
    // 달력 모달 띄우기
    const [isCalendarModalVisible, setIsCalendarModalVisible] = useState<boolean>(false);
    return (
        <div className={`flex flex-col`} style={{ marginRight: mr, marginBottom: mb }}>
            <Body2Regular className={'mb-[8px] text-LAVEL_4'}>{title}</Body2Regular>
            {isDrowDown && dropDownDatas && onClickDropDown ? (
                <NewRoundedDropDown
                    selectData={dropDownSelectData}
                    datas={dropDownDatas}
                    onClickSort={onClickDropDown}
                    width={width}
                    fontType={fontType}
                />
            ) : isMemo && onMemoChange ? (
                <MemoTextInput value={value as string} onChange={onMemoChange} width={width} height={height} />
            ) : isCalendar ? (
                <Col
                    className="border-[1px] border-solid border-WHITE_600 rounded-full justify-center pl-[16px]"
                    style={{ width: width, height: height }}
                    onClick={() => setIsCalendarModalVisible(true)}
                >
                    <TextSelector fontType={fontType} text={dayjs(value).format('YYYY년 M월 D일')} />
                </Col>
            ) : (
                onChange && (
                    <RadiusTextInput
                        value={value}
                        onChange={onChange}
                        textInputHeight={36}
                        width={width}
                        height={height}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        isLeftIcon={textInputLeftIcon}
                        isRightIcon={textInputRightIcon}
                        onClear={onClear}
                        unit={unit}
                        disabled={disabled}
                        isClearIcon={isClearIcon}
                        isEnterChange={isEnterChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                )
            )}
            {isCalendarModalVisible && (
                <Calendar
                    selectedDay={new Date(value)}
                    onClickDate={onClickDate}
                    setIsCalendar={setIsCalendarModalVisible}
                    isPrevMonth={true}
                    isNextMonth={true}
                    style={{
                        width: 420,
                        height: 370,
                        borderRadius: 24,
                        backgroundColor: colors.WHITE_50,
                        boxShadow: '8px 8px 24px 0px #00000052',

                        position: 'absolute',
                        zIndex: 1999,
                        marginTop: 70,
                        marginLeft: 0,
                    }}
                />
            )}
        </div>
    );
};

export default ColTitleAndTextInput;
