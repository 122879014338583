// apiService.js

import { getSalaryCalcs, GetSalaryCalcsParams } from '../api/salaryCalc';

export const fetchSalaryCalcs = (params: GetSalaryCalcsParams) => ({
    queryKey: [
        'getSalaryCalcsQuery',
        params.year,
        params.month,
        params.selectedCoachId,
        params.centerId,
        params.accessToken,
    ],
    queryFn: () => getSalaryCalcs({ ...params }),
    onSuccess: (data: any) => {
        return data?.data?.salaryCalcData ?? [];
    },
});
