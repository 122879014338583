import axios, { AxiosResponse } from 'axios';
import { MemberShipApiType } from './memberShip';
import { createDataObject } from '../lib/utils';
import { GENDERCODETYPE } from '../lib/varialbe';

export /**
 * @method get
 * @brief 센터 멤버 조회
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Member.md#%EC%84%BC%ED%84%B0-%EB%A9%A4%EB%B2%84-%EC%A1%B0%ED%9A%8C
 */
const getOneMember = async (centerId: string, memberId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/members/center/${centerId}/member/${memberId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method get
 * @brief 센터 멤버 조회
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Member.md#%EC%84%BC%ED%84%B0-%EB%A9%A4%EB%B2%84-%EC%A1%B0%ED%9A%8C
 */
const getClassFeeSettlement = async ({
    centerId,
    startDate,
    endDate,
    selectedCoach,
    accessToken,
}: GetClassFeeSettlementParamsType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/members/center/${centerId}/bymember?startDate=${startDate}&endDate=${endDate}&selectedCoach=${selectedCoach}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method get
 * @brief 수업료 정산 수강권별 조회
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1197/src/docs/Member.md#%EC%88%98%EC%97%85%EB%A3%8C-%EC%A0%95%EC%82%B0-%EC%88%98%EA%B0%95%EA%B6%8C%EB%B3%84-%EC%A1%B0%ED%9A%8C
 */
const getClassFeeSettlementForMembership = async ({
    centerId,
    startDate,
    endDate,
    selectedCoach,
    accessToken,
}: GetClassFeeSettlementParamsType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/members/center/${centerId}/bymembership?startDate=${startDate}&endDate=${endDate}&selectedCoach=${selectedCoach}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 관리자가 임시회원 생성
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1180/src/docs/Member.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EA%B0%80-%ED%9A%8C%EC%9B%90-%EC%83%9D%EC%84%B1
 */
const createTempMember = async ({
    accessToken,
    centerId,
    tempMember,
}: CreateTempMemberParamsType & { accessToken: string }): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;

    const data = await createDataObject(tempMember);
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/members/center/${centerId}`,
            headers: { authorization: accessToken },
            data,
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 413: // 이미 있는경우 튕겨내야함
                return { status: error.response.status };
            case 414: // 이미 연결대기상태인 경우 튕겨내야함
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method delete
 * @brief 관리자가 임시회원 삭제
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1180/src/docs/Member.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EA%B0%80-%ED%9A%8C%EC%9B%90-%EC%82%AD%EC%A0%9C
 */
const deleteTempMember = async ({ memberId, centerId, accessToken }: DeleteTempMemberParamsType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/members/center/${centerId}/member/${memberId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method get
 * @brief 관리자 회원 등록시 휴대폰 인증
 * @linkhttps://github.com/mustMoveProject/rappo-project/blob/RAPPO-1180/src/docs/Member.md#%EA%B4%80%EB%A6%AC%EC%9E%90-%ED%9A%8C%EC%9B%90-%EB%93%B1%EB%A1%9D%EC%8B%9C-%ED%9C%B4%EB%8C%80%ED%8F%B0-%EC%9D%B8%EC%A6%9D
 * @description
 * 413 , 202 가 있음. 413 에서는 우리 센터에 등록되어 있는 경우여서 막아야 하고, 어떤 회원때문에 그런지 duplicateMember 로 내려준다.
 * 202 인 경우는 센터에서 등록하지 않은 회원이므로 가입할 수 있다.
 * 확인만 하는 API 이다
 * param에 000-0000-0000 형식으로 보내주시면 됩니다
 */
const getAuthentificationPhoneNumber = async ({
    centerId,
    phoneNumber,
    accessToken,
}: {
    centerId: string;
    phoneNumber: string;
    accessToken: string;
}): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/members/center/${centerId}/phone-number-check/${phoneNumber}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 202) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 413:
                // return { status: error.response.status };

                return { status: error.response.status, data: error.response.data };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export type CreateTempMemberParamsType = {
    centerId: string;
    tempMember: TempMemberType;
};
export type DeleteTempMemberParamsType = {
    accessToken: string;
    centerId: string;
    memberId: string;
};

type WeightRecord = {
    value: number;
    recordDate: string; // ISO date string format
};

type TempMemberType = {
    name: string;
    coachId: string;
    phoneNumber: string;
    gender?: GENDERCODETYPE;
    height?: number;
    weight?: number;
    address?: string;
    birthday?: string; // ISO date string format
    membershipInfo?: MemberShipApiType[];
    isSendAlimtalkToMember?: boolean; // 알림톡 전송 여부
    memo?: string;
};

export type GetClassFeeSettlementParamsType = {
    centerId: string;
    startDate: string;
    endDate: string;
    selectedCoach: string | 'all';
    accessToken: string;
};
