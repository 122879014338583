import React from 'react';
import { colors } from '../lib/colors';
import { Row } from '../lib/utils';
import SvgIcon from './SvgIcon';

type Props = {
    imageUrl: string | undefined;
    className?: string;
    size?: number;
};

const NewProfileImage = ({ imageUrl, className, size }: Props) => {
    const width = size ? size : 20;
    const height = size ? size : 20;
    return (
        <Row className={className} style={{ width, height, overflow: 'hidden' }}>
            {imageUrl ? (
                <img
                    src={imageUrl}
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                    }}
                />
            ) : (
                <SvgIcon name={'SvgProfileInGreyCircle'} fill="#D2D8DE" size={size} strokeWidth={1} />
            )}
        </Row>
    );
};

export default NewProfileImage;
