import React from 'react';
import clsx from 'clsx';

interface EventCellProps {
    style?: React.CSSProperties;
    className?: string;
    event: any;
    selected?: boolean;
    isAllDay?: boolean;
    onSelect?: (event: any, e: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick?: (event: any, e: React.MouseEvent<HTMLDivElement>) => void;
    onKeyPress?: (event: any, e: React.KeyboardEvent<HTMLDivElement>) => void;
    continuesPrior?: boolean;
    continuesAfter?: boolean;

    children?: React.ReactNode | ((content: React.ReactNode) => React.ReactNode);
    components: {
        event?: React.ComponentType<any>;
        eventWrapper: React.ComponentType<any>;
    };
    slotStart?: Date;
    slotEnd?: Date;
    resizable?: boolean; // Remove from props explicitly as it's unused in rendering

    monthCustomEventComponent?: (event: any) => React.ReactNode;
}

const EventCell: React.FC<EventCellProps> = ({
    style,
    className,
    event,
    selected = false,
    isAllDay = false,
    onSelect,
    onDoubleClick,
    onKeyPress,
    continuesPrior = false,
    continuesAfter = false,
    children,
    // components: { event: Event, eventWrapper: EventWrapper },
    slotStart,
    slotEnd,
    monthCustomEventComponent,
    ...props
}) => {
    const title = event?.title;
    const tooltip = event?.tooltip;
    const start = event?.start;
    const end = event?.end;
    const allDay = event?.allDay;

    const showAsAllDay = isAllDay || allDay;

    // const userProps = getters.eventProp(event, start, end, selected);

    // const content = (
    //     <div className="rbc-event-content" title={tooltip || undefined}>
    //         {Event ? (
    //             <Event
    //                 event={event}
    //                 continuesPrior={continuesPrior}
    //                 continuesAfter={continuesAfter}
    //                 title={title}
    //                 isAllDay={allDay}
    //                 slotStart={slotStart}
    //                 slotEnd={slotEnd}
    //             />
    //         ) : (
    //             title
    //         )}
    //     </div>
    // );
    const isMonthsCustomEventComponent = monthCustomEventComponent ? true : false;

    return (
        <div className="rbc-event-content flex  w-full h-full justify-center items-center z-20 ">
            {isMonthsCustomEventComponent ? (
                <div className="flex w-full h-full ">
                    {monthCustomEventComponent && monthCustomEventComponent(event)}
                </div>
            ) : (
                <div
                    // style={{ ...userProps.style, ...style }}
                    // className={clsx('rbc-event', {
                    //     'rbc-selected': selected,
                    //     'rbc-event-allday': showAsAllDay,
                    //     'rbc-event-continues-prior': continuesPrior,
                    //     'rbc-event-continues-after': continuesAfter,
                    // })}
                    className=" justify-center items-center w-full h-full  "
                    onClick={(e) => {
                        console.log('fewfe');
                    }}
                    onDoubleClick={(e) => onDoubleClick && onDoubleClick(event, e)}
                    onKeyDown={(e) => onKeyPress && onKeyPress(event, e)}
                >
                    {title}
                    {/* {typeof children === 'function' ? children(content) : content} */}
                </div>
            )}
        </div>
    );
};

export default EventCell;
