import React, { useState } from 'react';
import LogoButton from '../Button/LogoButton';
import TextButtonDropDown from '../DropDown/TextButtonDropDown';
import { Col, Row, getItemWithExpiration } from '../../lib/utils';
import { colors } from '../../lib/colors';
import ColorRoundButton from '../Button/ColorRoundButton';
import ProfileImage from '../ProfileImage';
import { CaptionRegular, OverlineLight } from '../../lib/font';
import { useLocation, useNavigate } from 'react-router-dom';
import { I_CIRCLE_QUESTION } from '../../types/images';
import GnbMyInfo from './GnbMyInfo';

type Props = {
    onClickMemberRegister: () => void;
    onClickTerm: () => void;
};

const Gnb = ({ onClickMemberRegister, onClickTerm }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [isVisibleInfo, setIsVisibleInfo] = useState<boolean>(false);

    const onClickToggleModal = () => {
        setIsVisibleInfo(!isVisibleInfo);
    };

    let memberManage = [
        { nav: 'memberList', name: '회원 리스트', callBack: () => navigate('/memberList') },
        { nav: 'memberCommunication', name: '회원 소통 상자', callBack: () => navigate('/member/communication') },
    ];

    let scheduleManage = [
        { nav: 'calendar', name: '스케줄 캘린더', callBack: () => navigate('/schedule') },
        { nav: 'scheduleHistory', name: '스케줄 변경 / 취소', callBack: () => navigate('/schedule/history') },
    ];

    return (
        <div className="flex fixed h-[56px] flex-row top-0 z-[49] w-screen justify-between   items-center bg-white ">
            {/* Logo Button */}

            {/* Text Buttons */}
            <div className="relative flex items-center h-full ">
                <LogoButton
                    callBack={() => {
                        navigate('/dash');
                    }}
                />
                <TextButtonDropDown
                    isActive={location.pathname.includes('member')}
                    list={memberManage}
                    text={'회원 관리'}
                    isDropDown={true}
                />
                <TextButtonDropDown
                    isActive={location.pathname.includes('trainerManage')}
                    text={'강사 관리'}
                    callBack={() => navigate('/trainerManage')}
                    isDropDown={false}
                />
                <TextButtonDropDown
                    isActive={location.pathname.includes('schedule')}
                    text={'스케줄 관리'}
                    list={scheduleManage}
                    isDropDown={true}
                />
                <TextButtonDropDown
                    text={'수업 피드'}
                    isDropDown={false}
                    callBack={() => navigate('/feed')}
                    isActive={location.pathname.includes('feed')}
                />
                <TextButtonDropDown
                    text={'수강권 관리'}
                    isDropDown={false}
                    isActive={location.pathname.includes('classPass')}
                    callBack={() => navigate('/classPass')}
                />
                <TextButtonDropDown
                    text={'매출 정산'}
                    isDropDown={false}
                    isActive={location.pathname.includes('settlement')}
                    callBack={() => navigate('/settlement/password')}
                />
            </div>

            {/* Right Buttons */}
            <div className="  flex items-center ">
                <Row
                    className="cursor-pointer flex items-center "
                    onClick={() =>
                        window.open('https://mustmove.notion.site/568797c259034056a29b7fe29d7de7fa?pvs=4', '_blank')
                    }
                >
                    <img src={I_CIRCLE_QUESTION} className="w-[16px] h-[16px] mr-[8px]" />
                    <CaptionRegular className="text-primary cursor-pointer mr-[24px]">라포 이용방법</CaptionRegular>
                </Row>

                <ColorRoundButton callBack={onClickMemberRegister} isGradient={true} size={'tiny'} text={'회원등록'} />
                <div className="w-px h-6 bg-gray-300 ml-6"></div>

                {/* MyInfo Button */}
                <button
                    type="button"
                    className={`flex items-center w-[245px] h-16 pl-8 rounded-md transition-colors ${
                        isVisibleInfo ? 'bg-blue-100' : 'bg-blue-50 hover:bg-blue-100'
                    }`}
                    onClick={onClickToggleModal}
                >
                    <ProfileImage
                        type="Small"
                        imageUrl={authObject?.profileImageUrl}
                        profileName={authObject?.profileName}
                        profileColorType={authObject?.profileColorType}
                        right={8}
                    />
                    <div className="flex flex-col ml-4 text-left">
                        <CaptionRegular className="text-gray-600">{`${authObject?.name} 님`}</CaptionRegular>
                        <OverlineLight className="text-gray-500">{`${authObject?.centerName} 센터`}</OverlineLight>
                    </div>
                </button>

                {/* GnbMyInfo Modal */}
                {isVisibleInfo && (
                    <GnbMyInfo
                        authObject={authObject}
                        setIsVisibleInfo={setIsVisibleInfo}
                        navigate={navigate}
                        onClickTerm={onClickTerm}
                    />
                )}
            </div>
        </div>
    );
};

export default Gnb;
