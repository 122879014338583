import {
    getCenterMemberListAPI,
    GetCenterMemberListRemainingPeriod,
    GetCenterMemberListRemainingSessions,
    GetCenterMemberListStatus,
    getCenterRemainingScheduleFee,
    getCenterRemainingScheduleSession,
    getCenterSalesSettlementCalendar,
    getCoachNameListAPI,
    getMemberScheduleHistory,
} from '../api/center';

export const fetchGetCenterMemberList = (
    params: {
        centerId: string;
        memberStatus?: GetCenterMemberListStatus;
        remainingPeriod?: GetCenterMemberListRemainingPeriod;
        remainingSessions?: GetCenterMemberListRemainingSessions;
        coach?: string;
    },
    authorization: string
) => ({
    queryKey: [
        'getCenterMemberListQuery',
        params?.memberStatus,
        params?.remainingPeriod,
        params?.remainingSessions,
        params?.coach,
    ],
    queryFn: () =>
        getCenterMemberListAPI(
            params.centerId,
            params.memberStatus ?? 'all',
            params.remainingPeriod ?? 'all',
            params.remainingSessions ?? 'all',
            params.coach ?? 'all',
            authorization
        ),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.members ?? [];
    },
});

export const fetchGetMemberScheduleHistory = (
    params: {
        centerId?: string;
        coachId?: string;
        memberId?: string;
        date?: string;
    },
    authorization: string
) => ({
    queryKey: ['getMemberScheduleHistoryQuery', params?.centerId, params?.coachId, params?.memberId, params?.date],
    queryFn: async () =>
        await getMemberScheduleHistory(params.centerId, params.coachId, params.memberId, params.date, authorization),
    // onSuccess: (data: any) => {
    //     // 데이터 정제 로직
    //     return data?.data ?? undefined;
    // },
    staleTime: 0,
});

export const fetchCoachNameList = (params: { centerId: string; isAllConcat?: boolean }, authorization: string) => ({
    queryKey: ['getCoachListForCenterQuery', params.centerId],
    queryFn: () => getCoachNameListAPI(params.centerId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        if (params.isAllConcat) {
            return dropDownCoachList(data?.data?.coachNameList) ?? [];
        } else {
            return dropDownCoachListWithAll(data?.data?.coachNameList) ?? [];
        }
    },
});
export const fetchCenterSalesSettlementCalendar = (
    params: { centerId: string; targetMonth: string; targetCoach: string | 'all' },
    authorization: string
) => ({
    queryKey: ['getCenterSalesSettlementCalendarQuery', params.centerId, params.targetMonth, params.targetCoach],
    queryFn: () =>
        getCenterSalesSettlementCalendar(params.centerId, params.targetMonth, params.targetCoach, authorization),
    onSuccess: (data: any) => {
        return data?.data ?? {};
    },
});
export const fetchGetCenterRemainingScheduleFee = (
    params: {
        centerId: string;
        targetMonth: string;
        targetCoach: string;
    },
    authorization: string
) => ({
    queryKey: ['getCenterRemainingScheduleFeeQuery', params.centerId, params.targetMonth, params.targetCoach],
    queryFn: () =>
        getCenterRemainingScheduleFee(params.centerId, params.targetMonth, params.targetCoach, authorization),
    onSuccess: (data: any) => {
        return data?.data?.totalRemainingScheduleFee ?? undefined;
    },
});

export const fetchGetCenterRemainingScheduleSession = (
    params: {
        centerId: string;
        targetMonth: string;
        targetCoach: string;
    },
    authorization: string
) => ({
    queryKey: ['getCenterRemainingScheduleSessionQuery', params.centerId, params.targetMonth, params.targetCoach],
    queryFn: () =>
        getCenterRemainingScheduleSession(params.centerId, params.targetMonth, params.targetCoach, authorization),
    onSuccess: (data: any) => {
        return data?.data?.totalRemainSession ?? undefined;
    },
});

export // all, 전체 포함 시켜서 리턴
const dropDownCoachListWithAll = (coachListData: GetCoachName[]) =>
    [{ code: 'all', value: '전체' }].concat(
        coachListData.map((coach: GetCoachName) => ({
            code: coach.coachId,
            value: coach.name,
            object: coach,
        }))
    );
export // all, 전체 포함 시켜서 리턴
const dropDownCoachList = (coachListData: GetCoachName[]) =>
    coachListData.map((coach: GetCoachName) => ({
        code: coach.coachId,
        value: coach.name,
        object: coach,
    }));

export type GetCoachName = {
    coachId: string;
    name: string;
};

export type DaySchedule = {
    [day: string]: {
        schedules: MemberClassHistorySchedule[];
        dayScheduleCount: number;
    }[];
};

export type MonthSchedule = {
    [month: string]: DaySchedule[];
};

export type YearSchedule = {
    [year: string]: MonthSchedule;
};

export type MonthlySchedule = {
    success: boolean;
    thisMonthScheduleCount: number;
    thisYearScheduleCount: number;
    allScheduleCount: number;
    thisMonth: MemberClassHistorySchedule[];
    thisYear: YearSchedule;
    totalView: YearSchedule[];
};

export type MemberClassHistorySchedule = {
    scheduleBundleId: string | null;
    memo: string;
    conditionId: string;
    postId: string;
    membershipId: string;
    attendanceStatus: 'NOSHOW' | 'ATTENDANCE' | 'ABSENT';
    isDeleted: boolean;
    isCanceled: boolean;
    coachId: string;
    memberId: string;
    alias: string;
    startTime: string;
    endTime: string;
    requestDeadline: string;
    issuer: {
        issuerId: string;
        issuerRole: 'center' | 'coach' | 'admin';
    };
    createdAt: string;
    updatedAt: string;
    __v: number;
    id: string;
    currentSession: number;
    totalSession: number;
    membershipCount: number;
    membershipName: string;
};

// 월별 총 합계 타입
type MonthlyTotal = {
    monthlyIndex: number; // 1~12: 몇 월인지
    totalSales: number; // 총 매출액
    totalScheduleFee: number; // 수업료
    totalRemainingScheduleFee: number; // 미수업 금액
    totalPaidScheduleCount: number; // 결제 수업 수
    totalScheduleCount: number; // 잡힌 수업 수
    totalNoshowScheduleCount: number; // 노쇼 수업 수
};

// 주별 합계 타입
export type WeeklyTotal = {
    weeklyIndex: number; // 몇 주차인지 1~6
    weeklySales: number; // 매출금액
    weeklyPaidScheduleCount: number; // 결제 수업 수
    weeklyScheduleCount: number; // 확정 수업 수
    weeklyNoshowScheduleCount: number; // 노쇼 수업 수
};

// 매출 금액 상세 타입
type SalesInfo = {
    paidSalesCount: number; // 결제 건수
    cancelSalesCount: number; // 취소 건수
    paidSales: number; // 결제 금액
    cancelSales: number; // 취소 금액
    sales: number; // 매출 금액
};

// 결제 수업 상세 타입
type PaidScheduleInfo = {
    paidScheduleCount: number; // 결제 등록 수업 수
    paidMemberCount: number; // 결제 회원 수
    newPaymentCount: number; // 신규 결제 수
    reRegistrationPaymentCount: number; // 재등록 결제 수
    partialPaymentCount: number; // 분할 결제 수
};

// 완료한 수업 상세 타입
type ScheduleCountInfo = {
    scheduleCount: number; // 총 수업 수
    attendanceScheduleCount: number; // 출석 완료 수업 수
    noshowScheduleCount: number; // 노쇼 수업 수
};

// 일별 정보 타입
export type DailyInfo = {
    start: string; // 며칠인지 1~31
    end: string; // 며칠인지 1~31
    salesInfo: SalesInfo; // 매출 금액 상세
    paidScheduleInfo: PaidScheduleInfo; // 결제 수업 상세
    scheduleCountInfo: ScheduleCountInfo; // 완료한 수업 상세
};

// 메인 데이터 타입
export type SalesSummary = {
    monthlyTotal: MonthlyTotal; // 월별 총 합계
    weeklyTotals: WeeklyTotal[]; // 주별 합계 배열
    dailyInfos: DailyInfo[]; // 일별 정보 배열
};
