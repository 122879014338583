import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, Row } from '../../lib/utils';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import { colors } from '../../lib/colors';
type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    selectedEtcBonus?: { name: string; amount: number };
    onClickConfirm?: (etc: { name: string; amount: number }, type: 'new' | 'update') => void;
};

const ModalAddEtcSalary = ({ setIsVisible, selectedEtcBonus, onClickConfirm }: Props) => {
    const [etcName, setEtcName] = useState(selectedEtcBonus?.name || '기타 수당');
    const [etcAmount, setEtcAmount] = useState(selectedEtcBonus?.amount || 0);

    // name 포커싱 여부
    const [isNameFocused, setIsNameFocused] = useState(false);
    // amount 포커싱 여부
    const [isAmountFocused, setIsAmountFocused] = useState(false);

    // disable
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (etcName && etcAmount) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [etcName, etcAmount]);
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && !isDisabled && onClickConfirm) {
                setIsVisible(false);
                onClickConfirm({ name: etcName, amount: etcAmount }, selectedEtcBonus ? 'update' : 'new');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isDisabled, etcName, etcAmount, onClickConfirm, selectedEtcBonus, setIsVisible]);

    // input 포커싱
    useEffect(() => {
        etcNameRef.current?.focus();
    }, []);

    const etcNameRef = React.useRef<HTMLInputElement>(null);
    const etcAmountRef = React.useRef<HTMLInputElement>(null);
    return (
        <Modal setIsVisible={setIsVisible} whiteViewWidth={495} borderRadius={15}>
            <Col className="py-[36px] px-[33px]">
                <MediumText
                    fontSize={22}
                    lineHeight={38}
                    letterSpacing={-0.32}
                    color={colors.Text_1}
                    className="mb-[27px]"
                >
                    기타 수당 추가하기
                </MediumText>

                <Col className="mb-[31px]">
                    <RegularText
                        fontSize={16}
                        lineHeight={23}
                        letterSpacing={-0.32}
                        color={colors.Text_1}
                        className="mb-[9px]"
                    >
                        기타 수당 이름
                    </RegularText>
                    <input
                        ref={etcNameRef}
                        value={etcName}
                        onChange={(e) => setEtcName(e.target.value)}
                        className={`font-noto-demilight text-[17px] leading-[24px] text-[#3D4244] bg-trans  mr-[5px] border border-[#D9D9D9B2] p-[23px] rounded-[10px] hover:border-primary transition-colors duration-300 ease-in-out
                            ${isNameFocused ? 'border-primary' : ''}
                            `}
                        maxLength={15}
                        placeholder="기타 수당"
                        onFocus={() => setIsNameFocused(true)}
                        onBlur={() => setIsNameFocused(false)}
                    />
                </Col>
                <Col>
                    <RegularText
                        fontSize={16}
                        lineHeight={23}
                        letterSpacing={-0.32}
                        color={colors.Text_1}
                        className="mb-[9px]"
                    >
                        기타 수당 금액
                    </RegularText>
                    <Row
                        className="w-full  border border-solid border-[#D9D9D9B2] rounded-[9px] p-[23px] justify-between mr-[20px] mb-[48px] cursor-text hover:border-primary transition-colors duration-300 ease-in-out"
                        onClick={() => etcAmountRef.current?.focus()}
                    >
                        <input
                            ref={etcAmountRef}
                            value={etcAmount || ''}
                            onChange={(e) => setEtcAmount(Number.parseInt(e.target.value))}
                            className={`font-noto-demilight text-[17px] leading-[24px] text-[#3D4244] bg-trans  mr-[5px] w-full
                                ${isAmountFocused ? 'border-primary' : ''}
                                `}
                            maxLength={15}
                            placeholder="기타 수당 금액을 입력해주세요"
                            onFocus={() => setIsAmountFocused(true)}
                            onBlur={() => setIsAmountFocused(false)}
                        />
                        <DemiLightText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#71757C">
                            원
                        </DemiLightText>
                    </Row>
                </Col>
                <Row className="items-center self-end">
                    <Col
                        className="bg-GREY w-[110px] h-[41px] justify-center items-center rounded-[9px] mr-[9px] cursor-pointer"
                        onClick={() => setIsVisible(false)}
                    >
                        <RegularText fontSize={16} lineHeight={24} letterSpacing={-0.32} color={colors.Text_1}>
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className={`bg-primary w-[110px] h-[41px] justify-center items-center rounded-[9px] 
                            ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                            `}
                        onClick={() => {
                            if (!isDisabled && onClickConfirm) {
                                setIsVisible(false);
                                onClickConfirm(
                                    { name: etcName, amount: etcAmount },
                                    selectedEtcBonus ? 'update' : 'new'
                                );
                            }
                        }}
                    >
                        <RegularText fontSize={16} lineHeight={24} letterSpacing={-0.32} color={colors.white}>
                            적용하기
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalAddEtcSalary;
