import { navigate } from './constants';
import Views from '../lib/Views';
import { NavigateAction, ViewStatic } from '../types/types';
import dayjs, { Dayjs } from 'dayjs';

export interface MoveOptions {
    action: NavigateAction;
    date: Date;
    today: Date;
}

export const moveDate = (
    view: string,
    { action, date, today }: { action: string; date: Date | string | Dayjs; today: Date }
): Date | string | Dayjs => {
    switch (action) {
        case 'TODAY':
            return today; // 오늘 날짜로 이동
        case 'NEXT':
            if (view === 'month') {
                return dayjs(date).add(1, 'month').toDate(); // 다음 달로 이동
            } else if (view === 'week') {
                return dayjs(date).add(1, 'week').toDate(); // 다음 주로 이동
            }
            return dayjs(date).add(1, 'day').toDate(); // 기본적으로 다음 날로 이동
        case 'PREV':
            if (view === 'month') {
                return dayjs(date).subtract(1, 'month').toDate(); // 이전 달로 이동
            } else if (view === 'week') {
                return dayjs(date).subtract(1, 'week').toDate(); // 이전 주로 이동
            }
            return dayjs(date).subtract(1, 'day').toDate(); // 기본적으로 이전 날로 이동
        case 'DATE':
            return date; // 주어진 날짜로 이동
        default:
            return date; // 기본적으로 현재 날짜 유지
    }
};
