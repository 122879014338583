import React from 'react';
import { Col, Row } from '../../lib/utils';
import { MediumText, RegularText } from '../../lib/Text';
import { MenuType } from '../Schedule/SettlementPage';
import SvgIcon from '../../components/SvgIcon';

type Props = {
    selectedMenu: MenuType;
    onMenuClick: (menuType: MenuType) => void;
    onCommissionClick: () => void;
};

const menuList = [
    {
        title: '매출 달력',
        menuType: 'calendar',
    },
    {
        title: '수업료 정산',
        menuType: 'classSales',
    },

    {
        title: '매출 목록',
        menuType: 'salesList',
    },
    {
        title: '미수금 목록',
        menuType: 'unpaidList',
    },
    {
        title: '급여 계산',
        menuType: 'salaryCalculation',
    },
];

const SettlementHeader = ({ selectedMenu, onMenuClick, onCommissionClick }: Props) => {
    return (
        <Row className="mt-[40px] mb-[33px] self-start items-center">
            {menuList.map((menu, index) => (
                <Col key={index} className="mr-[42px]" onClick={() => onMenuClick(menu.menuType as MenuType)}>
                    <MediumText
                        fontSize={25}
                        lineHeight={32}
                        color={selectedMenu === menu.menuType ? '#3D4244' : '#B6B8BA'}
                        className="mb-[8px] cursor-pointer"
                    >
                        {menu.title}
                    </MediumText>
                    {selectedMenu === menu.menuType && <div className={`w-full h-[5px] bg-[#3D4244]`} />}
                </Col>
            ))}

            <Row
                className="bg-white rounded-[10px] justify-center items-center flex-row flex px-[14px] py-[8px] cursor-pointer"
                onClick={onCommissionClick}
            >
                <SvgIcon name={'SvgSetting'} size={17} fill={'#5C626A'} className="mr-[9px]" />
                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#42484D">
                    수업 정산 비율 설정
                </RegularText>
            </Row>
        </Row>
    );
};

export default SettlementHeader;
