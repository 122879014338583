import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../modal/Modal';
import { BoldText, RegularText } from '../../lib/Text';
import { Col, Row } from '../../lib/utils';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onClickNoShow: () => void;
};

const ModalNoShowAlert = ({ setIsVisible, onClickNoShow }: Props) => {
    return (
        <Modal setIsVisible={setIsVisible} whiteViewWidth={685} whiteViewHeight={385} borderRadius={25}>
            <Col className="p-[35px]">
                <BoldText fontSize={28} lineHeight={38} color="#3D4244" className="mb-[38px]">
                    노쇼로 변경하시겠습니까?
                </BoldText>
                <RegularText fontSize={22} lineHeight={38} color="#3D4244">
                    변경 시 회원에게 노쇼 변경에 대한 푸시 알림이 전송됩니다.
                </RegularText>
                <Row className=" absolute bottom-0 right-0 p-[35px]">
                    <Col
                        className=" justify-center items-center w-[119px] h-[51px] bg-[#F2F4F6] rounded-[11px] cursor-pointer mr-[18px]"
                        onClick={() => setIsVisible(false)}
                    >
                        <RegularText fontSize={20} lineHeight={28} letterSpacing={-3} color="#3D4244">
                            아니오
                        </RegularText>
                    </Col>
                    <Col
                        className=" justify-center items-center w-[119px] h-[51px] bg-primary rounded-[11px] cursor-pointer"
                        onClick={onClickNoShow}
                    >
                        <RegularText fontSize={20} lineHeight={28} letterSpacing={-3} color="white">
                            네
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalNoShowAlert;
