import { getEmployeeInfoAtManageAPI } from '../api/employee';
import { getSalesCommission } from '../api/salesCommision';

export const fetchSalesCommissionQuery = (
    params: {
        year: number;
        month: number;
        centerId: string;
    },
    authorization: string
) => ({
    queryKey: ['getSalesCommissionQuery', params.year, params.month, params.centerId],
    queryFn: () => getSalesCommission(params.year, params.month, params.centerId, authorization),
    onSuccess: (data: any) => {
        const result: SalesCommission[] = data?.data?.salesCommissions;
        return result;
    },
});

// {
//     "coachId": "65814845ca42a00012a2d5d9",
//     "coachName": "qw52",
//     "commissionType": "fixed",
//     "scheduleCommission": 24000,
//     "reRegisterCommission": 25000,
//     "noshowCommission": 60000
// }

type CommissionType = 'fixed' | 'percentage';

export type SalesCommission = {
    coachId: string;
    coachName: string;
    commissionType: CommissionType;
    scheduleCommission: string;
    reRegisterCommission?: string | null;
    noshowCommission?: string | null;
};
