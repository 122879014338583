import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';

type Props = {
    children: React.ReactNode;
    whiteViewWidth?: number;
    whiteViewHeight?: number;
    setIsVisible?: Dispatch<SetStateAction<boolean>>;
    isCloseButton?: boolean;
    isCloseOutside?: boolean;
    onClose?: any;
    borderRadius?: number;
    heightFull?: boolean;
    modalCommpont?: React.ReactNode;
    modalAlign?: 'center' | 'start';
    mt?: number;
    isWhiteOverFlowScroll?: boolean;
};
const Modal = ({
    children,
    whiteViewWidth,
    whiteViewHeight,
    setIsVisible,
    isCloseButton = false,
    isCloseOutside = true,
    onClose,
    borderRadius,
    heightFull,
    modalCommpont,
    modalAlign = 'center',
    mt,
    isWhiteOverFlowScroll = true,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node) && isCloseOutside) {
            // modalCommpont
            //     ? undefined
            //     : onClose
            //     ? onClose()
            //     : setIsVisible && setIsVisible(false);
            if (modalCommpont) {
                return;
            } else if (onClose) {
                onClose();
            } else {
                setIsVisible && setIsVisible(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
        // document.body.style.overflow = 'auto'; // Allow scrolling

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsVisible && setIsVisible(false);
        }
    };
    // const handleBackgroundScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    //     // 모달 외부 스크롤 시 id="App"에 스크롤 이벤트 적용
    //     if (event.target === event.currentTarget) {
    //         const appElement = document.getElementById('App');
    //         if (appElement) {
    //             appElement.scrollTop += event.deltaY;
    //         }
    //     }
    // };
    return (
        <div
            className={`fixed  w-full h-full bg-[#00000047] flex overflow-y-auto   ${
                modalAlign === 'center' ? 'justify-center items-center top-0 left-0 ' : ' justify-center items-start'
            }`}
            // onWheel={handleBackgroundScroll} // 배경 스크롤 이벤트 추가
            style={{
                zIndex: 60,
            }}
        >
            <div
                ref={ref}
                style={{
                    width: whiteViewWidth,
                    height: whiteViewHeight,
                    position: 'relative',
                    borderRadius: borderRadius || 8,
                    boxShadow: '8px 8px 24px 0px #00000052',
                    marginTop: mt,
                }}
                className={` ${heightFull ? 'h-full' : ''}  bg-white rounded-[8px]
                ${modalAlign === 'center' ? '' : 'mt-[60px]'}
                ${isWhiteOverFlowScroll ? 'overflow-y-auto' : ''}
                `}
            >
                {isCloseButton && (
                    <SvgIcon
                        className=" absolute right-[27px] top-[27px]  cursor-pointer"
                        name={'SvgX'}
                        stroke={'#A5ABB5'}
                        size={40}
                        onClick={onClose ? onClose : () => setIsVisible && setIsVisible(false)}
                    />
                )}

                {children}
            </div>
            {modalCommpont && modalCommpont}
        </div>
    );
};

export default Modal;
