import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_ALBUM, I_CLIP, I_VIDEO_PLAY } from '../../../types/images';
import { Body1Regular, Body3Regular } from '../../../lib/font';
import styled from 'styled-components';

const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 4px;
`;

const BigContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4;
    background-color: #d4dbe8;
`;

const MiddleContainer = styled.div`
    width: 372px;
    height: 424px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #d4dbe8;
`;

const SmallContainer = styled.div`
    width: 372px;
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #d4dbe8;
`;

const VideoImage = styled.img`
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 1;
`;

const FeedImageItem = ({ imageVideoList, index, fileObject, onClickDetail }: any) => {
    return (
        <Row
            onClick={onClickDetail}
            style={{
                width: 786,
                paddingLeft: 16,
                paddingRight: 16,
                height: 424,
                borderRadius: 4,

                marginTop: 24,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {imageVideoList?.length === 1 && (
                <BigContainer>
                    <Image
                        src={
                            imageVideoList[0]?.contentType === 'VIDEO'
                                ? imageVideoList[0]?.thumbnail
                                : imageVideoList[0]?.content
                        }
                        style={{ objectFit: 'contain' }}
                    />
                    {imageVideoList[0]?.contentType === 'VIDEO' && <VideoImage src={I_VIDEO_PLAY} />}
                </BigContainer>
            )}
            {imageVideoList?.length === 2 && (
                <>
                    <MiddleContainer>
                        <Image
                            src={
                                imageVideoList[0]?.contentType === 'VIDEO'
                                    ? imageVideoList[0]?.thumbnail
                                    : imageVideoList[0]?.content
                            }
                            style={{ objectFit: 'contain' }}
                        />
                        {imageVideoList[0]?.contentType === 'VIDEO' && <VideoImage src={I_VIDEO_PLAY} />}
                    </MiddleContainer>
                    <MiddleContainer>
                        <Image
                            src={
                                imageVideoList[1]?.contentType === 'VIDEO'
                                    ? imageVideoList[1]?.thumbnail
                                    : imageVideoList[1]?.content
                            }
                            style={{ objectFit: 'contain' }}
                        />
                        {imageVideoList[1]?.contentType === 'VIDEO' && <VideoImage src={I_VIDEO_PLAY} />}
                    </MiddleContainer>
                </>
            )}

            {imageVideoList?.length > 2 && (
                <>
                    <MiddleContainer>
                        <Image
                            src={
                                imageVideoList[0]?.contentType === 'VIDEO'
                                    ? imageVideoList[0]?.thumbnail
                                    : imageVideoList[0]?.content
                            }
                            style={{ objectFit: 'contain' }}
                        />
                        {imageVideoList[0]?.contentType === 'VIDEO' && <VideoImage src={I_VIDEO_PLAY} />}
                    </MiddleContainer>
                    <MiddleContainer style={{ justifyContent: 'space-between' }}>
                        <SmallContainer>
                            <Image
                                src={
                                    imageVideoList[1]?.contentType === 'VIDEO'
                                        ? imageVideoList[1]?.thumbnail
                                        : imageVideoList[1]?.content
                                }
                                style={{ objectFit: 'contain' }}
                            />
                            {imageVideoList[1]?.contentType === 'VIDEO' && <VideoImage src={I_VIDEO_PLAY} />}
                        </SmallContainer>
                        <SmallContainer>
                            <Image
                                src={
                                    imageVideoList[2]?.contentType === 'VIDEO'
                                        ? imageVideoList[2]?.thumbnail
                                        : imageVideoList[2]?.content
                                }
                                style={{ objectFit: 'contain' }}
                            />
                            {imageVideoList[2]?.contentType === 'VIDEO' && <VideoImage src={I_VIDEO_PLAY} />}
                        </SmallContainer>
                    </MiddleContainer>
                </>
            )}
            {imageVideoList?.length > 3 && (
                <Row
                    style={{
                        position: 'absolute',
                        width: 84,
                        height: 36,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#1B1E3199',
                        borderRadius: 31,
                        marginLeft: 658,
                        marginTop: 350,
                        // opacity: 0.6,
                    }}
                >
                    <img src={I_ALBUM} style={{ width: 24, height: 24, marginRight: 4 }} />
                    <Body3Regular style={{ color: colors.WHITE_50 }}>{`${imageVideoList.length}장`}</Body3Regular>
                </Row>
            )}
            {fileObject && fileObject?.length > 0 && imageVideoList?.length > 0 && (
                <Row
                    style={{
                        position: 'absolute',
                        width: 84,
                        height: 36,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#1B1E3199',
                        borderRadius: 31,
                        marginLeft: 554,
                        marginTop: 350,
                        // opacity: 0.6,
                    }}
                >
                    <img src={I_CLIP} style={{ width: 24, height: 24, marginRight: 4 }} />
                    <Body3Regular style={{ color: colors.WHITE_50 }}>{`${fileObject.length}개`}</Body3Regular>
                </Row>
            )}
        </Row>
    );
};

export default FeedImageItem;
