import axios, { AxiosResponse } from 'axios';
import { createDataObject } from '../lib/utils';
import { CommissionType } from './salesCommision';

export /**
 * @method get
 * @brief 급여 계산 조회
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1201/src/docs/SalaryCalc.md#%EA%B8%89%EC%97%AC-%EA%B3%84%EC%82%B0-%EC%A1%B0%ED%9A%8C
 */
const getSalaryCalcs = async ({
    year,
    month,
    selectedCoachId,
    centerId,
    accessToken,
}: GetSalaryCalcsParams): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/salarycalcs/center/${centerId}?year=${year}&month=${month}&selectedCoachId=${selectedCoachId}`,
            headers: {
                authorization: accessToken,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method post
 * @brief 급여 계산 수정
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1201/src/docs/SalaryCalc.md#%EA%B8%89%EC%97%AC-%EA%B3%84%EC%82%B0-%EC%88%98%EC%A0%95
 */
const postSalaryCalcs = async ({
    year,
    month,
    incentive,
    incentiveType,
    baseSalary,
    etcBonus,
    isTaxable,
    selectedCoachId,
    centerId,
    accessToken,
}: PostSalaryCalcsParams & { accessToken: string; centerId: string }): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;

    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/salarycalcs/center/${centerId}?selectedCoachId=${selectedCoachId}`,
            headers: {
                authorization: accessToken,
            },
            data: {
                year,
                month,
                incentive,
                incentiveType,
                baseSalary,
                etcBonus,
                isTaxable,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export type GetSalaryCalcsParams = {
    year: string;
    month: string;
    selectedCoachId: string;
    centerId: string;
    accessToken: string;
};

// 개별 보너스 타입
interface EtcBonus {
    name: string; // 보너스 이름
    amount: number; // 보너스 금액
}

// salaryCalcData 타입
export interface SalaryCalcData {
    year: string; // 연도
    month: string; // 월
    incentive: number; // 인센티브 (고정 값 또는 비율)
    incentiveType: CommissionType;
    baseSalary: number; // 기본 급여
    etcBonus: EtcBonus[]; // 추가 보너스 목록
    isTaxable: boolean; // 과세 여부
    _id?: string; // 고유 ID (옵션)
    totalScheduleFee?: number; // 총 스케줄 수수료
    totalSales?: number; // 총 판매 수수료
}
// SalaryCalcData 에서 _id 를 제외한 나머지 필수 값들, selectedCoachId 는 추가
export type PostSalaryCalcsParams = Omit<SalaryCalcData, '_id'> & {
    selectedCoachId: string;
};
