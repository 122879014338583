import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { colors } from '../lib/colors';
import { Col } from '../lib/utils';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
    SignInPage,
    SignUpPage,
    MyPage,
    DashBoardPage,
    DailySchedulePage,
    WeeklySchedulePage,
    ScheduleHistoryPage,
    TrainerManagePage,
    TrainerInformationPage,
    SubscriptionPlanPage,
    SuccessSubscriptionPage,
    FailSubscriptionPlanPage,
    SubscriptionPlanChangePage,
    ExpiredPlanPage,
    SuccessChangePayInformation,
    SuccessPurchaseAtExpired,
    FailPurchaseAtExpired,
    FailChangePayInformation,
    CommunicationPage,
    FeedPage,
    FeedMemberPage,
    SchedulePage,
    CreateSchedulePage,
    UpdateSchedulePage,
} from './index';
import PrivateRoutes from '../lib/PrivateRoutes';
import { useRecoilState } from 'recoil';
import { screenHeightState, screenWidthState } from '../atoms/atom';
import ClassPassPage from './ClassPass/ClassPassPage';
import MemberlistPage from './MemberlistPage';
import MemberProfilePage from './MemberProfilePage';
import MemberMembershipManagePage from '../components/MemberProfile/MemberMembershipManagePage';
import MemberTemporaryPage from './MemberTemporaryPage';
import Temp from './Settlement/ClassPaySettlementPage';
import Temp2 from './Schedule/SettlementPage';
import SettlementPage from './Schedule/SettlementPage';
import SettlementPasswordPage from './Settlement/SettlementPasswordPage';
import { KeepAlive } from 'keepalive-for-react';

const RouteGroup = ({
    createAlert,
    onClickTerm,
    createTwoButtonAlert,
    setIsLoading,
    onClickAddTrainer,
    authObject,
    setAuthObject,
    authorization,
    scrollToTop,
}: any) => {
    const [screenWidth, setScreenWidth] = useRecoilState(screenWidthState);
    const [screenHeight, setScreenHeight] = useRecoilState(screenHeightState);
    const location = useLocation();

    return (
        <Col
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // backgroundColor: location.pathname.includes('sign') ? colors.WHITE_50 : colors.WHITE_200,
                backgroundColor: '#F4F5F7',
            }}
        >
            <Col style={{ width: '100vw', backgroundColor: colors.white }}>
                <Routes>
                    <Route path="/" element={<Navigate to="/dash" />} />
                    <Route path="/signIn" element={<SignInPage createAlert={createAlert} />} />
                    <Route
                        path="/signUp"
                        element={<SignUpPage createAlert={createAlert} onClickTerm={onClickTerm} />}
                    />

                    <Route element={<PrivateRoutes />}>
                        {/* 대시보드 페이지 */}
                        <Route path="/dash" element={<DashBoardPage createAlert={createAlert} />} />
                        {/* 스케줄 페이지 */}
                        <Route
                            path="/schedule"
                            element={
                                <SchedulePage
                                    createAlert={createAlert}
                                    createTwoButtonAlert={createTwoButtonAlert}
                                    setIsLoading={setIsLoading}
                                />
                            }
                        />

                        <Route
                            path="/schedule/history"
                            element={<ScheduleHistoryPage createAlert={createAlert} setIsLoading={setIsLoading} />}
                        />

                        <Route
                            path="/schedule/create"
                            element={<CreateSchedulePage createAlert={createAlert} setIsLoading={setIsLoading} />}
                        />

                        <Route
                            path="/schedule/update"
                            element={<UpdateSchedulePage createAlert={createAlert} setIsLoading={setIsLoading} />}
                        />
                        <Route path="/settlement" element={<SettlementPage />} />

                        <Route path="/settlement/password" element={<SettlementPasswordPage />} />

                        {/* 스케줄 관리 탭 */}
                        {/* <Route
                            path="/schedule/daily"
                            element={
                                <DailySchedulePage
                                    createAlert={createAlert}
                                    createTwoButtonAlert={createTwoButtonAlert}
                                    setIsLoading={setIsLoading}
                                />
                            }
                        />
                        <Route
                            path="/schedule/weekly"
                            element={<WeeklySchedulePage createAlert={createAlert} setIsLoading={setIsLoading} />}
                        />
                       */}

                        {/* 강사 관리 탭 */}
                        <Route
                            path="/trainerManage"
                            element={
                                <TrainerManagePage
                                    createAlert={createAlert}
                                    onClickAddTrainer={onClickAddTrainer}
                                    setIsLoading={setIsLoading}
                                />
                            }
                        />
                        <Route
                            path="/trainerManage/trainerInfo"
                            element={
                                <TrainerInformationPage
                                    createAlert={createAlert}
                                    onClickAddTrainer={onClickAddTrainer}
                                />
                            }
                        />
                        {/* 회원 리스트 탭 */}
                        <Route path="/memberList" element={<MemberlistPage />} />
                        {/* 회원 프로필 */}
                        <Route path="/member/detail" element={<MemberProfilePage createAlert={createAlert} />} />
                        {/* 임시회원 등록 화면 */}
                        <Route path="/member/temporary" element={<MemberTemporaryPage createAlert={createAlert} />} />
                        {/* 회원 프로필 -> 회원 수강권 상세 */}
                        <Route
                            path="/member/detail/membership"
                            element={<MemberMembershipManagePage createAlert={createAlert} />}
                        />
                        {/* 회원 소통 상자 탭 */}
                        <Route
                            path="/member/communication"
                            element={
                                <CommunicationPage
                                    createTwoButtonAlert={createTwoButtonAlert}
                                    createAlert={createAlert}
                                    authObject={authObject}
                                    setAuthObject={setAuthObject}
                                />
                            }
                        />

                        {/* 수업 피드 탭 */}
                        <Route
                            path="/feed"
                            element={
                                <FeedPage
                                    authObject={authObject}
                                    setAuthObject={setAuthObject}
                                    authorization={authorization}
                                    setIsLoading={setIsLoading}
                                />
                            }
                        />
                        <Route
                            path="/feed/member"
                            element={
                                <FeedMemberPage
                                    authObject={authObject}
                                    setAuthObject={setAuthObject}
                                    authorization={authorization}
                                    setIsLoading={setIsLoading}
                                />
                            }
                        />

                        {/* 수강권 관리 탭 */}
                        <Route
                            path="/classPass"
                            element={
                                <ClassPassPage
                                    authObject={authObject}
                                    setAuthObject={setAuthObject}
                                    authorization={authorization}
                                    createAlert={createAlert}
                                />
                            }
                        />

                        {/* 마이페이지 탭 */}
                        <Route
                            path="/myPage"
                            element={
                                <MyPage
                                    createAlert={createAlert}
                                    authObject={authObject}
                                    setAuthObject={setAuthObject}
                                />
                            }
                        />
                        {/* 무료 플랜중 구독플랜 구입 페이지 */}
                        <Route
                            path="/myPage/SubscriptionPlanPage"
                            element={
                                <SubscriptionPlanPage
                                    createAlert={createAlert}
                                    createTwoButtonAlert={createTwoButtonAlert}
                                />
                            }
                        />
                        <Route
                            path="/myPage/SubscriptionPlanPage/success"
                            element={<SuccessSubscriptionPage createAlert={createAlert} onClickTerm={onClickTerm} />}
                        />
                        <Route path="/myPage/SubscriptionPlanPage/fail" element={<FailSubscriptionPlanPage />} />

                        {/* 유료구독 기간중 구독플랜 변경 페이지 */}
                        <Route
                            path="/myPage/SubscriptionPlanChangePage"
                            element={
                                <SubscriptionPlanChangePage
                                    createAlert={createAlert}
                                    onClickTerm={onClickTerm}
                                    scrollToTop={scrollToTop}
                                    createTwoButtonAlert={createTwoButtonAlert}
                                />
                            }
                        />
                        <Route
                            path="/myPage/changePayInfo/success"
                            element={<SuccessChangePayInformation createAlert={createAlert} />}
                        />
                        <Route
                            path="/myPage/changePayInfo/fail"
                            element={<FailChangePayInformation createAlert={createAlert} />}
                        />

                        <Route path="/expired" element={<ExpiredPlanPage createAlert={createAlert} />} />
                        <Route
                            path="/expired/success"
                            element={<SuccessPurchaseAtExpired createAlert={createAlert} onClickTerm={onClickTerm} />}
                        />
                        <Route path="/expired/fail" element={<FailPurchaseAtExpired />} />
                    </Route>
                </Routes>
                {/* </Col> */}
                {/* {authorization && <Bottom />} */}
            </Col>
        </Col>
    );
};

export default RouteGroup;
