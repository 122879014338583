import React, { memo, useState } from 'react';
import { Body3Regular, Subtitle1Bold } from '../../lib/font';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import MembershipCard from './MembershipCard';
import NewColorRoundButton from '../Button/NewColorRoundButton';
import ModalMembershipCreate from './ModalMembershipCreate';
import { useLocation, useNavigate } from 'react-router-dom';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';
import SvgIcon from '../SvgIcon';
import dayjs from 'dayjs';

const MemberMemberShipComponents = ({
    memberId,
    coachId,
    coachName,
    memberName,
    onClickCreateNewMembership,
    allMembershipListData,
    createAlert,
}: {
    memberId: string;
    coachId: string;
    coachName?: string;
    memberName?: string;
    onClickCreateNewMembership: () => void;
    allMembershipListData: MemberMembershipDataType[];
    createAlert: any;
}) => {
    // 수강권 등록 모달 열기
    const [isModalMembershipCreateVisible, setIsModalMembershipCreateVisible] = useState(false);
    const navigate = useNavigate();

    // 수업 등록 바로가기
    const onClickMoveToClassRegister = () => {
        // coachId, coachName, scheduleStartTime
        const selectedSchedule = {
            memberId: memberId,
            coachId: coachId,
            coachName: coachName,
            scheduleStartTime: dayjs().format('YYYY-MM-DD HH:mm'),
        };
        const calendarType = 'daily';
        // 오늘날짜
        const calendarSelectedDay = dayjs().format('YYYY-MM-DD');
        const calendarScrollY = 0;

        navigate('/schedule/create', {
            state: {
                type: 'oneSchedule',
                selectedSchedule: selectedSchedule,
                calendarType,
                calendarSelectedDay,
                calendarScrollY,
            },
        });
    };

    return (
        <div className="flex flex-col w-full h-full justify-center items-center mb-[80px]">
            <Row className="w-[1192px] items-center mb-[24px]">
                <Subtitle1Bold className=" text-LAVEL_4 mr-[32px]">수강권 정보</Subtitle1Bold>
                <Row className="flex items-center cursor-pointer" onClick={onClickMoveToClassRegister}>
                    <Body3Regular className=" text-BLUE_500 mr-[8px]">수업 등록 바로가기</Body3Regular>
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={14}
                        fill={colors.trans}
                        stroke={colors.BLUE_500}
                        strokeWidth={1.2}
                    />
                </Row>
            </Row>
            {/* <MembershipCard/> */}
            <Row className="flex flex-row w-[1192px] justify-start items-start overflow-x-scroll scrollbar scrollbar-thumb-WHITE_600 scrollbar-corner-GREY_100 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-h-3">
                {allMembershipListData?.map((membership: MemberMembershipDataType, index: number) => (
                    <MembershipCard
                        index={index}
                        membership={membership}
                        onClickDetailPage={() =>
                            navigate('/member/detail/membership', {
                                state: {
                                    membershipId: membership._id,
                                    memberName: memberName,
                                    coachName: coachName,
                                    memberId: memberId,
                                    coachId: coachId,
                                },
                            })
                        }
                    />
                ))}
                <Col
                    className="flex flex-col w-[310px] h-[160px] border-BLUE_200 border-spacing-50 border-dashed border-[1px] bg-BLUE_50 justify-center items-center rounded-[8px] flex-shrink-0 cursor-pointer"
                    onClick={() => setIsModalMembershipCreateVisible(true)}
                >
                    <NewColorRoundButton
                        text="수강권 등록하기"
                        fontType={'CaptionRegular'}
                        width={112}
                        height={24}
                        bgColor="primary"
                        textColor="white"
                    />
                </Col>
            </Row>
            {isModalMembershipCreateVisible && (
                <ModalMembershipCreate
                    setIsVisible={setIsModalMembershipCreateVisible}
                    onClickCreateNewMembership={onClickCreateNewMembership}
                    memberName={memberName}
                    coachName={coachName}
                    coachId={coachId}
                    memberId={memberId}
                    createAlert={createAlert}
                />
            )}
        </div>
    );
};

export default MemberMemberShipComponents;
