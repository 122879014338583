import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Col, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import { connectingTrainerAPI, deleteTrainerAPI } from '../../api/employee';
import DetailHeader from '../../components/TrainerInformation/DetailHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalClassHistory from '../../components/ModalClassHistory/ModalClassHistory';
import ModalReRegister from '../../components/modal/ModalReRegister';
import { storeUserFlow } from '../../api/adminuserflow';
import LargeToggleButton from '../../components/Button/LargeToggleButton';
import ManageMember from '../../components/TrainerInformation/ManageMember/ManageMember';
import ClassHistory from '../../components/TrainerInformation/ClassHistory/ClassHistory';
import ModalCoachDelete from './ModalCoachDelete';
import TrainerProfileComponent from './TrainerProfileComponent';
import useSingleQuery from '../../hook/useSingleQuery';
import { EmployeeProfile, fetchEmployeeProfileQuery } from '../../services/useEmployeeQueryService';
import ModalTrainerProfileChange from './ModalTrainerProfileChange';

const TrainerInformationPage = ({ createAlert, onClickAddTrainer }: any) => {
    /**
     * isConnected : false , isDeleted :false -> 수락 대기중 상태
     * isConnected : true , isDeleted :false -> 정상 활성화 상태
     * isConnected : true , isDeleted :true -> 비활성화 상태 (센터에서 삭제된 트레이너)
     * isConnected : false , isDeleted :true -> 강사가 탈퇴한 상태 (센터에서 삭제 + 트레이너라포 탈퇴)
     */
    const navigate = useNavigate();
    const location = useLocation();

    const queryClient = useQueryClient();
    const [authorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    // 라우트로 전달받은 파라미터
    const employeeId = location?.state?.employeeId;

    const [pageType, setPageType] = useState<'manageMember' | 'classHistory'>('manageMember');

    // 담당회원정보 에서 수업기록을 누를때 멤버이름 저장 변수
    const [searchMemberName, setSearchMemberName] = useState<string>('');
    // 성별 샐랙트박스 상태
    const [isOpenGender, setIsOpenGender] = useState<boolean>(false);

    // 모달 플래그
    const [isMemberInfoModal, setIsMemberInfoModal] = useState<boolean>(false);
    const [isClassHistoryModal, setIsClassHistoryModal] = useState<boolean>(false);
    const [isDeleteAlertModal, setIsDeleteAlertModal] = useState<boolean>(false);
    const [isReRegisterModal, setIsReRegisterModal] = useState<boolean>(false);

    // 담당회원 정보 > 수업히스토리 클릭시 뒤로가기 버튼을위한 플래그
    const [isClickedHistoryAtInfoModal, setIsClickedHistoryAtInfoModal] = useState<boolean>(false);

    // 프로필 모달 변경 여부
    const [isChangeProfileModal, setIsChangeProfileModal] = useState<boolean>(false);

    const onClickOutSide = () => {
        if (isOpenGender) {
            setIsOpenGender(false);
        }
    };

    const onClickBackButton = () => {
        navigate(-1);
    };

    // 담당 회원정보 버튼 클릭이벤트
    const onClickMemberInfo = () => {
        setIsMemberInfoModal(true);
    };

    // 수업 히스토리 버튼 클릭이벤트
    const onClickClassHistory = () => {
        setIsClassHistoryModal(true);
    };

    // 담당회원정보 > 수업기록 > 뒤로가기 버튼 누를시
    const onClickBackMemberInfo = () => {
        setIsClassHistoryModal(false);
        setIsClickedHistoryAtInfoModal(false);
        setIsMemberInfoModal(true);
    };

    // 담당회원정보 > 수업기록 버튼클릭이벤트
    const onClickScheduleHistoryButton = (memberName: string) => {
        setSearchMemberName(memberName);
        setIsMemberInfoModal(false);
        setIsClassHistoryModal(true);
        setIsClickedHistoryAtInfoModal(true);
    };

    const { refinedResult } = useSingleQuery({
        fetchFn: fetchEmployeeProfileQuery,
        params: {
            employeeId,
        },
        enabled: !!employeeId,
    });

    const trainerProfile: EmployeeProfile = refinedResult.data as EmployeeProfile;

    const coachId = trainerProfile?.coachId;
    const coachName = trainerProfile?.name;
    const coachCode = trainerProfile?.inviteCode;
    const totalMemberCount = trainerProfile?.totalMemberCount;

    //  트레이너 삭제API : (PATCH)
    const deleteTrainer = useMutation({
        mutationFn: async () => await deleteTrainerAPI(authObject.centerId, employeeId, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data;
                const connectedCoachs = response.center.relations.filter(
                    (coach: any) => coach.isDeleted === false && coach.isConnected === true
                );

                const authObjectData = {
                    ...authObject,
                    coachCount: connectedCoachs.length,
                };

                setItemWithExpiration('authObject', authObjectData, 12);
                setAuthObject(authObjectData);
                queryClient.invalidateQueries({ queryKey: ['getEmployeeInfoAtManageAPIQuery'] });

                createAlert('삭제 완료', '해당 트레이너 삭제를 완료하였습니다');
                navigate('/trainerManage');
            } else {
                setIsDeleteAlertModal(false);
                createAlert('', '트레이너 삭제중 오류가 발생하였습니다.');
            }
        },
    });

    // 트레이너 재등록API : (PATCH)
    const connectingTrainer = useMutation({
        mutationFn: async () => await connectingTrainerAPI(authObject.centerId, coachId, authorization),
        onSuccess: (res) => {
            console.log(res);
            if (res.status === 200) {
                setIsReRegisterModal(false);
                queryClient.invalidateQueries({ queryKey: ['getTrainerListAPI'] });
                createAlert(
                    '등록 완료',
                    `트레이너 등록이 완료되었습니다\n트레이너의 상세 정보는 '트레이너 정보'에서 상세 입력할 수 있습니다`
                );
                navigate('/trainerManage');
            }
            if (res.status === 404) {
                createAlert('', '트레이너를 찾을 수 없습니다!\n 코드를 다시 한번 확인해주세요', true);
            }
            if (res.status === 406) {
                createAlert(
                    '',
                    '현재 요금제에서는 더이상 추가가 불가능합니다.\n 문제가 있을 시 카카오톡 Rappo 채널로 문의 부탁드려요'
                );
            }
            if (res.status === 409) {
                createAlert('', '이미 등록된 트레이너 입니다');
            }
        },
    });

    // 삭제하기 버튼클릭이벤트
    const onClickDeleteTrainer = () => {
        setIsDeleteAlertModal(true);
    };

    // 트레이너 삭제하기
    const onClickDeleteAccount = () => {
        deleteTrainer.mutate();
    };

    // 삭제된 트레이너 재등록하기
    const onClickReRegisterTrainer = () => {
        connectingTrainer.mutate();
    };

    // 재등록하기 버튼 클릭이벤트
    const onClickReRegisterButton = () => {
        setIsReRegisterModal(true);
    };

    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('trainerInfo', authorization),
    });
    // 접속 데이터 측정 API
    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <Col style={{ alignItems: 'center', marginTop: 64 }} onClick={onClickOutSide}>
            <DetailHeader onClickBackButton={onClickBackButton} />
            <TrainerProfileComponent
                trainerProfile={trainerProfile}
                onClickDeleteTrainer={onClickDeleteTrainer}
                onClickEditProfile={() => setIsChangeProfileModal(true)}
            />

            <LargeToggleButton
                leftText="회원 관리"
                rightText="수업 기록"
                isLeft={pageType === 'manageMember'}
                isRight={pageType === 'classHistory'}
                callBackLeft={() => {
                    setPageType('manageMember');
                }}
                callBackRight={() => {
                    setPageType('classHistory');
                }}
                customStyle={{ marginTop: 128 }}
            />
            {pageType === 'manageMember' && (
                <ManageMember
                    authorization={authorization}
                    employeeId={employeeId}
                    authObject={authObject}
                    coachId={coachId}
                />
            )}
            {pageType === 'classHistory' && (
                <ClassHistory authorization={authorization} employeeId={employeeId} authObject={authObject} />
            )}

            {/* 담당 회원정보 모달 */}
            {/* {isMemberInfoModal && (
                <ModalMemberInfo
                    modalVisible={isMemberInfoModal}
                    setModalVisible={setIsMemberInfoModal}
                    authorization={authorization}
                    employeeId={employeeId}
                    onClickScheduleHistoryButton={onClickScheduleHistoryButton}
                    authObject={authObject}
                />
            )} */}

            {/* 수업 히스토리 모달 */}
            {isClassHistoryModal && !isMemberInfoModal && (
                <ModalClassHistory
                    modalVisible={isClassHistoryModal}
                    setModalVisible={setIsClassHistoryModal}
                    authorization={authorization}
                    employeeId={employeeId}
                    searchMemberName={searchMemberName}
                    setSearchMemberName={setSearchMemberName}
                    authObject={authObject}
                    isClickedHistoryAtInfoModal={isClickedHistoryAtInfoModal}
                    setIsClickedHistoryAtInfoModal={setIsClickedHistoryAtInfoModal}
                    onClickBackMemberInfo={onClickBackMemberInfo}
                />
            )}

            {/* 트레이너 삭제모달 */}
            {isDeleteAlertModal && (
                <ModalCoachDelete
                    setIsVisible={setIsDeleteAlertModal}
                    onClickDelete={onClickDeleteAccount}
                    isDisableDelete={totalMemberCount === 0}
                />
            )}

            {/* 재등록하기 버튼 클릭시 모달 */}
            {isReRegisterModal && (
                <ModalReRegister
                    modalVisible={isReRegisterModal}
                    setModalVisible={setIsReRegisterModal}
                    coachName={coachName}
                    coachCode={coachCode}
                    onClickReRegisterTrainer={onClickReRegisterTrainer}
                />
            )}
            {/* 프로필 편집 모달 */}
            {isChangeProfileModal && (
                <ModalTrainerProfileChange
                    setIsVisible={setIsChangeProfileModal}
                    trainerProfile={trainerProfile}
                    employeeId={employeeId}
                    createAlert={createAlert}
                />
            )}
        </Col>
    );
};

export default TrainerInformationPage;
