import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { BoldText, DemiLightText, MediumText, RegularText } from '../../lib/Text';
import { ToggleSwitch } from './ModalCommission';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import { GetClassFeeSettlementTableDataForMembership, MembershipInfo } from '../../services/useMemberQueryService';
import NewColorSquareButton from '../../components/Button/NewColorSquareButton';
import useMembershipService from '../../services/useMembershipService';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    row?: GetClassFeeSettlementTableDataForMembership;
    year: number;
    month: number;
    commission?: number;
    onPatchMonthCommissionSuccess?: () => void;
};

const ModalMonthCommision = ({ setIsVisible, row, year, month, onPatchMonthCommissionSuccess }: Props) => {
    const membership = row?.membershipInfo?.membership;
    const commission = row?.commission;
    const commissionType = row?.commissionType;
    const BGColor = membership?.BGColor;
    const isActive = BGColor && BGColor.length > 0;
    const membershipName = membership?.name;
    const startDate = dayjs(membership?.activationDate).format('YYYY-MM-DD');
    const endDate = dayjs(membership?.expirationDate).format('YYYY-MM-DD');
    // 현재회차, 총회차, 회당 가격, 발급일자
    const currentSession = membership?.currentSession;
    const totalSession = membership?.totalSession;
    const pricePerSchedule = membership?.pricePerSchedule;
    const createdAt = dayjs(membership?.createdAt).format('YYYY-MM-DD');

    const { patchMonthCommissionMutation } = useMembershipService({
        onPatchMonthCommissionSuccess: onPatchMonthCommissionSuccess,
    });

    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isFocus, setIsFocus] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);

    const [value, setValue] = React.useState(commission ?? '');
    // 정산 type
    const [type, setType] = React.useState<'fixed' | 'percentage'>(commissionType ?? 'percentage');

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // 숫자만 입력 가능
        let value = e.target.value;
        const reg = /^[0-9]*$/;
        if (!reg.test(e.target.value)) {
            return;
        }

        // type 이 percentage 일때 100 이상 입력 불가
        if (type === 'percentage' && parseInt(value) > 100) {
            return;
        }
        setValue(value);
    };

    useEffect(() => {
        if (value === '') {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [value]);

    return (
        <Modal
            isCloseOutside
            isCloseButton
            setIsVisible={setIsVisible}
            whiteViewWidth={554}
            borderRadius={25}
            modalAlign="start"
            mt={80}
        >
            <Col className="p-[35px] justify-center items-center">
                <MediumText fontSize={38} lineHeight={55} color="#3D4244" className="mb-[24px] self-start">
                    수강권 정산 비율 설정
                </MediumText>
                <Row
                    className={`border border-solid  rounded-[10px] w-[342px] py-[14px] pl-[21px] pr-[13px] justify-between items-center mb-[14px] cursor-text  hover:border-primary transition-colors duration-300 ease-in-out ${
                        isFocus ? 'border-primary' : 'border-[#E0E0E0]'
                    }`}
                    onClick={() => inputRef.current?.focus()}
                >
                    <input
                        ref={inputRef}
                        type="text"
                        className=" text-[16px] font-noto-regular leading-[23px] "
                        value={value}
                        onChange={onChange}
                        maxLength={type === 'percentage' ? 3 : 6}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                        placeholder="정산 비율을 입력해주세요"
                        style={{ letterSpacing: '-0.5px' }}
                    />
                    <ToggleSwitch
                        value={type}
                        onToggle={(x) => {
                            setType(x);
                            setValue('');
                        }}
                    />
                </Row>
                <Col className="mb-[32px] w-[342px]">
                    <Col
                        className="flex flex-col w-full rounded-tr-[12px] rounded-tl-[12px] h-[123px] justify-center items-center "
                        style={{
                            background: isActive
                                ? `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`
                                : colors.GREY_200,
                            alignItems: 'center',
                        }}
                    >
                        <Col className="flex w-full p-[24px] justify-start items-start">
                            <BoldText fontSize={18} lineHeight={28} color="white" className="w-full h-[56px]">
                                {membershipName}
                            </BoldText>
                            <Row className=" items-center">
                                <Col className="bg-white bg-opacity-[34%] rounded-[18px] w-[60px] h-[25px] justify-center items-center mr-[7px] ">
                                    <RegularText fontSize={11} lineHeight={15.93} color="white">
                                        이용 기간
                                    </RegularText>
                                </Col>
                                <RegularText fontSize={15} lineHeight={21.72} color="white" className=" opacity-[0.6]">
                                    {`${startDate} ~ ${endDate}`}
                                </RegularText>
                            </Row>
                        </Col>
                    </Col>
                    <Col className="w-full h-[183px] border border-solid border-[#D9D9D9] rounded-bl-[21px] rounded-br-[21px] px-[24px] justify-between py-[20px]">
                        <Row className="w-full justify-between">
                            <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                현재 회차
                            </DemiLightText>
                            <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                {currentSession + '회'}
                            </MediumText>
                        </Row>
                        <Row className="w-full justify-between">
                            <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                총 회차
                            </DemiLightText>
                            <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                {totalSession + '회'}
                            </MediumText>
                        </Row>
                        <Row className="w-full justify-between">
                            <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                회당 가격
                            </DemiLightText>
                            <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                {convertToKRW(pricePerSchedule ?? 0) + '원'}
                            </MediumText>
                        </Row>
                        <Row className="w-full justify-between">
                            <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                발급 일자
                            </DemiLightText>
                            <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                {createdAt}
                            </MediumText>
                        </Row>
                    </Col>
                </Col>
                <Row className=" justify-center items-center">
                    <RegularText
                        fontSize={16}
                        lineHeight={23}
                        letterSpacing={-0.32}
                        className="mr-[28px]"
                        color="#505967"
                    >
                        설정한 수강권 정산비율은 이번 달만 적용돼요
                    </RegularText>
                    <NewColorSquareButton
                        text="적용하기"
                        bgColor="primary"
                        textColor="white"
                        disabled={isDisabled}
                        borderRadius={9}
                        width={150}
                        height={48}
                        onClick={() => {
                            if (!membership?._id) return;
                            patchMonthCommissionMutation.mutate({
                                membershipId: membership?._id,
                                year: year.toString(),
                                month: month.toString(),
                                commissionType: type,
                                commission: (value as number) ?? 0,
                            });
                            setIsVisible(false);
                        }}
                    />
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalMonthCommision;
