export const colors = {
    primary: '#3D73DD',
    secondary: '#FFD841',
    system_blue: '#C9DBFF',
    system_blue40: '#C9DBFF66',
    system_blue70: '#3D73DDB2',
    deep_blue: '#153861',
    deep_blue50: '#5E7093',
    white: '#FFFFFF',
    black: '#000000',
    red: '#FF553E',

    Text_1: '#3D4244',
    Text_2: '#787878',
    Text_3: '#1C3669',
    trans: '#00ff0000',
    Secondiary: '#FFD841',
    schdule: '#A8C0F0',
    off: 'rgba(124,124,124,0.4)',
    oneTime: '#6A6F7C',

    // 승연님 디자인 시스템 컬러
    PRIMARY_BLUE: '#3D73DD',
    ERROR: '#FF3E4A',
    ERROR_50: '#FFE8E9',
    ERROR_200: '#FF9BA1',
    SUCCESS: '#00A793',
    SUCCESS_50: '#E5F8F3',
    CHANGE: '#FF8777',
    CHANGEYELLOW: '#FFF8E0',
    PINK: '#FD8FE5',
    PURPLE: '#9E76F3',
    GREEN: '#00A793',
    ORANGE: '#FF8777',
    YELLOW: '#FFD977',
    CYAN: '#17B5CB',

    POINT_1: '#3D73DD',
    POINT_2: '#1A305D',
    LAVEL_0: '#FDFEFF',
    LAVEL_1: '#6E6E6E',
    LAVEL_2: '#4A4A4A',
    LAVEL_3: '#353535',
    LAVEL_4: '#000000',

    BLUE_50: '#ECF1FC',
    BLUE_100: '#C3D4F4',
    BLUE_200: '#A6BFEF',
    BLUE_300: '#7DA1E8',
    BLUE_400: '#648FE4',
    BLUE_500: '#3D73DD',
    BLUE_600: '#3869C9',
    BLUE_700: '#2B529D',
    BLUE_800: '#223F7A',
    BLUE_900: '#1A305D',

    WHITE_50: '#FDFEFF',
    WHITE_100: '#F8FBFF',
    WHITE_200: '#F5F9FF',
    WHITE_300: '#F0F6FF',
    WHITE_400: '#EDF4FF',
    WHITE_500: '#E9F1FF',
    WHITE_600: '#D4DBE8',
    WHITE_700: '#A5ABB5',
    WHITE_800: '#80858C',
    WHITE_900: '#62656B',

    Gray: '#F4F5F7',
    GREY_50: '#EDEDED',
    GREY_100: '#C7C7C7',
    GREY_200: '#ACACAC',
    GREY_300: '#868686',
    GREY_400: '#6E6E6E',
    GREY_500: '#4A4A4A',
    GREY_600: '#434343',
    GREY_700: '#353535',
    GREY_800: '#292929',
    GREY_900: '#1F1F1F',

    GREEN_50: '#51C070',
    GREY: '#F2F4F6',
};

export const WHITE = '#ffffff';
