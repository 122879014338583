import React, { useEffect, useRef, useState } from 'react';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import { Col } from '../../lib/utils';
import clsx from 'clsx';

type DropdownProps = {
    options: {
        code: string;
        value: string;
        onClick?: () => void;
        object?: any;
    }[];
    defaultLabel?: string;
    onSelect?: (selected: { code: string; value: string; object?: any }) => void;
    bgColor?: string;
    textColor?: string;
    totalText?: string;
    mr?: number;
    borderRadius?: number;
    className?: string;
    arrowColor?: string;
    isAllOption?: boolean;
    initialSelected?: string;
    textType?: 'regular' | 'medium' | 'demilight';
    selectedBgColor?: string;
    selectedTextColor?: string;
    selectedArrowColor?: string;
    totalObjectOption?: any;
};

const Dropdown: React.FC<DropdownProps> = ({
    options,
    onSelect,
    bgColor = '#F4F5F7',
    textColor = '#3D4244',
    totalText = '전체',
    borderRadius = 7,
    mr,
    className,
    arrowColor = '#3D4244',
    isAllOption = true,
    initialSelected,
    textType = 'regular',
    selectedBgColor = bgColor,
    selectedTextColor = textColor,
    selectedArrowColor = arrowColor,
}) => {
    const TextComponent = textType === 'regular' ? RegularText : textType === 'medium' ? MediumText : DemiLightText;

    const dropdownRef = useRef<HTMLDivElement>(null);
    const initialOption = options?.find((o) => o.code === initialSelected) ?? undefined;

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>(
        initialSelected ? initialOption?.value ?? totalText : isAllOption ? totalText : options[0].value
    );

    useEffect(() => {
        if (initialSelected) {
            // 바뀌었을경우 find해서 찾아서 넣어주기

            options.find((option) => {
                if (option.code === initialSelected) {
                    setSelectedOption(option.value);
                    onSelect && onSelect(option);
                }
            });
        }
    }, [initialSelected]);

    const handleSelect = (option: { code: string; value: string; object?: any }) => {
        setSelectedOption(option.value);
        setIsOpen(false);
        if (onSelect) {
            onSelect(option);
        }
    };
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block " ref={dropdownRef}>
            <button
                onClick={() => setIsOpen((prev) => !prev)}
                className={clsx(
                    `flex flex-row items-center justify-center rounded-full px-[16px]`, // 공통 클래스
                    className // className을 마지막에 병합
                )}
                style={{
                    backgroundColor: selectedOption === totalText ? bgColor : selectedBgColor,
                    borderRadius: borderRadius,
                    marginRight: mr,
                }}
            >
                <TextComponent
                    fontSize={17}
                    lineHeight={24.62}
                    color={selectedOption === totalText ? textColor : selectedTextColor}
                    className="mr-[12px]"
                >
                    {selectedOption}
                </TextComponent>
                <SvgIcon
                    name={'SvgDownStrokeArrow'}
                    fill={colors.trans}
                    stroke={selectedOption === totalText ? arrowColor : selectedArrowColor}
                    size={14}
                    strokeWidth={1.5}
                />
            </button>
            {isOpen && (
                <div className="absolute mt-[10px] flex pb-[21px] w-[190px] bg-[primary] bg-white rounded-[15px] [box-shadow:0px_4px_20px_rgba(0,0,0,0.2)] z-40">
                    <ul
                        className=" pl-[12px] pr-[12px] w-[180px] rounded-[15px]  left-0 mt-[18px] bg-white border border-gray-300  max-h-[200px] overflow-y-scroll 
                scrollbar scrollbar-w-[6px] scrollbar-thumb-[#CFCFCF] scrollbar-corner-GREY_100 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-h-[20px] "
                    >
                        {isAllOption && (
                            <li
                                onClick={() =>
                                    handleSelect({
                                        code: 'all',
                                        value: totalText,
                                        object: undefined,
                                    })
                                }
                                className={`pb-[8px] text-gray-700 cursor-pointer hover:bg-gray-100  ${
                                    selectedOption === totalText ? 'bg-gray-200' : ''
                                } `}
                            >
                                <Col className="flex w-full h-full hover:bg-[#F2F4F6] rounded-[7px] pl-[8px] py-[4px] ">
                                    <RegularText
                                        fontSize={16}
                                        lineHeight={24}
                                        color={selectedOption === totalText ? colors.primary : '#3D4244'}
                                    >
                                        {totalText}
                                    </RegularText>
                                </Col>
                            </li>
                        )}
                        {options.map((option) => (
                            <li
                                key={option.code}
                                onClick={() => {
                                    if (option.onClick) {
                                        option.onClick();
                                        setIsOpen(false);
                                    } else {
                                        handleSelect(option);
                                    }
                                }}
                                className={`pb-[8px] text-gray-700 cursor-pointer hover:bg-gray-100  ${
                                    selectedOption === option.value ? 'bg-gray-200' : ''
                                }`}
                            >
                                <Col className="flex w-full h-full hover:bg-[#F2F4F6] rounded-[7px] px-[8px] py-[4px] ">
                                    <RegularText
                                        fontSize={16}
                                        lineHeight={24}
                                        color={selectedOption === option.value ? colors.primary : '3D4244'}
                                    >
                                        {option.value}
                                    </RegularText>
                                </Col>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
