'use strict';

import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import { findIndex } from 'lodash';
// 한국 설정
import 'dayjs/locale/ko';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);
dayjs.extend(weekday);
dayjs.extend(duration);

// // 범위 끝 계산
export function endOfRange({ dateRange, unit = 'day' }: { dateRange: Dayjs[]; unit?: ManipulateType }) {
    if (!dateRange || dateRange.length === 0) return { first: null, last: null };
    const first = dateRange[0];
    const last = dayjs(dateRange[dateRange.length - 1])
        .add(1, unit as ManipulateType)
        .toDate();
    return {
        first,
        last,
    };
}

// // 이벤트 세그먼트 계산
export function eventSegments(event: any, range: any) {
    const { first, last } = endOfRange({ dateRange: range });
    const slots = dayjs(last).diff(dayjs(first), 'day');
    const start = dayjs.max(dayjs(event.start).startOf('day'), dayjs(first));
    const end = dayjs.min(dayjs(event.end).endOf('day'), dayjs(last));
    const padding = findIndex(range, (x: any) => dayjs(x).isSame(start, 'day'));
    let span = dayjs(end).diff(start, 'day') + 1;
    span = Math.min(span, slots);
    span = Math.max(span, 1); // 최소 1개 이상의 세그먼트는 필요
    return {
        event,
        span,
        left: padding + 1,
        right: Math.max(padding + span, 1),
    };
}

// // 이벤트 레벨 분류
export function eventLevels(rowSegments: any, limit = Infinity) {
    let levels = [];
    let extra = [];
    for (const seg of rowSegments) {
        let placed = false;
        for (const level of levels) {
            if (!segsOverlap(seg, level)) {
                level.push(seg);
                placed = true;
                break;
            }
        }
        if (!placed) {
            if (levels.length < limit) {
                levels.push([seg]);
            } else {
                extra.push(seg);
            }
        }
    }

    for (const level of levels) {
        level.sort((a, b) => a.left - b.left);
    }

    return {
        levels,
        extra,
    };
}

// 이벤트가 범위 내에 있는지 확인
export function inRange(e: any, start: any, end: any) {
    const event = {
        start: e.start,
        end: e.end,
    };
    const range = {
        start,
        end,
    };
    return inEventRange({ event, range });
}

function inEventRange({ event, range }: { event: any; range: any }) {
    const { start, end } = event;
    const { start: rangeStart, end: rangeEnd } = range;

    const eStart = dayjs(start).startOf('day');
    const startsBeforeEnd = eStart.isSameOrBefore(dayjs(rangeEnd), 'day');

    // 이벤트가 0초짜리(종료 시간이 시작 시간과 같은 경우)일 때 다르게 처리
    const sameMin = !dayjs(eStart).isSame(dayjs(end), 'minute');
    const endsAfterStart = sameMin
        ? dayjs(end).isAfter(dayjs(rangeStart), 'minute')
        : dayjs(end).isSameOrAfter(dayjs(rangeStart), 'minute');

    return startsBeforeEnd && endsAfterStart;
}

// // 세그먼트가 서로 겹치는지 확인
export function segsOverlap(seg: any, otherSegs: any) {
    return otherSegs.some((otherSeg: any) => otherSeg.left <= seg.right && otherSeg.right >= seg.left);
}

// // 주별 이벤트 정렬
export function sortWeekEvents(events: any[], accessors: any) {
    const multiDayEvents: any[] = [];
    const standardEvents: any[] = [];

    events.forEach((event) => {
        const start = accessors.start(event);
        const end = accessors.end(event);
        if (dayjs(end).diff(dayjs(start), 'day') > 1) {
            multiDayEvents.push(event);
        } else {
            standardEvents.push(event);
        }
    });

    const sortedMultiDay = multiDayEvents.sort((a, b) => sortEvents(a, b, accessors));
    const sortedStandard = standardEvents.sort((a, b) => sortEvents(a, b, accessors));

    return [...sortedMultiDay, ...sortedStandard];
}

// // 이벤트 정렬
export function sortEvents(eventA: any, eventB: any, accessors: any) {
    const startA = dayjs(accessors.start(eventA));
    const endA = dayjs(accessors.end(eventA));
    const startB = dayjs(accessors.start(eventB));
    const endB = dayjs(accessors.end(eventB));

    if (startA.isBefore(startB)) return -1;
    if (startA.isAfter(startB)) return 1;
    if (endA.isBefore(endB)) return -1;
    if (endA.isAfter(endB)) return 1;

    const allDayA = accessors.allDay(eventA);
    const allDayB = accessors.allDay(eventB);

    if (allDayA && !allDayB) return -1;
    if (!allDayA && allDayB) return 1;

    return 0;
}
