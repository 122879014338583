import React, { useEffect, useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import ProfileImage from '../../components/ProfileImage';
import { Body2Regular, Body3Regular, Head3 } from '../../lib/font';
import SvgIcon from '../../components/SvgIcon';
import dayjs from 'dayjs';
import { colors } from '../../lib/colors';
import { EmployeeProfile } from '../../services/useEmployeeQueryService';
import NormalToggleButton from '../../components/ToggleButton/NormalToggleButton';
import useCenterMutationService from '../../services/useCenterMutationService';
import { useQueryClient } from '@tanstack/react-query';
import TrashButton from '../../components/Button/TrashButton';
import NewColorRoundButton from '../../components/Button/NewColorRoundButton';
import { RegularText } from '../../lib/Text';

type Props = {
    trainerProfile: EmployeeProfile;
    // 강사 삭제 버튼 클릭 시
    onClickDeleteTrainer: () => void;
    // 프로필 편집 버튼 클릭 시
    onClickEditProfile: () => void;
};

const TrainerProfileComponent = ({ trainerProfile, onClickDeleteTrainer, onClickEditProfile }: Props) => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const queryClient = useQueryClient();

    const { patchUpdateTrainerPermission } = useCenterMutationService({});
    const centerId = authObject?.centerId;
    const coachId = trainerProfile?.coachId;
    const name = trainerProfile?.name;
    const code = trainerProfile?.inviteCode;
    // 휴대폰 번호
    const phoneNumber = trainerProfile?.phoneNumber ? trainerProfile.phoneNumber : '-';
    // 이메일
    const email = trainerProfile?.email ? trainerProfile.email : '-';
    // 생년월일
    const birthDay = trainerProfile?.birthday ? dayjs(trainerProfile?.birthday).format('YYYY/MM/DD') : '-';
    // 성별
    const gender = trainerProfile?.gender ? (trainerProfile.gender === 'male' ? '남자' : '여자') : '-';
    // 입사일
    const startDate = trainerProfile?.startDate ? dayjs(trainerProfile.startDate).format('YYYY/MM/DD') : '-';
    // 직급
    const jobPosition = trainerProfile?.jobPosition ? trainerProfile.jobPosition : '-';
    // 수강권 권한
    // const isMembershipPermissionAll = trainerProfile?.isMembershipPermissionAll;
    const [isMembershipPermissionAll, setIsMembershipPermissionAll] = useState(
        trainerProfile?.isMembershipPermissionAll
    );
    // [ ] : 강사도 앱사용 여부 ?
    // 앱사용 여부

    const onClickPermissionChange = async () => {
        const result = await patchUpdateTrainerPermission.mutateAsync({
            centerId,
            coachId,
            isMembershipPermissionAll: !isMembershipPermissionAll,
        });

        if (result.status === 200) {
            setIsMembershipPermissionAll(!isMembershipPermissionAll);
            queryClient.invalidateQueries({
                queryKey: ['getEmployeeInfoAtManageAPIQuery'],
            });
        } else {
            alert('수강권 권한 변경에 실패하였습니다.');
        }
    };

    useEffect(() => {
        setIsMembershipPermissionAll(trainerProfile?.isMembershipPermissionAll);
    }, [trainerProfile?.isMembershipPermissionAll]);

    return (
        <Col className="w-[1192px]">
            <Row className="flex border-[1px] border-solid border-BLUE_200 rounded-[8px] w-[1192px] p-[56px] bg-white mb-[16px] relative">
                {/* 왼쪽 프로필 */}
                <Col className="flex mr-[90px]">
                    <ProfileImage imageUrl={trainerProfile?.profileImageUrl} type={'VeryBig'} className="mb-[16px]" />
                    <Head3 className="mb-[40px] text-LAVEL_4">{name}</Head3>
                    <Row className=" items-center mb-[12px] w-full">
                        <Body2Regular className="mr-[4px] text-LAVEL_4">앱 사용</Body2Regular>
                        <Col className=" bg-primary rounded-full justify-center items-center w-[18px] h-[18px]">
                            <SvgIcon name={'SvgCheck'} size={10} fill={colors.trans} stroke={colors.white} />
                        </Col>
                    </Row>
                    <Body2Regular className="mr-[4px] text-LAVEL_4">{`강사코드 : ${code}`}</Body2Regular>
                </Col>
                {/* 오른쪽 프로필 */}
                <Col>
                    <Row className="mb-[40px]">
                        <Col className="mr-[126px] w-[148px]">
                            <Body3Regular className=" text-LAVEL_1">휴대폰 번호</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{phoneNumber}</Body2Regular>
                        </Col>
                        <Col className="">
                            <Body3Regular className=" text-LAVEL_1">이메일</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{email}</Body2Regular>
                        </Col>
                    </Row>
                    <Row className="mb-[40px]">
                        <Col className="mr-[126px] w-[148px]">
                            <Body3Regular className=" text-LAVEL_1">생년월일</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{birthDay}</Body2Regular>
                        </Col>
                        <Col className="">
                            <Body3Regular className=" text-LAVEL_1">성별</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{gender}</Body2Regular>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mr-[126px] w-[148px]">
                            <Body3Regular className=" text-LAVEL_1">입사일</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{startDate}</Body2Regular>
                        </Col>
                        <Col className="">
                            <Body3Regular className=" text-LAVEL_1">직급</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{jobPosition}</Body2Regular>
                        </Col>
                    </Row>
                </Col>

                <Col className=" absolute right-[56px] bottom-[56px]">
                    <Col className="mb-[3px]">
                        <Col className="bg-[#36393E] justify-center items-center px-[16px] py-[4px] rounded-[6px]">
                            <RegularText fontSize={13} lineHeight={18} color="white">
                                강사가 수강권을 발급할 수 있는 권한이에요
                            </RegularText>
                        </Col>
                        <div className="ml-[24px] borer-[#36393E] w-[10px] h-[10px] border-solid border-l-[0px] border-b-[10px] border-t-[0px] border-r-[10px] border-b-white  border-l-transparent  border-t-white border-r-transparent " />
                    </Col>
                    <Row
                        className={`py-[16px] px-[28.5px] mb-[10px]  rounded-lg flex items-center justify-between border-solid  ${
                            isMembershipPermissionAll
                                ? 'border border-[#5fddbf] bg-[#E6F7EF]'
                                : 'border border-[#acacac] bg-[#f2f2f2]'
                        }`}
                    >
                        <Body2Regular className="text-LAVEL_4 mr-[13px]">수강권 관리 권한</Body2Regular>
                        <NormalToggleButton isActive={isMembershipPermissionAll} callBack={onClickPermissionChange} />
                    </Row>
                    <Row className=" items-center">
                        <SvgIcon name={'SvgWarningInCircle'} size={20} fill={'#B4BCC4'} className="mr-[6px]" />
                        <RegularText fontSize={14} lineHeight={18} letterSpacing={-0.3} color="#3D4244">
                            수강권 수정은 오직 관리자만 할 수 있어요
                        </RegularText>
                    </Row>
                </Col>
            </Row>
            <Row className=" justify-between w-full">
                <TrashButton text={'강사 삭제'} onClick={onClickDeleteTrainer} />
                <NewColorRoundButton
                    bgColor="primary"
                    textColor="white"
                    text="프로필 편집"
                    width={126}
                    height={36}
                    onClick={onClickEditProfile}
                />
            </Row>
        </Col>
    );
};

export default TrainerProfileComponent;
