import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, convertToKRW } from '../../lib/utils';
import { BoldText, DemiLightText, MediumText, RegularText } from '../../lib/Text';
import dayjs from 'dayjs';
import NewColorSquareButton from '../../components/Button/NewColorSquareButton';
import { DailyInfo } from '../../services/useCenterQueryService';
import { MenuType } from '../Schedule/SettlementPage';

type ModalTotalSalesProps = {
    event?: DailyInfo & { type: 'salesAmount' | 'paymentCount' | 'completedCount' };
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onClickDetail?: (event: DailyInfo, type: MenuType) => void;
};

const ModalTotalSales = ({ event, setIsVisible, onClickDetail }: ModalTotalSalesProps) => {
    const date = dayjs(event?.start);
    const type: 'salesAmount' | 'paymentCount' | 'completedCount' | undefined = event?.type;

    // 매출금액
    const salesData = [
        { label: '결제 건수', value: event?.salesInfo?.paidSalesCount ?? 0 },
        { label: '취소 건수', value: event?.salesInfo?.cancelSalesCount ?? 0 },
        { label: '결제 금액', value: event?.salesInfo?.paidSales ?? 0 },
        { label: '취소 금액', value: event?.salesInfo?.cancelSales ?? 0 },
        { label: '매출 금액', value: event?.salesInfo?.sales ?? 0 },
    ];
    // 결제 수업수
    const paymentData = [
        { label: '결제 등록 수업 수', value: event?.paidScheduleInfo?.paidScheduleCount ?? 0 },
        { label: '결제 회원 수', value: event?.paidScheduleInfo?.paidMemberCount ?? 0 },
        { label: '신규 결제 회원', value: event?.paidScheduleInfo?.newPaymentCount ?? 0 },
        { label: '재등록 결제 회원', value: event?.paidScheduleInfo?.reRegistrationPaymentCount ?? 0 },
        { label: '분할 결제 회원', value: event?.paidScheduleInfo?.partialPaymentCount ?? 0 },
    ];
    // 완료한 수업 수
    const completeData = [
        { label: '총 수업 수', value: event?.scheduleCountInfo?.scheduleCount ?? 0 },
        { label: '출석 완료', value: event?.scheduleCountInfo?.attendanceScheduleCount ?? 0 },
        { label: '노쇼', value: event?.scheduleCountInfo?.noshowScheduleCount ?? 0 },
    ];

    const title = type === 'salesAmount' ? '매출 금액' : type === 'paymentCount' ? '결제 수업 수' : '잡힌 수업 수/노쇼';
    const mapData = type === 'salesAmount' ? salesData : type === 'paymentCount' ? paymentData : completeData;

    return (
        <Modal setIsVisible={setIsVisible} whiteViewWidth={543} borderRadius={25} isCloseOutside isCloseButton>
            <Col className="flex flex-col w-full h-full pt-[32px] px-[32px]">
                <DemiLightText fontSize={20} lineHeight={30} color="#6B7684" className="mb-[8px]" letterSpacing={-0.3}>
                    {date.format('MM월 DD일 ddd요일')}
                </DemiLightText>
                <BoldText fontSize={38} lineHeight={38} color="#3D4244" className="mb-[41px]">
                    {title}
                </BoldText>
                <div className="flex flex-col w-full h-full ">
                    <table className="table-auto w-full border-collapse mb-[27px]">
                        <tbody>
                            {mapData.map((item, index) => (
                                <tr key={index} className="border border-solid border-[#D9D9D9]">
                                    <td className="border-r-[1px] border-solid border-[#D9D9D9] w-[224px] h-[58px] bg-[#F4F5F7] pl-[17px]  align-middle">
                                        <RegularText fontSize={16} lineHeight={24} color="#6B7684">
                                            {item.label}
                                        </RegularText>
                                    </td>
                                    <td className="h-[58px] align-middle pl-[17px]">
                                        <RegularText fontSize={20} lineHeight={24} color="#3D4244">
                                            {convertToKRW(item.value)}
                                        </RegularText>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* 오늘보다 미래일경우 안보여줌 */}
                    {dayjs().isAfter(date) ? (
                        <NewColorSquareButton
                            className=" self-end "
                            width={150}
                            height={48}
                            borderRadius={8}
                            mb={32}
                            textColor="white"
                            bgColor="primary"
                            text="자세히보기"
                            onClick={() => {
                                onClickDetail &&
                                    onClickDetail(
                                        event as DailyInfo,
                                        type === 'salesAmount'
                                            ? 'salesList'
                                            : type === 'paymentCount'
                                            ? 'salesList'
                                            : 'classSales'
                                    );
                                setIsVisible(false);
                            }}
                        />
                    ) : (
                        <Col className="h-[80px]" />
                    )}
                </div>
            </Col>
        </Modal>
    );
};

export default ModalTotalSales;
