import React, { useRef, useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import { notify } from '../utils/helpers';
import '../css/react-big-calendar.css';
import dayjs from 'dayjs';
import { getWeekOfMonth, isOffRange } from '../utils/date';
import { WeeklyTotal } from '../../../services/useCenterQueryService';

// import { isEvent, isShowMore, getBoundsForNode, getSlotAtX, dateCellSelection } from './utils/selection';
// import Selection from './Selection';

interface BackgroundCellsProps {
    range: Date[];
    getNow: () => Date;
    getters: {
        dayProp: (date: Date) => { className: string; style: React.CSSProperties };
    };
    date: Date;
    components: {
        dateCellWrapper: React.ComponentType<any>;
    };
    selectable?: boolean | 'ignoreEvents';
    container?: () => HTMLElement;
    onSelectSlot?: (slot: any) => void;
    onSelectStart?: (box: any) => void;
    onSelectEnd?: (state: any) => void;
    longPressThreshold?: number;
    rtl?: boolean;
    resourceId?: string;
    monthCellHeaderCustomComponent?: (date: Date) => React.ReactNode;
    weeklyEvents?: any[];
    monthWeeklyCustomComponent?: (event: WeeklyTotal, isLastWeek: boolean) => React.ReactNode;
    isLastWeek?: boolean;
}

const BackgroundCells: React.FC<BackgroundCellsProps> = ({
    range,
    getNow,
    getters,
    date: currentDate,
    components: { dateCellWrapper: Wrapper },
    selectable,
    container,
    onSelectSlot,
    onSelectStart,
    onSelectEnd,
    longPressThreshold = 250,
    rtl = false,
    resourceId,
    monthCellHeaderCustomComponent,
    weeklyEvents,
    monthWeeklyCustomComponent,
    isLastWeek = false,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [selecting, setSelecting] = useState(false);
    const [startIdx, setStartIdx] = useState<number | null>(null);
    const [endIdx, setEndIdx] = useState<number | null>(null);
    const [initial, setInitial] = useState<{ x: number; y: number } | null>(null);
    const selectorRef = useRef<Selection | null>(null);

    const handleSelectSlot = useCallback(
        (slot: any) => {
            if (onSelectSlot) {
                onSelectSlot({
                    start: range[slot.start],
                    end: range[slot.end],
                    action: slot.action,
                    bounds: slot.bounds,
                    box: slot.box,
                    resourceId,
                });
            }
        },
        [range, onSelectSlot, resourceId]
    );
    // range 배열안에서 이번달인거 찾기
    const rangeIndex = range.findIndex((date) => dayjs(date).month() === dayjs(currentDate).month());
    const weekNumber = getWeekOfMonth(dayjs(range[rangeIndex]));

    const handleShowMore = useCallback(
        (slot: number, target: any) => {
            const row = containerRef.current?.querySelector('.rbc-row-bg') as HTMLElement;
            const cell = row?.children[slot - 1] as HTMLElement;
            const events = []; // You can replace this with the actual logic for fetching events for the slot
            if (cell && onSelectSlot) {
                onSelectSlot({
                    start: range[slot - 1],
                    end: range[slot - 1],
                    action: 'showMore',
                    // bounds: getBoundsForNode(cell),
                    box: target,
                });
            }
        },
        [range, onSelectSlot]
    );

    useEffect(() => {
        if (!selectable) return;

        const node = containerRef.current;
        if (!node) return;

        // const selector = new Selection(container, { longPressThreshold });
        // selectorRef.current = selector;

        const handleSelecting = (box: any) => {
            if (!selecting) {
                notify(onSelectStart, [box]);
                setInitial({ x: box.x, y: box.y });
            }

            // const nodeBox = getBoundsForNode(node);
            // const { startIdx, endIdx } = dateCellSelection(initial, nodeBox, box, range.length, rtl);

            setSelecting(true);
            setStartIdx(startIdx);
            setEndIdx(endIdx);
        };

        const handleSelect = (bounds: any) => {
            if (startIdx !== null && endIdx !== null) {
                handleSelectSlot({
                    startIdx,
                    endIdx,
                    action: 'select',
                    bounds,
                    box: bounds,
                });
            }

            setSelecting(false);
            setInitial(null);
            notify(onSelectEnd, [{ startIdx, endIdx }]);
        };

        // selector.on('selecting', handleSelecting);
        // selector.on('select', handleSelect);

        return () => {
            // selector.teardown();
            // selectorRef.current = null;
        };
    }, [
        selectable,
        container,
        longPressThreshold,
        range,
        rtl,
        initial,
        selecting,
        startIdx,
        endIdx,
        handleSelectSlot,
        onSelectStart,
        onSelectEnd,
    ]);

    const renderDefaultHeader = (date: Date) => {
        return (
            <>
                {range.map((_date, index) => {
                    // const { className, style } = getters.dayProp(_date);
                    const isToday = getNow().toDateString() === _date.toDateString();
                    // const isOffRange = currentDate.getMonth() !== _date.getMonth();
                    const isOffDate = isOffRange(dayjs(date), dayjs(_date));
                    const isSelected = selecting && index >= (startIdx || 0) && index <= (endIdx || 0);

                    return (
                        // <Wrapper key={index} value={_date} range={range}>
                        <div
                            // style={style}
                            className={clsx(
                                'rbc-day-bg',
                                // className,
                                isToday && 'rbc-today',
                                isOffDate && 'rbc-off-range-bg',
                                isSelected && 'rbc-selected-cell'
                            )}
                        >
                            <span className="flex w-full ">{dayjs(_date).format('D')}</span>
                        </div>
                        // </Wrapper>
                    );
                })}
                {/* 합산 칼럼 추가 */}
                <div className="rbc-day-bg rbc-summary-column">
                    <span className="rbc-date-cell font-bold text-center">합산</span>
                </div>
            </>
        );
    };

    const renderCustomHeader = (date: Date) => {
        // 이번달의 몇번째 주인지

        return (
            <div className="flex w-full ">
                {range.map((date, index) => {
                    // const isToday = getNow().toDateString() === date.toDateString();
                    // const isOffDate = currentDate.getMonth() !== date.getMonth();
                    // const isSelected = selecting && index >= (startIdx || 0) && index <= (endIdx || 0);
                    const isTotalColumn = index === range.length - 1;
                    const weeklyEvent = isTotalColumn && weeklyEvents?.[weekNumber - 1];

                    return (
                        <React.Fragment key={'BackgruondCells' + index}>
                            <div
                                // style={style}
                                className={clsx(
                                    'flex flex-col w-full h-full'

                                    // className,
                                    // isToday && 'rbc-today',
                                    // isOffDate && 'rbc-off-range-bg',
                                    // isSelected && 'rbc-selected-cell'
                                )}
                            >
                                {monthCellHeaderCustomComponent && monthCellHeaderCustomComponent(date)}
                            </div>
                            {/* 합산 칼럼 추가 */}
                            {weeklyEvent &&
                                index === range.length - 1 &&
                                monthWeeklyCustomComponent &&
                                monthWeeklyCustomComponent(weeklyEvent, isLastWeek)}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="flex absolute w-full h-full z-0" ref={containerRef}>
            {monthCellHeaderCustomComponent ? renderCustomHeader(currentDate) : renderDefaultHeader(currentDate)}
        </div>
    );
};

export default BackgroundCells;
