/**
 * 주어진 객체 또는 데이터에 대해 accessor-like 속성으로 값을 가져옵니다.
 *
 *    accessor(obj, 'name')   // => obj['name'] 값을 반환
 *    accessor(data, func)    // => func(data) 호출 후 반환
 *    ... 그 외 null 반환
 */
export function accessor<T>(data: T, field: keyof T | ((data: T) => any)): any {
    let value: any = null;

    if (typeof field === 'function') {
        // field가 함수일 경우, data를 함수에 전달
        value = field(data);
    } else if (typeof field === 'string' && typeof data === 'object' && data != null && field in data) {
        // field가 문자열이고, data 객체에 해당 키가 존재할 경우
        value = data[field];
    }

    return value;
}

export const wrapAccessor = <T>(acc: keyof T | ((data: T) => any)) => {
    // 주어진 accessor를 감싸는 함수 반환
    return (data: T): any => accessor(data, acc);
};
