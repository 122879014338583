import { View, ViewsProps } from '../types/types';
import { views } from '../utils/constants';
import Month from './Month';

// import DayView from './Day';
// import WeekView from './Week';
// import WorkWeekView from './WorkWeek';
// import AgendaView from './Agenda';

// VIEWS 객체 생성
const Views: any = {
    [views.MONTH]: Month,
    // [views.WEEK]: WeekView,
    // [views.WORK_WEEK]: WorkWeekView,
    // [views.DAY]: DayView,
    // [views.AGENDA]: AgendaView,
};

export default Views;
