import * as React from 'react';

import {
    Cell,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Header,
    PaginationState,
    useReactTable,
} from '@tanstack/react-table';
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    type DragEndEvent,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DemiLightText, MediumText } from '../../lib/Text';
import SvgIcon from '../SvgIcon';
import { Col, Row } from '../../lib/utils';
import { size } from 'lodash';
import clsx from 'clsx';

type Props = {
    data: any[];
    setData?: any;
    columns: any[];
    globalFilter?: string;
    onGlobalFilterChange?: any;
    selection?: any;
    onSelectionChange?: any;
    onRowCountChange?: any;
    columnsToSum?: string[];
    onColumnTotalsChange?: (totals: any) => void;
    width?: string | number;
    height?: string | number;
    isPagination?: boolean;
    rowHeight?: number;
    // 다음, 이전
    //    () => table.previousPage()
    //!table.getCanPreviousPage()
    //    () => table.nextPage()
    //!table.getCanNextPage()
    // customPaginationComponent?: (
    //     previousPage: () => void,
    //     canPreviousPage: boolean,
    //     nextPage: () => void,
    //     canNextPage: boolean
    // ) => React.ReactNode;
    customPaginationComponent?: ({
        firstPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
    }: {
        firstPage: () => void;
        previousPage: () => void;
        canPreviousPage: boolean;
        nextPage: () => void;
        canNextPage: boolean;
    }) => React.ReactNode;
    isColumnVisibility?: boolean;
    //table.getAllLeafColumns()
    columnVisibility?: any;
    setColumnVisibility?: any;
    className?: string;
    onRowClick?: any;
};
function useSkipper() {
    const shouldSkipRef = React.useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
        shouldSkipRef.current = false;
    }, []);

    React.useEffect(() => {
        shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
}

const Table = React.forwardRef((props: Props, ref) => {
    const {
        data: _data,
        setData,
        columns: _columns,
        globalFilter: _globalFilter,
        onGlobalFilterChange,
        isPagination = false,
        rowHeight = 73,
        customPaginationComponent,
        selection,
        onSelectionChange,
        columnsToSum,
        onColumnTotalsChange,
        isColumnVisibility,
        columnVisibility,
        setColumnVisibility,
        width,
        className,
        height,
        onRowClick,
        ...otherProps
    } = props;

    const columns = [
        ...(onSelectionChange
            ? [
                  {
                      id: 'selection',
                      header: ({ table }: { table: any }) => (
                          <IndeterminateCheckbox
                              {...{
                                  checked: table.getIsAllRowsSelected(),
                                  indeterminate: table.getIsSomeRowsSelected(),
                                  onChange: table.getToggleAllRowsSelectedHandler(),
                              }}
                          />
                      ),
                      cell: ({ row }: { row: any }) => (
                          <div className="px-1">
                              <IndeterminateCheckbox
                                  {...{
                                      checked: row.getIsSelected(),
                                      disabled: !row.getCanSelect(),
                                      indeterminate: row.getIsSomeSelected(),
                                      onChange: row.getToggleSelectedHandler(),
                                  }}
                              />
                          </div>
                      ),
                      sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
                      ordering: false, //disable sorting
                      size: 40,
                  },
              ]
            : []), // onSelectionChange가 없으면 빈 배열 반환,
        ..._columns,
    ];
    const [columnOrder, setColumnOrder] = React.useState<string[]>(() => columns.map((c) => c.id));
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

    const table = useReactTable({
        data: _data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),

        state: {
            globalFilter: _globalFilter ? _globalFilter : undefined,
            rowSelection: selection ? selection : undefined,
            // columnOrder,
            pagination: isPagination ? pagination : undefined,
            columnVisibility: isColumnVisibility ? columnVisibility : undefined,
        },
        onGlobalFilterChange: onGlobalFilterChange,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: isPagination ? getPaginationRowModel() : undefined,

        onRowSelectionChange: onSelectionChange,
        enableRowSelection: true,

        onColumnOrderChange: setColumnOrder,
        onPaginationChange: setPagination,

        onColumnVisibilityChange: setColumnVisibility,
        autoResetPageIndex,
        meta: {
            updateData: (rowIndex: any, columnId: any, value: any) => {
                // Skip page index reset until after next rerender
                //   skipAutoResetPageIndex()
                skipAutoResetPageIndex();

                setData((old: any) =>
                    old.map((row: any, index: any) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex]!,
                                [columnId]: value,
                            };
                        }
                        return row;
                    })
                );
            },
        },
    });

    React.useImperativeHandle(ref, () => ({
        getTableInstance: () => table, // table 인스턴스 반환
        table,
    }));

    // Calculate totals for specified columns
    const calculateTotals = () => {
        const totals = columnsToSum?.reduce((acc: any, col) => {
            acc[col] = table
                .getRowModel()
                .rows.map((row) => row.getValue(col))
                .reduce((sum: any, value) => sum + (value || 0), 0);
            return acc;
        }, {});

        return totals;
    };

    // reorder columns after drag & drop
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id as string);
                const newIndex = columnOrder.indexOf(over.id as string);
                return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
            });
        }
    }
    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

    // 로우 카운터

    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
        >
            <div className="flex flex-col w-full relative">
                <div
                    className={clsx(
                        ' flex flex-col relative  overflow-auto scrollbar scrollbar-thumb-[#B4B4B6] scrollbar-corner-GREY_100 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-h-[10px] scrollbar-w-[6px]',
                        className
                    )}
                    {...otherProps}
                    style={{ height: height }}
                >
                    <table style={{ width: width }}>
                        <thead className="sticky top-0  bg-[#EEF0F2] z-[20]">
                            {table.getHeaderGroups().map((headerGroup, index) => (
                                <tr key={headerGroup.id}>
                                    <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                                        {headerGroup.headers.map((header) => (
                                            <DraggableTableHeader
                                                key={header.id}
                                                header={header}
                                                totalLength={headerGroup.headers.length - 1}
                                            />
                                        ))}
                                    </SortableContext>
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    key={row.id} // ✅ `row.id`를 사용하여 고유 key 설정
                                    style={{ height: rowHeight }}
                                    className={` border-t border-b border-gray-200 hover:bg-[#F8F9FB] items-center justify-center 
                                        ${onRowClick ? 'cursor-pointer' : ''}`}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <SortableContext
                                            items={columnOrder}
                                            strategy={horizontalListSortingStrategy}
                                            key={'SortableContext' + cell.id}
                                        >
                                            <DragAlongCell cell={cell} onRowClick={onRowClick} />
                                        </SortableContext>
                                        // <td key={cell.id} className=" border-solid border-[1px]">
                                        //     {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        // </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot className="">
                            {table.getFooterGroups().map((footerGroup) => (
                                <tr key={footerGroup.id}>
                                    {footerGroup.headers.map((header) => (
                                        <th key={header.id} className="">
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.footer, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>
                    </table>
                </div>
                {isPagination ? (
                    customPaginationComponent ? (
                        customPaginationComponent({
                            firstPage: () => table.firstPage(),
                            previousPage: () => table.previousPage(),
                            canPreviousPage: !table.getCanPreviousPage(),
                            nextPage: () => table.nextPage(),
                            canNextPage: !table.getCanNextPage(),
                        })
                    ) : (
                        <div className="flex items-center gap-2 my-[16px] self-center">
                            <button
                                className="border rounded p-1"
                                onClick={() => table.firstPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {'<<'}
                            </button>
                            <button
                                className="border rounded p-1"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {'<'}
                            </button>
                            <button
                                className="border rounded p-1"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                {'>'}
                            </button>
                            <button
                                className="border rounded p-1"
                                onClick={() => table.lastPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                {'>>'}
                            </button>
                            <span className="flex items-center gap-1">
                                <div>Page</div>
                                <strong>
                                    {table.getState().pagination.pageIndex + 1} of{' '}
                                    {table.getPageCount().toLocaleString()}
                                </strong>
                            </span>
                            <span className="flex items-center gap-1">
                                | Go to page:
                                <input
                                    type="number"
                                    min="1"
                                    max={table.getPageCount()}
                                    defaultValue={table.getState().pagination.pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        table.setPageIndex(page);
                                    }}
                                    className="border p-1 rounded w-16"
                                />
                            </span>
                            {isPagination && (
                                <select
                                    value={table.getState().pagination.pageSize}
                                    onChange={(e) => {
                                        table.setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    )
                ) : null}
            </div>
        </DndContext>
    );
});

export default Table;

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />;
}

const DragAlongCell = ({ cell, onRowClick }: { cell: Cell<unknown, unknown>; onRowClick: any }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
        id: cell.column.id,
    });

    const style: React.CSSProperties = {
        opacity: isDragging ? 0.8 : 1,
        position: 'relative',
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: 'width transform 0.2s ease-in-out',
        width: cell.column.getSize(),
        zIndex: isDragging ? 1 : 0,
        borderBottom: '1px solid #E5E5E5',
        justifyContent: 'center',
        // padding: '20px 0',
        alignContent: 'center',
        height: '100%',
    };

    return (
        <td style={style} ref={setNodeRef} onClick={() => onRowClick && onRowClick(cell.row.original)}>
            <DemiLightText
                fontSize={16}
                lineHeight={24}
                color="#3D4244"
                className="justify-center items-center flex w-full h-full relative"
            >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </DemiLightText>
        </td>
    );
};

const DraggableTableHeader = ({ header, totalLength }: { header: Header<unknown, unknown>; totalLength: number }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
        id: header.column.id,
    });

    const style: React.CSSProperties = {
        opacity: isDragging ? 0.8 : 1,
        position: 'relative',
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: 'width transform 0.2s ease-in-out',
        whiteSpace: 'nowrap',
        width: header.column.getSize(),
        zIndex: isDragging ? 1 : 0,

        padding: '10px 0',
        borderTopLeftRadius: header.index === 0 ? '8px' : '0',
        // 맨 마지막 칼럼 border-radius
        borderTopRightRadius: header.index === totalLength ? '8px' : '0',
        borderBottomLeftRadius: header.index === 0 ? '8px' : '0',
        borderBottomRightRadius: header.index === totalLength ? '8px' : '0',
    };

    const isSelectionColumn = header.column.id === 'selection';
    //
    return (
        <th
            colSpan={header.colSpan}
            ref={setNodeRef}
            style={style}
            className={`cursor-pointer items-center justify-center`}
        >
            <Row className="flex w-full  items-center justify-center ">
                {/* {{
                    asc: ' 🔼',
                    desc: ' 🔽',
                }[header.column.getIsSorted() as string] ?? null} */}
                <Row
                    {...attributes}
                    {...listeners}
                    className="flex items-center justify-center text-center content-center "
                >
                    <DemiLightText
                        fontSize={16}
                        lineHeight={24}
                        color="#5D6166"
                        className="flex- h-full w-full justify-center items-center content-center relative "
                    >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </DemiLightText>
                </Row>

                {!isSelectionColumn && header.column.getCanSort() && (
                    <Col className=" z-100" onClick={header.column.getToggleSortingHandler()}>
                        <SvgIcon
                            name={'SvgFillUpArrow'}
                            style={{ marginBottom: 2 }}
                            width={14}
                            height={8}
                            fill="#5D6166"
                        />
                        <SvgIcon name={'SvgFillDownArrow'} width={14} height={8} fill="#5D6166" />
                    </Col>
                )}
                {/* {!isSelectionColumn && (
                    <button {...attributes} {...listeners}>
                        🟰
                    </button>
                )} */}
            </Row>
        </th>
    );
};
