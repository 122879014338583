import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { BoldText, DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../../components/SvgIcon';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import NewCalendar from '../../components/Calendar/NewCalendar';
import { MemberMembershipDataType, UnpaidPaymentTableType } from '../../services/useMembershipQueryService';
import useMembershipPaymentsMutationService from '../../services/useMembershipPaymentsMutationService';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    unpaidRowData?: UnpaidPaymentTableType;
    onPostSplitPaymentSuccess?: () => void;
    membership?: MemberMembershipDataType;
    coachName?: string;
    memberName?: string;
};

const ModalPartialPayment = ({
    setIsVisible,
    unpaidRowData,
    onPostSplitPaymentSuccess,
    membership,
    coachName: _coachName,
    memberName: _memberName,
}: Props) => {
    const queryClient = useQueryClient();
    const { postSplitPaymentMutation } = useMembershipPaymentsMutationService({
        onPostSplitPaymentSuccess: onPostSplitPaymentSuccess,
    });
    const membershipId = membership ? membership._id : unpaidRowData?.membershipId || '';
    const coachName = membership ? _coachName : unpaidRowData?.coachName || '';
    const memberName = membership ? _memberName : unpaidRowData?.memberName || '';
    const BGColor = membership ? membership.BGColor : unpaidRowData?.BGColor || ['#3248d8', '#176c53'];
    const isActive = true;
    const membershipName = membership ? membership.name : unpaidRowData?.name || '';

    // 판매가 , 결제금액, 미수금액
    const totalPaymentAmount = (membership ? membership.totalPaymentAmount : unpaidRowData?.totalPaymentAmount) || 0;
    const totalPrice = (membership ? membership.totalPrice : unpaidRowData?.totalPrice) || 0;
    const totalUnpaidAmount = (membership ? membership.totalUnpaidAmount : unpaidRowData?.totalUnpaidAmount) || 0;

    // 분할결제 금액, 분할 결제일시
    const [partialPrice, setPartialPrice] = useState(0);
    const [partialDate, setPartialDate] = useState(dayjs().toISOString());

    const [isPriceError, setIsPriceError] = useState(false);
    const divRef = React.useRef<HTMLDivElement>(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [divPosition, setDivPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        if (divRef.current) {
            const rect = divRef.current.getBoundingClientRect();
            setDivPosition({ top: rect.top + rect.height, left: rect.left });
        }
    }, [divRef]);

    // 엔터 입력시 분할결제하기
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                if (isPriceError || !partialPrice) return;
                setIsVisible(false);
                postSplitPaymentMutation.mutate({
                    membershipId,
                    amount: partialPrice,
                    date: partialDate,
                });
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isPriceError, partialPrice, partialDate, postSplitPaymentMutation, membershipId, setIsVisible]);

    return (
        <Modal
            setIsVisible={setIsVisible}
            borderRadius={25}
            whiteViewWidth={825}
            isCloseButton
            isCloseOutside={false}
            modalCommpont={
                isCalendarOpen ? (
                    <NewCalendar
                        date={dayjs(partialDate)}
                        selectedDay={dayjs(partialDate)}
                        setIsCalendar={setIsCalendarOpen}
                        onClickDate={(day) => {
                            setPartialDate(dayjs(day).toISOString());
                        }}
                        isAllDate={false}
                        top={divPosition.top - 250}
                        left={divPosition.left}
                        isNextMonth
                        isPrevMonth
                        titleFormat="YYYY년 MM월"
                    />
                ) : undefined
            }
        >
            <Col className="p-[35px]">
                <Row className="items-center mb-[57px]">
                    <MediumText fontSize={38} lineHeight={45.6} color="#3D4244" className="mr-[31px]">
                        분할 결제
                    </MediumText>
                    <MediumText fontSize={23} lineHeight={33} color="#3D4244" className="mr-[8px]">
                        {coachName} 강사
                    </MediumText>
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={20}
                        fill={colors.trans}
                        stroke="#3D4244"
                        strokeWidth={1.5}
                    />
                    <MediumText fontSize={23} lineHeight={33} color="#3D4244" className="ml-[8px]">
                        {memberName} 회원
                    </MediumText>
                </Row>
                <Row className="flex justify-between w-full items-center mb-[51px]">
                    <Col className="flex flex-col w-full items-center">
                        <DemiLightText fontSize={16} lineHeight={22} color="#3D4244" className="mb-[20px]">
                            현재 수강권 정보
                        </DemiLightText>
                        <Col
                            className="flex flex-col w-full rounded-tl-[12px] rounded-tr-[12px] h-[88px]  justify-center items-center "
                            style={{
                                background: isActive
                                    ? `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`
                                    : colors.GREY_200,
                                alignItems: 'center',
                            }}
                        >
                            <Col className="flex w-full h-full pt-[16px] pb-[21px] pl-[24px] justify-between items-start">
                                <BoldText fontSize={18} lineHeight={28} color="white">{`${membershipName}`}</BoldText>
                            </Col>
                        </Col>
                        <Col className="flex border border-solid border-[#E5E5E9] pl-[29px] pr-[21px] py-[31px]  rounded-bl-[12px] rounded-br-[12px] h-[250px] w-full">
                            <Row className="flex justify-between w-full mb-[17px]">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    판매가
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {convertToKRW(totalPrice)}
                                </RegularText>
                            </Row>

                            <Row className="flex justify-between w-full mb-[17px]">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    결제 금액
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {convertToKRW(totalPaymentAmount)}
                                </RegularText>
                            </Row>

                            <Row className="flex justify-between w-full">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    미수금액
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {convertToKRW(totalUnpaidAmount)}
                                </RegularText>
                            </Row>
                        </Col>
                    </Col>
                    <Col className="flex flex-col w-[1px] h-[375px] bg-[#D9D9D9] mx-[41px]" />
                    <Col className="flex flex-col w-full h-[309px] ">
                        <Col className="mb-[33px]">
                            <Row className="items-center mb-[12px]">
                                <RegularText fontSize={16} lineHeight={23} color="#505967" className=" mr-[12px]">
                                    분할 결제 금액
                                </RegularText>
                                <RegularText
                                    fontSize={11}
                                    lineHeight={17}
                                    color={isPriceError ? 'red' : '#505967'}
                                    letterSpacing={-0.3}
                                >
                                    {isPriceError ? '분할 결제금액이 미수금액을 초과할 수 없습니다' : ''}
                                </RegularText>
                            </Row>
                            <TextInput
                                value={partialPrice}
                                onChange={(value) => {
                                    // 숫자만 입력
                                    const reg = /^[0-9]*$/;
                                    if (reg.test(value as string) === false) {
                                        return;
                                    }
                                    // 남은 회차 초과 입력 방지
                                    if ((value as number) > totalUnpaidAmount) {
                                        setIsPriceError(true);
                                        setPartialPrice(value as number);
                                        return;
                                    } else {
                                        setPartialPrice(value as number);
                                        setIsPriceError(false);
                                    }
                                }}
                                unit="원"
                                placeholder="0"
                                width={116}
                                error={isPriceError}
                            />
                        </Col>

                        <Col ref={divRef} className="flex flex-col">
                            <RegularText fontSize={16} lineHeight={23} color="#505967" className="mb-[12px]">
                                분할 결제 일시
                            </RegularText>
                            <Col
                                className={`flex  border  border-solid h-[46px] justify-center rounded-[10px] bg-white px-[18px] cursor-pointer hover:border-primary transition-colors duration-300 ease-in-out ${
                                    isCalendarOpen ? 'border-primary' : 'border-[#D9D9D9B2]'
                                }`}
                                onClick={() => {
                                    setIsCalendarOpen(true);
                                }}
                            >
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {dayjs(partialDate).format('YYYY-MM-DD')}
                                </RegularText>
                            </Col>
                        </Col>
                    </Col>
                </Row>
                <Col
                    className={`flex justify-center items-center self-end w-[214px] h-[71px]  bg-primary rounded-[9px] 
                        ${isPriceError || !partialPrice ? 'opacity-50 cursor-not-allowed' : ' cursor-pointer'}
                        `}
                    onClick={() => {
                        if (isPriceError || !partialPrice) return;
                        setIsVisible(false);
                        postSplitPaymentMutation.mutate({
                            membershipId,
                            amount: partialPrice,
                            date: partialDate,
                        });
                    }}
                >
                    <RegularText fontSize={20} lineHeight={23} color="white">
                        분할 결제하기
                    </RegularText>
                </Col>
            </Col>
        </Modal>
    );
};

export default ModalPartialPayment;

type TextInputProps = {
    value: string | number;
    onChange: (value: string | number) => void;
    unit?: string; // 단위 (예: %, 원)
    placeholder?: string; // 플레이스홀더 텍스트
    width?: number; // 입력 필드 너비
    disabled?: boolean; // 비활성화 여부
    onBlur?: () => void;
    error?: boolean;
};
const TextInput: React.FC<TextInputProps> = ({
    value,
    onChange,
    unit = '',
    placeholder = '',
    width = 116,
    disabled = false,
    onBlur,
    error = false,
}) => {
    // 포커스
    const [isFocused, setIsFocused] = useState(false);
    const ref = React.useRef<HTMLInputElement>(null);
    //
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    return (
        <div
            className={`flex items-center border  border-solid h-[46px] rounded-[10px] bg-white px-[18px] cursor-text hover:border-primary transition-colors duration-300 ease-in-out ${
                disabled ? 'bg-gray-100 text-gray-400' : 'bg-white'
            } ${isFocused ? 'border-primary' : error ? 'border-red' : 'border-[#D9D9D9B2]'}`}
            onClick={() => {
                ref?.current?.focus();
            }}
        >
            <input
                ref={ref}
                type="text"
                value={value}
                onChange={(e) => {
                    // 숫자입력시 0으로 시작하는 경우 0 제거
                    if (e.target.value.length > 1 && e.target.value[0] === '0') {
                        onChange(e.target.value.slice(1));
                        return;
                    }

                    // setText(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={placeholder}
                className={`flex flex-grow outline-none w-[80px] text-[16px] leading-[23px] text-[#3D4244]  ${
                    disabled ? 'cursor-not-allowed' : ''
                }`}
                // onFocus={() => setIsFocused(true)}
                // onBlur={() => {
                //     // onChange(text);
                //     setIsFocused(false);
                // }}
                onBlur={onBlur}
                disabled={disabled}

                // 숫자만
            />
            {unit && <DemiLightText className="flex">{unit}</DemiLightText>}
        </div>
    );
};
