import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from '../../lib/utils';
import { Body1Regular, Body3Regular, CaptionRegular } from '../../lib/font';
import dayjs from 'dayjs';
import { colors } from '../../lib/colors';
import SmallToggleButton from '../Button/SmallToggleButton';
import { I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../types/images';
import ResetButton from '../Button/ResetButton';

type Props = {
    currentDate: any;
    selectedDay: any;
    pageType: 'daily' | 'weekly';
    setPageType: Dispatch<SetStateAction<'daily' | 'weekly'>>;
    onClickSubDate: () => void;
    onClickAddDate: () => void;
    onClickDateToToday: () => void;
    onClickCalendar: () => void;
};

const ScheduleTop = ({
    currentDate,
    selectedDay,
    pageType,
    setPageType,
    onClickSubDate,
    onClickAddDate,
    onClickDateToToday,
    onClickCalendar,
}: Props) => {
    const baseDate = dayjs(selectedDay);
    const startWeekNumberDate = baseDate.startOf('month').startOf('week');
    const endWeekNumberDate = baseDate.startOf('week');
    let weekDiff = endWeekNumberDate.diff(startWeekNumberDate, 'day', true);
    let weekNumber = Math.floor(weekDiff) / 7 + 1; // 0주차는 존재할 수 없으니 1을 더해줌
    return (
        <Col
            style={{ width: '100%', height: '88px', position: 'fixed', backgroundColor: colors.WHITE_200, zIndex: 10 }}
        >
            <Row
                style={{
                    width: '100%',
                    marginTop: 24,
                    marginBottom: 24,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: '4.44%',
                    paddingRight: '4.44%',
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <SmallToggleButton
                        leftText={'일간'}
                        rightText={'주간'}
                        isLeft={pageType === 'daily'}
                        isRight={pageType === 'weekly'}
                        callBackLeft={() => setPageType('daily')}
                        callBackRight={() => setPageType('weekly')}
                        // customStyle={{ width: '15.5%' }}
                    />

                    <Row
                        style={{
                            width: 232,
                            height: 32,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginRight: 24,
                            marginLeft: 24,
                        }}
                    >
                        <button onClick={onClickSubDate} style={{ width: 24, height: 24 }}>
                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body3Regular
                            onClick={onClickCalendar}
                            style={{ width: 160, textAlign: 'center', color: colors.LAVEL_2, cursor: 'pointer' }}
                        >
                            {pageType === 'weekly'
                                ? `${dayjs(selectedDay).format('YYYY년 MM월')} ${weekNumber}주차`
                                : dayjs(selectedDay).format('YYYY년 MM월 DD일(ddd)')}
                        </Body3Regular>
                        <button onClick={onClickAddDate} style={{ width: 32, height: 32 }}>
                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>

                    <ResetButton text="오늘" style={{}} callBack={onClickDateToToday} />
                </Row>

                <Row style={{ alignItems: 'center' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Row
                            style={{
                                width: 16,
                                height: 16,
                                border: '2px solid #3D73DD',
                                backgroundColor: colors.WHITE_50,
                                borderRadius: 2,
                                marginRight: 8,
                            }}
                        />
                        <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'수업 시간'}</CaptionRegular>
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 24 }}>
                        <Row
                            style={{
                                width: 16,
                                height: 16,
                                border: '2px solid #FF8777',
                                backgroundColor: colors.WHITE_50,
                                borderRadius: 2,
                                marginRight: 8,
                            }}
                        />
                        <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'수업 신청 대기'}</CaptionRegular>
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 24 }}>
                        <Row
                            style={{
                                width: 16,
                                height: 16,
                                border: '2px solid #6E6E6E',
                                backgroundColor: colors.WHITE_50,
                                borderRadius: 2,
                                marginRight: 8,
                            }}
                        />
                        <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'일반 일정'}</CaptionRegular>
                    </Row>

                    {pageType === 'daily' && (
                        <Row style={{ alignItems: 'center', marginLeft: 24 }}>
                            <Row
                                style={{
                                    width: 16,
                                    height: 16,
                                    backgroundColor: colors.WHITE_600,
                                    borderRadius: 2,
                                    marginRight: 8,
                                }}
                            />
                            <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'OFF'}</CaptionRegular>
                        </Row>
                    )}
                    <Row style={{ alignItems: 'center', marginLeft: 24 }}>
                        <div
                            style={{
                                width: 6,
                                height: 6,
                                backgroundColor: colors.ERROR,
                                borderRadius: '50%',
                                marginRight: 8,
                            }}
                        />
                        <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'노쇼'}</CaptionRegular>
                    </Row>
                </Row>
            </Row>
        </Col>
    );
};

export default ScheduleTop;
