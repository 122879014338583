import React, { useEffect } from 'react';
import { Col, convertToKRW, Row } from '../../lib/utils';
import dayjs, { Dayjs } from 'dayjs';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../../components/SvgIcon';
import { colors } from '../../lib/colors';
import Dropdown from '../../components/DropDown/DropDown';
import {
    MembershipPaymentData,
    SalesDataResponse,
    SalesListSummaryData,
    SalesManager,
} from '../../services/useMembershipPaymentsQueryService';
import MemberSearchTextInput from '../../components/Button/MemberSearchTextInput';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../components/Table/Table';
import * as XLSX from 'xlsx'; // 엑셀 변환 라이브러리
import { saveAs } from 'file-saver'; // 파일 저장 라이브러리
import { useNavigate } from 'react-router-dom';
import { TooltipIcon } from './SettlementCalendar';
import { CodeValueType } from '../../types/types';
import NewCalendar from '../../components/Calendar/NewCalendar';
import { attendanceStatusData, memberStatusData } from '../../lib/varialbe';

type Props = {
    date: string;
    onMonthNavigate: (date: Date | string | Dayjs) => void;
    salesListData: SalesDataResponse;
    selectedSalesManager: SalesManager;
    onSalesManagerSelect: (salesManager: CodeValueType) => void;
    selectedSalesListDate: Dayjs | string | undefined;
    onChangeSelectedSalesListDate: (date: Dayjs | string | undefined) => void;
};
const columnHelper = createColumnHelper<MembershipPaymentData>();

// 판매상태, 결제일시, 판매담당자, 담당강사, 회원, 결제금액, 취소액, 총 회차, 수강권, 이용기간
const columns = [
    columnHelper.accessor('saleStatus', {
        id: 'saleStatus',
        header: '판매상태',
        cell: (info) => {
            const text =
                info.getValue() === 'COMPLETED'
                    ? '완료'
                    : info.getValue() === 'UPGRADED'
                    ? '업그레이드'
                    : info.getValue() === 'PARTIAL_REFUND'
                    ? '부분 환불'
                    : info.getValue() === 'PARTIAL_PAYMENT'
                    ? '분할 결제'
                    : '전체 환불';
            const textColor =
                info.getValue() === 'COMPLETED'
                    ? '#3D4244'
                    : info.getValue() === 'UPGRADED'
                    ? '#9536D4'
                    : info.getValue() === 'PARTIAL_REFUND'
                    ? '#FF7220'
                    : info.getValue() === 'PARTIAL_PAYMENT'
                    ? '#15BA72'
                    : '#FF2020';
            return (
                <RegularText fontSize={16} lineHeight={24} color={textColor}>
                    {text}
                </RegularText>
            );
        },

        size: 130,
        enableSorting: false,
    }),
    columnHelper.accessor('paymentDate', {
        id: 'paymentDate',
        header: '결제일시',
        cell: (info) => dayjs(info.getValue()).format('YYYY-MM-DD'),
        size: 146,
        enableSorting: false,
    }),
    columnHelper.accessor('salesManager', {
        id: 'salesManager',
        header: '판매담당자',
        cell: (info) => info.getValue().userName,
        size: 138,
        enableSorting: false,
    }),

    columnHelper.accessor('memberName', {
        id: 'memberName',
        header: '회원',
        cell: (info) => {
            // 신규면 #7E7EF5 , 재등록이면 #64637B
            const bgColor = info.row.original.isNewSales ? '#7E7EF5' : '#64637B';

            return (
                <Row className="flex w-full items-center justify-center">
                    <RegularText
                        fontSize={16}
                        lineHeight={24}
                        color="#3D4244"
                        className="mr-[6px] w-[80px]"
                        letterSpacing={-0.32}
                    >
                        {info.getValue()?.length > 5 ? `${info.getValue().slice(0, 5)}...` : info.getValue()}
                    </RegularText>
                    <Col
                        className=" justify-center items-center rounded-[16px]"
                        style={{ backgroundColor: bgColor, width: 45, height: 27 }}
                    >
                        <DemiLightText fontSize={12} lineHeight={24} color="#FFFFFF">
                            {info.row.original.isNewSales ? '신규' : '재등록'}
                        </DemiLightText>
                    </Col>
                </Row>
            );
        },
        size: 193,
        enableSorting: false,
    }),
    columnHelper.accessor('totalPrice', {
        id: 'totalPrice',
        header: '판매금액',
        cell: (info) =>
            info.getValue() === null || info.getValue() === undefined ? '-' : convertToKRW(info.getValue()),
        size: 129,
        enableSorting: false,
    }),
    columnHelper.accessor('paymentAmount', {
        id: 'paymentAmount',
        header: '결제금액',
        cell: (info) =>
            info.getValue() === null || info.getValue() === undefined ? '-' : convertToKRW(info.getValue()),
        size: 129,
        enableSorting: false,
    }),
    columnHelper.accessor('cancelAmount', {
        id: 'cancelAmount',
        header: '취소액',
        cell: (info) =>
            info.getValue() === null || info.getValue() === undefined ? '-' : convertToKRW(info.getValue()),
        size: 129,
        enableSorting: false,
    }),
    columnHelper.accessor('totalSession', {
        id: 'totalSession',
        header: '총 회차',
        cell: (info) => {
            const textColor =
                info.row.original.saleStatus === 'COMPLETED'
                    ? '#3D4244'
                    : info.row.original.saleStatus === 'UPGRADED'
                    ? '#9536D4'
                    : info.row.original.saleStatus === 'PARTIAL_REFUND'
                    ? '#FF7220'
                    : '#FF2020';

            //환불 , 부분환불
            const minus =
                info.row.original.saleStatus === 'FULL_REFUND' || info.row.original.saleStatus === 'PARTIAL_REFUND';

            // 업그이드
            const plus = info.row.original.saleStatus === 'UPGRADED';

            return (
                <>
                    <RegularText fontSize={16} lineHeight={24} color={'#3D4244'}>
                        {info.getValue()}
                    </RegularText>
                    <RegularText fontSize={16} lineHeight={24} color={textColor}>
                        {`${
                            info.row.original.totalSessionOffset === 0
                                ? ''
                                : minus
                                ? `(-${info.row.original.totalSessionOffset})`
                                : plus
                                ? `(+${info.row.original.totalSessionOffset})`
                                : ''
                        }`}
                    </RegularText>
                </>
            );
        },
        size: 129,
        enableSorting: false,
    }),
    columnHelper.accessor('membershipName', {
        id: 'membershipName',
        header: '수강권',
        cell: (info) => info.getValue(),
        size: 216,
        enableSorting: false,
    }),
    columnHelper.accessor('activationDate', {
        id: 'activationDate',
        header: '이용기간',
        cell: (info) =>
            `${dayjs(info.row.original.activationDate).format('YYYY-MM-DD')} ~ ${dayjs(
                info.row.original.expirationDate
            ).format('YYYY-MM-DD')}`,
        size: 228,
        enableSorting: false,
    }),
    columnHelper.accessor('coachName', {
        id: 'coachName',
        header: '담당강사',
        cell: (info) => info.getValue(),
        size: 129,
        enableSorting: false,
    }),
];

const SalesListPage = ({
    date,
    onMonthNavigate,
    salesListData,
    selectedSalesManager,
    onSalesManagerSelect,
    selectedSalesListDate,
    onChangeSelectedSalesListDate,
}: Props) => {
    const navigate = useNavigate();
    const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');

    const summaryData = salesListData?.summaryData;
    const tableDataByMembershipPayment = salesListData?.tableDataByMembershipPayment;
    // 모든 수강권 id,name -> code value로 변경
    const membershipTemplateData = salesListData?.filters.membershiptemplateList.map((item) => ({
        code: item.membershipTemplateId,
        value: item.membershipTemplateName,
    }));
    // 회원
    const memberData = salesListData?.filters.memberList.map((item) => ({
        code: item.memberId,
        value: item.memberName,
    }));
    // 판매자
    const salesManagerData = salesListData?.salesManagers.map((item) => ({
        code: item.userId,
        value: item.userName,
        object: item,
    }));

    const [filteredTableData, setFilteredTableData] = React.useState(tableDataByMembershipPayment);
    const filteredCount = filteredTableData?.length;
    const filteredTotalPrice = filteredTableData?.reduce(
        (acc: any, cur: any) => acc + cur.paymentAmount - cur.cancelAmount,
        0
    );
    //선택된 수강권
    const [selectedMembershipTemplate, setSelectedMembershipTemplate] = React.useState('all');
    const [selectedMember, setSelectedMember] = React.useState('all');
    const [selectedMemberStatus, setSelectedMemberStatus] = React.useState('all');
    const [selectedAttendanceStatus, setSelectedAttendanceStatus] = React.useState('all');
    const handleSelect = (
        selected: { code: string; value: string; object?: any },
        type: 'salesManager' | 'membershipTemplate' | 'memberStatus' | 'attendanceStatus'
    ) => {
        if (type === 'membershipTemplate') {
            setSelectedMembershipTemplate(selected.code);
        } else if (type === 'salesManager') {
            onSalesManagerSelect(selected);
        } else if (type === 'memberStatus') {
            setSelectedMemberStatus(selected.code);
        } else if (type === 'attendanceStatus') {
            setSelectedAttendanceStatus(selected.code);
        }
    };

    const exportToExcel = () => {
        const data = filteredTableData;
        const localizedData = data.map((item: any) => ({
            판매상태:
                item.saleStatus === 'COMPLETED'
                    ? '완료'
                    : item.saleStatus === 'UPGRADED'
                    ? '업그레이드'
                    : item.saleStatus === 'PARTIAL_REFUND'
                    ? '부분 환불'
                    : item.saleStatus === 'PARTIAL_PAYMENT'
                    ? '분할 결제'
                    : '전체 환불',
            결제일시: dayjs(item.paymentDate).format('YYYY-MM-DD'),
            판매담당자: item.salesManager.userName,
            담당강사: item.coachName,
            회원: item.memberName,
            결제금액: convertToKRW(item.paymentAmount),
            취소액: convertToKRW(item.cancelAmount),
            총회차: `${item.totalSession}회 ${
                item.totalSessionChange > 0
                    ? `(+${item.totalSessionChange})`
                    : item.totalSessionChange < 0
                    ? `(-${item.totalSessionChange})`
                    : ''
            }`,
            수강권: item.membershipName,
            이용기간: `${dayjs(item.activationDate).format('YYYY-MM-DD')} ~ ${dayjs(item.expirationDate).format(
                'YYYY-MM-DD'
            )}`,
        }));
        // 1. JSON 데이터를 Sheet로 변환
        const worksheet = XLSX.utils.json_to_sheet(localizedData);
        // 2. Workbook 생성 및 Sheet 추가
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'FilteredData');
        // 3. 엑셀 파일 생성 및 다운로드
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${dayjs(date).format('YYYY-MM')} 매출 정산.xlsx`);
    };

    // 강사, 수강권, 출석여부 필터링
    useEffect(() => {
        if (tableDataByMembershipPayment) {
            let tempData: any = tableDataByMembershipPayment;

            // 판매상태 필터링
            if (selectedAttendanceStatus !== 'all') {
                tempData = tempData.filter(
                    (item: MembershipPaymentData) => item.saleStatus === selectedAttendanceStatus
                );
            }
            // 수강권 필터링
            if (selectedMembershipTemplate !== 'all') {
                tempData = tempData.filter(
                    (item: MembershipPaymentData) => item.membershipTemplateId === selectedMembershipTemplate
                );
            }
            // 회원 구분 필터링
            if (selectedMemberStatus !== 'all') {
                tempData = tempData.filter(
                    (item: MembershipPaymentData) => item.isNewSales === (selectedMemberStatus === 'isNewSales')
                );
            }
            // 회원 이름 필터링
            if (selectedMember !== 'all') {
                tempData = tempData.filter((item: MembershipPaymentData) => item.memberId === selectedMember);
            }
            // selectedSalesListDate 필터링
            if (selectedSalesListDate) {
                tempData = tempData.filter(
                    (item: MembershipPaymentData) =>
                        dayjs(item.paymentDate).format('YYYY-MM-DD') ===
                        dayjs(selectedSalesListDate).format('YYYY-MM-DD')
                );
            }
            setFilteredTableData(tempData);
        }
    }, [
        selectedAttendanceStatus,
        selectedMembershipTemplate,
        selectedMember,
        selectedMemberStatus,
        tableDataByMembershipPayment,
        selectedSalesListDate,
    ]);

    return (
        <div className="flex flex-col w-full h-full pt-[29px] px-[46px] bg-white rounded-[27px] mb-[50px]">
            <Row className=" items-center mb-[40px]">
                <MediumText fontSize={25} lineHeight={25} color={'#3D4244'} className="mr-[14px]">
                    {dayjs(date).format('YYYY년 MM월')}
                </MediumText>
                <div
                    className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[5px]"
                    onClick={() => {
                        onMonthNavigate(dayjs(date).subtract(1, 'month'));
                    }}
                >
                    <SvgIcon
                        name={'SvgLeftStrokeArrow'}
                        size={15}
                        fill={colors.trans}
                        stroke={'#70747C'}
                        strokeWidth={1.2}
                    />
                </div>

                <div
                    className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[30px]"
                    onClick={() => {
                        onMonthNavigate(dayjs(date).add(1, 'month'));
                    }}
                >
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={15}
                        fill={colors.trans}
                        stroke={'#70747C'}
                        strokeWidth={1.2}
                    />
                </div>

                {salesManagerData && salesManagerData.length > 0 && (
                    <Dropdown
                        initialSelected={selectedSalesManager.userId}
                        options={salesManagerData}
                        onSelect={(selected) => {
                            handleSelect(selected, 'salesManager');
                        }}
                        bgColor="rgba(201, 219, 255, 0.4)"
                        textColor={colors.primary}
                        borderRadius={50}
                        totalText="판매자 전체"
                        arrowColor={colors.primary}
                        className="py-[14px]"
                        textType="medium"
                    />
                )}
            </Row>
            <TableHeader summaryData={summaryData} />
            <Row className="flex w-full justify-between mb-[30px] items-center">
                <Row>
                    <RegularText fontSize={18} lineHeight={24} color="#3D4244" letterSpacing={-0.32}>
                        {`총 ${filteredCount}건 / 매출액 ${convertToKRW(filteredTotalPrice)}`}
                    </RegularText>
                </Row>
                <Row>
                    <button
                        className="flex flex-row relative items-center justify-center rounded-[7px] px-[12px] mr-[12px]"
                        onClick={() => setIsCalendarOpen(true)}
                        style={{
                            backgroundColor: selectedSalesListDate ? '#C9DBFF66' : '#F2F4F6',
                        }}
                    >
                        <DemiLightText
                            fontSize={17}
                            lineHeight={24.62}
                            color={selectedSalesListDate ? colors.primary : '#3D4244'}
                            className="mr-[12px]"
                        >
                            {selectedSalesListDate ? dayjs(selectedSalesListDate).format('D일') : '모든 날짜'}
                        </DemiLightText>
                        <SvgIcon
                            name={'SvgDownStrokeArrow'}
                            fill={colors.trans}
                            stroke={selectedSalesListDate ? colors.primary : '#3D4244'}
                            size={14}
                            strokeWidth={1.5}
                        />
                        {isCalendarOpen && (
                            <NewCalendar
                                date={dayjs(date)}
                                selectedDay={selectedSalesListDate}
                                setIsCalendar={setIsCalendarOpen}
                                onClickDate={(day) => {
                                    onChangeSelectedSalesListDate((day && dayjs(day)) ?? undefined);
                                }}
                            />
                        )}
                    </button>
                    <Dropdown
                        options={attendanceStatusData}
                        className="h-[33px] mr-[12px]"
                        totalText="판매 상태 전체"
                        onSelect={(selected) => {
                            handleSelect(selected, 'attendanceStatus');
                        }}
                        textType="demilight"
                        selectedBgColor="#C9DBFF66"
                        selectedTextColor={colors.primary}
                        selectedArrowColor={colors.primary}
                    />
                    <Dropdown
                        options={membershipTemplateData}
                        className="h-[33px] mr-[12px]"
                        totalText="모든 수강권"
                        onSelect={(selected) => {
                            handleSelect(selected, 'membershipTemplate');
                        }}
                        textType="demilight"
                        selectedBgColor="#C9DBFF66"
                        selectedTextColor={colors.primary}
                        selectedArrowColor={colors.primary}
                    />
                    <Dropdown
                        options={memberStatusData}
                        className="h-[33px] mr-[12px]"
                        totalText="회원 구분"
                        onSelect={(selected) => {
                            handleSelect(selected, 'memberStatus');
                        }}
                        textType="demilight"
                        selectedBgColor="#C9DBFF66"
                        selectedTextColor={colors.primary}
                        selectedArrowColor={colors.primary}
                    />

                    <Row
                        className={` items-center justify-center bg-[#F2F4F6] rounded-[7px] px-[15px] py-[5px] mr-[18px]
                            ${
                                filteredTableData && filteredTableData.length
                                    ? ' cursor-pointer'
                                    : 'opacity-50 cursor-not-allowed'
                            }
                            `}
                        onClick={() => filteredTableData && filteredTableData.length && exportToExcel()}
                    >
                        <SvgIcon name={'SvgExcel'} size={20} fill={colors.trans} className="mr-[7px]" />
                        <DemiLightText fontSize={16} lineHeight={23} color="#3D4244" letterSpacing={-0.32}>
                            엑셀
                        </DemiLightText>
                    </Row>
                    <MemberSearchTextInput
                        data={memberData}
                        width={154}
                        placeholder="회원 검색"
                        onSelect={(select) => setSearch(select.value)}
                        onSearch={(search) => {
                            setSearch(search);
                        }}
                    />
                </Row>
            </Row>
            {filteredTableData && filteredTableData.length > 0 ? (
                <Table
                    data={filteredTableData}
                    columns={columns}
                    width={1584}
                    globalFilter={search}
                    onGlobalFilterChange={setSearch}
                    onRowClick={(row: any) => {
                        navigate('/member/detail/membership', {
                            state: {
                                membershipId: row.membershipId,
                                memberName: row.memberName,
                                coachName: row.coachName,
                                memberId: row.memberId,
                                coachId: row.coachId,
                            },
                        });
                    }}
                    className="mb-[100px]"
                    isPagination
                    customPaginationComponent={({
                        firstPage,
                        nextPage,
                        canNextPage,
                        previousPage,
                        canPreviousPage,
                    }) => (
                        <Row className=" absolute items-center flex right-0 bottom-0 mb-[50px]">
                            <RegularText
                                fontSize={16}
                                lineHeight={23}
                                color="#3D4244"
                                className={`mr-[16px] ${
                                    canPreviousPage ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                                }`}
                                onClick={() => !canPreviousPage && firstPage()}
                            >
                                처음으로
                            </RegularText>
                            <button
                                className="flex flex-row items-center mr-[13px] bg-[#F4F5F7] rounded-[7px] px-[19px] py-[7px] 
                disabled:opacity-50 disabled:cursor-not-allowed
                "
                                onClick={previousPage}
                                disabled={canPreviousPage}
                            >
                                <SvgIcon
                                    name={'SvgLeftStrokeArrow'}
                                    size={15}
                                    fill={colors.trans}
                                    stroke={'#70747C'}
                                    strokeWidth={1.2}
                                    className="mr-[7px]"
                                />
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    이전
                                </RegularText>
                            </button>
                            <button
                                className="flex flex-row items-center mr-[13px] bg-[#F4F5F7] rounded-[7px] px-[19px] py-[7px] 
                disabled:opacity-50 disabled:cursor-not-allowed
                "
                                onClick={nextPage}
                                disabled={canNextPage}
                            >
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244" className="mr-[7px]">
                                    다음
                                </RegularText>
                                <SvgIcon
                                    name={'SvgRightStrokeArrow'}
                                    size={15}
                                    fill={colors.trans}
                                    stroke={'#70747C'}
                                    strokeWidth={1.2}
                                />
                            </button>
                        </Row>
                    )}
                />
            ) : (
                <Col className="flex flex-col overflow-x-auto w-full">
                    <Col className="w-full  items-center h-[500px]">
                        <MediumText
                            fontSize={20}
                            lineHeight={29}
                            letterSpacing={-0.3}
                            color="#3D4244"
                            className="mb-[8px] mt-[100px]"
                        >
                            조회 결과가 없어요
                        </MediumText>
                        <RegularText fontSize={16} lineHeight={24} color="#6B7684">
                            입력한 정보가 올바른지 확인해주세요.
                        </RegularText>
                    </Col>
                </Col>
            )}
        </div>
    );
};

export default SalesListPage;

// 헤더 표
const TableHeader = ({ summaryData }: { summaryData: SalesListSummaryData }) => {
    const totalSales = summaryData?.totalSales ?? 0;
    const totalSalesCount = summaryData?.totalSalesCount ?? 0;
    const totalSalesMemberCount = summaryData?.totalSalesMemberCount ?? 0;
    const newPaymentAmount = summaryData?.newPaymentAmount ?? 0;
    const newPaymentCount = summaryData?.newPaymentCount ?? 0;
    const newPaymentMemberCount = summaryData?.newPaymentMemberCount ?? 0;
    const reRegistrationPaymentAmount = summaryData?.reRegistrationPaymentAmount ?? 0;
    const reRegistrationPaymentCount = summaryData?.reRegistrationPaymentCount ?? 0;
    const reRegistrationPaymentMemberCount = summaryData?.reRegistrationPaymentMemberCount ?? 0;
    const cancelSales = summaryData?.cancelSales ?? 0;
    const cancelSalesCount = summaryData?.cancelSalesCount ?? 0;
    const cancelSalesMemberCount = summaryData?.cancelSalesMemberCount ?? 0;
    const partialPaymentAmount = summaryData?.partialPaymentAmount; // 분할 결제 금액
    const partialPaymentCount = summaryData?.partialPaymentCount; // 분할 결제 건수
    const partialPaymentMemberCount = summaryData?.partialPaymentMemberCount; // 분할 결제 회원수

    return (
        <div className="w-full">
            <table className="min-w-full bg-white border border-solid border-gray-300 text-center mb-[66px]">
                <thead>
                    <tr className="bg-gray-100 border-solid border-b  h-[40px] justify-center items-center">
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    {'총 매출액'}
                                </RegularText>
                                {/* TooltipIcon 추가 */}
                                <TooltipIcon
                                    title={`회원의 수강권 결제를 통해 발생한 총 금액`}
                                    subTitle="* 라포 서비스 내에 등록된 수강권 내역을 통해 산출된 매출 금액입니다."
                                />
                            </Row>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    {'신규 결제액'}
                                </RegularText>
                                {/* TooltipIcon 추가 */}
                                <TooltipIcon
                                    title={`처음 등록한 신규 회원이 실제로 결제한 금액`}
                                    subTitle="* 할인, 패키지 요금제, 또는 프로모션이 반영된 금액일 수 있습니다."
                                />
                            </Row>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    {'재등록 결제액'}
                                </RegularText>
                                {/* TooltipIcon 추가 */}
                                <TooltipIcon
                                    title={`기존에 서비스를 이용한 적이 있는 재등록 회원이 실제로 결제한 금액`}
                                    subTitle="* 할인, 패키지 요금제, 또는 프로모션이 반영된 금액일 수 있습니다."
                                />
                            </Row>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    {'분할 결제액'}
                                </RegularText>
                            </Row>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    {'환불금액'}
                                </RegularText>
                                {/* TooltipIcon 추가 */}
                                <TooltipIcon
                                    title={`회원이 결제한 금액 중 취소되거나 환불 요청으로 반환된 금액`}
                                    subTitle="* 결제 취소 금액과 환불된 금액이 모두 포함됩니다."
                                />
                            </Row>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/* 수업료 */}
                        <td className=" py-[22px] border border-solid border-[#D9D9D9] ">
                            <Col>
                                <RegularText fontSize={23} lineHeight={24} color="#3D4244" className="mb-[9px]">
                                    {convertToKRW(totalSales)}
                                </RegularText>
                                <DemiLightText fontSize={15} lineHeight={24} color="#6B7684">
                                    {totalSalesCount}건/{totalSalesMemberCount}명
                                </DemiLightText>
                            </Col>
                        </td>
                        {/* 총 등록단가 */}
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <Col>
                                <RegularText fontSize={23} lineHeight={24} color="#3D4244" className="mb-[9px]">
                                    {convertToKRW(newPaymentAmount)}
                                </RegularText>
                                <DemiLightText fontSize={15} lineHeight={24} color="#6B7684">
                                    {newPaymentCount}건/{newPaymentMemberCount}명
                                </DemiLightText>
                            </Col>
                        </td>
                        {/* 남은 수업수 */}
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <Col>
                                <RegularText fontSize={23} lineHeight={24} color="#3D4244" className="mb-[9px]">
                                    {convertToKRW(reRegistrationPaymentAmount)}
                                </RegularText>
                                <DemiLightText fontSize={15} lineHeight={24} color="#6B7684">
                                    {reRegistrationPaymentCount}건/{reRegistrationPaymentMemberCount}명
                                </DemiLightText>
                            </Col>
                        </td>
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <Col>
                                <RegularText fontSize={23} lineHeight={24} color="#3D4244" className="mb-[9px]">
                                    {convertToKRW(partialPaymentAmount)}
                                </RegularText>
                                <DemiLightText fontSize={15} lineHeight={24} color="#6B7684">
                                    {partialPaymentCount}건/{partialPaymentMemberCount}명
                                </DemiLightText>
                            </Col>
                        </td>
                        {/* 완료수업수 / 노쇼 */}
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <Col>
                                <RegularText fontSize={23} lineHeight={24} color="#3D4244" className="mb-[9px]">
                                    {convertToKRW(cancelSales)}
                                </RegularText>
                                <DemiLightText fontSize={15} lineHeight={24} color="#6B7684">
                                    {cancelSalesCount}건/{cancelSalesMemberCount}명
                                </DemiLightText>
                            </Col>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
