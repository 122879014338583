import axios, { AxiosResponse } from 'axios';
import { SalesCommission } from '../services/useSalesCommissionQueryService';

export /**
 * @method get
 * @brief 수업료 비율 조회
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1195/src/docs/SalesCommission.md#%EC%88%98%EC%97%85%EB%A3%8C-%EB%B9%84%EC%9C%A8-%EC%A1%B0%ED%9A%8C
 */
const getSalesCommission = async (year: number, month: number, centerId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/salescommissions/center/${centerId}?year=${year}&month=${month}`,

            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method patch
 * @brief 수업료 비율 설정
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1195/src/docs/SalesCommission.md#%EC%88%98%EC%97%85%EB%A3%8C-%EB%B9%84%EC%9C%A8-%EC%84%A4%EC%A0%95
 */
const patchSalesCommission = async ({
    year,
    month,
    centerId,
    salesCommissionArray,
    accessToken,
}: PatchSalesCommissionApiType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/salescommissions/center/${centerId}`,

            headers: { authorization: accessToken },
            data: {
                year,
                month,
                salesCommissionArray,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};
export type CommissionType = 'fixed' | 'percentage';

export type PatchSalesCommissionApiType = {
    year: string;
    month: string;
    centerId: string;
    salesCommissionArray: {
        coachId: string;
        commissionType: CommissionType;
        scheduleCommission: number;
        reRegisterCommission?: number | null;
        noshowCommission?: number | null;
    }[];
    accessToken: string;
};
