import React from 'react';
import EventRowMixin from './EventRowMixin';

type EventRowProps = {
    segments: any[];
    slotMetrics: any;
    className?: string;
    monthCustomEventComponent?: (event: any) => React.ReactNode;
};

const EventRow = ({ segments, slotMetrics, monthCustomEventComponent }: EventRowProps) => {
    const { slots } = slotMetrics;
    let lastEnd = 1;

    const className = monthCustomEventComponent ? 'rbc-row  w-full h-full' : 'rbc-row';

    return (
        <div className={className}>
            {segments.reduce((row, { event, left, right, span }, li) => {
                const key = `_lvl_${li}`;
                const gap = left - lastEnd;
                const content = EventRowMixin.renderEvent({ segments, slotMetrics, monthCustomEventComponent }, event);

                if (gap) {
                    row.push(EventRowMixin.renderSpan(slots, gap, `${key}_gap`));
                }

                row.push(EventRowMixin.renderSpan(slots, span, key, content));
                lastEnd = right + 1;

                return row;
            }, [])}
        </div>
    );
};

EventRow.defaultProps = {
    ...EventRowMixin.defaultProps,
};

export default EventRow;
