import { CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { colors } from '../../lib/colors';
import { Col, Row } from '../../lib/utils';
import { Body2Bold, Body3Regular } from '../../lib/font';
import { I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../types/images';
import { useRecoilState } from 'recoil';
import { sidebarStatusState } from '../../atoms/atom';
import dayjs, { Dayjs } from 'dayjs';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../SvgIcon';

type CalendarProps = {
    date: Date | Dayjs | string;
    isPrevMonth?: any;
    isNextMonth?: any;
    setIsCalendar: Dispatch<SetStateAction<boolean>>;
    style?: CSSProperties;
    onClickDate?: (day?: Dayjs | string) => void;
    isAllDate?: boolean;
    top?: number;
    left?: number;
    selectedDay?: Dayjs | undefined | string;
    titleFormat?: string;
};

const NewCalendar = ({
    date: _date,
    isPrevMonth = false,
    isNextMonth = false,
    setIsCalendar,
    style,
    onClickDate,
    isAllDate: _isAllDate = true,
    top = 31,
    left,
    selectedDay: _selectedDay,
    titleFormat = 'YYYY년 MM월 전체',
}: CalendarProps) => {
    const [date, setDate] = useState<Date | Dayjs | string>(_date);
    const [selectedDay, setSelectedDay] = useState<Dayjs | undefined | string>(_selectedDay);
    // 모든날짜 여부
    const [isAllDate, setIsAllDate] = useState<boolean>(_isAllDate);
    const calendarRef = useRef<HTMLDivElement>(null);
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // const onClickDay = (day: Dayjs) => {
    //     if (isSameDay(day, selectedDay)) {
    //         !onClickDate && setSelectedDay(day);
    //         onClickDate && onClickDate(day);
    //     } else {
    //         !onClickDate && setSelectedDay(day);
    //         onClickDate && onClickDate(day);
    //     }
    // };

    useEffect(() => {
        // 날짜 선택시 모든날짜 false
        if (selectedDay) {
            setIsAllDate(false);
        }
    }, [selectedDay]);

    // const prevCalendar = (event: React.MouseEvent) => {
    //     event.stopPropagation();
    //     setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, currentMonth.getDate()));
    // };

    // const nextCalendar = (event: React.MouseEvent) => {
    //     event.stopPropagation();
    //     setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, currentMonth.getDate()));
    // };

    const buildCalendarDays = () => {
        const curMonthStartDate = dayjs(date).startOf('month').day(); // 현재 월의 시작 요일
        const curMonthEndDate = dayjs(date).endOf('month'); // 현재 월의 마지막 날
        const prevMonthEndDate = dayjs(date).subtract(1, 'month').endOf('month'); // 이전 월의 마지막 날
        const nextMonthStartDate = dayjs(date).add(1, 'month').startOf('month'); // 다음 월의 시작 날

        const days: dayjs.Dayjs[] = Array.from({ length: curMonthStartDate }, (_, i) => {
            return prevMonthEndDate.subtract(curMonthStartDate - i - 1, 'day');
        });

        days.push(...Array.from({ length: curMonthEndDate.date() }, (_, i) => dayjs(date).date(i + 1)));

        const remainingDays = 7 - (days.length % 7);
        if (remainingDays < 7) {
            days.push(...Array.from({ length: remainingDays }, (_, i) => nextMonthStartDate.add(i, 'day')));
        }
        return days;
    };

    const buildCalendarTag = (calendarDays: dayjs.Dayjs[]) => {
        return calendarDays.map((day, i) => {
            const isInThisMonth = dayjs(date).isSame(day, 'month'); // 이번 달인지 확인
            const isFirstDayOfMonth = day.date() === 1 && isInThisMonth; // 이번 달의 첫 번째 날인지 확인
            const isLastDayOfMonth = dayjs(day).isSame(dayjs(date).endOf('month'), 'day'); // 이번 달의 마지막 날인지 확인

            // 주의 첫 번째 날인지
            const isFirstDayOfWeek = isInThisMonth && (day.day() === 0 || isFirstDayOfMonth);

            // 주의 마지막 날인지
            const isLastDayOfWeek = isInThisMonth && (day.day() === 6 || isLastDayOfMonth); // 마지막 날인 경우 우선 처리

            const isSelected = selectedDay ? isSameDay(day, selectedDay) : false; // 선택된 날인지 확인
            const isToday = dayjs().isSame(day, 'day'); // 오늘인지 확인

            return (
                <td key={i} className="text-center align-middle  pb-[10px]" style={{ width: '40px', height: '40px' }}>
                    <div
                        className={`flex justify-center items-center w-full h-full ${
                            isAllDate && isFirstDayOfWeek ? 'bg-[#EFF2F8] rounded-l-full' : '' // 첫 주의 1일 또는 주의 첫날 스타일
                        } ${
                            isAllDate && isLastDayOfWeek ? 'bg-[#EFF2F8] rounded-r-full' : '' // 마지막 주의 마지막 날 또는 주의 마지막날 스타일
                        }
                        ${
                            isAllDate && !isFirstDayOfWeek && !isLastDayOfWeek && isInThisMonth ? 'bg-[#EFF2F8]' : '' // 첫 주와 마지막 주를 제외한 주의 스타일
                        }
                        `}
                    >
                        <div
                            className={`flex justify-center items-center w-[35px] h-[35px] rounded-full  text-[15px] leading-[24px] cursor-pointer font-noto-demilight
                                ${isSelected ? 'bg-primary text-white' : ''}
                                ${isToday && !isSelected ? 'bg-blue-100 text-blue-700' : ''}
                                ${
                                    !isInThisMonth
                                        ? 'text-white cursor-default'
                                        : 'text-[#3D4244] hover:bg-primary hover:text-white'
                                }
                            `}
                            onClick={() => isInThisMonth && setSelectedDay(day)}
                        >
                            {day.date()}
                        </div>
                    </div>
                </td>
            );
        });
    };

    // isSameDay 함수도 Dayjs로 변경
    const isSameDay = (day1: dayjs.Dayjs, day2?: Dayjs | undefined | string) => {
        return dayjs(day1).isSame(day2, 'day');
    };

    const divideWeek = (calendarTags: JSX.Element[]) => {
        return calendarTags.reduce((acc: JSX.Element[][], day: JSX.Element, i: number) => {
            if (i % 7 === 0) acc.push([day]);
            else acc[acc.length - 1].push(day);
            return acc;
        }, []);
    };

    // useEffect(() => {
    //     if (selectedDay) {
    //         setDate(new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate()));
    //     }
    // }, [selectedDay]);

    const calendarDays = buildCalendarDays();
    const calendarTags = buildCalendarTag(calendarDays);
    const calendarRows = divideWeek(calendarTags);

    const handleClickOutside = (event: MouseEvent) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
            setIsCalendar && setIsCalendar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={calendarRef}
            className="absolute z-50 bg-white rounded-[20px]  p-[27px] w-[400px] flex flex-col"
            style={{
                boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
                top: top ? top : undefined,
                left: left ? left : undefined,
            }}
        >
            <Row className="flex items-center justify-center mb-[25px]">
                {isPrevMonth && (
                    <SvgIcon
                        name={'SvgLeftStrokeArrow'}
                        size={20}
                        fill={colors.trans}
                        stroke="#3D4244"
                        strokeWidth={1.5}
                        onClick={() => {
                            setDate(dayjs(date).subtract(1, 'month'));
                        }}
                        className="cursor-pointer"
                    />
                )}
                <MediumText fontSize={20} lineHeight={24} color={colors.Text_1} className="px-[10px]">
                    {dayjs(date).format(titleFormat)}
                </MediumText>
                {isNextMonth && (
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={20}
                        fill={colors.trans}
                        stroke="#3D4244"
                        strokeWidth={1.5}
                        onClick={() => {
                            setDate(dayjs(date).add(1, 'month'));
                        }}
                        className="cursor-pointer"
                    />
                )}
            </Row>
            <table className="w-full text-center table-fixed mb-[30px]">
                <thead>
                    <tr>
                        {daysOfWeek.map((day, i) => (
                            <th key={i} className=" text-center pb-[22px]">
                                <DemiLightText fontSize={15} lineHeight={24} color="#9CA4A7">
                                    {day}
                                </DemiLightText>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {calendarRows.map((row: JSX.Element[], rowIndex: number) => (
                        <tr key={rowIndex}>{row}</tr>
                    ))}
                </tbody>
            </table>
            <Row className="self-end">
                {_isAllDate && (
                    <Col
                        className="flex justify-center items-center w-[102px] h-[48px] bg-[#C9DBFF66] rounded-[9px] mr-[12px] cursor-pointer"
                        onClick={() => {
                            setIsAllDate(!isAllDate);
                            setSelectedDay(undefined);
                        }}
                    >
                        <RegularText fontSize={15} lineHeight={24} color={colors.primary}>
                            모든 날짜
                        </RegularText>
                    </Col>
                )}
                <Col
                    className="flex justify-center items-center w-[102px] h-[48px] bg-[#F4F5F7] rounded-[9px] cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation(); // 이벤트 확산 방지
                        onClickDate && onClickDate(isAllDate ? undefined : selectedDay || dayjs());
                        setIsCalendar && setIsCalendar(false);
                    }}
                >
                    <RegularText fontSize={15} lineHeight={24} color={colors.Text_1}>
                        확인
                    </RegularText>
                </Col>
            </Row>
        </div>
    );
};

export default NewCalendar;
