import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getItemWithExpiration } from '../lib/utils';
import {
    createMemberShip,
    MemberShipApiType,
    deleteMemberShip,
    getAllMembershipForMember,
    getMembershipTemplatesListAPI,
    updateMemberShip,
    UpdateMemberShipApiType,
    patchMonthCommission,
    PatchMonthCommissionApiType,
} from '../api/memberShip';
import { Issuer } from '../api/center';
import { MemberMembershipDataType } from './useMembershipQueryService';
import { MembershipTemplateDataType } from './useMembershipTemplateQueryService';

type Props = {
    getAllMembershipForMemberParams?: {
        memberId: string;
        coachId: string;
    };
    fetchAllMembershipTemplatesList?: boolean;
    onCreateMemberShipSuccess?: () => void;
    onUpdatedMemberShipSuccess?: () => void;
    onDeleteMemberShipSuccess?: () => void;
    onPatchMonthCommissionSuccess?: () => void;
};

const useMembershipService = ({
    getAllMembershipForMemberParams,
    fetchAllMembershipTemplatesList,
    onCreateMemberShipSuccess,
    onUpdatedMemberShipSuccess,
    onDeleteMemberShipSuccess,
    onPatchMonthCommissionSuccess,
}: Props) => {
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [authorization] = useState(getItemWithExpiration('authorization'));

    // 모든 회원권 가져오기
    const { data: allMembershipListData, isLoading: allMembershipListDataLoading } = useQuery(
        ['getAllMembershipForMemberQuery', getAllMembershipForMemberParams?.memberId ?? '', authorization],
        async () =>
            await getAllMembershipForMember(
                getAllMembershipForMemberParams?.coachId ?? '',
                getAllMembershipForMemberParams?.memberId ?? '',
                authorization
            ),
        {
            enabled:
                !!authorization &&
                !!getAllMembershipForMemberParams?.memberId &&
                !!getAllMembershipForMemberParams?.coachId,
            suspense: true,
        }
    );
    // 나의 수강권들 상품 조회
    const { data: allMembershipTemplatesListData, isLoading: allMembershipTemplatesListLoading } = useQuery(
        ['getMembershipTemplatesListAPIQuery', authorization],
        async () => await getMembershipTemplatesListAPI(authorization, authObject?.centerId),
        {
            enabled: !!authorization && fetchAllMembershipTemplatesList,
            suspense: true,
        }
    );

    // 회원권 생성
    const createMemberShipMutation = useMutation(
        ['createMemberShipMutation'],
        async (params: MemberShipApiType & { accessToken: string }) => createMemberShip(params),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 201) {
                    onCreateMemberShipSuccess && onCreateMemberShipSuccess();
                }
            },
        }
    );
    const updateMemberShipMutation = useMutation(
        ['updateMemberShipMutation'],
        async (params: UpdateMemberShipApiType) => updateMemberShip(params),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 200) {
                    onUpdatedMemberShipSuccess && onUpdatedMemberShipSuccess();
                }
            },
        }
    );
    const deleteMemberShipMutation = useMutation(
        ['deleteMemberShipMutation'],
        async (params: { membershipId: string; accessToken: string }) =>
            deleteMemberShip(params.membershipId, params.accessToken),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 204) {
                    onDeleteMemberShipSuccess && onDeleteMemberShipSuccess();
                }
            },
        }
    );
    const patchMonthCommissionMutation = useMutation(
        ['patchMonthCommissionMutation'],
        async (params: Omit<PatchMonthCommissionApiType, 'accessToken'>) =>
            patchMonthCommission({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 200) {
                    onPatchMonthCommissionSuccess && onPatchMonthCommissionSuccess();
                }
            },
        }
    );

    return {
        allMembershipListData: allMembershipListData?.data?.membersMemberships || ([] as MemberMembershipDataType[]),
        allMembershipListDataLoading,
        allMembershipTemplatesListData:
            allMembershipTemplatesListData?.data?.membershipTemplates || ([] as MembershipTemplateDataType[]),
        allMembershipTemplatesListLoading,
        createMemberShipMutation,
        updateMemberShipMutation,
        deleteMemberShipMutation,
        patchMonthCommissionMutation,
    };
};

export default useMembershipService;
