// apiService.js

import { Issuer } from '../api/center';
import {
    getAllMembershipForMember,
    getIsNewSales,
    getMembershipForMember,
    getUnpaidPayments,
    GetUnpaidPaymentsApiType,
} from '../api/memberShip';
import { SalesManager, SalesStatus } from './useMembershipPaymentsQueryService';

export const fetchAllMembershipForMember = (
    params: {
        coachId: string;
        memberId: string;
        accessToken: string;
    },
    authorization: string
) => ({
    queryKey: ['getAllMembershipForMemberQuery', params.coachId, params.memberId],
    queryFn: () => getAllMembershipForMember(params.coachId, params.memberId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membersMemberships ?? [];
    },
});
export const fetchMembershipForMember = (
    params: {
        membershipId: string;
    },
    authorization: string
) => ({
    queryKey: ['getMembershipForMemberQuery', params.membershipId],
    queryFn: () => getMembershipForMember(params.membershipId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membership ?? [];
    },
});
//getIsNewSales
export const fetchGetIsNewSales = (
    params: {
        memberId: string;
        coachId: string;
    },
    authorization: string
) => ({
    queryKey: ['getIsNewSalesQuery', params.memberId, params.coachId],
    queryFn: async () => await getIsNewSales(params.memberId, params.coachId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data ?? undefined;
    },
});
export const fetchGetUnpaidPayments = (params: GetUnpaidPaymentsApiType, authorization: string) => ({
    queryKey: ['getUnpaidPaymentsQuery', ...Object.values(params)],
    queryFn: async () => await getUnpaidPayments({ ...params, accessToken: authorization }),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data ?? undefined;
    },
});

/**
 * 회원권
 * */
export type MemberMembershipDataType = {
    _id: string;
    period: number;
    isDeleted: boolean;
    isCanceled: boolean;
    coachId: string;
    memberId: string;
    name: string;
    activationDate: string;
    expirationDate: string;
    startSessionValue: number;
    totalSession: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
    BGColor: string[];
    issuer: Issuer;
    membershipTemplateId: string;
    memo: string;
    pricePerSchedule: number;
    status: 'expired' | 'active';
    totalPrice: number;
    currentSession: number;
    schedules: MemberScheduleDataType[];
    remainSession: number;
    commissionType: string;
    commission: number;
    coachName?: string;
    memberName?: string;
    attendanceStatus?: 'ATTENDANCE' | 'NOSHOW';
    startTime?: string;
    membershipName?: string;
    memberScheduleFee?: number;
    isNewSales?: boolean; // 신규 수강권이면 True 재등록이면 false
    initialPaymentDate?: string; // 결제 일자
    salesManager?: SalesManager;
    paymentHistory?: PaymentHistoryType[];
    totalUnpaidAmount?: number;
    totalPaymentAmount?: number;
    isSplitPayment?: boolean;
};

/** 회원권의 스케줄 */
export type MemberScheduleDataType = {
    __v: number;
    _id: string;
    alias: string;
    attendanceStatus: 'ATTENDANCE' | 'NOSHOW';
    coachId: string;
    conditionId: string;
    createdAt: string;
    currentSession: number;
    endTime: string;
    isCanceled: boolean;
    isDeleted: boolean;
    memberId: string;
    membershipId: string;
    membership: MemberMembershipDataType;
    memo: string;
    postId: string;
    requestDeadline: string;
    scheduleBundleId: string | null;
    startTime: string;
    totalSession: number;
    updatedAt: string;
    commission?: number;
    commissionType?: string;
    coachName?: string;
    memberName?: string;
    membershipName?: string;
    pricePerSchedule?: number;
    memberScheduleFee?: number;
};

export type PaymentHistoryType = {
    _id: string;
    paymentAmount: number;
    cancelAmount: number;
    saleStatus: SalesStatus;
    paymentDate: string;
    cancelDate: string;
    totalSession: number;
    totalSessionOffset: number;
};

export type UnpaidPaymentTableType = {
    totalUnpaidAmount: number;
    totalPaymentAmount: number;
    recentPaymentDate: string;
    isNewSales: boolean;
    totalSession: number;
    name: string;
    status: string;
    membershipTemplateId: string;
    salesManager: SalesManager;
    membershipId: string;
    coachName: string;
    memberName: string;
    activationDate: string;
    expirationDate: string;
    totalPrice: number;
    BGColor: string[];
};

type SummaryData = {
    totalUnpaidPayment: number;
    totalUnpaidPaymentCount: number;
};

type FilterMember = {
    memberId: string;
    memberName: string;
};

type Filters = {
    salesManagers: SalesManager[];
    memberList: FilterMember[];
    membershiptemplateList: {
        membershipTemplateId: string;
        membershipTemplateName: string;
    }[];
};

export type UnpaidPaymentData = {
    tableDataByMembership: UnpaidPaymentTableType[];
    summaryData: SummaryData;
    filters: Filters;
};
