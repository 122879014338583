import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useState } from 'react';
import { getItemWithExpiration } from '../lib/utils';

type QueryConfig = {
    fetchFn: (
        params: any,
        authorization: string
    ) => {
        queryKey: any[];
        queryFn: () => Promise<any>;
        onSuccess?: (data: any) => any;
    };
    params: any;
    enabled?: boolean;
    suspense?: boolean;
    cacheTime?: number;
};

const useSingleQuery = (query: QueryConfig) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const { queryKey, queryFn, onSuccess } = query.fetchFn(query.params, authorization);

    const queryOptions: UseQueryOptions = {
        queryKey,
        queryFn,
        enabled: !!authorization && !!query.enabled,
        onSuccess,
        suspense: query.suspense ?? true,
        cacheTime: query.cacheTime,
    };

    const result = useQuery(queryOptions);

    const refinedResult =
        result.isSuccess && onSuccess
            ? {
                  ...result,
                  data: onSuccess(result.data),
              }
            : result;

    return { refinedResult, isLoading: result.isLoading, isFetching: result.isFetching };
};

export default useSingleQuery;
