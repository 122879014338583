import React, { useEffect, useState } from 'react';
import { Col, getItemWithExpiration, Row, setItemWithExpiration } from '../../lib/utils';
import { BoldText, DemiLightText, RegularText } from '../../lib/Text';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCheckPayrollAuth, postCheckPayrollAuth } from '../../api/auth';
import { useNavigate } from 'react-router-dom';

const SettlementPasswordPage = () => {
    const navigate = useNavigate();
    // 타입이 설정, 확인인지
    const [isSetPassword, setIsSetPassword] = React.useState(false);
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));

    // 일반 일정 생성 API : (POST)
    const postCheckPayrollAuthMutation = useMutation({
        mutationFn: async () =>
            await postCheckPayrollAuth(
                authObject.adminId,
                isSetPassword ? parseInt(createPassword) : parseInt(password),
                authorization
            ),
        onSuccess: ({ status, data }) => {
            switch (status) {
                case 200:
                    setPassword('');
                    navigate('/settlement', {
                        state: { isPasswordSet: true },
                    });
                    return;
                case 201:
                    alert('비밀번호가 설정되었습니다.');
                    setIsSetPassword(false);
                    return;
                case 415:
                    alert('비밀번호가 틀렸습니다.');
                    return;
            }
        },
    });

    const { data, isLoading } = useQuery({
        queryKey: ['getCheckPayrollAuthQuery'],
        queryFn: async () => await getCheckPayrollAuth(authObject.adminId, authorization),
        enabled: true,
    });

    const isSettingPayrolPassword = data?.data?.isSettingPayrolPassword;

    useEffect(() => {
        if (!isLoading && !isSettingPayrolPassword) {
            setIsSetPassword(true);
        }
    }, [isLoading]);

    // 비밀번호 만들때
    const [createPassword, setCreatePassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    // 비밀번호
    const [password, setPassword] = React.useState('');

    const onClickSetPassword = () => {
        // 비교
        if (isSetPassword && createPassword !== confirmPassword) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        postCheckPayrollAuthMutation.mutate();
    };

    // 취소
    const onClickCancel = () => {
        // 루트로
        window.location.href = '/';
    };

    const renderSetPassword = () => {
        return (
            <Col
                className="mt-[100px] w-[495px] h-[492px] bg-white rounded-[15px] py-[36px] px-[32px]"
                style={{
                    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
                }}
            >
                <BoldText fontSize={24} lineHeight={38} color="#3D4244" className="mb-[10px]">
                    비밀번호를 설정해주세요
                </BoldText>
                <DemiLightText fontSize={16} lineHeight={23} color="#3D4244" className="mb-[31px]">
                    매출 정산 페이지는 비밀번호 입력이 필요해요.
                </DemiLightText>
                <Col>
                    <Row>
                        <DemiLightText fontSize={16} lineHeight={23} color="#3D4244" className="mr-[3px] mb-[10px]">
                            비밀번호
                        </DemiLightText>
                        <DemiLightText fontSize={16} lineHeight={23} color="red" className="mr-[3px]">
                            *
                        </DemiLightText>
                        <DemiLightText fontSize={16} lineHeight={23} color="#3D4244">
                            (숫자 4자 ~20자)
                        </DemiLightText>
                    </Row>

                    <TextInput
                        value={createPassword}
                        onChange={(value) => {
                            setCreatePassword(value as string);
                        }}
                        placeholder="비밀번호를 입력해주세요"
                        height={68}
                        className="mb-[30px]"
                    />
                </Col>
                <Col>
                    <Row>
                        <DemiLightText fontSize={16} lineHeight={23} color="#3D4244" className="mr-[3px] mb-[10px]">
                            비밀번호 확인
                        </DemiLightText>
                        <DemiLightText fontSize={16} lineHeight={23} color="red" className="mr-[3px]">
                            *
                        </DemiLightText>
                    </Row>

                    <TextInput
                        value={confirmPassword}
                        onChange={(value) => {
                            setConfirmPassword(value as string);
                        }}
                        placeholder="비밀번호 확인을 위해 한 번 더 입력해주세요."
                        height={68}
                        className="mb-[30px]"
                    />
                </Col>
                <Row className=" self-end">
                    <Col
                        className="w-[91px] h-[41px] justify-center items-center bg-[#F2F4F6] rounded-[11px] cursor-pointer mr-[9px]"
                        onClick={onClickCancel}
                    >
                        <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className="w-[91px] h-[41px] justify-center items-center bg-primary rounded-[11px] cursor-pointer "
                        onClick={onClickSetPassword}
                    >
                        <RegularText fontSize={16} lineHeight={23} color="white">
                            확인
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        );
    };

    const renderConfirmPassword = () => {
        return (
            <Col
                className="mt-[100px] w-[495px] h-[310px] bg-white rounded-[15px] py-[36px] px-[32px]"
                style={{
                    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
                }}
            >
                <BoldText fontSize={24} lineHeight={38} color="#3D4244" className="mb-[10px]">
                    비밀번호를 입력해주세요
                </BoldText>
                <DemiLightText fontSize={16} lineHeight={23} color="#3D4244" className="mb-[31px]">
                    매출 정산 페이지는 비밀번호 입력이 필요해요.
                </DemiLightText>
                <TextInput
                    value={password}
                    onChange={(value) => {
                        setPassword(value as string);
                    }}
                    placeholder="비밀번호를 입력해주세요"
                    height={68}
                    className="mb-[30px]"
                    onKeyDown={onEnter}
                />
                <Row className=" self-end">
                    <Col
                        className="w-[91px] h-[41px] justify-center items-center bg-[#F2F4F6] rounded-[11px] cursor-pointer mr-[9px]"
                        onClick={onClickCancel}
                    >
                        <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className="w-[91px] h-[41px] justify-center items-center bg-primary rounded-[11px] cursor-pointer "
                        onClick={onClickSetPassword}
                    >
                        <RegularText fontSize={16} lineHeight={23} color="white">
                            확인
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        );
    };

    // 엔터시 확인
    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onClickSetPassword();
        }
    };

    return (
        <Col className="flex w-full h-full mt-[60px] bg-[#F4F5F7] items-center">
            {isLoading ? undefined : isSetPassword ? renderSetPassword() : renderConfirmPassword()}
        </Col>
    );
};

export default SettlementPasswordPage;

type TextInputProps = {
    value: string | number;
    onChange: (value: string | number) => void;
    unit?: string; // 단위 (예: %, 원)
    placeholder?: string; // 플레이스홀더 텍스트
    width?: number; // 입력 필드 너비
    disabled?: boolean; // 비활성화 여부
    onBlur?: () => void;
    height?: number;
    className?: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const TextInput: React.FC<TextInputProps> = ({
    value,
    onChange,
    unit = '',
    placeholder = '',
    width = 116,
    disabled = false,
    onBlur,
    height = 38,
    className,
    onKeyDown,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    // 포커스
    const [isFocused, setIsFocused] = React.useState(false);
    //

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    return (
        <div
            className={`flex items-center border  border-solid rounded-[10px] bg-white px-[18px] cursor-text hover:border-primary transition-colors duration-300 ease-in-out ${
                disabled ? 'bg-gray-100 text-gray-400' : 'bg-white'
            } ${isFocused ? 'border-primary' : 'border-[#D9D9D9B2]'} ${className}`}
            onClick={() => {
                inputRef.current?.focus();
            }}
            style={{
                height,
            }}
        >
            <input
                ref={inputRef}
                type="password"
                value={value}
                onChange={(e) => {
                    // 숫자입력시 0으로 시작하는 경우 0 제거
                    if (e.target.value.length > 1 && e.target.value[0] === '0') {
                        onChange(e.target.value.slice(1));
                        return;
                    }

                    const numericValue = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 남기기
                    onChange(numericValue);
                }}
                placeholder={placeholder}
                className={`flex flex-grow outline-none w-[80px] text-[16px] leading-[23px] text-[#3D4244]  ${
                    disabled ? 'cursor-not-allowed' : ''
                }`}
                // onFocus={() => setIsFocused(true)}
                // onBlur={() => {
                //     // onChange(text);
                //     setIsFocused(false);
                // }}
                onBlur={onBlur}
                disabled={disabled}
                maxLength={20}
                onKeyDown={onKeyDown}

                // 숫자만
            />
            {unit && <DemiLightText className="flex">{unit}</DemiLightText>}
        </div>
    );
};
