import React, { useState, useCallback, useMemo } from 'react';
// import clsx from 'clsx';
// import { uncontrollable } from 'uncontrollable';
import { views as defaultViews, views } from '../utils/constants';
import Views from './Views';
import { NavigateAction, View, ViewsProps } from '../types/types';
import Toolbar from './Toolbar';
import { moveDate } from '../utils/move';
import { wrapAccessor } from '../utils/accessors';
import dayjs, { Dayjs } from 'dayjs';
import { WeeklyTotal } from '../../../services/useCenterQueryService';
// import { mergeWithDefaults } from './localizer';
// import { notify } from './utils/helpers';
// import { wrapAccessor } from './utils/accessors';
// import Toolbar from './Toolbar';
// import Views from './Views';
// import NoopWrapper from './NoopWrapper';

interface CalendarProps<TEvent extends object = Event, TResource extends object = object> {
    events: any[];
    weeklyEvents?: any[];
    backgroundEvents?: any[];
    date?: Date | string | Dayjs;
    height?: number | string;
    view?: View | undefined;
    views?: ViewsProps<TEvent, TResource> | undefined;
    toolbar?: boolean;
    onNavigate?: (date: Date | string | Dayjs, view: string, action: string) => void;
    onView?: (view: string) => void;
    onSelectEvent?: (event: any) => void;
    onDoubleClickEvent?: (event: any) => void;
    onKeyPressEvent?: (event: any) => void;
    onSelectSlot?: (slotInfo: any) => void;
    onShowMore?: () => void;
    components?: Record<string, any>;
    culture?: string;
    formats?: Record<string, any>;
    messages?: Record<string, any>;
    length?: number;
    style?: React.CSSProperties;
    className?: string;
    elementProps?: React.HTMLAttributes<HTMLDivElement>;
    step?: number;
    drilldownView?: string;
    doShowMoreDrillDown?: boolean;
    popup?: boolean;
    dayLayoutAlgorithm?: string;
    allDayAccessor?: string;
    startAccessor?: string;
    endAccessor?: string;
    titleAccessor?: string;
    tooltipAccessor?: string;
    resourceAccessor?: string;
    resourceIdAccessor?: string;
    resourceTitleAccessor?: string;
    showMultiDayTimes?: boolean;
    getNow?: () => Date;

    monthCellHeaderCustomComponent?: (date: Date) => React.ReactNode;
    monthCustomEventComponent?: (event: any) => React.ReactNode;
    monthTooltipCustomComponent?: (
        onNavigate: (action: NavigateAction) => void,
        date: Dayjs | string
    ) => React.ReactNode;
    monthWeeklyCustomComponent?: (event: WeeklyTotal, isLastWeek: boolean) => React.ReactNode;
}

const CustomCalendar: React.FC<CalendarProps> = ({
    events = [],
    weeklyEvents = [],
    backgroundEvents = [],
    date = new Date(),
    view = 'month',
    views = defaultViews,
    toolbar = true,
    height = '100%',
    onNavigate,
    onView,
    onSelectEvent,
    onDoubleClickEvent,
    onKeyPressEvent,
    onSelectSlot,
    onShowMore,
    components = {},
    culture,
    formats,
    messages,
    length = 30,
    style,
    className,
    elementProps,
    step = 30,
    drilldownView = 'day',
    doShowMoreDrillDown = true,
    popup = false,
    dayLayoutAlgorithm = 'overlap',
    allDayAccessor = 'allDay',
    startAccessor = 'start',
    endAccessor = 'end',
    titleAccessor = 'title',
    tooltipAccessor = 'title',
    resourceAccessor = 'resourceId',
    resourceIdAccessor = 'id',
    resourceTitleAccessor = 'title',
    showMultiDayTimes = false,
    getNow = () => new Date(),
    monthCellHeaderCustomComponent,
    monthCustomEventComponent,
    monthTooltipCustomComponent,
    monthWeeklyCustomComponent,
}) => {
    // Context 및 상태 관리
    // const context = useMemo(() => {
    //     const viewNames = Array.isArray(views) ? views : Object.keys(views).filter((key) => views[key]);

    //     const msgs = localizer.messages(messages);

    //     return {
    //         viewNames,
    //         localizer: mergeWithDefaults(localizer, culture, formats, msgs),
    //         accessors: {
    //             start: wrapAccessor(startAccessor),
    //             end: wrapAccessor(endAccessor),
    //             allDay: wrapAccessor(allDayAccessor),
    //             tooltip: wrapAccessor(tooltipAccessor),
    //             title: wrapAccessor(titleAccessor),
    //             resource: wrapAccessor(resourceAccessor),
    //             resourceId: wrapAccessor(resourceIdAccessor),
    //             resourceTitle: wrapAccessor(resourceTitleAccessor),
    //         },
    //         getters: {},
    //         components: {
    //             toolbar: Toolbar,
    //             ...components,
    //             eventWrapper: NoopWrapper,
    //             backgroundEventWrapper: NoopWrapper,
    //             eventContainerWrapper: NoopWrapper,
    //             dateCellWrapper: NoopWrapper,
    //             weekWrapper: NoopWrapper,
    //             timeSlotWrapper: NoopWrapper,
    //             timeGutterWrapper: NoopWrapper,
    //         },
    //     };
    // }, [
    //     views,
    //     localizer,
    //     culture,
    //     formats,
    //     messages,
    //     startAccessor,
    //     endAccessor,
    //     allDayAccessor,
    //     tooltipAccessor,
    //     titleAccessor,
    //     resourceAccessor,
    //     resourceIdAccessor,
    //     resourceTitleAccessor,
    // ]);

    const context = useMemo(() => {
        const viewNames = ['month'];

        return {
            viewNames: viewNames,
            components: {
                toolbar: Toolbar,
            },
            accessors: {
                start: wrapAccessor(startAccessor),
                end: wrapAccessor(endAccessor),
                allDay: wrapAccessor(allDayAccessor),
                tooltip: wrapAccessor(tooltipAccessor),
                title: wrapAccessor(titleAccessor),
                resource: wrapAccessor(resourceAccessor),
                resourceId: wrapAccessor(resourceIdAccessor),
                resourceTitle: wrapAccessor(resourceTitleAccessor),
            },
        };
    }, []);

    const handleNavigate = useCallback(
        (action: string, newDate?: Date) => {
            const today = getNow();

            const updatedDate = moveDate(view, {
                action,
                date: newDate || date || today,
                today,
            });

            if (onNavigate) {
                onNavigate(updatedDate, view, action);
            }
        },
        [date, view, getNow, onNavigate]
    );

    const handleViewChange = useCallback(
        (newView: string) => {
            if (onView) {
                onView(newView);
            }
        },
        [onView]
    );

    // 렌더링
    const View = useMemo(() => Views[view], [view]);
    // const toolbarLabel = useMemo(
    //     () => View && View.title(date || getNow(), { localizer, length }),
    //     [View, date, getNow, localizer, length]
    // );

    const monthCustomProps = {
        // Cell 헤더 커스텀
        monthCellHeaderCustomComponent: monthCellHeaderCustomComponent,
        // Cell 커스텀
        monthCustomEventComponent: monthCustomEventComponent,
        // 합산 주간 이벤트
        weeklyEvents: weeklyEvents,
        // 주간 이벤트 커스텀
        monthWeeklyCustomComponent: monthWeeklyCustomComponent,
    };

    return (
        <div
            style={{
                height: height,
            }}
            className={`flex flex-col w-full h-full `}
        >
            {toolbar && (
                <Toolbar
                    date={date || getNow()}
                    view={view}
                    views={context.viewNames}
                    // label={toolbarLabel}
                    onView={handleViewChange}
                    onNavigate={handleNavigate}
                    monthTooltipCustomComponent={monthTooltipCustomComponent}
                />
            )}
            {View && (
                <View
                    events={events}
                    backgroundEvents={backgroundEvents}
                    date={date}
                    getNow={getNow}
                    length={length}
                    components={context.components}
                    accessors={context.accessors}
                    showMultiDayTimes={showMultiDayTimes}
                    onNavigate={handleNavigate}
                    onSelectEvent={onSelectEvent}
                    onDoubleClickEvent={onDoubleClickEvent}
                    onKeyPressEvent={onKeyPressEvent}
                    onSelectSlot={onSelectSlot}
                    onShowMore={onShowMore}
                    {...monthCustomProps}
                />
            )}
        </div>
    );
};

export default CustomCalendar;

// export default uncontrollable(Calendar, {
//     view: 'onView',
//     date: 'onNavigate',
//     selected: 'onSelectEvent',
// });
