import React, { useEffect, useState } from 'react';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { MediumText, RegularText } from '../../lib/Text';
import dayjs from 'dayjs';
import Dropdown from '../../components/DropDown/DropDown';
import { CodeValueType } from '../../types/types';
import { colors } from '../../lib/colors';
import SvgIcon from '../../components/SvgIcon';
import MemberSearchTextInput from '../../components/Button/MemberSearchTextInput';
import Table from '../../components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { UnpaidPaymentData, UnpaidPaymentTableType } from '../../services/useMembershipQueryService';
import { cloneDeep } from 'lodash';

type Props = {
    // coachList: CodeValueType[];
    selectedCoach?: CodeValueType;
    onCoachSelect: (coach: CodeValueType) => void;
    unpaidListData?: UnpaidPaymentData;
    onClickUnpaid: (unpaidRow: UnpaidPaymentTableType) => void;
};

const columnHelper = createColumnHelper<UnpaidPaymentTableType>();

const UnpaidListPage = ({ selectedCoach, onCoachSelect, unpaidListData, onClickUnpaid }: Props) => {
    const columns = [
        columnHelper.accessor('recentPaymentDate', {
            id: 'paymentDate',
            header: '결제일시',
            cell: (info) => dayjs(info.getValue()).format('YYYY-MM-DD'),
            size: 146,
            enableSorting: false,
        }),
        columnHelper.accessor('salesManager', {
            id: 'salesManager',
            header: '판매담당자',
            cell: (info) => info.getValue().userName,
            size: 138,
            enableSorting: false,
        }),

        columnHelper.accessor('memberName', {
            id: 'memberName',
            header: '회원',
            cell: (info) => info.getValue(),
            size: 193,
            enableSorting: false,
        }),
        columnHelper.accessor('totalPrice', {
            id: 'totalPrice',
            header: '판매금액',
            cell: (info) =>
                info.getValue() === null || info.getValue() === undefined ? '-' : convertToKRW(info.getValue()),
            size: 129,
            enableSorting: false,
        }),
        columnHelper.accessor('totalPaymentAmount', {
            id: 'totalPaymentAmount',
            header: '결제금액',
            cell: (info) =>
                info.getValue() === null || info.getValue() === undefined ? '-' : convertToKRW(info.getValue()),
            size: 129,
            enableSorting: false,
        }),
        columnHelper.accessor('totalUnpaidAmount', {
            id: 'totalUnpaidAmount',
            header: '미수금',
            cell: (info) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const [isHover, setIsHover] = useState(false);

                const unPaidAmount = info.getValue();

                return unPaidAmount ? (
                    <Row
                        className="items-center cursor-pointer "
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        onClick={() => onClickUnpaid(info.row.original)}
                    >
                        <RegularText
                            fontSize={16}
                            lineHeight={24}
                            color={isHover ? '#15BA72' : '#3D4244'}
                            className={`mr-[8px] underline underline-offset-4 decoration-[1px] self-start  overflow-hidden whitespace-nowrap `}
                        >
                            {convertToKRW(unPaidAmount)}
                        </RegularText>

                        <SvgIcon
                            name="SvgCreditCard"
                            size={20}
                            fill={isHover ? '#15BA72' : '#B4BCC4'}
                            className="w-[20px]"
                        />
                    </Row>
                ) : (
                    <RegularText fontSize={16} lineHeight={24}>
                        {'-'}
                    </RegularText>
                );
            },

            size: 129,
            enableSorting: false,
        }),
        columnHelper.accessor('totalSession', {
            id: 'totalSession',
            header: '총 회차',
            cell: (info) => info.getValue(),
            size: 129,
            enableSorting: false,
        }),
        columnHelper.accessor('name', {
            id: 'name',
            header: '수강권',
            cell: (info) => info.getValue(),
            size: 216,
            enableSorting: false,
        }),

        columnHelper.accessor('coachName', {
            id: 'coachName',
            header: '담당강사',
            cell: (info) => info.getValue(),
            size: 129,
            enableSorting: false,
        }),
    ];
    const totalUnpaid = unpaidListData?.summaryData?.totalUnpaidPayment;
    //const filteredCount = unpaidListData?.summaryData.totalUnpaidPaymentCount;
    const [filteredCount, setFilteredCount] = React.useState(0);
    //const filteredTotalPrice = unpaidListData?.summaryData.totalUnpaidPayment;
    const [filteredTotalPrice, setFilteredTotalPrice] = React.useState(0);
    const tableData = unpaidListData?.tableDataByMembership;
    const [search, setSearch] = React.useState('');

    // 숙강권 선택
    const [selectedClassTicket, setSelectedClassTicket] = React.useState('');

    // 회원 검색데이터, memberId, memberName -> code, value
    const memberData = unpaidListData?.filters?.memberList.map((member) => ({
        code: member.memberId,
        value: member.memberName,
    }));
    // 판매자
    const salesManagerData = unpaidListData?.filters?.salesManagers.map((item) => ({
        code: item.userId,
        value: item.userName,
        object: item,
    }));
    // 수강권 템플릿
    const membershipTemplateData = unpaidListData?.filters?.membershiptemplateList.map((item) => ({
        code: item.membershipTemplateId,
        value: item.membershipTemplateName,
    }));

    const [filteredTableData, setFilteredTableData] = React.useState(tableData);

    // 필터링
    useEffect(() => {
        if (!tableData) return;
        let filteredData = cloneDeep(tableData);

        if (selectedClassTicket && selectedClassTicket !== 'all') {
            filteredData = filteredData.filter((data) => data.membershipTemplateId === selectedClassTicket);
        }

        setFilteredTableData(filteredData);
    }, [selectedCoach, selectedClassTicket]);

    useEffect(() => {
        setFilteredTableData(tableData);
    }, [unpaidListData]);

    // 필터링된 데이터의 총 미수금액, 건수 계산
    useEffect(() => {
        if (!filteredTableData) return;
        let totalPrice = 0;
        let count = 0;
        filteredTableData.forEach((data) => {
            totalPrice += data.totalUnpaidAmount;
            count += 1;
        });
        setFilteredTotalPrice(totalPrice);
        setFilteredCount(count);
    }, [filteredTableData]);

    return (
        <div className="flex flex-col w-full h-full pt-[29px] px-[46px] bg-white rounded-[27px] mb-[50px]">
            <Row className=" items-center mb-[50px]">
                <MediumText fontSize={22} lineHeight={26} color="#3D4244" letterSpacing={-0.32} className="mr-[30px]">
                    {dayjs().format('YYYY년 MM월 DD일')} 현재 기준
                </MediumText>
                {salesManagerData && salesManagerData.length > 0 && (
                    <Dropdown
                        initialSelected={selectedCoach?.code}
                        options={salesManagerData}
                        onSelect={(selected) => {
                            onCoachSelect(selected);
                        }}
                        bgColor="rgba(201, 219, 255, 0.4)"
                        textColor={colors.primary}
                        borderRadius={50}
                        totalText="판매자 전체"
                        arrowColor={colors.primary}
                        className="py-[14px]"
                        textType="medium"
                        totalObjectOption={{ code: 'all', value: '판매자 전체', object: { role: 'all' } }}
                    />
                )}
            </Row>
            <Col className="flex flex-col border border-solid border-[#D9D9D9] w-[333px] mb-[99px]">
                <Col className=" justify-center items-center bg-[#F0F2F3] h-[40px] border-b-[1px] border-solid border-[#D9D9D9]">
                    <RegularText fontSize={16} lineHeight={24} color="#6B7684">
                        전체 미수금액
                    </RegularText>
                </Col>
                <Col className="h-[68px] w-full justify-center items-center ">
                    <RegularText fontSize={23} lineHeight={24} color="#3D4244">
                        {convertToKRW(totalUnpaid || 0)}
                    </RegularText>
                </Col>
            </Col>
            <Row className="flex w-full justify-between mb-[30px] items-center">
                <Row>
                    <RegularText fontSize={18} lineHeight={24} color="#3D4244" letterSpacing={-0.32}>
                        {`총 ${filteredCount}건 / 미수금액 ${convertToKRW(filteredTotalPrice || 0)}`}
                    </RegularText>
                </Row>
                <Row>
                    <Dropdown
                        options={membershipTemplateData || []}
                        className="h-[33px] mr-[12px]"
                        totalText="모든 수강권"
                        onSelect={(selected) => {
                            setSelectedClassTicket(selected.code);
                        }}
                        textType="demilight"
                        selectedBgColor="#C9DBFF66"
                        selectedTextColor={colors.primary}
                        selectedArrowColor={colors.primary}
                    />

                    {memberData && memberData.length > 0 && (
                        <MemberSearchTextInput
                            data={memberData}
                            width={154}
                            placeholder="회원 검색"
                            onSelect={(select) => setSearch(select.value)}
                            onSearch={(search) => {
                                setSearch(search);
                            }}
                        />
                    )}
                </Row>
            </Row>
            {filteredTableData && filteredTableData.length > 0 ? (
                <Table
                    data={filteredTableData}
                    columns={columns}
                    width={1584}
                    globalFilter={search}
                    onGlobalFilterChange={setSearch}
                    // onRowClick={(row: any) => {
                    //     navigate('/member/detail/membership', {
                    //         state: {
                    //             membershipId: row.membershipId,
                    //             memberName: row.memberName,
                    //             coachName: row.coachName,
                    //             memberId: row.memberId,
                    //             coachId: row.coachId,
                    //         },
                    //     });
                    // }}
                    className="mb-[100px]"
                    isPagination
                    customPaginationComponent={({
                        firstPage,
                        nextPage,
                        canNextPage,
                        previousPage,
                        canPreviousPage,
                    }) => (
                        <Row className=" absolute items-center flex right-0 bottom-0 mb-[50px]">
                            <RegularText
                                fontSize={16}
                                lineHeight={23}
                                color="#3D4244"
                                className={`mr-[16px] ${
                                    canPreviousPage ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                                }`}
                                onClick={() => !canPreviousPage && firstPage()}
                            >
                                처음으로
                            </RegularText>
                            <button
                                className="flex flex-row items-center mr-[13px] bg-[#F4F5F7] rounded-[7px] px-[19px] py-[7px] 
                disabled:opacity-50 disabled:cursor-not-allowed
                "
                                onClick={previousPage}
                                disabled={canPreviousPage}
                            >
                                <SvgIcon
                                    name={'SvgLeftStrokeArrow'}
                                    size={15}
                                    fill={colors.trans}
                                    stroke={'#70747C'}
                                    strokeWidth={1.2}
                                    className="mr-[7px]"
                                />
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    이전
                                </RegularText>
                            </button>
                            <button
                                className="flex flex-row items-center mr-[13px] bg-[#F4F5F7] rounded-[7px] px-[19px] py-[7px] 
                disabled:opacity-50 disabled:cursor-not-allowed
                "
                                onClick={nextPage}
                                disabled={canNextPage}
                            >
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244" className="mr-[7px]">
                                    다음
                                </RegularText>
                                <SvgIcon
                                    name={'SvgRightStrokeArrow'}
                                    size={15}
                                    fill={colors.trans}
                                    stroke={'#70747C'}
                                    strokeWidth={1.2}
                                />
                            </button>
                        </Row>
                    )}
                />
            ) : (
                <Col className="flex flex-col overflow-x-auto w-full">
                    <Col className="w-full  items-center h-[500px]">
                        <MediumText
                            fontSize={20}
                            lineHeight={29}
                            letterSpacing={-0.3}
                            color="#3D4244"
                            className="mb-[8px] mt-[100px]"
                        >
                            조회 결과가 없어요
                        </MediumText>
                        <RegularText fontSize={16} lineHeight={24} color="#6B7684">
                            입력한 정보가 올바른지 확인해주세요.
                        </RegularText>
                    </Col>
                </Col>
            )}
        </div>
    );
};

export default UnpaidListPage;
