import React from 'react';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import { Row } from '../../lib/utils';
import TextSelector, { FontsType } from '../TextSelector';
import dayjs from 'dayjs';

type Props = {
    width?: number;
    currentDate: string;
    fontType: FontsType;
    type?: PeriodNavigatorType;
    onClickPrev?: (type: PeriodNavigatorType) => void;
    onClickNext?: (type: PeriodNavigatorType) => void;
    mr?: number;
};

const PeriodNavigator = ({
    width = 232,
    currentDate,
    fontType,
    type = 'thisMonth',
    onClickPrev,
    onClickNext,
    mr = 0,
}: Props) => {
    const dateText =
        type === 'thisMonth'
            ? dayjs(currentDate).format('YYYY년 M월')
            : type === 'thisYear'
            ? dayjs(currentDate).format('YYYY년')
            : '전체';
    return (
        <Row className="flex justify-between" style={{ width: width, marginRight: mr }}>
            <SvgIcon
                name={'SvgLeftStrokeArrow'}
                size={24}
                fill={colors.trans}
                stroke={colors.BLUE_300}
                onClick={() => onClickPrev && onClickPrev(type)}
                className="cursor-pointer"
            />
            <TextSelector fontType={fontType} text={dateText} className="cursor-pointer" />

            <SvgIcon
                name={'SvgRightStrokeArrow'}
                size={24}
                fill={colors.trans}
                stroke={colors.BLUE_300}
                onClick={() => onClickNext && onClickNext(type)}
                className="cursor-pointer"
                strokeWidth={1.2}
            />
        </Row>
    );
};

export default PeriodNavigator;

// 이번 달, 이번 연도, 전체
export type PeriodNavigatorType = 'thisMonth' | 'thisYear' | 'all';
