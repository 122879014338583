import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../modal/Modal';
import { Col, Row } from '../../lib/utils';
import { BoldText, DemiLightText, RegularText } from '../../lib/Text';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onClickConfirm: (name: string) => void;
};

const ModalSalesManagerName = ({ setIsVisible, onClickConfirm }: Props) => {
    const [name, setName] = useState('');
    return (
        <Modal whiteViewWidth={495} whiteViewHeight={310} setIsVisible={setIsVisible}>
            <Col className="flex justify-center  px-[40px] py-[36px]">
                <BoldText fontSize={22} lineHeight={38} className="mb-[20px]" color="#3D4244">
                    판매 담당자 이름을 입력해주세요
                </BoldText>
                <DemiLightText fontSize={16} lineHeight={23} color="#3D4244">
                    등록되지 않은 강사 및 기타 직원분들을 입력해주세요
                </DemiLightText>
                <input
                    ref={(input) => input && input.focus()}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`w-full border border-solid text-[16px] py-[21px] pl-[17px] ${
                        name ? 'border-primary' : 'border-[#D9D9D9B2]'
                    } mt-[20px] mb-[36px] rounded-[10px]`}
                    maxLength={5}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && name) {
                            onClickConfirm && onClickConfirm(name);
                            setIsVisible(false);
                        }
                    }}
                />
                <Row className=" justify-end">
                    <Col
                        className="flex justify-center items-center bg-[#F2F4F6] rounded-[11px] w-[99px] h-[41px] mr-[9px]"
                        onClick={() => {
                            setIsVisible(false);
                        }}
                    >
                        <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.32} color="#3D4244">
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className={`flex justify-center items-center bg-primary rounded-[11px] w-[99px] h-[41px] ${
                            name ? 'cursor-pointer bg-primary' : 'cursor-not-allowed bg-opacity-50'
                        } `}
                        onClick={() => {
                            if (!name) return;
                            onClickConfirm && onClickConfirm(name);
                            setIsVisible(false);
                        }}
                    >
                        <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.32} color="white">
                            확인
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalSalesManagerName;
