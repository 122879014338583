import { Col, convertHourMin, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body1Bold, Body1Regular, Body2Regular, Body3Bold, Body3Regular, CaptionRegular } from '../../../lib/font';
import {
    I_BOOKMARK,
    I_DOWNLOAD,
    I_EMPTY_CONDITION,
    I_PRIMARY_RIGHT_TRIANGLE,
    I_VIDEO_PLAY,
} from '../../../types/images';
import ProfileImage from '../../ProfileImage';
import dayjs from 'dayjs';
import { CONDITION_TYPE } from '../../../lib/varialbe';
import styled from 'styled-components';

const ConditionButton = styled.button`
    display: flex;
    flex-direction: column;
    width: 968px;
    height: 104px;
    border-radius: 8px;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    background-color: #f8fbff;
    align-self: center;
    border: 1px solid #c3d4f4;
    &:hover {
        background-color: #f0f6ff;
    }
    &:active {
        background-color: #f0f6ff;
    }
`;

const ModalFeedDetailBody = ({
    contents,
    scheduleReport,
    receivers,
    postType,
    initialPost,
    setIsConditionModal,
    onClickImageAtDetailModal,
    onClickVideoAtDetailModal,
}: any) => {
    const scheduleInfo = scheduleReport?.scheduleInfo;
    const condition = scheduleReport?.condition;

    console.log(initialPost);
    let conditionText = condition
        ? CONDITION_TYPE.find((item) => item.code === condition.todayCondition)?.text
        : '기록 안함';

    let conditionImg = condition
        ? CONDITION_TYPE.find((item) => item.code === condition.todayCondition)?.src
        : I_EMPTY_CONDITION;

    const pictureList = contents && contents.filter((item: any) => item.contentType === 'IMG');
    const videoList = contents && contents.filter((item: any) => item.contentType === 'VIDEO');

    const handleDownload = (url: string) => {
        try {
            // 파일을 다운로드할 URL
            const fileUrl = url;

            // 다운로드 링크를 생성하여 클릭
            const link: any = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', true);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            alert('다운로드에 실패하였습니다.');
        }
    };

    return (
        <Col style={{ width: 1080, minHeight: 264, marginTop: 106 }}>
            {postType === 'REPLYBASIC' && (
                <Col
                    style={{
                        width: 970,
                        borderRadius: 8,
                        border: '1px solid #d4dbe8',
                        alignSelf: 'center',
                        position: 'relative',
                        marginTop: 24,
                        paddingBottom: 16,
                        paddingLeft: 20,
                    }}
                >
                    <img
                        src={I_BOOKMARK}
                        style={{
                            width: 32,
                            height: 40,
                            marginLeft: 906,

                            position: 'absolute',
                        }}
                    />
                    <Row style={{ alignItems: 'center', paddingTop: 18 }}>
                        <ProfileImage
                            imageUrl={initialPost?.profileImageUrl}
                            type="Middle"
                            profileName={initialPost?.profileName}
                            profileColorType={initialPost?.profileColorType}
                            right={10}
                        />
                        <Col>
                            <Row style={{ alignItems: 'center' }}>
                                <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${initialPost?.writerName} ${
                                    initialPost?.role === 'coach' ? '강사' : '회원'
                                }`}</Body3Bold>
                                <img
                                    src={I_PRIMARY_RIGHT_TRIANGLE}
                                    style={{ width: 14, height: 14, marginLeft: 8, marginRight: 8 }}
                                />
                                <Body3Bold style={{ color: colors.POINT_1 }}>{'공지글'}</Body3Bold>
                            </Row>
                            <Row style={{ alignItems: 'center' }}>
                                <CaptionRegular style={{ color: colors.LAVEL_1 }}>{`회원 총 123명`}</CaptionRegular>
                                <CaptionRegular style={{ marginLeft: 660, color: colors.LAVEL_1 }}>
                                    {dayjs(initialPost?.createdTime).format('YY/MM/DD(ddd)/A HH:mm')}
                                </CaptionRegular>
                            </Row>
                        </Col>
                    </Row>

                    <Col
                        style={{
                            width: 918,
                            alignSelf: 'center',
                            flexWrap: 'wrap',
                            marginTop: 16,
                        }}
                    >
                        <Body3Regular>{initialPost?.contents[0]?.content}</Body3Regular>
                    </Col>
                </Col>
            )}
            {scheduleReport && (
                <ConditionButton onClick={() => setIsConditionModal && setIsConditionModal(true)} style={{}}>
                    <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Row style={{ alignItems: 'center' }}>
                            <ProfileImage
                                imageUrl={receivers[0]?.profileImageUrl}
                                type="VerySmall"
                                profileName={receivers[0]?.profileName}
                                profileColorType={receivers[0]?.profileColorType}
                                right={10}
                            />
                            <Body3Bold
                                style={{ color: colors.LAVEL_3 }}
                            >{`${receivers[0]?.receiverName} 회원`}</Body3Bold>
                        </Row>
                    </Row>
                    <Row style={{ marginTop: 8, marginLeft: 36 }}>
                        <Col style={{ alignItems: 'flex-start' }}>
                            <CaptionRegular>{`수강권 : ${scheduleInfo?.membershipName}`}</CaptionRegular>

                            <CaptionRegular style={{ color: colors.LAVEL_2 }}>{`회차 : ${
                                scheduleInfo?.currentSession ? scheduleInfo?.currentSession : '?'
                            }/${scheduleInfo?.totalSession ? scheduleInfo?.totalSession : '?'}`}</CaptionRegular>
                        </Col>
                        <Col style={{ marginLeft: 56, alignItems: 'flex-start' }}>
                            <CaptionRegular style={{ color: colors.LAVEL_2 }}>
                                {dayjs(scheduleInfo?.startTime).format('수업 날짜 : YY/MM/DD(ddd)')}
                            </CaptionRegular>

                            <CaptionRegular style={{ color: colors.LAVEL_2 }}>
                                {`수업 시간 : ${convertHourMin(
                                    scheduleInfo?.startTime,
                                    'text',
                                    true
                                )} ~ ${convertHourMin(scheduleInfo?.endTime, 'text', true)}`}
                            </CaptionRegular>
                        </Col>

                        <Col style={{ marginLeft: 56, alignItems: 'flex-start' }}>
                            <Row style={{ alignItems: 'center' }}>
                                <CaptionRegular style={{ color: colors.LAVEL_2, marginRight: 8 }}>
                                    {`컨디션 : ${conditionText}`}
                                </CaptionRegular>
                                <img src={conditionImg} style={{ width: 16, height: 16 }} />
                            </Row>

                            <CaptionRegular style={{ color: colors.LAVEL_2 }}>{'특이사항 : 없음'}</CaptionRegular>
                        </Col>
                    </Row>
                </ConditionButton>
            )}
            {contents &&
                contents.map((item: any, index: number) => {
                    return (
                        <Col
                            style={{
                                width: 968,
                                alignSelf: 'center',
                                flexWrap: 'wrap',
                                marginTop: 16,
                            }}
                            key={index + 'feedDetailModalContent'}
                        >
                            {item.contentType === 'TEXT' && (
                                <Body3Regular style={{ color: colors.LAVEL_3 }}>{item.content}</Body3Regular>
                            )}

                            {item.contentType === 'IMG' && (
                                <img
                                    src={item.content}
                                    style={{ width: 390, borderRadius: 4 }}
                                    onClick={() => onClickImageAtDetailModal && onClickImageAtDetailModal(pictureList)}
                                />
                            )}

                            {item.contentType === 'VIDEO' && (
                                <Row
                                    onClick={() =>
                                        onClickVideoAtDetailModal && onClickVideoAtDetailModal(videoList, item._id)
                                    }
                                    style={{ width: 390, position: 'relative' }}
                                >
                                    <img
                                        src={item?.thumbnail}
                                        style={{
                                            opacity: 0.8,
                                            filter: 'blur(2px)',
                                            width: 390,
                                            height: 342,
                                            borderRadius: 4,
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: 390,
                                            height: 342,
                                            position: 'absolute',
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            src={I_VIDEO_PLAY}
                                            style={{
                                                width: 64,
                                                height: 64,
                                            }}
                                        />
                                    </div>
                                </Row>
                            )}

                            {item.contentType === 'FILE' && (
                                <button
                                    onClick={() => handleDownload(item.content)}
                                    style={{
                                        width: 640,
                                        height: 112,
                                        borderRadius: 8,
                                        borderWidth: 1.5,
                                        borderColor: colors.BLUE_200,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingTop: 17,
                                    }}
                                >
                                    <img src={I_DOWNLOAD} style={{ width: 24, height: 24 }} />
                                    <Body1Bold style={{ color: colors.primary }}>{'첨부파일 다운 :'}</Body1Bold>
                                    <Body2Regular style={{ color: colors.primary }}>{item?.fileName}</Body2Regular>
                                </button>
                            )}
                        </Col>
                    );
                })}
        </Col>
    );
};

export default ModalFeedDetailBody;
