import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getItemWithExpiration, setItemWithExpiration } from './utils';
import ModalTempMemberUpdatePopup from '../components/modal/ModalTempMemberUpdatePopup';
import { KeepAliveRouteOutlet } from 'keepalive-for-react';

// 결제상태
// needAction : 최초 접속
// trial : 무료 체험중
// trialPaymentPending : 무료 체험 유예
// plan : 유료 구독
// planWillChanged : 유료 구독 변경예정
// planWillCanceled : 유료 구독 취소예정
// planAborted : 유료 구독 유예
// needPlanToFree : 무료 체험 만료
// needPlanToCharge : 유료 구독 만료

const PrivateRoutes = () => {
    const navigate = useNavigate();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [isModalVisible, setIsModalVisible] = useState(false);
    const location = useLocation();

    // usableStatus 가 needPlan이면 만료페이지로 던진다. 하지만 expired가 url에 포함되잇으면 네비게이팅멈춘다
    useEffect(() => {
        if (authObject) {
            authObject.usableStatus === 'needPlanToFree' &&
                !location.pathname.includes('expired') &&
                navigate('/expired', { replace: true });
            authObject.usableStatus === 'needPlanToCharge' &&
                !location.pathname.includes('expired') &&
                navigate('/expired', { replace: true });
        }
    }, [authObject]);

    const onClickNerverShowAgain = () => {
        setItemWithExpiration('isModalVisible', false, 60 * 60 * 24 * 365);
        setIsModalVisible(false);
    };

    useEffect(() => {
        const isModalVisible = getItemWithExpiration('isModalVisible');
        if (isModalVisible === null) {
            setIsModalVisible(true);
        } else {
            setIsModalVisible(isModalVisible);
        }
    }, []);

    return authorization && authObject ? (
        <>
            <KeepAliveRouteOutlet
                include={['/settlement', '/memberList', '/trainerManage/trainerInfo']}
                exclude={['dash', 'schedule/history']}
            />

            {/* {isModalVisible && (
                <ModalTempMemberUpdatePopup
                    setIsVisible={setIsModalVisible}
                    onClickNerverShowAgain={onClickNerverShowAgain}
                    onClickClose={() => setIsModalVisible(false)}
                />
            )} */}
        </>
    ) : (
        <Navigate to="/signIn" />
    );
};

export default PrivateRoutes;
