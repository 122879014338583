import React from 'react';
import EventCell from './EventCell';
import { isSelected } from './../utils/selection';

interface EventRowMixinProps {
    slotMetrics?: {
        continuesPrior: (event: any) => boolean;
        continuesAfter: (event: any) => boolean;
        first: Date;
        last: Date;
    };
    segments?: any[];
    selected?: any;
    isAllDay?: boolean;
    accessors?: any;
    components?: any;
    getters?: any;
    className?: string;
    onSelect?: (event: any) => void;
    onDoubleClick?: (event: any) => void;
    onKeyPress?: (event: any) => void;
    resizable?: boolean;
    monthCustomEventComponent?: (event: any) => React.ReactNode;
}

const EventRowMixin = {
    defaultProps: {
        segments: [],
        selected: {},
    },

    renderEvent: (props: EventRowMixinProps, event: any) => {
        const {
            selected,
            accessors,
            getters,
            onSelect,
            onDoubleClick,
            onKeyPress,
            slotMetrics,
            components,
            resizable,
            monthCustomEventComponent,
            // 월간달력인데 커스텀 이벤트 컴포넌트가 있을 경우
        } = props;

        const continuesPrior = slotMetrics?.continuesPrior(event);
        const continuesAfter = slotMetrics?.continuesAfter(event);

        return (
            <EventCell
                event={event}
                components={components}
                onSelect={onSelect}
                onDoubleClick={onDoubleClick}
                onKeyPress={onKeyPress}
                continuesPrior={continuesPrior}
                continuesAfter={continuesAfter}
                slotStart={slotMetrics?.first}
                slotEnd={slotMetrics?.last}
                selected={isSelected(event, selected)}
                resizable={resizable}
                monthCustomEventComponent={monthCustomEventComponent}
            />
        );
    },

    renderSpan: (slots: number, len: number, key: string, content: React.ReactNode = ' ') => {
        const adjustedSlots = slots + 1;

        const per = `${(Math.abs(len) / adjustedSlots) * 100}%`;

        return (
            <div
                key={key}
                className=" flex w-full h-full "
                style={{
                    WebkitFlexBasis: per,
                    flexBasis: per,
                    maxWidth: per, // IE10/11 need max-width. flex-basis doesn't respect box-sizing
                }}
            >
                {content}
            </div>
        );
    },
};

export default EventRowMixin;
