import memoizeOne from 'memoize-one';
import { endOfRange, eventSegments, eventLevels } from '../utils/eventLevel';
import dayjs from 'dayjs';

const isSegmentInSlot = (seg: any, slot: any) => seg.left <= slot && seg.right >= slot;

const isEqual = (a: any, b: any) => {
    return a[0].range === b[0].range && a[0].events === b[0].events;
};

export function getSlotMetrics() {
    return memoizeOne((options) => {
        const { range, events, maxRows, minRows } = options;
        if (!range)
            return {
                first: dayjs(),
                last: dayjs(),
                levels: [],
                extra: [],
                range: [],
                slots: 0,
            };

        const { first, last } = endOfRange({ dateRange: range });

        const segments = events.map((event: any) => eventSegments(event, range));

        const { levels, extra } = eventLevels(
            segments,
            Math.max(maxRows - 1, 1) // Ensure at least 1 row for events
        );

        // Adjust levels to have at least `minRows` rows
        const minEventRows = extra.length > 0 ? minRows - 1 : minRows;
        while (levels.length < minEventRows) levels.push([]);

        return {
            first,
            last,
            levels,
            extra,
            range,
            slots: range.length,

            clone: (args: any) => {
                const metrics = getSlotMetrics();
                return metrics({ ...options, ...args });
            },

            getDateForSlot: (slotNumber: any) => range[slotNumber],

            // getSlotForDate: (date: any) => range.find((r: any) => localizer.isSameDate(r, date)),

            getEventsForSlot: (slot: any) =>
                segments.filter((seg: any) => isSegmentInSlot(seg, slot)).map((seg: any) => seg.event),

            continuesPrior: (event: any) => {
                const start = event.start ? dayjs(event.start) : dayjs();
                return dayjs(start).isBefore(dayjs(first), 'day');
            },

            continuesAfter: (event: any) => {
                const start = event.start ? dayjs(event.start) : dayjs();
                const end = event.end ? dayjs(event.end) : dayjs();
                const singleDayDuration = dayjs(start).isSame(dayjs(end), 'minute');

                // If single-day event, check if the end is greater than or equal to `last` visible date
                if (singleDayDuration) {
                    return dayjs(end).isSameOrAfter(dayjs(last), 'minute');
                }

                // For multi-day events, check if the event ends after `last` visible date
                return dayjs(end).isAfter(dayjs(last), 'minute');
            },
        };
    }, isEqual);
}
