import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSingleQuery from '../../hook/useSingleQuery';
import { fetchMembershipForMember, MemberMembershipDataType } from '../../services/useMembershipQueryService';
import MemberMembershipManageComponents from './MemberMembershipManageComponents';
import MemberMembershipManageHistory from './MemberMembershipManageHistory';
import ModalMembershipUpdate from './ModalMembershipUpdate';
import ModalDeleteAlert from './ModalDeleteAlert';
import ModalRefundAndUpgrade from './ModalRefund';
import { getItemWithExpiration } from '../../lib/utils';
import useMembershipService from '../../services/useMembershipService';
import { useQueryClient } from '@tanstack/react-query';
import ModalPartialPayment from '../../scenes/Settlement/ModalPartialPayment';

type Props = {
    createAlert: any;
};

const MemberMembershipManagePage = ({ createAlert }: Props) => {
    const queryClient = useQueryClient();
    const location = useLocation();
    const membershipId = location.state?.membershipId;
    const memberName = location.state?.memberName;
    const coachName = location.state?.coachName;
    const memberId = location.state?.memberId;
    const coachId = location.state?.coachId;

    const { refinedResult: membershipData } = useSingleQuery({
        fetchFn: fetchMembershipForMember,
        params: {
            membershipId,
        },
        enabled: !!membershipId,
    });
    const membership = membershipData?.data as MemberMembershipDataType;

    const isActive = membership?.status === 'active';
    const isRemainSession = membership?.remainSession > 0;

    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));

    const { updateMemberShipMutation, deleteMemberShipMutation } = useMembershipService({
        onUpdatedMemberShipSuccess: () => {
            if (isActive) {
                createAlert(
                    '수강권 만료 완료',
                    '수강권이 만료되었습니다!\n수강권의 사용 회차가 남아있을 경우, 재활성화가 가능해요!',
                    true
                );
            } else {
                createAlert('수강권 재활성화 완료!', '수강권이 재활성화 되었어요!', false);
            }

            queryClient.invalidateQueries({
                queryKey: ['getMembershipForMemberQuery'],
            });
        },
        onDeleteMemberShipSuccess: () => {
            createAlert(
                '수강권 삭제 완료',
                '수강권이 삭제되었습니다!\n수강권이 삭제되었으니, 회원의 수강권을 다시 등록해주세요!',
                false
            );
            queryClient.invalidateQueries({
                queryKey: ['getAllMembershipForMemberQuery'],
            });

            // 뒤로가기
            window.history.back();
        },
    });

    //수강권 수정하기 모달
    const [isMembershipUpdateModalVisible, setIsMembershipUpdateModalVisible] = useState(false);
    // 삭제 경고 모달
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    // 환불, 업그레이드 모달
    const [isRefundAndUpgradeModalVisible, setIsRefundAndUpgradeModalVisible] = useState(false);
    // 환불인지 업그레이드인지
    const [refundAndUpgradeType, setRefundAndUpgradeType] = useState<'refund' | 'upgrade'>('refund');
    // 미수금 결제 모달
    const [isUnpaidPaymentModalVisible, setIsUnpaidPaymentModalVisible] = useState(false);

    return (
        <div className="flex flex-col pt-[112px] items-center bg-[#F4F5F7]">
            <div className="flex flex-col w-[1192px] item-center justify-center ">
                <MemberMembershipManageComponents
                    membership={membership}
                    memberName={memberName}
                    coachName={coachName}
                    onClickUpdate={() => {
                        setIsMembershipUpdateModalVisible(true);
                    }}
                    onClickDelete={() => {
                        setIsDeleteModalVisible(true);
                    }}
                    createAlert={createAlert}
                    onClickRefund={() => {
                        setIsRefundAndUpgradeModalVisible(true);
                        setRefundAndUpgradeType('refund');
                    }}
                    onClickUpgrade={() => {
                        setIsRefundAndUpgradeModalVisible(true);
                        setRefundAndUpgradeType('upgrade');
                    }}
                    onClickExpire={() => {
                        updateMemberShipMutation.mutate({
                            membershipId: membershipId,
                            status: 'expired',
                            accessToken: authorization,
                        });
                    }}
                    onClickReactivation={() => {
                        if (!isRemainSession) {
                            createAlert(
                                '회차 조절이 필요해요!',
                                `만료된 수강권을 활성화 시키려면 ‘수강권 수정하기’를 눌러\n‘총 회차’를 늘려주세요!`,
                                true
                            );
                        } else {
                            updateMemberShipMutation.mutate({
                                membershipId: membership._id,
                                status: 'active',
                                accessToken: authorization,
                            });
                        }
                    }}
                    onClickUnpaidPayment={() => {
                        setIsUnpaidPaymentModalVisible(true);
                    }}
                />
                <MemberMembershipManageHistory
                    schedules={membership?.schedules}
                    memberId={memberId}
                    coachId={coachId}
                    coachName={coachName}
                    membershipId={membershipId}
                />
            </div>
            {isMembershipUpdateModalVisible && (
                <ModalMembershipUpdate
                    coachName={coachName}
                    memberName={memberName}
                    membership={membership}
                    setIsVisible={setIsMembershipUpdateModalVisible}
                    createAlert={createAlert}
                />
            )}
            {isDeleteModalVisible && (
                <ModalDeleteAlert
                    setIsVisible={setIsDeleteModalVisible}
                    onClickDelete={() => {
                        deleteMemberShipMutation.mutate({ membershipId: membershipId, accessToken: authorization });
                    }}
                />
            )}
            {isRefundAndUpgradeModalVisible && (
                <ModalRefundAndUpgrade
                    setIsVisible={setIsRefundAndUpgradeModalVisible}
                    membership={membership}
                    coachName={coachName}
                    memberName={memberName}
                    type={refundAndUpgradeType}
                />
            )}
            {isUnpaidPaymentModalVisible && (
                <ModalPartialPayment
                    setIsVisible={setIsUnpaidPaymentModalVisible}
                    membership={membership}
                    coachName={coachName}
                    memberName={memberName}
                    onPostSplitPaymentSuccess={() => {
                        queryClient.invalidateQueries({
                            queryKey: ['getMembershipForMemberQuery'],
                        });
                    }}
                />
            )}
        </div>
    );
};

export default MemberMembershipManagePage;
