export const views = {
    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda',
};

// export cpnst navigate = exports.navigate = {
//     PREVIOUS: 'PREV',
//     NEXT: 'NEXT',
//     TODAY: 'TODAY',
//     DATE: 'DATE'
//   };

export const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
};
