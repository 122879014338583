import isEqual from 'lodash/isEqual';

interface Box {
    x: number;
    y: number;
    top: number;
    bottom: number;
}

interface RowBox {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export function isSelected(event: any, selected: any): boolean {
    if (!event || selected == null) return false;
    return isEqual(event, selected);
}

export function slotWidth(rowBox: RowBox, slots: number): number {
    const rowWidth = rowBox.right - rowBox.left;
    return rowWidth / slots;
}

export function getSlotAtX(rowBox: RowBox, x: number, rtl: boolean, slots: number): number {
    const cellWidth = slotWidth(rowBox, slots);
    return rtl ? slots - 1 - Math.floor((x - rowBox.left) / cellWidth) : Math.floor((x - rowBox.left) / cellWidth);
}

export function pointInBox(box: RowBox, point: Box): boolean {
    return point.y >= box.top && point.y <= box.bottom && point.x >= box.left && point.x <= box.right;
}

export function dateCellSelection(
    start: Box,
    rowBox: RowBox,
    box: Box,
    slots: number,
    rtl: boolean
): { startIdx: number; endIdx: number } {
    let startIdx = -1;
    let endIdx = -1;
    const lastSlotIdx = slots - 1;
    const cellWidth = slotWidth(rowBox, slots);

    // Cell under the mouse
    const currentSlot = getSlotAtX(rowBox, box.x, rtl, slots);

    // Identify row as either the initial row or the row under the current mouse point
    const isCurrentRow = rowBox.top < box.y && rowBox.bottom > box.y;
    const isStartRow = rowBox.top < start.y && rowBox.bottom > start.y;

    // This row's position relative to the start point
    const isAboveStart = start.y > rowBox.bottom;
    const isBelowStart = rowBox.top > start.y;
    const isBetween = box?.top < rowBox.top && box?.bottom > rowBox.bottom;

    // This row is between the current and start rows, so entirely selected
    if (isBetween) {
        startIdx = 0;
        endIdx = lastSlotIdx;
    }

    if (isCurrentRow) {
        if (isBelowStart) {
            startIdx = 0;
            endIdx = currentSlot;
        } else if (isAboveStart) {
            startIdx = currentSlot;
            endIdx = lastSlotIdx;
        }
    }

    if (isStartRow) {
        // Select the cell under the initial point
        startIdx = endIdx = rtl
            ? lastSlotIdx - Math.floor((start.x - rowBox.left) / cellWidth)
            : Math.floor((start.x - rowBox.left) / cellWidth);

        if (isCurrentRow) {
            if (currentSlot < startIdx) startIdx = currentSlot;
            else endIdx = currentSlot; // Select current range
        } else if (start.y < box.y) {
            // The current row is below start row, select cells to the right of the start cell
            endIdx = lastSlotIdx;
        } else {
            // Select cells to the left of the start cell
            startIdx = 0;
        }
    }

    return { startIdx, endIdx };
}
