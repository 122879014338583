// apiService.js

import { Issuer } from '../api/center';
import { getDetailMembershipAPI } from '../api/memberShip';
import { MemberMembershipDataType } from './useMembershipQueryService';

// 수강권 템플릿 하나 조회
export const fetchGetOneMembershipTemplate = (
    params: {
        membershipTemplateId: string;
    },
    authorization: string
) => ({
    queryKey: ['getDetailMembershipAPIQuery', params.membershipTemplateId],
    queryFn: () => getDetailMembershipAPI(params.membershipTemplateId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membershipTemplate ?? {};
    },
});

export type MembershipTemplateDataType = {
    _id: string;
    period: number;
    isAvailable: boolean;
    BGColor: string[];
    isDeleted: boolean;
    isCanceled: boolean;
    name: string;
    pricePerSchedule: number;
    totalPrice: number;
    totalSession: number;
    issuer: Issuer;
    memo: string;
    validDays: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
    recentUsedTime: string;
    memberships?: MemberMembershipDataType[];
};

// "paymentHistory": [
//       // 판매 기록
//       {
//         "_id": "67627fc2fa71db645fe1449f",
//         "paymentAmount": 500000, // 판매 금액
//         "cancelAmount": null, // 취소 금액
//         "saleStatus": "COMPLETED", // 판매 상태
//         "paymentDate": "2024-12-06T06:03:31.677Z", // 결제 일시
//         "cancelDate": "2025-03-16T06:03:31.677Z", // 취소 일시
//         "totalSession": 30, // 총회차
//         "totalSessionOffset": 0 // 총회차 변화량
//       }
//     ],
