import React, { useState } from 'react';
import { Col, convertToKRW, getItemWithExpiration, Row } from '../../lib/utils';
import { Body2Bold } from '../../lib/font';
import { MemberMembershipDataType, PaymentHistoryType } from '../../services/useMembershipQueryService';
import dayjs from 'dayjs';
import { colors } from '../../lib/colors';
import useMembershipService from '../../services/useMembershipService';
import { useQueryClient } from '@tanstack/react-query';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../SvgIcon';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '../Table/Table';

type Props = {
    membership: MemberMembershipDataType;
    memberName?: string;
    coachName?: string;
    onClickUpdate: () => void;
    createAlert: any;
    onClickDelete: () => void;
    onClickRefund: () => void;
    onClickUpgrade: () => void;
    // 만료
    onClickExpire: () => void;
    // 재활성화
    onClickReactivation: () => void;
    // 미수금 결제
    onClickUnpaidPayment: () => void;
};
const columnHelper = createColumnHelper<PaymentHistoryType>();
// 판매상태, 결제일시 , 결제금액, 취소액, 총회차
const columns = [
    columnHelper.accessor('saleStatus', {
        id: 'saleStatus',
        header: '판매상태',
        cell: (info) => {
            const text =
                info.getValue() === 'COMPLETED'
                    ? '완료'
                    : info.getValue() === 'UPGRADED'
                    ? '업그레이드'
                    : info.getValue() === 'PARTIAL_REFUND'
                    ? '부분 환불'
                    : info.getValue() === 'PARTIAL_PAYMENT'
                    ? '분할 결제'
                    : '전체 환불';
            const textColor =
                info.getValue() === 'COMPLETED'
                    ? '#3D4244'
                    : info.getValue() === 'UPGRADED'
                    ? '#9536D4'
                    : info.getValue() === 'PARTIAL_REFUND'
                    ? '#FF7220'
                    : info.getValue() === 'PARTIAL_PAYMENT'
                    ? '#15BA72'
                    : '#FF2020';
            return (
                <RegularText fontSize={16} lineHeight={24} color={textColor} letterSpacing={-0.3}>
                    {text}
                </RegularText>
            );
        },
        size: 200,
        enableSorting: false,
    }),
    columnHelper.accessor('paymentDate', {
        id: 'paymentDate',
        header: '결제일시',
        cell: (info) => {
            return (
                <RegularText fontSize={16} lineHeight={24} color={'#3D4244'}>
                    {dayjs(info.getValue()).format('YYYY-MM-DD')}
                </RegularText>
            );
        },
        size: 200,
        enableSorting: false,
    }),
    columnHelper.accessor('paymentAmount', {
        id: 'paymentAmount',
        header: '결제금액',
        cell: (info) => {
            return (
                <RegularText fontSize={16} lineHeight={24} color={'#3D4244'}>
                    {convertToKRW(info.getValue())}
                </RegularText>
            );
        },
        size: 200,
        enableSorting: false,
    }),
    columnHelper.accessor('cancelAmount', {
        id: 'cancelAmount',
        header: '취소액',
        cell: (info) => {
            return (
                <RegularText fontSize={16} lineHeight={24} color={'#3D4244'}>
                    {convertToKRW(info.getValue())}
                </RegularText>
            );
        },
        size: 200,
        enableSorting: false,
    }),
    columnHelper.accessor('totalSession', {
        id: 'totalSession',
        header: '총회차',
        cell: (info) => {
            const textColor =
                info.row.original.saleStatus === 'COMPLETED'
                    ? '#3D4244'
                    : info.row.original.saleStatus === 'UPGRADED'
                    ? '#9536D4'
                    : info.row.original.saleStatus === 'PARTIAL_REFUND'
                    ? '#FF7220'
                    : '#FF2020';

            const offsetText =
                info.row.original.totalSessionOffset === 0
                    ? ''
                    : info.row.original.saleStatus === 'COMPLETED' || info.row.original.saleStatus === 'UPGRADED'
                    ? `(+${info.row.original.totalSessionOffset})`
                    : `(-${info.row.original.totalSessionOffset})`;

            return (
                <>
                    <RegularText fontSize={16} lineHeight={24} color={'#3D4244'}>
                        {info.getValue()}
                    </RegularText>
                    <RegularText fontSize={16} lineHeight={24} color={textColor}>
                        {offsetText}
                    </RegularText>
                </>
            );
        },
        size: 200,
        enableSorting: false,
    }),
];

const MemberMembershipManageComponents = ({
    membership,
    memberName,
    coachName,
    onClickUpdate,
    createAlert,
    onClickDelete,
    onClickRefund,
    onClickUpgrade,
    onClickExpire,
    onClickReactivation,
    onClickUnpaidPayment,
}: Props) => {
    // 활성화 상태ㅐ
    const isActive = membership?.status === 'active';
    const isEmptySchedule = membership?.schedules.length === 0;

    const queryClient = useQueryClient();

    const paymentHistory = membership?.paymentHistory;

    // 남은 일수
    const remainDays = dayjs(membership?.expirationDate).diff(dayjs(membership?.activationDate), 'day');

    const startDate = dayjs(membership?.activationDate).format('YYYY-MM-DD');
    const endDate = dayjs(membership?.expirationDate).format('YYYY-MM-DD');
    const isNewSalesText = membership?.isNewSales ? '신규' : '재등록';
    const isNewSalesBgColor = membership?.isNewSales ? '#7E7EF5' : '#64637B';
    // 판매 담당자
    const salesManagerName = membership?.salesManager?.userName;
    // 수업 시간
    const scheduleTime = membership?.period;
    // 회당 가격
    const pricePerSchedule = membership?.pricePerSchedule;
    // 시작 회차
    const startSession = membership?.startSessionValue;
    // 결제 금액
    const totalPrice = membership?.totalPrice;
    // 현재 회차
    const currentSession = membership?.currentSession;
    // 총 회차
    const totalSession = membership?.totalSession;
    // 결제 일자
    const paymentDate = dayjs(membership?.initialPaymentDate).format('YYYY-MM-DD');
    // 메모
    const memo = membership?.memo;
    // 결제 금액
    const totalPaymentAmount = membership?.totalPaymentAmount;
    // 미수금액
    const unpaidPrice = membership?.totalUnpaidAmount;

    return (
        <Col className="flex  h-full mb-[80px]  p-[55px] rounded-[12px] bg-white">
            <Row className="flex flex-row justify-center  h-[200px]  mb-[56px] ">
                <Col
                    className="flex flex-col min-w-[275px] rounded-[12px] h-full   justify-center items-center mr-[17px]"
                    style={{
                        background: isActive
                            ? `linear-gradient(135deg, ${membership?.BGColor[0]} 0%, ${membership?.BGColor[1]} 100%)`
                            : colors.GREY_200,
                        alignItems: 'center',
                    }}
                >
                    <Col className="flex w-full h-full pt-[16px] pb-[21px]   items-center">
                        <Body2Bold className="text-LAVEL_0 self-start mx-[21px] mb-[28px]">{`${membership?.name}`}</Body2Bold>
                        <Row className=" items-center">
                            <Col className="bg-white bg-opacity-[34%] rounded-[18px] w-[60px] h-[25px] justify-center items-center mr-[7px] ">
                                <RegularText fontSize={11} lineHeight={15.93} color="white">
                                    이용 기간
                                </RegularText>
                            </Col>
                            <RegularText
                                fontSize={15}
                                lineHeight={21.72}
                                color="white"
                                className=" opacity-[0.6]"
                                letterSpacing={-0.3}
                            >
                                {`${startDate} ~ ${endDate}`}
                            </RegularText>
                        </Row>
                    </Col>
                </Col>
                <Col className="flex relative px-[27px] py-[18px]  h-full border border-solid border-[#E5E5E9] bg-[#F2F4F699] bg-opacity-[0.6] rounded-[12px] justify-between items-center">
                    <Row className="w-full justify-between ">
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                회원
                            </DemiLightText>
                            <Row className="w-[170px] ">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={23}
                                    letterSpacing={-0.3}
                                    color="#3D4244"
                                    className="mr-[5px]"
                                >
                                    {memberName}
                                </RegularText>
                                <Col
                                    className=" justify-center items-center rounded-[16px] w-[45px] h-[23px]"
                                    style={{ backgroundColor: isNewSalesBgColor }}
                                >
                                    <DemiLightText fontSize={12} lineHeight={24} color="#FFFFFF">
                                        {isNewSalesText}
                                    </DemiLightText>
                                </Col>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                수업시간
                            </DemiLightText>
                            <Row className="w-[170px] ">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={23}
                                    letterSpacing={-0.3}
                                    color="#3D4244"
                                    className="mr-[5px]"
                                >
                                    {scheduleTime ? scheduleTime + '분' : '없음'}
                                </RegularText>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[70px]"
                            >
                                회당 가격
                            </DemiLightText>
                            <Row className=" w-[80px] justify-end ">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {pricePerSchedule ? convertToKRW(pricePerSchedule) : '없음'}
                                </RegularText>
                            </Row>
                        </Row>
                    </Row>

                    <Row className="w-full justify-between ">
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                담당 강사
                            </DemiLightText>
                            <Row className="w-[170px]">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {coachName}
                                </RegularText>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                시작 회차
                            </DemiLightText>
                            <Row className="w-[170px]">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {startSession ? startSession + '회' : '없음'}
                                </RegularText>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[70px]"
                            >
                                판매 금액
                            </DemiLightText>
                            <Row className=" w-[80px] justify-end ">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {totalPrice ? convertToKRW(totalPrice) : '없음'}
                                </RegularText>
                            </Row>
                        </Row>
                    </Row>
                    <Row className="flex w-full justify-between ">
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                판매 담당자
                            </DemiLightText>
                            <Row className="w-[170px]">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {salesManagerName}
                                </RegularText>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                현재/총 회차
                            </DemiLightText>
                            <Row className="w-[170px]">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {currentSession + '/' + totalSession + '회'}
                                </RegularText>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[70px]"
                            >
                                결제 금액
                            </DemiLightText>
                            <Row className=" w-[80px] justify-end ">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {totalPaymentAmount ? convertToKRW(totalPaymentAmount) : '없음'}
                                </RegularText>
                            </Row>
                        </Row>
                    </Row>
                    <Row className="w-full justify-between ">
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                발급일자
                            </DemiLightText>
                            <Row className="w-[170px]">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {dayjs(membership?.createdAt).format('YYYY-MM-DD')}
                                </RegularText>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[120px]"
                            >
                                결제일자
                            </DemiLightText>
                            <Row className="w-[170px]">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {paymentDate}
                                </RegularText>
                            </Row>
                        </Row>
                        <Row>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.3}
                                color="#505967"
                                className="w-[70px]"
                            >
                                미수금액
                            </DemiLightText>
                            <Row className=" w-[80px] justify-end ">
                                <RegularText fontSize={16} lineHeight={23} letterSpacing={-0.3} color="#3D4244">
                                    {unpaidPrice ? convertToKRW(unpaidPrice) : '없음'}
                                </RegularText>
                            </Row>
                        </Row>
                    </Row>
                    <Row
                        className="  bg-[#3D3E46] right-[27px] bottom-[17px] items-center px-[22px] py-[4px] rounded-[5px] cursor-pointer self-end mt-[20px]"
                        onClick={onClickUpdate}
                    >
                        <SvgIcon name="SvgPencil" size={15} fill="white" className="mr-[15px]" />
                        <DemiLightText fontSize={15} lineHeight={21} letterSpacing={-0.32} color="white">
                            수강권 수정
                        </DemiLightText>
                    </Row>
                </Col>
            </Row>
            {paymentHistory && <Table data={paymentHistory} columns={columns} className="mb-[41px]" />}
            <Col className="w-full h-[172px] border border-solid border-[#E5E5E9] rounded-[23px] py-[21px] px-[25px] mb-[51px]">
                <DemiLightText
                    fontSize={18}
                    lineHeight={23}
                    letterSpacing={-0.3}
                    color="#505967"
                    className=" w-full break-all h-full line-clamp-4 text-ellipsis opacity-[60%]"
                >
                    {memo ? memo : '메모가 작성되지 않은 수강권입니다'}
                </DemiLightText>
            </Col>
            <Row className=" self-end">
                <Col
                    className="w-[140px] h-[71px] bg-[#3D4244] justify-center items-center rounded-[9px] mr-[9px] cursor-pointer"
                    onClick={() => {
                        // setIsDeleteModalVisible(true);

                        if (isActive) {
                            // 수강권 삭제 모달
                            onClickExpire();
                        }
                        if (!isActive && isEmptySchedule) {
                            // 수강권 만료 모달
                            onClickDelete();
                        } else if (!isActive) {
                            // 수강권 재활성화 모달
                            onClickReactivation();
                        }
                    }}
                >
                    <RegularText fontSize={20} lineHeight={24} letterSpacing={-0.3} color="#FFFFFF">
                        {isActive ? '만료' : isEmptySchedule ? '삭제' : '재활성화'}
                    </RegularText>
                </Col>
                <Col
                    className="w-[140px] h-[71px] bg-[#FF20201A] justify-center items-center rounded-[9px] border border-solid border-[#FF20200D] mr-[9px] cursor-pointer"
                    onClick={onClickRefund}
                >
                    <RegularText fontSize={20} lineHeight={24} letterSpacing={-0.3} color="#FF2020">
                        환불
                    </RegularText>
                </Col>
                <Col
                    className="w-[140px] h-[71px] bg-[#9536D41A] justify-center items-center rounded-[9px] border border-solid border-[#9536D40D] mr-[9px] cursor-pointer"
                    onClick={onClickUpgrade}
                >
                    <RegularText fontSize={20} lineHeight={24} letterSpacing={-0.3} color="#9536D4">
                        업그레이드
                    </RegularText>
                </Col>
                {membership?.isSplitPayment && (
                    <Col
                        className="w-[140px] h-[71px] bg-[#15BA721A] justify-center items-center rounded-[9px] border border-solid border-[#15BA720D] mr-[9px] cursor-pointer"
                        onClick={onClickUnpaidPayment}
                    >
                        <RegularText fontSize={20} lineHeight={24} letterSpacing={-0.3} color="#15BA72">
                            미수금 결제
                        </RegularText>
                    </Col>
                )}
            </Row>
        </Col>
    );
};

export default MemberMembershipManageComponents;

//   {/* 메모 */}
//   <Col className="mb-[40px] w-[786px] h-[106px] ">
//   <Body3Regular className="text-LAVEL_1">메모</Body3Regular>
//   <Body2Regular className="text-LAVEL_4 w-full break-all h-full line-clamp-3 text-ellipsis">
//       {membership.memo}
//   </Body2Regular>
// </Col>

// <Row className="w-full justify-between">
// {isActive ? (
//     isEmptySchedule ? (
//         <NewWhiteRoundButton
//             color={'GREY_300'}
//             text={'수강권 삭제'}
//             width={120}
//             height={24}
//             fontType={'CaptionRegular'}
//             onClick={() =>
//                 deleteMemberShipMutation.mutate({
//                     membershipId: membership._id,
//                     accessToken: authorization,
//                 })
//             }
//         />
//     ) : (
//         <NewWhiteRoundButton
//             color={'GREY_300'}
//             text={'수강권 만료'}
//             width={88}
//             height={24}
//             fontType={'CaptionRegular'}
//             onClick={() =>
//                 updateMemberShipMutation.mutate({
//                     membershipId: membership._id,
//                     status: 'expired',
//                     accessToken: authorization,
//                 })
//             }
//         />
//     )
// ) : (
//     <Row className=" items-center ">
//         <NewWhiteRoundButton
//             color={'primary'}
//             text={'수강권 재활성화'}
//             width={120}
//             height={24}
//             className="mr-[16px]"
//             fontType={'CaptionRegular'}
//             disabled={!isRemainSession}
//             onClick={() => {
//                 if (!isRemainSession) {
//                     createAlert(
//                         '회차 조절이 필요해요!',
//                         `만료된 수강권을 활성화 시키려면 ‘수강권 수정하기’를 눌러\n‘총 회차’를 늘려주세요!`,
//                         true
//                     );
//                 } else {
//                     updateMemberShipMutation.mutate({
//                         membershipId: membership._id,
//                         status: 'active',
//                         accessToken: authorization,
//                     });
//                 }
//             }}
//         />
//         {isRemainSession ? (
//             <CaptionRegular className="" style={{ color: colors.LAVEL_1 }}>
//                 회차가 남아있는 수강권은 재활성화가 가능해요!
//             </CaptionRegular>
//         ) : (
//             <>
//                 <CaptionRegular style={{ color: colors.LAVEL_1 }}>
//                     만료된 수강권을 활성화 시키시려면
//                 </CaptionRegular>
//                 <CaptionRegular className="" style={{ color: colors.LAVEL_1 }}>
//                     회차를 조절해주세요!
//                 </CaptionRegular>
//             </>
//         )}
//     </Row>
// )}
// <NewColorRoundButton
//     text={'수강권 수정'}
//     bgColor="primary"
//     textColor="white"
//     width={126}
//     height={36}
//     onClick={onClickUpdate}
// />
// </Row>
