let idCount = 0;

// 유니크 ID를 생성하는 함수
const uniqueId = (prefix: string = ''): string => `${prefix}${++idCount}`;

// 핸들러 호출 유틸리티 함수
export const notify = (handler: ((...args: any[]) => void) | undefined, args: any[]): void => {
    if (handler) {
        handler(...args);
    }
};

// 컴포넌트 인스턴스 ID를 생성하는 함수
export const instanceId = (component: any, suffix: string = ''): string => {
    if (!component.__id) {
        component.__id = uniqueId('rw_');
    }
    return `${component.props.id || component.__id}${suffix}`;
};

// 컴포넌트가 처음으로 포커스를 렌더링하는지 확인하는 함수
export const isFirstFocusedRender = (component: any): boolean => {
    if (component._firstFocus) {
        return true;
    }

    if (component.state.focused) {
        component._firstFocus = true;
        return true;
    }

    return false;
};
