import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Col, convertToKRW, getItemWithExpiration, Row } from '../../lib/utils';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../../components/SvgIcon';
import Dropdown from '../../components/DropDown/DropDown';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import { NavigateAction } from '../../components/CustomCalendar/types/types';
import { isOffRange } from '../../components/CustomCalendar/utils/date';
import CustomCalendar from '../../components/CustomCalendar/lib/CustomCalendar';
import useMultipleQueries from '../../hook/useMultipleQueries';
import {
    DailyInfo,
    fetchCenterSalesSettlementCalendar,
    SalesSummary,
    WeeklyTotal,
} from '../../services/useCenterQueryService';
import { CodeValueType } from '../../types/types';

type Props = {
    date: string;
    onClickEvent: (event: any, type: 'salesAmount' | 'paymentCount' | 'completedCount') => void;
    onClickCommissionModal: () => void;
    onMonthNavigate: (date: Date | string | dayjs.Dayjs) => void;
    coachList: CodeValueType[];
    selectedCalendarCoach: CodeValueType | 'all';
    onSelectedCalendarCoach: (coach: CodeValueType) => void;
    totalRemainingScheduleFee?: number;
    calendar: SalesSummary;
};

const SettlementCalendar = ({
    date,
    onClickEvent,
    onClickCommissionModal,
    onMonthNavigate,
    coachList,
    selectedCalendarCoach,
    onSelectedCalendarCoach,
    totalRemainingScheduleFee: _totalRemainingScheduleFee,
    calendar,
}: Props) => {
    // 달별 총 합계
    const monthlyTotal = calendar?.monthlyTotal;
    // 주간 데이터 생성
    const weeklyTotals = calendar?.weeklyTotals;
    // 31일 달력 데이터 생성
    const dailyInfos = calendar?.dailyInfos;

    // 달력 이벤트 Cell 커스텀
    const customEventComponent = (event: DailyInfo) => {
        const isOffDate = isOffRange(dayjs(date), dayjs(event.start));

        //매출금액 salesInfo sales
        //결제 수업수 paidScheduleInfo paidScheduleCount
        // 잡힌 수업수/노쇼 scheduleCountInfo attendanceScheduleCount/noshowScheduleCount

        const sales = event.salesInfo.sales;
        const paidScheduleCount = event.paidScheduleInfo.paidScheduleCount;
        const attendanceScheduleCount = event.scheduleCountInfo.attendanceScheduleCount;
        const noshowScheduleCount = event.scheduleCountInfo.noshowScheduleCount;

        const isOffDateMoneyClass = isOffDate ? '#D8E3F8' : colors.primary;
        const isOffDateCount1Class = isOffDate ? '#DCF2E2' : '#51C070';
        const isOffDateCount2Class = isOffDate ? '#E5E6E7' : '#7D8386';

        return (
            <div className="flex w-full h-full  pl-[12px] mt-[52px]">
                <Col>
                    <RegularText
                        fontSize={15}
                        lineHeight={21}
                        color={isOffDateMoneyClass}
                        onClick={() => onClickEvent(event, 'salesAmount')}
                        className={`cursor-pointer hover:underline hover:decoration-primary underline-offset-[2px] mb-[4px] `}
                    >
                        {convertToKRW(sales) ?? 0}
                    </RegularText>
                    <RegularText
                        fontSize={15}
                        lineHeight={21}
                        color={isOffDateCount1Class}
                        onClick={() => onClickEvent(event, 'paymentCount')}
                        className={`cursor-pointer hover:underline hover:decoration-[#51C070] underline-offset-[2px] mb-[4px] `}
                    >
                        {paidScheduleCount}
                    </RegularText>
                    <RegularText
                        fontSize={15}
                        lineHeight={21}
                        color={isOffDateCount2Class}
                        onClick={() => onClickEvent(event, 'completedCount')}
                        className={`cursor-pointer hover:underline hover:decoration-[#7D8386] underline-offset-[2px] mb-[4px] `}
                    >
                        {attendanceScheduleCount + (noshowScheduleCount === 0 ? '' : '/' + noshowScheduleCount)}
                    </RegularText>
                </Col>
            </div>
        );
    };

    // #region Cell 헤더 커스텀
    const customCellHeaderComponent = (_date: Date) => {
        // 이번달인지
        const isThisMonth = dayjs(_date).isSame(dayjs(date), 'month');

        const textColor = isThisMonth ? (dayjs(_date).day() === 0 ? '#FF553E' : '#42484D') : 'rgba(66, 72, 77, 0.1)';

        const isStartOfWeek = dayjs(_date).day() === 0;
        const isEndOfWeek = dayjs(_date).day() === 6;
        const startOfWeekClass = isStartOfWeek ? 'rounded-tl-[8px] rounded-bl-[8px]  ' : '';
        const endOfWeekClass = isEndOfWeek ? 'rounded-tr-[8px] rounded-br-[8px] mr-[10px]' : '';

        // 오늘인지
        const isToday = dayjs(_date).isSame(dayjs(), 'day');

        return (
            <div
                className={`flex flex-row  justify-start mt-[16px] bg-[#FAFBFC]  py-[2px] ${startOfWeekClass} ${endOfWeekClass}`}
            >
                <DemiLightText fontSize={14} lineHeight={20} color={textColor} className="pl-[12px] ">
                    {dayjs(_date).format('D')}
                </DemiLightText>
                {isToday && (
                    <Col className="ml-[8px] bg-[#FF553E] rounded-[14px] justify-center items-center w-[32px] h-[20px]">
                        <DemiLightText fontSize={11} lineHeight={15} color="white">
                            오늘
                        </DemiLightText>
                    </Col>
                )}
            </div>
        );
    };

    // #region 툴팁 커스텀
    const customTootipComponent = (onNavigate: (action: NavigateAction) => void) => {
        // 총 매출액
        const totalSales = monthlyTotal?.totalSales;
        // 수업료
        const totalScheduleFee = monthlyTotal?.totalScheduleFee;
        // const totalScheduleFee = null;

        // 미수업금액
        const totalRemainingScheduleFee = _totalRemainingScheduleFee;
        // 결제 수업 수
        const totalPaidScheduleCount = monthlyTotal?.totalPaidScheduleCount;
        // 잡힌 수업 수
        const totalScheduleCount = monthlyTotal?.totalScheduleCount;
        // 노쇼 수업수
        const totalNoshowScheduleCount = monthlyTotal?.totalNoshowScheduleCount;

        // 오늘이 이번달에 속해있거나 미래일경우
        const isThisMonth = dayjs().isSame(dayjs(date), 'month') || dayjs().isBefore(dayjs(date), 'month');

        return (
            <Col>
                <Row className=" items-center mb-[40px]">
                    <MediumText fontSize={25} lineHeight={25} color={'#3D4244'} className="mr-[14px]">
                        {dayjs(date).format('YYYY년 MM월')}
                    </MediumText>
                    <div
                        className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[5px]"
                        onClick={() => onNavigate('PREV')}
                    >
                        <SvgIcon
                            name={'SvgLeftStrokeArrow'}
                            size={15}
                            fill={colors.trans}
                            stroke={'#70747C'}
                            strokeWidth={1.2}
                        />
                    </div>

                    <div
                        className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[30px]"
                        onClick={() => onNavigate('NEXT')}
                    >
                        <SvgIcon
                            name={'SvgRightStrokeArrow'}
                            size={15}
                            fill={colors.trans}
                            stroke={'#70747C'}
                            strokeWidth={1.2}
                        />
                    </div>
                    {coachList && (
                        <Dropdown
                            initialSelected={(selectedCalendarCoach as CodeValueType).code}
                            options={coachList}
                            onSelect={(selected) => {
                                onSelectedCalendarCoach(selected);
                            }}
                            bgColor="rgba(201, 219, 255, 0.4)"
                            textColor={colors.primary}
                            borderRadius={50}
                            totalText="모든 강사"
                            arrowColor={colors.primary}
                            className="py-[8px]"
                            textType="medium"
                        />
                    )}
                </Row>
                <Row className="flex flex-row w-full h-[60px] mb-[29px] items-center ">
                    <Col className="flex w-[227px]">
                        <Row className=" items-center  mb-[8px]">
                            <RegularText fontSize={16} lineHeight={24} color="#6B7684" className="mr-[5px]">
                                {dayjs(date).format('M')}월 매출 금액
                            </RegularText>
                            <TooltipIcon
                                title={`
                                회원의 수강권 결제를 통해 발생한 총 금액`}
                                subTitle="* 라포 서비스 내에 등록된 수강권 내역을 통해 산출된 매출 금액입니다."
                            />
                        </Row>

                        <MediumText fontSize={20} lineHeight={24} color="#3D4244">
                            {convertToKRW(totalSales)}
                        </MediumText>
                    </Col>
                    <Col className="flex border-l-[1px] border-solid pl-[18px] border-[#D9D9D9] w-[227px]">
                        <Row className="mb-[8px] items-center">
                            <RegularText fontSize={16} lineHeight={24} color="#6B7684" className="mr-[5px]">
                                {isThisMonth ? '예상 수업 정산액' : '확정된 수업 정산액'}
                            </RegularText>
                            <TooltipIcon
                                title={
                                    isThisMonth
                                        ? `현재 등록된 ${
                                              dayjs(date).month() + 1
                                          }월 수업 일정과 정산 비율을기준으로 계산된 예상 정산 금액`
                                        : `${
                                              dayjs(date).month() + 1
                                          }월에 실제로 완료된 수업 데이터를 기준으로 계산된 최종 정산 금액`
                                }
                            />
                        </Row>
                        {!(totalScheduleFee === undefined && totalScheduleFee === null) ? (
                            !(totalScheduleFee === undefined || totalScheduleFee === null) ? (
                                <MediumText fontSize={20} lineHeight={24} color="#3D4244">
                                    {convertToKRW(totalScheduleFee)}
                                </MediumText>
                            ) : (
                                <Col
                                    className="bg-[#36393E] rounded-[6px] justify-center px-[11px] py-[4px] self-start cursor-pointer"
                                    onClick={onClickCommissionModal}
                                >
                                    <RegularText fontSize={13} lineHeight={18} color="white" letterSpacing={-0.3}>
                                        수업 정산 비율 설정 후 확인 👆
                                    </RegularText>
                                </Col>
                            )
                        ) : (
                            <RegularText fontSize={20} lineHeight={24} color="#3D4244">
                                계산중...
                            </RegularText>
                        )}
                    </Col>
                    <Col className="flex border-l-[1px] border-solid pl-[18px] border-[#D9D9D9] w-[228px]">
                        <Row className="mb-[8px] items-center">
                            <RegularText fontSize={16} lineHeight={24} color="#6B7684" className="mr-[5px]">
                                미수업금액
                            </RegularText>
                            <TooltipIcon
                                title="아직 진행되지 않은 모든 수업 일정의 금액을 합산한 총액"
                                subTitle="* 센터 수수료 및 정산 비율은 적용되지 않은 금액입니다."
                            />
                        </Row>
                        <MediumText fontSize={20} lineHeight={24} color="#3D4244">
                            {totalRemainingScheduleFee ? convertToKRW(totalRemainingScheduleFee) : '계산중...'}
                        </MediumText>
                    </Col>
                    <Col className="flex border-l-[1px] border-solid pl-[18px] border-[#D9D9D9] w-[228px]">
                        <Row className="mb-[8px] items-center">
                            <RegularText fontSize={16} lineHeight={24} color="#6B7684" className="mr-[5px]">
                                판매 수업 수
                            </RegularText>
                            <TooltipIcon title="회원이 결제를 통해 등록한 수업의 총 수" />
                        </Row>
                        <MediumText fontSize={20} lineHeight={24} color="#3D4244">
                            {totalPaidScheduleCount}
                        </MediumText>
                    </Col>
                    <Col className="flex border-l-[1px] border-solid pl-[18px] border-[#D9D9D9] w-[200px]">
                        <RegularText fontSize={16} lineHeight={24} color="#6B7684" className="mb-[8px]">
                            잡힌 수업 수/노쇼
                        </RegularText>
                        <MediumText fontSize={20} lineHeight={24} color="#3D4244">
                            {totalScheduleCount}/{totalNoshowScheduleCount}
                        </MediumText>
                    </Col>
                </Row>
                <div className="w-full bg-[#D9D9D9] h-[1px] mb-[32px] " />
                <Row className=" items-center  mb-[38px]">
                    <Row className=" items-center mr-[35px]">
                        <Col className="w-[13px] h-[13px] rounded-full bg-primary mr-[9px]" />
                        <DemiLightText fontSize={15} lineHeight={21.72} color="#3D4244">
                            매출 금액
                        </DemiLightText>
                    </Row>
                    <Row className=" items-center mr-[35px]">
                        <Col className="w-[13px] h-[13px] rounded-full bg-[#51C070] mr-[9px]" />
                        <DemiLightText fontSize={15} lineHeight={21.72} color="#3D4244">
                            결제 수업 수
                        </DemiLightText>
                    </Row>
                    <Row className=" items-center mr-[35px]">
                        <Col className="w-[13px] h-[13px] rounded-full bg-[#7D8386] mr-[9px]" />
                        <DemiLightText fontSize={15} lineHeight={21.72} color="#3D4244">
                            잡힌 수업 수/노쇼
                        </DemiLightText>
                    </Row>
                </Row>
            </Col>
        );
    };

    // #region 주간 커스텀
    const customWeeklyComponent = (event: WeeklyTotal, isLastWeek: boolean) => {
        const weeklyIndex = event?.weeklyIndex;
        const weeklySales = event?.weeklySales;
        const weeklyPaidScheduleCount = event?.weeklyPaidScheduleCount;
        const weeklyScheduleCount = event?.weeklyScheduleCount;
        const weeklyNoshowScheduleCount = event?.weeklyNoshowScheduleCount;
        return (
            <div
                className={`flex flex-col w-full h-full justify-center bg-[#F2F4F6] ${
                    isLastWeek ? 'rounded-bl-[13px] rounded-br-[13px]' : ''
                }`}
            >
                <span className="pl-[9px] bg-[#D6D9DE] mx-[14px] rounded-[6px] py-[2px] text-[14px] font-[400] leading-[20.27px]">
                    {weeklyIndex + 'W'}
                </span>
                <div className="mt-[13px] pl-[14px] flex flex-col ">
                    <span
                        className={`cursor-pointer hover:underline hover:decoration-primary underline-offset-[2px] mb-[2px] text-primary text-[16px] leading-[23px]`}
                    >
                        {convertToKRW(weeklySales ?? 0)}
                    </span>
                    <span
                        className={`cursor-pointer hover:underline hover:decoration-primary underline-offset-[2px] mb-[2px] text-[#51C070] text-[16px] leading-[23px]`}
                    >
                        {weeklyPaidScheduleCount}
                    </span>
                    <span
                        className={`cursor-pointer hover:underline hover:decoration-primary underline-offset-[2px] mb-[2px] text-[#7D8386] text-[16px] leading-[23px]`}
                    >
                        {weeklyScheduleCount + (weeklyNoshowScheduleCount === 0 ? '' : '/' + weeklyNoshowScheduleCount)}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col w-full h-full  justify-center items-center bg-white px-[46px] rounded-[27px] py-[35px] mb-[100px]">
            <CustomCalendar
                events={dailyInfos}
                weeklyEvents={weeklyTotals}
                date={date}
                monthCustomEventComponent={customEventComponent}
                monthCellHeaderCustomComponent={customCellHeaderComponent}
                monthTooltipCustomComponent={customTootipComponent}
                monthWeeklyCustomComponent={customWeeklyComponent}
                onNavigate={onMonthNavigate}
            />
        </div>
    );
};

export default SettlementCalendar;

export const TooltipIcon = ({
    title,
    subTitle,
    classNmae,
    fontSize = 13,
    lineHeight = 16,
    color = 'white',
    letterSpacing = -0.3,
    fontType = 'regular',
}: {
    title: string;
    subTitle?: string;
    classNmae?: string;
    fontSize?: number;
    lineHeight?: number;
    color?: string;
    letterSpacing?: number;
    fontType?: 'regular' | 'demilight';
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [tooltipHeight, setTooltipHeight] = useState<number>(0); // State to track tooltip height
    const tooltipRef = useRef<HTMLDivElement>(null); // Ref for the tooltip
    // Update the tooltip height when it is rendered
    useEffect(() => {
        if (tooltipRef.current) {
            setTooltipHeight(tooltipRef.current.offsetHeight);
        }
    }, [isHovered]); // Recalculate height whenever tooltip visibility changes

    const TextComponent = fontType === 'demilight' ? DemiLightText : RegularText;

    return (
        <div className="relative items-center z-30">
            <SvgIcon
                name={'SvgWarningInCircle'}
                size={16}
                fill={'#B4BCC4'}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />

            {/* Tooltip */}
            {isHovered && (
                <div
                    ref={tooltipRef}
                    className={`absolute  left-[32px] transform -translate-x-1/2 bg-[#36393E] text-white rounded-[9px] w-[220px] shadow-lg justify-center items-center text-start 
                px-[14px] py-[16px] 
                ${classNmae}
                `}
                    style={{
                        top: `-${tooltipHeight + 10}px`, // Dynamically position tooltip above the icon
                    }}
                >
                    <TextComponent
                        fontSize={fontSize}
                        lineHeight={lineHeight}
                        color={color}
                        letterSpacing={letterSpacing}
                    >
                        {title}
                    </TextComponent>
                    {subTitle && (
                        <>
                            <br />
                            <br />
                            <RegularText
                                fontSize={fontSize}
                                lineHeight={lineHeight}
                                color={color}
                                letterSpacing={letterSpacing}
                            >
                                {subTitle}
                            </RegularText>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
