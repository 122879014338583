import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getItemWithExpiration } from '../lib/utils';
import { postSalaryCalcs, PostSalaryCalcsParams } from '../api/salaryCalc';

type Props = {
    onPostSalaryCalcsSuccess?: () => void;
};

const useSalaryCalcMutationService = ({ onPostSalaryCalcsSuccess }: Props) => {
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [authorization] = useState(getItemWithExpiration('authorization'));

    // 회원권 이전하기
    const postSalaryCalcsMutation = useMutation(
        ['postSalaryCalcsMutation'],
        async (params: PostSalaryCalcsParams) => {
            return await postSalaryCalcs({ ...params, centerId: authObject.centerId, accessToken: authorization });
        },
        {
            onSuccess({ status }) {
                if (status === 200) {
                    onPostSalaryCalcsSuccess && onPostSalaryCalcsSuccess();
                }
            },
        }
    );

    return {
        postSalaryCalcsMutation,
    };
};

export default useSalaryCalcMutationService;
