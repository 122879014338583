import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getItemWithExpiration } from '../lib/utils';
import { deleteMembershipTemplate, patchRelocateMembership, PatchRelocateMembershipApiType } from '../api/memberShip';
import { patchSalesCommission, PatchSalesCommissionApiType } from '../api/salesCommision';

type Props = {
    onPatchSalesCommissionSuccess?: () => void;
};

const useSalesCommissionMutationService = ({ onPatchSalesCommissionSuccess }: Props) => {
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [authorization] = useState(getItemWithExpiration('authorization'));

    // 회원권 이전하기
    const patchSalesCommissionMutation = useMutation(
        ['patchSalesCommissionMutation'],
        async (params: {
            year: string;
            month: string;
            salesCommissionArray: {
                coachId: string;
                commissionType: 'fixed' | 'percentage';
                scheduleCommission: string;
                reRegisterCommission?: string | null;
                noshowCommission?: string | null;
            }[];
        }) => {
            const _data = params?.salesCommissionArray.map((item) => {
                return {
                    // 빈값일경우 null 처리
                    coachId: item.coachId,
                    commissionType: item.commissionType,
                    scheduleCommission: parseInt(item.scheduleCommission),
                    reRegisterCommission: item?.reRegisterCommission
                        ? item?.reRegisterCommission === ''
                            ? null
                            : parseInt(item?.reRegisterCommission ? item?.reRegisterCommission : '0')
                        : null,
                    noshowCommission: item.noshowCommission
                        ? item.noshowCommission === ''
                            ? null
                            : parseInt(item?.noshowCommission ? item?.noshowCommission : '0')
                        : null,
                };
            });
            return patchSalesCommission({
                ...params,
                salesCommissionArray: _data,
                centerId: authObject.centerId,
                accessToken: authorization,
            });
        },
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 200) {
                    onPatchSalesCommissionSuccess && onPatchSalesCommissionSuccess();
                }
            },
        }
    );

    return {
        patchSalesCommissionMutation,
    };
};

export default useSalesCommissionMutationService;
