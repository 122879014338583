import React, { useEffect, useRef, useState } from 'react';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import { Col, Row } from '../../lib/utils';
import { RegularText } from '../../lib/Text';

type Option = {
    code: string;
    value: string;
};

type Props = {
    data: Option[];
    placeholder?: string;
    isDisabled?: boolean;
    onSelect?: (selected: Option) => void;
    width?: string | number;
    totalText?: string;
    onSearch?: (searchText: string) => void;
};

const MemberSearchTextInput = ({
    data,
    placeholder = '검색해보세요',
    isDisabled,
    onSelect,
    width = 320,
    totalText = '전체',
    onSearch,
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>(totalText);
    const listRef = useRef<HTMLUListElement>(null);
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const filterData = data?.filter((option) => {
        if (searchText === '전체' || searchText === '') {
            return true;
        }
        return option.value.includes(searchText);
    });

    const handleSelect = (option: Option) => {
        setSelectedOption(option.value);
        setIsOpen(false);
        setSearchText(option.value);
        if (onSelect) {
            onSelect(option);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };
    // 키보드 이벤트 핸들러
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!isOpen && e.key === 'ArrowDown') {
            setIsOpen(true);
            setTimeout(() => listRef.current?.focus(), 0); // ul로 포커스 이동
        } else if (!isOpen && e.key === 'Escape') {
            setIsOpen(false);
            setSearchText('');
            handleSelect({ code: 'all', value: '' });
        } else if (isOpen) {
            switch (e.key) {
                case 'ArrowDown':
                    setActiveIndex((prev) => (prev < filterData.length - 1 ? prev + 1 : 0));
                    break;
                case 'ArrowUp':
                    setActiveIndex((prev) => (prev > 0 ? prev - 1 : filterData.length - 1));
                    break;
                case 'Enter':
                    if (activeIndex >= 0) handleSelect(filterData[activeIndex]);
                    break;
                case 'Escape':
                    setIsOpen(false);
                    setSearchText('');
                    handleSelect({ code: 'all', value: '' });

                    break;
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="flex relative  items-center " ref={dropdownRef} style={{ width }}>
            <Row className="w-full h-full items-center  focus:outline-none text-[16px] leading-[23px]  border-solid border-[1.25px] border-[#959DA8] rounded-[7px] ">
                <SvgIcon
                    name={'SvgSearch'}
                    size={20}
                    fill={colors.trans}
                    stroke="#C3C8CD"
                    className="left-[8px] absolute "
                    strokeWidth={2}
                />
                {/* Search Input */}
                <input
                    disabled={isDisabled}
                    placeholder={placeholder}
                    value={searchText}
                    onChange={
                        isDisabled
                            ? () => {
                                  return;
                              }
                            : (e) => {
                                  setSearchText(e.target.value);
                                  onSearch && onSearch(e.target.value);
                                  setIsOpen(true);
                              }
                    }
                    onClick={toggleDropdown}
                    maxLength={5}
                    onKeyDown={handleKeyDown}
                    className={`w-full h-full font-noto-demilight bg-transparent  rounded-full pl-[30px] text-Text_1 `}
                />
                {
                    // 선택된게 없을때
                    !(selectedOption === '' || searchText === null || searchText === '') && (
                        <SvgIcon
                            name="SvgCircleX"
                            size={18}
                            fill={'#C3C8CD'}
                            className=" cursor-pointer absolute right-[8px]"
                            onClick={() => {
                                setSearchText('');
                                onSelect && onSelect({ code: 'all', value: '' });
                            }}
                        />
                    )
                }
            </Row>

            {/* Dropdown Content */}
            {isOpen && (
                <div className="absolute top-[40px] flex pb-[21px] w-[190px] bg-[primary] bg-white rounded-[15px] [box-shadow:0px_4px_20px_rgba(0,0,0,0.2)] z-30">
                    <ul
                        className="  mt-[8px] max-h-[200px] overflow-auto pl-[12px] pr-[20px] w-full scrollbar-thumb-black scrollbar-corner-black scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-[5px]"
                        ref={listRef}
                        tabIndex={0}
                    >
                        {/* <li
                            onClick={() => handleSelect({ code: 'all', value: totalText })}
                            className={`py-[8px] text-gray-700 cursor-pointer hover:bg-gray-100  ${
                                selectedOption === totalText ? 'bg-gray-200' : ''
                            } `}
                        >
                            <Col className="flex w-full h-full hover:bg-[#F2F4F6] rounded-[7px] px-[8px] py-[4px] ">
                                <RegularText fontSize={16} lineHeight={24} color={colors.primary}>
                                    {totalText}
                                </RegularText>
                            </Col>
                        </li> */}
                        {filterData.map((option, index) => (
                            <li
                                key={option.code}
                                onClick={() => handleSelect(option)}
                                className={`pb-[8px] text-gray-700 cursor-pointer   ${
                                    selectedOption === option.value ? 'bg-gray-200' : ''
                                } `}
                            >
                                <Col
                                    className={`flex w-full h-full hover:bg-[#F2F4F6] rounded-[7px] px-[8px] py-[4px] ${
                                        activeIndex === index ? 'bg-[#F2F4F6]' : ''
                                    }`}
                                >
                                    <RegularText fontSize={16} lineHeight={24} color="#3D4244">
                                        {option.value}
                                    </RegularText>
                                </Col>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MemberSearchTextInput;
