import React, { useEffect, useState } from 'react';
import { Col, convertToKRW, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import dayjs, { Dayjs } from 'dayjs';
import ModalSales from '../Settlement/ModalSales';
import ModalCommission from '../Settlement/ModalCommission';
import SettlementHeader from '../Settlement/SettlementHeader';
import SettlementCalendar from '../Settlement/SettlementCalendar';
import ClassPaySettlementPage from '../Settlement/ClassPaySettlementPage';
import {
    DailyInfo,
    fetchCenterSalesSettlementCalendar,
    fetchCoachNameList,
    fetchGetCenterRemainingScheduleFee,
    fetchGetCenterRemainingScheduleSession,
    SalesSummary,
} from '../../services/useCenterQueryService';
import useMultipleQueries from '../../hook/useMultipleQueries';
import {
    ClassFeeSettlementScheduleRecord,
    fetchGetClassFeeSettlement,
    fetchGetClassFeeSettlementForMembership,
    GetClassFeeSettlementTableDataForMembership,
    MembershipInfo,
} from '../../services/useMemberQueryService';
import ModalClassHistory from '../Settlement/ModalClassHistory';
import ModalMembershipInfo from '../Settlement/ModalMembershipInfo';
import SalesListPage from '../Settlement/SalesListPage';
import {
    fetchGetSalesList,
    fetchGetSalesManagers,
    SalesManager,
} from '../../services/useMembershipPaymentsQueryService';
import { CodeValueType } from '../../types/types';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import ModalMonthCommision from '../Settlement/ModalMonthCommision';
import SalaryCalculationPage from '../Settlement/SalaryCalculationPage';
import { fetchSalaryCalcs } from '../../services/useSalaryCalcQueryService';
import { SalaryCalcData } from '../../api/salaryCalc';
import XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
import UnpaidListPage from '../Settlement/UnpaidListPage';
import ModalPartialPayment from '../Settlement/ModalPartialPayment';
import {
    fetchGetUnpaidPayments,
    UnpaidPaymentData,
    UnpaidPaymentTableType,
} from '../../services/useMembershipQueryService';

// 매출달력, 수업료 정산, 매출목록, 급여 계산 , 미수금 목록
export type MenuType = 'calendar' | 'classSales' | 'salesList' | 'salaryCalculation' | 'unpaidList';

const SettlementPage = () => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const location = useLocation();
    const isPasswordSet = location.state?.isPasswordSet;
    const queryClient = useQueryClient();

    // 인증 여부
    const [isAuth, setIsAuth] = useState(false);

    const [date, setDate] = useState(dayjs().toISOString());
    // 매출 달력, 수업료 정산, 매출 목록 화면
    const [selectedMenu, setSelectedMenu] = useState<MenuType>('calendar');
    // 선택된 강사
    const [selectedCalendarCoach, setSelectedCalendarCoach] = useState<CodeValueType | 'all'>('all');

    /**
     *
     * 수업료 정산 화면
     * 선택된 수업기록
     */
    // 강사 선택
    const [selectedCoach, setSelectedCoach] = React.useState('all');

    const [startDate, setStartDate] = useState(dayjs(date).startOf('month').toISOString());
    const [endDate, setEndDate] = useState(dayjs(date).endOf('month').add(1, 'ms').toISOString());
    const [selectedScheduleRecord, setSelectedScheduleRecord] = useState<ClassFeeSettlementScheduleRecord | undefined>(
        undefined
    );
    const [selectedClassFeeSettlementRow, setSelectedClassFeeSettlementRow] = useState<
        GetClassFeeSettlementTableDataForMembership | undefined
    >(undefined);
    const [selectedMembership, setSelectedMembership] = useState<MembershipInfo | undefined>(undefined);
    // 선택한 날짜
    const [selectedDate, setSelectedDate] = useState<Dayjs | string | undefined>(undefined);

    const [selectedEvent, setSelectedEvent] = useState<
        (DailyInfo & { type: 'salesAmount' | 'paymentCount' | 'completedCount' }) | undefined
    >(undefined);

    /**
     * 매출목록 화면
     *
     */

    // 판매담당자 선택
    const [selectedSalesManager, setSelectedSalesManager] = React.useState<SalesManager>({
        userId: 'all',
        role: 'all',
        userName: 'all',
    });
    // 회원별인지 수업별인지 선택
    const [groupType, setGroupType] = React.useState<'class' | 'member'>('member');
    // 매출목록 선택 날짜
    const [selectedSalesListDate, setSelectedSalesListDate] = useState<Dayjs | string | undefined>(undefined);

    /**
     * 급여계산 화면
     * 선택된 강사
     */

    const [selectedSalaryCoach, setSelectedSalaryCoach] = useState<CodeValueType | undefined>(undefined);
    /**
     * 미수금 목록
     * 선택된 강사
     */

    const [selectedUnpaidCoach, setSelectedUnpaidCoach] = useState<CodeValueType | undefined>({
        code: 'all',
        value: '모든 강사',
    });

    const [selectedRow, setSelectedRow] = useState<UnpaidPaymentTableType | undefined>(undefined);

    /**
     * isSettlementPriceModal : 매출 금액 모달
     * isVisibleCommissionModal : 수수료 모달
     * isClassHistoryModal : 수업 이력 모달
     * isMembershipInfoModal : 수강권 정보 모달
     * isCommissionModal : 수강권 정산 비율 설정
     * isPartialPaymentModalOpen : 분할 결제 모달
     *
     */

    const [isSettlementPriceModal, setIsSettlementPriceModal] = useState(false);
    const [isVisibleCommissionModal, setIsVisibleCommissionModal] = useState(false);
    const [isClassHistoryModal, setIsClassHistoryModal] = useState(false);
    const [isMembershipInfoModal, setIsMembershipInfoModal] = useState(false);
    const [isCommissionModal, setIsCommissionModal] = useState(false);
    const [isPartialPaymentModalOpen, setIsPartialPaymentModalOpen] = React.useState(false);

    // type : 매출금액, 결제 수업수 , 완료한 수업수 영어로
    const onClickEvent = (event: DailyInfo, type: 'salesAmount' | 'paymentCount' | 'completedCount') => {
        setSelectedEvent({ ...event, type });
        setIsSettlementPriceModal(true);
    };

    const onMonthNavigate = (date: Date | string | Dayjs) => {
        // 수업료 정산화면일때 선택된 날짜 초기화
        // 미래의 달은 못넘어감
        if (selectedMenu === 'classSales') {
            setSelectedDate(undefined);
            // 파라미터 date 가 오늘의 달보다 미래의 달이면 return
            if (dayjs(date).isAfter(dayjs(), 'month')) {
                return;
            } else {
                setDate(date as string);
            }
        } else {
            setDate(date as string);
        }
    };

    const { refinedResults, isLoading, refresh } = useMultipleQueries([
        // 0 : 수업료 정산
        {
            fetchFn: fetchGetClassFeeSettlementForMembership,
            params: {
                centerId: authObject.centerId,
                startDate: startDate,
                endDate: endDate,
                selectedCoach: selectedCoach,
                accessToken: authorization,
            },
            enabled: !!isAuth,
            suspense: false,
        },
        // 1 : 매출목록
        {
            fetchFn: fetchGetSalesList,
            params: {
                centerId: authObject.centerId,
                startDate: startDate,
                endDate: endDate,
                userId: selectedSalesManager.userId,
                role: selectedSalesManager.role,
                accessToken: authorization,
            },
            enabled: !!isAuth,
            suspense: false,
        },
        // 2 : 강사 리스트
        {
            fetchFn: fetchCoachNameList,
            params: { centerId: authObject?.centerId, isAllConcat: true },
            enabled: !!authObject?.centerId && !!isAuth,
            suspense: false,
        },

        {
            fetchFn: fetchGetCenterRemainingScheduleFee,
            params: {
                centerId: authObject?.centerId,
                targetMonth: dayjs(date).format('YYYY-MM'),
                targetCoach: selectedCalendarCoach === 'all' ? 'all' : selectedCalendarCoach.code,
            },
            enabled: !!authObject?.centerId && !!isAuth,
            suspense: false,
        },

        {
            fetchFn: fetchGetCenterRemainingScheduleSession,
            params: {
                centerId: authObject?.centerId,
                targetMonth: dayjs(date).format('YYYY-MM'),
                targetCoach: selectedCoach === 'all' ? 'all' : selectedCoach,
            },
            enabled: !!authObject?.centerId && !!isAuth,
            suspense: false,
        },
        {
            fetchFn: fetchCenterSalesSettlementCalendar,
            params: {
                centerId: authObject?.centerId,
                targetMonth: dayjs(date).format('YYYY-MM'),
                targetCoach: selectedCalendarCoach === 'all' ? 'all' : selectedCalendarCoach.code,
            },
            enabled: !!authObject?.centerId && !!date && !!selectedCalendarCoach,
            suspense: false,
        },
        // 6 : 급여 계산
        {
            fetchFn: fetchSalaryCalcs,
            params: {
                year: dayjs(date).year(),
                month: dayjs(date).month() + 1,
                selectedCoachId: selectedSalaryCoach?.code,
                centerId: authObject?.centerId,
                accessToken: authorization,
            },
            enabled: !!authObject?.centerId && !!date && !!selectedSalaryCoach,
            suspense: false,
        },
        // 7 : 미수금 목록
        {
            fetchFn: fetchGetUnpaidPayments,
            params: {
                userId: selectedUnpaidCoach?.code,
                role: selectedUnpaidCoach?.object?.role ?? 'all',
                centerId: authObject?.centerId,
                accessToken: authorization,
            },
            enabled: !!authObject?.centerId,
            suspense: false,
        },

        // 판매 담당자 리스트
        // {
        //     fetchFn: fetchGetSalesManagers,
        //     params: { centerId: authObject?.centerId },
        //     enabled: !!authObject?.centerId,
        //     suspense: false,
        // },
    ]);

    const classSettlementData = refinedResults?.[0]?.data as any;
    const salesListData = refinedResults?.[1]?.data as any;
    const coachList: CodeValueType<string>[] = refinedResults?.[2]?.data as CodeValueType<string>[];
    const totalRemainingScheduleFee = refinedResults?.[3]?.data as any;

    const totalRemainingScheduleSession = refinedResults?.[4]?.data as any;
    const calendar = refinedResults?.[5]?.data as SalesSummary;
    const salaryCalcData = refinedResults?.[6]?.data as SalaryCalcData;
    const unpaidListData = refinedResults?.[7]?.data as UnpaidPaymentData;

    // const salesManagerList = refinedResults?.[3]?.data;

    useEffect(() => {
        setStartDate(dayjs(date).startOf('month').toISOString());
        setEndDate(dayjs(date).endOf('month').add(1, 'ms').toISOString());
    }, [date, selectedCoach, selectedSalesManager]);

    // 달력에서 자세히 보기 눌렀을때
    // - [ ] 매출금액 모달 -> 자세히보기 매출목록 날짜까지 물고
    // - [ ] 결제수업수 모달 -> 자세히보기 매출목록 날짜까지 물고
    // - [ ] 잡힌 수업수 모달 -> 자세히보기 수업별 날짜물고
    const onClickDetail = (event: DailyInfo, type: MenuType) => {
        setDate(event.start);
        if (type === 'classSales') {
            setGroupType('class');
            if (selectedCalendarCoach !== 'all') {
                const coachString = coachList?.find((coach) => coach?.code === selectedCalendarCoach?.code)?.code;
                setSelectedCoach(coachString ?? 'all');
            } else {
                setSelectedCoach('all');
            }
        } else if (type === 'salesList') {
            // 강사가 있을때 강사 선택
            if (selectedCalendarCoach !== 'all') {
                const result = salesListData.salesManagers.find(
                    (manager: SalesManager) => manager.userId === selectedCalendarCoach.code
                );
                setSelectedSalesManager({
                    userId: result.userId,
                    role: result.role,
                    userName: result.userName,
                });
            } else {
                setSelectedSalesManager({
                    userId: 'all',
                    role: 'all',
                    userName: 'all',
                });
            }
        }
        setStartDate(dayjs(event.start).startOf('day').toISOString());
        setEndDate(dayjs(event.start).endOf('day').toISOString());
        setSelectedDate(dayjs(event.start).startOf('day').toISOString());
        setSelectedSalesListDate(dayjs(event.start).startOf('day').toISOString());
        setSelectedMenu(type);
    };

    useEffect(() => {
        if (!isPasswordSet) {
            window.location.href = '/';
        } else {
            setIsAuth(true);
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname === '/settlement') {
            if (selectedMenu === 'calendar') {
                //
                refresh(5);
                refresh(4);
            } else if (selectedMenu === 'classSales') {
                refresh(0);
                refresh(3);
                // 포커싱 되었을 때 실행할 로직
            } else if (selectedMenu === 'salesList') {
                refresh(1);
                // 포커싱 되었을 때 실행할 로직
            } else if (selectedMenu === 'salaryCalculation') {
                refresh(6);
                // 포커싱 되었을 때 실행할 로직
            } else if (selectedMenu === 'unpaidList') {
                refresh(7);
                // 포커싱 되었을 때 실행할 로직
            }
        } else {
            // 포커싱 아웃 됐을때 실행할 로직
        }
    }, [location, selectedMenu]);

    // coachList 가 데이터가 비동기로 들어오면 setSelectedSalaryCoach
    useEffect(() => {
        if (!refinedResults[2].isLoading && coachList && coachList.length > 0) {
            setSelectedSalaryCoach(coachList[0]);
        }
    }, [refinedResults[2].isLoading]);

    const excelExport = async (value: any, coachId: string) => {
        const classFeeDataForClass = classSettlementData.tableDataBySchedule.filter(
            (item: any) => item.coachId === coachId
        );
        const salesList = salesListData?.tableDataByMembershipPayment?.filter(
            (manager: any) => manager.salesManager.userId === coachId
        );

        const etcHeaderData = value.etcBonus.map((item: any) => {
            return { title: item.name, value: item.amount };
        });

        const headerData = [
            { title: '강사이름', value: value.coachName },
            { title: '매출액', value: value.settlement },
            {
                title: value.incentiveType === 'percentage' ? `매출 인센티브 (${value.incentive} %)` : '매출 인센티브',
                value: value.incentive,
            },
            { title: '수업료', value: value.classFee },
            { title: '기본급', value: value.baseSalary },
            { title: '3.3% 공제', value: -Math.abs(value.deduction) }, // 값을 음수로 설정
        ];

        headerData.push(...etcHeaderData);
        headerData.push({ title: '최종 급여액', value: value.finalSalary });

        const transformedHeaderData = [headerData.map((item) => item.title), headerData.map((item) => item.value)];

        const headerSheet = XLSX.utils.aoa_to_sheet(transformedHeaderData);

        const classHeaders = [
            '강사',
            '회원',
            '상태',
            '수업 일시',
            '수강권',
            '회차/총 회차',
            '정산액',
            '수업 금액',
            '정산 비율',
            '정산 타입',
        ];
        const classData = classFeeDataForClass.map((item: any) => [
            item.coachName,
            item.memberName,
            item.attendanceStatus === 'ATTENDANCE' ? '출석' : '노쇼',
            dayjs(item.startTime).format('YYYY-MM-DD(ddd) HH:mm'),
            item.membershipName,
            `${item.currentSession}/${item.totalSession}`,
            item.memberScheduleFee ?? 0,
            item.pricePerSchedule ?? 0,
            item.commission ? item.commission : '-',
            item.commissionType === 'fixed' ? '정액제' : '정률제',
        ]);

        const salesListHeaders = [
            '판매상태',
            '결제일시',
            '판매담당자',
            '담당강사',
            '회원',
            '결제금액',
            '취소액',
            '총회차',
            '수강권',
            '이용기간',
        ];
        const _salesListData = salesList?.map((item: any) => [
            item.saleStatus === 'COMPLETED'
                ? '완료'
                : item.saleStatus === 'UPGRADED'
                ? '업그레이드'
                : item.saleStatus === 'PARTIAL_REFUND'
                ? '부분 환불'
                : '전체 환불',
            dayjs(item.paymentDate).format('YYYY-MM-DD'),
            item.salesManager.userName,
            item.coachName,
            item.memberName,
            item.paymentAmount,
            item.cancelAmount,
            `${item.totalSession}회 ${
                item.totalSessionChange > 0
                    ? `(+${item.totalSessionChange})`
                    : item.totalSessionChange < 0
                    ? `(-${item.totalSessionChange})`
                    : ''
            }`,
            item.membershipName,
            `${dayjs(item.activationDate).format('YYYY-MM-DD')} ~ ${dayjs(item.expirationDate).format('YYYY-MM-DD')}`,
        ]);

        // value.incentiveType 이 'percentange' 이면 C3에 value.incentive / 100 표시 후
        if (value.incentiveType === 'percentage') {
            // C3 에 매출인센티브 비율 표시
            XLSX.utils.sheet_add_aoa(headerSheet, [['매출 인센티브 비율']], { origin: 'C3' });

            headerSheet['C4'] = { t: 'n', f: `${value.incentive} ` };
            // C2에 PRODUCT(B2, C3)으로 계산
            headerSheet['C2'] = { t: 'n', f: `PRODUCT(B2, C4/100)` };
        }

        // 판매 금액 A6
        XLSX.utils.sheet_add_aoa(headerSheet, [['판매 금액']], { origin: 'A6' });
        headerSheet['A6'].s = {
            font: { bold: true, sz: 18 }, // Bold font, size 18
            alignment: { horizontal: 'center', vertical: 'center' }, // Center alignment
        };
        // Add sales list data starting from A9
        XLSX.utils.sheet_add_aoa(headerSheet, [salesListHeaders], { origin: `A7` });
        XLSX.utils.sheet_add_aoa(headerSheet, _salesListData, { origin: `A8` });

        // XLSX.utils.sheet_add_aoa(headerSheet, [['수업료 정산']], { origin: 'L6' }); // Add "수업료 정산" in L6

        // 수업료 정산 salesListData 끝나고
        XLSX.utils.sheet_add_aoa(headerSheet, [['수업료 정산']], { origin: `A${(_salesListData?.length ?? 0) + 11}` }); // Add "수업료 정산" in A11
        headerSheet[`A${(_salesListData?.length ?? 0) + 11}`].s = {
            font: { bold: true, sz: 18 }, // Bold font, size 18
            alignment: { horizontal: 'center', vertical: 'center' }, // Center
        };

        // Add class data directly below sales list
        const classDataStartRow = (_salesListData?.length ?? 0) + 12; // Add 11 to start below salesList
        XLSX.utils.sheet_add_aoa(headerSheet, [classHeaders], { origin: `A${classDataStartRow}` });
        XLSX.utils.sheet_add_aoa(headerSheet, classData, { origin: `A${classDataStartRow + 1}` });

        // Add class data with PRODUCT formula for 정산액
        classData.forEach((row: any, index: any) => {
            const rowIndex = classDataStartRow + 1 + index; // 각 데이터의 시작 행
            XLSX.utils.sheet_add_aoa(headerSheet, [row], { origin: `A${rowIndex}` });

            // 정산액 (수업 금액 * 정산 비율) 계산
            const classFeeCell = `H${rowIndex}`; // 수업 금액이 있는 열 (H)
            const comissionCell = `I${rowIndex}`; // 정산 비율이 있는 열 (I)
            const resultCell = `G${rowIndex}`; // result을 저장할 열 (G)

            if (row[9] === '정액제') {
                headerSheet[resultCell] = {
                    t: 'n',
                    v: row[6],
                };
            } else {
                headerSheet[resultCell] = {
                    t: 'n',
                    f: `PRODUCT(${classFeeCell}, ${comissionCell}/100)`, // 수업 금액 * 정산 비율
                };
            }
        });

        // Add SUM formula to D2
        const sumRange = `G${classDataStartRow + 1}:G${classDataStartRow + classData.length}`;
        headerSheet['D2'] = { t: 'n', f: `SUM(${sumRange})` }; // Add SUM formula to D2

        const sumRangeF = `F8:F${classDataStartRow - 5}`; // 총 결제금액 범위
        const sumRangeG = `G8:G${classDataStartRow - 5}`; // 총 취소액 범위
        headerSheet['B2'] = { t: 'n', f: `SUM(${sumRangeF}) - SUM(${sumRangeG})` }; // Add 계산식으로 매출액을 계산

        const headerEndColumnIndex = 65 + headerData.length - 1; // 65는 'A'의 ASCII 값
        const headerSumColumnIndex = 65 + headerData.length - 2; // 65는 'A'의 ASCII 값

        const headerEndColumn = String.fromCharCode(headerEndColumnIndex); // 마지막 열 계산
        const headerSumColumn = String.fromCharCode(headerSumColumnIndex); // 마지막 열 계산
        const sumRange3 = `C2:${headerSumColumn}2`; // B2에서 마지막 열까지의 범위 설정
        headerSheet[`${headerEndColumn}2`] = { t: 'n', f: `SUM(${sumRange3})` };

        // **Set Column Widths**
        headerSheet['!cols'] = Array(10).fill({ wch: 20 }); // Sets the width of columns A to J to 20 characters

        // Create a workbook
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, headerSheet, 'Details');

        // Write the workbook and download
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${dayjs(date).format('YYYY년 MM월') + ' ' + value.coachName + '강사 급여정산'}.xlsx`);
    };

    return (
        <Col className="flex w-full h-full  relative">
            <div className="flex w-full h-full flex-col bg-[#F4F5F7] justify-center items-center mt-[60px]">
                <Col className="flex w-[1232px] h-full flex-col justify-center items-center">
                    <SettlementHeader
                        selectedMenu={selectedMenu}
                        onMenuClick={(type) => {
                            setSelectedMenu(type);
                        }}
                        onCommissionClick={() => setIsVisibleCommissionModal(true)}
                    />

                    {/* 달력 눌렀을때 */}
                    {selectedMenu === 'calendar' && (
                        <SettlementCalendar
                            date={date}
                            onClickEvent={onClickEvent}
                            onMonthNavigate={onMonthNavigate}
                            onClickCommissionModal={() => setIsVisibleCommissionModal(true)}
                            coachList={coachList}
                            selectedCalendarCoach={selectedCalendarCoach}
                            onSelectedCalendarCoach={(coach) => {
                                if (coach.code === 'all') {
                                    setSelectedCalendarCoach('all');
                                } else {
                                    setSelectedCalendarCoach(coach);
                                }
                            }}
                            totalRemainingScheduleFee={totalRemainingScheduleFee}
                            calendar={calendar}
                        />
                    )}
                    {/* 수업료 정산 눌렀을때 */}
                    {selectedMenu === 'classSales' && (
                        <ClassPaySettlementPage
                            date={date}
                            onMonthNavigate={onMonthNavigate}
                            classSettlementData={classSettlementData}
                            selectedCoach={selectedCoach}
                            onCoachSelect={(coach) => setSelectedCoach(coach)}
                            onMemberScheduleRecordClick={(scheduleRecord) => {
                                setSelectedScheduleRecord(scheduleRecord);
                                setIsClassHistoryModal(true);
                            }}
                            onMemberMembershipInfoClick={(membershipInfo) => {
                                setIsMembershipInfoModal(true);
                                setSelectedMembership(membershipInfo);
                            }}
                            groupType={groupType}
                            onGroupTypeChange={(type) => setGroupType(type)}
                            selectedDate={selectedDate}
                            onChangeSelectedDate={(date) => setSelectedDate(date)}
                            totalRemainingScheduleSession={totalRemainingScheduleSession}
                            onCommissionClick={(row) => {
                                setSelectedClassFeeSettlementRow(row);
                                setIsCommissionModal(true);
                            }}
                        />
                    )}
                    {/* 매출목록 눌렀을때 */}
                    {selectedMenu === 'salesList' && (
                        <SalesListPage
                            date={date}
                            onMonthNavigate={onMonthNavigate}
                            salesListData={salesListData}
                            selectedSalesManager={selectedSalesManager}
                            onSalesManagerSelect={(salesManager) => {
                                salesManager.code === 'all'
                                    ? setSelectedSalesManager({
                                          userId: 'all',
                                          role: 'all',
                                          userName: 'all',
                                      })
                                    : setSelectedSalesManager({
                                          userId: salesManager.code,
                                          role: salesManager.object.role,
                                          userName: salesManager.value,
                                      });
                            }}
                            selectedSalesListDate={selectedSalesListDate}
                            onChangeSelectedSalesListDate={(date) => setSelectedSalesListDate(date)}
                        />
                    )}
                    {/* 급여 계산 눌렀을때 */}
                    {selectedMenu === 'salaryCalculation' && (
                        <SalaryCalculationPage
                            date={date}
                            onMonthNavigate={onMonthNavigate}
                            salaryCalcData={salaryCalcData}
                            coachList={coachList}
                            selectedCoach={selectedSalaryCoach}
                            onCoachSelect={(coach) => setSelectedSalaryCoach(coach)}
                            // 매출액, 수업료
                            refresh={() => refresh(6)}
                            onClickExcel={excelExport}
                        />
                    )}
                    {/* 미수금 목록 눌렀을때 */}
                    {selectedMenu === 'unpaidList' && (
                        <UnpaidListPage
                            selectedCoach={selectedUnpaidCoach}
                            onCoachSelect={setSelectedUnpaidCoach}
                            unpaidListData={unpaidListData}
                            onClickUnpaid={(row) => {
                                setSelectedRow(row);
                                setIsPartialPaymentModalOpen(true);
                            }}
                        />
                    )}
                </Col>
            </div>
            {isSettlementPriceModal && (
                <ModalSales
                    event={selectedEvent}
                    setIsVisible={setIsSettlementPriceModal}
                    onClickDetail={onClickDetail}
                />
            )}
            {isVisibleCommissionModal && <ModalCommission setIsVisible={setIsVisibleCommissionModal} date={date} />}
            {isClassHistoryModal && (
                <ModalClassHistory setIsVisible={setIsClassHistoryModal} scheduleRecord={selectedScheduleRecord} />
            )}
            {isMembershipInfoModal && selectedMembership && (
                <ModalMembershipInfo setIsVisible={setIsMembershipInfoModal} membershipInfo={selectedMembership} />
            )}
            {isCommissionModal && (
                <ModalMonthCommision
                    setIsVisible={setIsCommissionModal}
                    row={selectedClassFeeSettlementRow}
                    year={dayjs(date).year()}
                    month={dayjs(date).month() + 1}
                    onPatchMonthCommissionSuccess={() => {
                        refresh(0);
                    }}
                />
            )}
            {isPartialPaymentModalOpen && ( // 분할 결제 모달
                <ModalPartialPayment
                    setIsVisible={setIsPartialPaymentModalOpen}
                    unpaidRowData={selectedRow}
                    onPostSplitPaymentSuccess={() => {
                        refresh(7);
                    }}
                />
            )}
        </Col>
    );
};

export default SettlementPage;
