import React, { useRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import height from 'dom-helpers/height';
import querySelectorAll from 'dom-helpers/querySelectorAll';
import BackgroundCells from '../lib/BackgroundCells';
// import EventRow from './EventRow';
// import EventEndingRow from './EventEndingRow';
// import NoopWrapper from './NoopWrapper';
// import ScrollableWeekWrapper from './ScrollableWeekWrapper';
import * as DateSlotMetrics from './DateSlotMetrics';
import ScrollableWeekWrapper from './ScrollableWeekWrapper';
import NoopWrapper from './NoopWrapper';
import EventRow from './EventsRow';
import dayjs from 'dayjs';
import { WeeklyTotal } from '../../../services/useCenterQueryService';

interface DateContentRowProps {
    date: Date;
    weeklyEvents?: any[];
    events: any[];
    range: Date[];
    rtl?: boolean;
    className?: string;
    selected?: any[];
    selectable?: boolean;
    renderForMeasure?: boolean;
    accessors?: any;
    getters?: any;
    components?: any;
    getNow?: () => Date;
    renderHeader?: (props: { date: Date; key: string; className: string }) => React.ReactNode;
    onSelect?: (event: any) => void;
    onSelectStart?: (range: any) => void;
    onSelectEnd?: (range: any) => void;
    onDoubleClick?: (event: any) => void;
    onKeyPress?: (event: any) => void;
    resourceId?: string;
    longPressThreshold?: number;
    isAllDay?: boolean;
    resizable?: boolean;
    showAllEvents?: boolean;
    onShowMore?: (events: any[], date: Date, cell: HTMLElement, slot: number, target: Event) => void;
    onSelectSlot?: (range: Date[], slotInfo: any) => void;
    container?: () => HTMLElement;
    monthCustomEventComponent?: (event: any) => React.ReactNode;
    monthCellHeaderCustomComponent?: (date: Date) => React.ReactNode;
    monthWeeklyCustomComponent?: (event: WeeklyTotal, isLastWeek: boolean) => React.ReactNode;
    isLastWeek?: boolean;
}

const DateContentRow: React.FC<DateContentRowProps> = ({
    date,
    events,
    weeklyEvents,
    range,
    rtl,
    className,
    selected,
    selectable,
    renderForMeasure,
    accessors,
    getters,
    components = {},
    getNow,
    renderHeader,
    onSelect,
    onSelectStart,
    onSelectEnd,
    onDoubleClick,
    onKeyPress,
    resourceId,
    longPressThreshold,
    isAllDay,
    resizable,
    showAllEvents,
    onShowMore,
    onSelectSlot,
    container,
    monthCustomEventComponent,
    monthCellHeaderCustomComponent,
    monthWeeklyCustomComponent,
    isLastWeek,
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const headingRowRef = useRef<HTMLDivElement | null>(null);
    const eventRowRef = useRef<HTMLDivElement | null>(null);

    const slotMetrics = useMemo(() => {
        return DateSlotMetrics.getSlotMetrics()({
            range,
            events,
            maxRows: 5, // Example value for max rows
            minRows: 0, // Example value for min rows
        });
    }, [range, events]);
    const { levels, extra } = slotMetrics;

    const getContainer = useCallback(() => {
        return container ? container() : containerRef.current;
    }, [container]);

    const renderHeadingCell = useCallback(
        (date: Date, index: number) => {
            return renderHeader
                ? renderHeader({
                      date,
                      key: `header_${index}`,
                      className: clsx(
                          'rbc-date-cell',
                          getNow && getNow().toDateString() === date.toDateString() && 'rbc-now'
                      ),
                  })
                : null;
        },
        [renderHeader, getNow]
    );

    const handleSelectSlot = useCallback(
        (slot: { start: number; end: number }) => {
            const rangeSelection = range.slice(slot.start, slot.end + 1);
            onSelectSlot && onSelectSlot(rangeSelection, slot);
        },
        [range, onSelectSlot]
    );

    const getRowLimit = useCallback(() => {
        const eventHeight = eventRowRef.current ? height(eventRowRef.current) : 0;
        const headingHeight = headingRowRef.current ? height(headingRowRef.current) : 0;
        const eventSpace = containerRef.current ? height(containerRef.current) - headingHeight : 0;

        return Math.max(Math.floor(eventSpace / eventHeight), 1);
    }, []);

    // if (renderForMeasure) {
    //     return renderDummy();
    // }

    const ScrollableWeekComponent = showAllEvents ? ScrollableWeekWrapper : NoopWrapper;
    const WeekWrapper = components.weekWrapper;

    // const eventRowProps = {
    //     selected,
    //     accessors,
    //     getters,
    //     components,
    //     onSelect,
    //     onDoubleClick,
    //     onKeyPress,
    //     resourceId,
    //     slotMetrics: metrics,
    //     resizable,
    // };
    const eventRowProps = {
        selected: selected,
        accessors: accessors,
        getters: getters,
        // localizer: localizer,
        components: components,
        onSelect: onSelect,
        onDoubleClick: onDoubleClick,
        onKeyPress: onKeyPress,
        resourceId: resourceId,
        slotMetrics: slotMetrics,
        resizable: resizable,
        monthCustomEventComponent: monthCustomEventComponent,
    };

    return (
        <div
            className={`flex relative w-full h-[140px] overflow-hidden
            ${
                // 마지막 줄에 border-bottom 없애기
                isLastWeek ? 'border-b-0' : 'border-b-[1px] border-b-[#D9D9D9] border-solid'
            }
          `}
            role="rowgroup"
            ref={containerRef}
        >
            <div className={' w-full h-full relative z-10 '} role="row">
                <div className=" w-full h-full">
                    {levels.map((segs, idx) => (
                        <EventRow key={idx} segments={segs} {...eventRowProps} />
                    ))}
                    {/* {!!extra.length && (
                            <EventEndingRow segments={extra} onShowMore={handleShowMore} {...eventRowProps} />
                        )} */}
                </div>
            </div>
            <BackgroundCells
                date={date}
                rtl={rtl}
                range={range}
                selectable={selectable}
                // container={getContainer}
                getNow={() => new Date()}
                getters={getters}
                onSelectStart={onSelectStart}
                onSelectEnd={onSelectEnd}
                onSelectSlot={handleSelectSlot}
                components={components}
                longPressThreshold={longPressThreshold}
                resourceId={resourceId}
                monthCellHeaderCustomComponent={monthCellHeaderCustomComponent}
                weeklyEvents={weeklyEvents}
                monthWeeklyCustomComponent={monthWeeklyCustomComponent}
                isLastWeek={isLastWeek}
            />
        </div>
    );
};

export default DateContentRow;
