import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getItemWithExpiration } from '../lib/utils';
import {
    patchMembershipRefund,
    PatchMembershipRefundAndUpgradedParams,
    patchMembershipUpgrade,
    postSalesManager,
    postSplitPayment,
    PostSplitPaymentParams,
} from '../api/membershipPayments';
import { SalesManager } from './useMembershipPaymentsQueryService';

type Props = {
    onPostSalesManagerSuccess?: (data: SalesManager[]) => void;
    onPatchMembershipRefundSuccess?: () => void;
    onPatchMembershipUpgradeSuccess?: () => void;
    onPostSplitPaymentSuccess?: () => void;
};

const useMembershipPaymentsMutationService = ({
    onPostSalesManagerSuccess,
    onPatchMembershipRefundSuccess,
    onPatchMembershipUpgradeSuccess,
    onPostSplitPaymentSuccess,
}: Props) => {
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [authorization] = useState(getItemWithExpiration('authorization'));

    // 회원권 이전하기
    const postSalesManagerMutation = useMutation(
        ['postSalesManagerMutation'],
        async (params: { membershipId: string; managerName: string }) =>
            postSalesManager(params.membershipId, params.managerName, authorization),

        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status, data }) => {
                if (status === 201) {
                    const result = data.salesManagers as SalesManager[];
                    onPostSalesManagerSuccess && onPostSalesManagerSuccess(result);
                }
            },
        }
    );
    // 회원권 환불
    const patchMembershipRefundMutation = useMutation(
        ['patchMembershipRefundMutation'],
        async (params: Omit<PatchMembershipRefundAndUpgradedParams, 'accessToken'>) =>
            patchMembershipRefund({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status, data }) => {
                if (status === 200) {
                    onPatchMembershipRefundSuccess && onPatchMembershipRefundSuccess();
                }
            },
        }
    );
    // 회원권 업그레이드
    const patchMembershipUpgradeMutation = useMutation(
        ['patchMembershipUpgradeMutation'],
        async (params: Omit<PatchMembershipRefundAndUpgradedParams, 'accessToken'>) =>
            patchMembershipUpgrade({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status, data }) => {
                if (status === 200) {
                    onPatchMembershipUpgradeSuccess && onPatchMembershipUpgradeSuccess();
                }
            },
        }
    );
    // 회원권 업그레이드
    const postSplitPaymentMutation = useMutation(
        ['postSplitPaymentMutation'],
        async (params: Omit<PostSplitPaymentParams, 'accessToken'>) =>
            postSplitPayment({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status, data }) => {
                if (status === 201) {
                    onPostSplitPaymentSuccess && onPostSplitPaymentSuccess();
                }
            },
        }
    );

    return {
        postSalesManagerMutation,
        patchMembershipRefundMutation,
        patchMembershipUpgradeMutation,
        postSplitPaymentMutation,
    };
};

export default useMembershipPaymentsMutationService;
