import React, { useEffect, useRef, useState } from 'react';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';

type Props = {
    text: string;
    isDropDown: boolean;
    callBack?: () => void;
    isActive?: boolean;
    list?: any;
};

const TextButtonDropDown = ({ text, isDropDown, callBack, isActive, list }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        if (isDropDown) {
            setIsOpen(!isOpen);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="relative inline-block ">
            {/* 버튼 */}
            <button
                onClick={() => (isDropDown ? toggleDropdown() : callBack && callBack())}
                className={`flex items-center justify-center w-[146px] h-[46px] rounded-md transition-colors duration-200 
                    ${isOpen ? 'text-blue-600' : 'text-gray-800'} 
                    hover:text-blue-400 active:text-blue-600`}
            >
                <Body3Regular style={{ color: isActive ? colors.BLUE_500 : '' }}>{text}</Body3Regular>
            </button>

            {/* 드롭다운 콘텐츠 */}
            {isOpen && (
                <div
                    className={`absolute mt-2 w-[240px] bg-white bg-opacity-95 rounded-md shadow-lg p-2 
                    animate-slide-down z-200`}
                >
                    {list?.map((item: any, index: number) => (
                        <div
                            key={`${text}-${index}`}
                            onClick={() => {
                                item.callBack();
                                toggleDropdown();
                            }}
                            className="flex items-center p-2 mb-1 cursor-pointer rounded-md hover:bg-[#d7e0f3] transition"
                        >
                            <div className="w-1.5 h-8 rounded-md bg-blue-100 mr-4 "></div>
                            <Body3Regular style={{ color: colors.LAVEL_3 }}>{item.name}</Body3Regular>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TextButtonDropDown;
