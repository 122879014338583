import React from 'react';
import TextSelector, { FontsType } from '../TextSelector';
import { colors } from '../../lib/colors';

type Props = {
    text: string;
    fontType?: FontsType;
    width?: number;
    height?: number;
    onClick?: () => void;
    className?: string;
    mb?: number;
    textColor?: keyof typeof colors;
    bgColor?: keyof typeof colors;
    borderRadius?: number;
    disabled?: boolean;
};

const NewColorSquareButton = ({
    text,
    fontType = 'Body2Regular',
    width,
    height,
    onClick,
    className,
    mb,
    textColor = 'white',
    bgColor = 'primary',
    borderRadius,
    disabled,
}: Props) => {
    const disabledStyle = disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer';
    return (
        <div
            onClick={onClick}
            className={`flex flex-col justify-center items-center   rounded-[2px] ${className} border-[1px] border-solid border-primary  ${disabledStyle}`}
            style={{
                width: width ? `${width}px` : 'auto',
                height: height ? `${height}px` : 'auto',
                marginBottom: mb ? `${mb}px` : '0',
                borderRadius: borderRadius ? `${borderRadius}px` : '0',
                backgroundColor: colors[bgColor],
            }}
        >
            <TextSelector fontType={fontType} text={text} color={textColor} />
        </div>
    );
};

export default NewColorSquareButton;
