import React, { useEffect, useMemo, useRef, useState } from 'react';
import Table from '../../components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import Dropdown from '../../components/DropDown/DropDown';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../../components/SvgIcon';
import dayjs, { Dayjs } from 'dayjs';
import { colors } from '../../lib/colors';
import * as XLSX from 'xlsx'; // 엑셀 변환 라이브러리
import { saveAs } from 'file-saver'; // 파일 저장 라이브러리
import MemberSearchTextInput from '../../components/Button/MemberSearchTextInput';
import {
    ClassFeeSettlementScheduleRecord,
    FetchGetClassFeeSettlementResponse,
    GetClassFeeSettlementSummaryData,
    GetClassFeeSettlementTableData,
    GetClassFeeSettlementTableDataForMembership,
    MembershipInfo,
} from '../../services/useMemberQueryService';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';
import NewCalendar from '../../components/Calendar/NewCalendar';
import { TooltipIcon } from './SettlementCalendar';

// 더미 출석여부
const dummyAttendanceStatusData = [
    {
        code: 'NOSHOW',
        value: '노쇼',
    },
    {
        code: 'ATTENDANCE',
        value: '출석',
    },
];

const columnHelper = createColumnHelper<GetClassFeeSettlementTableDataForMembership>();
const columnHelper2 = createColumnHelper<MemberMembershipDataType>();

type Props = {
    date: string;
    onMonthNavigate: (date: Date | string | Dayjs) => void;
    classSettlementData: FetchGetClassFeeSettlementResponse;

    // 강사 선택
    selectedCoach: string;
    onCoachSelect: (coachId: string) => void;

    // 회원별 -> 수업기록 클릭
    onMemberScheduleRecordClick: (scheduleRecord: ClassFeeSettlementScheduleRecord) => void;
    // 회원별 -> 수강권 정보 클릭
    onMemberMembershipInfoClick: (membershipInfo: MembershipInfo) => void;
    groupType: 'class' | 'member';
    onGroupTypeChange: (type: 'class' | 'member') => void;
    selectedDate?: Dayjs | string | undefined;
    onChangeSelectedDate: (date?: Dayjs | string | undefined) => void;
    totalRemainingScheduleSession: number;
    onCommissionClick: (row: GetClassFeeSettlementTableDataForMembership) => void;
};

const ClassPaySettlementPage = ({
    date,
    onMonthNavigate,
    classSettlementData,
    selectedCoach,
    onCoachSelect,
    onMemberScheduleRecordClick,
    onMemberMembershipInfoClick,
    groupType,
    onGroupTypeChange,
    selectedDate,
    onChangeSelectedDate,
    totalRemainingScheduleSession,
    onCommissionClick,
}: Props) => {
    const [position, setPosition] = useState({ top: 0, left: 0 }); // 위치 상태
    const colRef = React.useRef<HTMLDivElement>(null);
    // 툴팁 호버했을때
    const [isHover, setIsHover] = useState(false);

    // 컴포넌트가 렌더링된 후 위치값 가져오기
    useEffect(() => {
        if (colRef.current) {
            const rect = colRef?.current?.getBoundingClientRect(); // 요소의 위치와 크기 가져오기
            setPosition({ top: rect.top, left: rect.left });
        }
    }, [isHover]);
    // 수강권 정보, 발급 일자, 회원, 강사, 회당가격, 정산 비율, 완료/노쇼 수업정산액, 남은 수업수
    const columns = useMemo(
        () => [
            columnHelper.accessor('membershipName', {
                id: 'membershipName',
                header: '수강권 정보',
                cell: (info) => {
                    return (
                        <RegularText
                            fontSize={16}
                            lineHeight={23}
                            letterSpacing={-0.3}
                            className="cursor-pointer underline underline-offset-4 hover:text-primary decoration-[1px]"
                            isNoColor
                            color="#3D4244"
                            onClick={() => onMemberMembershipInfoClick(info.row.original.membershipInfo)}
                        >
                            {info.getValue()}
                        </RegularText>
                    );
                },
                size: 226,
                enableSorting: false,
            }),
            columnHelper.accessor('createdAt', {
                id: 'createdAt',
                header: '발급일자',
                cell: (info) => dayjs(info.getValue()).format('YYYY-MM-DD'),
                size: 135,
                enableSorting: false,
            }),

            columnHelper.accessor('memberName', {
                id: 'memberName',
                header: '회원',
                cell: (info) => info.getValue(),
                size: 131,
                enableSorting: false,
            }),
            columnHelper.accessor('coachName', {
                id: 'coachName',
                header: '강사',
                cell: (info) => info.getValue(),
                size: 131,
                enableSorting: false,
            }),
            columnHelper.accessor('pricePerSchedule', {
                id: 'pricePerSchedule',
                header: '회당가격',
                cell: (info) => convertToKRW(info.getValue()),
                size: 126,
                enableSorting: false,
            }),
            columnHelper.accessor('commission', {
                id: 'commission',
                header: () => {
                    return (
                        <Row className=" items-center" ref={colRef}>
                            <DemiLightText fontSize={16} lineHeight={23} color="#3D4244" className="mr-[4px]">
                                정산 비율
                            </DemiLightText>
                            <SvgIcon
                                name={'SvgWarningInCircle'}
                                size={16}
                                fill={'#B4BCC4'}
                                onMouseEnter={() => setIsHover(true)}
                                onMouseLeave={() => setIsHover(false)}
                            />
                        </Row>
                    );
                },
                // header: '정산 비율',
                cell: (info) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [isHover, setIsHover] = useState(false);
                    return (
                        <Row
                            onClick={() => onCommissionClick(info.row.original)}
                            className=" items-center cursor-pointer "
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                        >
                            <RegularText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.4}
                                className={`mr-[8px] underline underline-offset-4 decoration-[1px] self-start  overflow-hidden whitespace-nowrap w-[50px] ${
                                    isHover ? 'text-primary underline-primary' : ''
                                }`}
                                isNoColor
                                color="#3D4244"
                            >
                                {` ${
                                    info?.getValue()
                                        ? info.row.original.commissionType === 'fixed'
                                            ? convertToKRW(info?.getValue() ?? 0)
                                            : info.getValue() + '%'
                                        : '-'
                                }`}
                            </RegularText>
                            {info.row.original.isCustomedCommission ? (
                                <SvgIcon
                                    name="SvgMembershipTicket"
                                    size={20}
                                    fill={isHover ? colors.primary : '#B4BCC4'}
                                    className="w-[20px]"
                                />
                            ) : (
                                <Col className="w-[20px]" />
                            )}
                        </Row>
                    );
                },
                size: 163,
                enableSorting: false,
            }),
            columnHelper.accessor('attendanceScheduleCount', {
                id: 'attendanceScheduleCount',
                header: '완료/노쇼',
                cell: (info) => {
                    return (
                        <RegularText
                            fontSize={16}
                            lineHeight={23}
                            letterSpacing={-0.3}
                            className="underline underline-offset-4 cursor-pointer deco보ration-[1px] hover:text-primary "
                            isNoColor
                            color="#3D4244"
                            onClick={() => onMemberScheduleRecordClick(info.row.original.scheduleRecord)}
                        >
                            {info.row.original.noshowScheduleCount === 0
                                ? ` ${info.getValue()}`
                                : ` ${info.getValue()}/${info.row.original.noshowScheduleCount}`}
                        </RegularText>
                    );
                },

                size: 119,
                enableSorting: false,
            }),
            columnHelper.accessor('memberTotalScheduleFee', {
                id: 'memberTotalScheduleFee',
                header: '수업 정산액',
                cell: (info) => convertToKRW(info.getValue() ?? 0),
                size: 126,
                enableSorting: false,
            }),
            columnHelper.accessor('remainSession', {
                id: 'remainSession',
                header: '남은 수업수',
                cell: (info) => info.getValue(),
                size: 119,
                enableSorting: false,
            }),
        ],
        []
    );
    const columns2 = useMemo(
        () => [
            columnHelper2.accessor('coachName', {
                id: 'coachName',
                header: '강사',
                cell: (info) => info.getValue(),
                size: 149,
                enableSorting: false,
            }),
            columnHelper2.accessor('memberName', {
                id: 'memberName',
                header: '회원',
                cell: (info) => info.getValue(),
                size: 149,
                enableSorting: false,
            }),
            columnHelper2.accessor('attendanceStatus', {
                id: 'attendanceStatus',
                header: '상태',
                cell: (info) => {
                    const text = info.getValue() === 'ATTENDANCE' ? '출석' : '노쇼';
                    const isAttendance = info.getValue() === 'ATTENDANCE';
                    const color = isAttendance ? '#E5F8F3' : '#FFE8E9';
                    const textColor = isAttendance ? '#00A793' : '#FF553E';
                    return (
                        <Col
                            className="flex items-center justify-center w-[57px] h-[32px]"
                            style={{ backgroundColor: color, borderRadius: 5 }}
                        >
                            <RegularText fontSize={16} lineHeight={24} color={textColor}>
                                {text}
                            </RegularText>
                        </Col>
                    );
                },
                size: 133,
                enableSorting: false,
            }),
            columnHelper2.accessor('membershipName', {
                id: 'membershipName',
                header: '수강권',
                cell: (info) => info.getValue(),
                size: 226,
                enableSorting: false,
            }),

            columnHelper2.accessor('startTime', {
                id: 'startTime',
                header: '수업 일시',
                cell: (info) => dayjs(info.getValue()).format('YYYY-MM-DD(ddd) HH:mm'),
                size: 219,
                enableSorting: false,
            }),

            columnHelper2.accessor('currentSession', {
                id: 'currentSession',
                header: '회차/총 회차',
                cell: (info) => info.getValue() + '/' + info.row.original.totalSession,
                size: 129,
                enableSorting: false,
            }),
            columnHelper2.accessor('memberScheduleFee', {
                id: 'memberScheduleFee',
                header: '정산액',
                cell: (info) =>
                    !(info.getValue() === null || info.getValue() === undefined)
                        ? convertToKRW(info.getValue() ?? 0)
                        : '-',
                size: 135,
                enableSorting: false,
            }),
            columnHelper2.accessor('pricePerSchedule', {
                id: 'pricePerSchedule',
                header: '수업 금액',
                cell: (info) =>
                    !(info.getValue() === null || info.getValue() === undefined)
                        ? convertToKRW(info.getValue() ?? 0)
                        : '-',
                size: 129,
                enableSorting: false,
            }),
            columnHelper2.accessor('commission', {
                id: 'commission',
                header: '정산 비율',
                cell: (info) =>
                    ` ${
                        info?.getValue()
                            ? info.row.original.commissionType === 'fixed'
                                ? convertToKRW(info?.getValue() ?? 0)
                                : info.getValue() + '%'
                            : '-'
                    }`,
                size: 134,
                enableSorting: false,
            }),
        ],
        []
    );
    const [isCalendarOpen, setIsCalendarOpen] = useState(false); // 달력 표시 여부

    // 월 요약 데이터
    const monthlySummary = useMemo(() => classSettlementData?.summaryData, [classSettlementData]);

    // 필터링 데이터
    // 강사 데이터 , coachId, coachName -> code, value
    const coachData = classSettlementData?.coachs.map((coach) => ({
        code: coach.coachId,
        value: coach.coachName,
    }));
    // 회원 검색데이터, memberId, memberName -> code, value
    const memberData = classSettlementData?.filters?.memberList.map((member) => ({
        code: member.memberId,
        value: member.memberName,
    }));
    // 수강권 데이터, membershipTemplateId, membershipTemplateName -> code, value
    const membershipTemplateData = classSettlementData?.filters?.membershiptemplateList.map((membership) => ({
        code: membership.membershipTemplateId,
        value: membership.membershipTemplateName,
    }));

    const [search, setSearch] = React.useState('');
    const [rowSelected, setRowSelected] = React.useState({});

    // 테이블 데이터
    const tableData =
        groupType === 'member' ? classSettlementData?.tableDataByMembership : classSettlementData?.tableDataBySchedule;

    // const [defaultData, setDefaultData] = React.useState(tableData);
    // 로우 총합 단가수
    const [totalUnitPrice, setTotalUnitPrice] = React.useState(0);
    // 필터데이터
    const [filteredData, setFilteredData] = React.useState(tableData);

    // 숙강권 선택
    const [selectedClassTicket, setSelectedClassTicket] = React.useState('');
    // 출석여부 선택
    const [selectedAttendanceStatus, setSelectedAttendanceStatus] = React.useState('');
    // 회원 선택
    const [selectedMember, setSelectedMember] = React.useState('');

    const handleSelect = (
        selected: { code: string; value: string },
        type: 'coach' | 'classTicket' | 'attendanceStatus'
    ) => {
        if (type === 'coach') {
            // setSelectedCoach(selected.code);
            onCoachSelect(selected.code);
        } else if (type === 'classTicket') {
            setSelectedClassTicket(selected.code);
        } else if (type === 'attendanceStatus') {
            setSelectedAttendanceStatus(selected.code);
        }
    };

    // 회원별 엑셀 다운로드
    const exportToExcelForMember = () => {
        const data: GetClassFeeSettlementTableDataForMembership[] =
            filteredData as GetClassFeeSettlementTableDataForMembership[];

        const localizedData = data.map((item) => ({
            '수강권 정보': item.membershipName,
            발급일자: dayjs(item.createdAt).format('YYYY-MM-DD'),
            회원: item.memberName,
            강사: item.coachName,
            회당가격: convertToKRW(item.pricePerSchedule),
            '정산 비율': item.commission
                ? item.commissionType === 'fixed'
                    ? convertToKRW(item.commission)
                    : item.commission + '%'
                : '-',
            '완료/노쇼': item.attendanceScheduleCount + '/' + item.noshowScheduleCount,
            '수업 정산액': convertToKRW(item.memberTotalScheduleFee),
            '남은 수업수': item.remainSession,
        }));
        // 1. JSON 데이터를 Sheet로 변환
        const worksheet = XLSX.utils.json_to_sheet(localizedData);
        // 2. Workbook 생성 및 Sheet 추가
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'FilteredData');
        // 3. 엑셀 파일 생성 및 다운로드
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${dayjs(date).format('YYYY-MM')} 수강권별 수업료 정산.xlsx`);
    };
    // 수업별 엑셀 다운로드
    const exportToExcelForClss = () => {
        const data: MemberMembershipDataType[] = filteredData as MemberMembershipDataType[];
        const localizedData = data.map((item) => ({
            강사: item.coachName,
            회원: item.memberName,
            상태: item.attendanceStatus === 'ATTENDANCE' ? '출석' : '노쇼',
            '수업 일시': dayjs(item.startTime).format('YYYY-MM-DD(ddd) HH:mm'),
            수강권: item.membershipName,
            '회차/총 회차': item.currentSession + '/' + item.totalSession,
            정산액: item.memberScheduleFee,
            '수업 금액': item.pricePerSchedule,
            '정산 비율': item.commission
                ? item.commissionType === 'fixed'
                    ? convertToKRW(item.commission)
                    : item.commission + '%'
                : '-',
        }));
        // 1. JSON 데이터를 Sheet로 변환
        const worksheet = XLSX.utils.json_to_sheet(localizedData);
        // 2. Workbook 생성 및 Sheet 추가
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'FilteredData');
        // 3. 엑셀 파일 생성 및 다운로드
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${dayjs(date).format('YYYY-MM')} 수업별 수업료 정산.xlsx`);
    };

    // 강사, 수강권, 출석여부 필터링
    useEffect(() => {
        if (tableData) {
            let tempData: any = tableData;

            if (selectedCoach && selectedCoach !== 'all') {
                tempData = tempData.filter((data: any) => data.coachId === selectedCoach);
            }
            if (selectedMember && selectedMember !== 'all') {
                tempData = tempData.filter((data: any) => data.memberId === selectedMember);
            }

            // 수업별일때만 필터링
            if (groupType === 'class') {
                if (selectedClassTicket && selectedClassTicket !== 'all') {
                    tempData = tempData.filter((data: any) => data.membershipTemplateId === selectedClassTicket);
                }
                if (selectedAttendanceStatus && selectedAttendanceStatus !== 'all') {
                    tempData = tempData.filter((data: any) => data.attendanceStatus === selectedAttendanceStatus);
                }
                if (selectedDate) {
                    tempData = tempData.filter((data: any) => dayjs(data.startTime).isSame(dayjs(selectedDate), 'day'));
                }
            }
            setFilteredData(tempData);
        }
    }, [tableData, selectedCoach, selectedClassTicket, selectedAttendanceStatus, selectedMember, selectedDate]);

    // 총 필터된 건수
    const filteredCount = filteredData?.length ?? 0;
    // 필터된 데이터의 수업료 총합
    const filteredTotalPrice =
        (groupType === 'class' &&
            filteredData &&
            filteredData.length > 0 &&
            (filteredData as any[]).reduce((acc, cur) => acc + (cur?.memberScheduleFee || 0), 0)) ||
        0;

    return (
        <div className="flex flex-col w-full h-full pt-[29px] px-[46px] bg-white rounded-[27px] mb-[50px]">
            <Row className=" items-center mb-[40px] ">
                <MediumText fontSize={25} lineHeight={25} color={'#3D4244'} className="mr-[14px]">
                    {dayjs(date).format('YYYY년 MM월')}
                </MediumText>
                <div
                    className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[5px]"
                    onClick={() => {
                        onMonthNavigate(dayjs(date).subtract(1, 'month'));
                    }}
                >
                    <SvgIcon
                        name={'SvgLeftStrokeArrow'}
                        size={15}
                        fill={colors.trans}
                        stroke={'#70747C'}
                        strokeWidth={1.2}
                    />
                </div>

                <div
                    className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[30px]"
                    onClick={() => {
                        onMonthNavigate(dayjs(date).add(1, 'month'));
                    }}
                >
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={15}
                        fill={colors.trans}
                        stroke={'#70747C'}
                        strokeWidth={1.2}
                    />
                </div>
                <GroupButtonRow
                    groupType={groupType}
                    onGroupTypeChange={(type) => {
                        onGroupTypeChange(type);

                        setFilteredData(
                            type === 'member'
                                ? classSettlementData?.tableDataByMembership
                                : classSettlementData?.tableDataBySchedule
                        );
                    }}
                />
                {coachData && coachData.length > 0 && (
                    <Dropdown
                        initialSelected={selectedCoach}
                        options={coachData}
                        onSelect={(selected) => {
                            handleSelect(selected, 'coach');
                        }}
                        bgColor="rgba(201, 219, 255, 0.4)"
                        textColor={colors.primary}
                        borderRadius={50}
                        totalText="모든 강사"
                        arrowColor={colors.primary}
                        className="py-[14px]"
                        textType="medium"
                    />
                )}
            </Row>
            <TableHeader
                summaryData={monthlySummary}
                date={date}
                totalRemainingScheduleSession={totalRemainingScheduleSession}
            />
            {groupType === 'member' ? (
                <Row className="flex w-full justify-end mb-[30px]">
                    <Row
                        className={` items-center justify-center bg-[#F2F4F6] rounded-[7px] px-[15px] py-[5px] mr-[18px]  ${
                            filteredData && filteredData.length > 0 ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'
                        }`}
                        onClick={() => {
                            filteredData && filteredData.length > 0 && exportToExcelForMember();
                        }}
                    >
                        <SvgIcon name={'SvgExcel'} size={20} fill={colors.trans} className="mr-[7px]" />
                        <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                            엑셀 다운로드
                        </RegularText>
                    </Row>
                    {memberData && memberData.length > 0 && (
                        <MemberSearchTextInput
                            data={memberData}
                            width={154}
                            placeholder="회원검색"
                            onSelect={(select) => setSearch(select.value)}
                            onSearch={(search) => setSearch(search)}
                        />
                    )}
                </Row>
            ) : (
                <Row className="flex w-full justify-between mb-[30px]">
                    <Row>
                        <RegularText fontSize={20} lineHeight={24} color="#3D4244">
                            {`총 ${filteredCount}건 / 총 수업료 ${convertToKRW(filteredTotalPrice)}`}
                        </RegularText>
                    </Row>
                    <Row>
                        <button
                            className="flex flex-row relative items-center justify-center rounded-[7px] px-[12px] mr-[12px] "
                            onClick={() => setIsCalendarOpen(true)}
                            style={{
                                backgroundColor: selectedDate ? '#C9DBFF66' : '#F2F4F6',
                            }}
                        >
                            <DemiLightText
                                fontSize={17}
                                lineHeight={24.62}
                                color={selectedDate ? colors.primary : '#3D4244'}
                                className="mr-[12px]"
                            >
                                {selectedDate ? dayjs(selectedDate).format('D일') : '모든 날짜'}
                            </DemiLightText>
                            <SvgIcon
                                name={'SvgDownStrokeArrow'}
                                fill={colors.trans}
                                stroke={selectedDate ? colors.primary : '#3D4244'}
                                size={14}
                                strokeWidth={1.5}
                            />
                            {isCalendarOpen && (
                                <NewCalendar
                                    date={dayjs(date)}
                                    selectedDay={selectedDate}
                                    setIsCalendar={setIsCalendarOpen}
                                    onClickDate={(day) => {
                                        onChangeSelectedDate((day && dayjs(day)) ?? undefined);
                                    }}
                                />
                            )}
                        </button>
                        <Dropdown
                            options={membershipTemplateData}
                            className="h-[33px] mr-[12px]"
                            totalText="모든 수강권"
                            onSelect={(selected) => {
                                handleSelect(selected, 'classTicket');
                            }}
                            selectedBgColor="#C9DBFF66"
                            selectedTextColor={colors.primary}
                            selectedArrowColor={colors.primary}
                        />
                        <Dropdown
                            options={dummyAttendanceStatusData}
                            className="h-[33px] mr-[12px]"
                            totalText="전체 상태"
                            onSelect={(selected) => {
                                handleSelect(selected, 'attendanceStatus');
                            }}
                            selectedBgColor="#C9DBFF66"
                            selectedTextColor={colors.primary}
                            selectedArrowColor={colors.primary}
                        />
                        <Row
                            className={` items-center justify-center bg-[#F2F4F6] rounded-[7px] px-[15px] py-[5px] mr-[18px]  ${
                                filteredData && filteredData.length > 0
                                    ? 'cursor-pointer'
                                    : 'opacity-50 cursor-not-allowed'
                            }`}
                            onClick={() => filteredData && filteredData.length > 0 && exportToExcelForClss()}
                        >
                            <SvgIcon name={'SvgExcel'} size={20} fill={colors.trans} className="mr-[7px]" />
                            <RegularText fontSize={16} lineHeight={23} color="#3D4244" letterSpacing={-0.32}>
                                엑셀 다운로드
                            </RegularText>
                        </Row>
                        <MemberSearchTextInput
                            data={memberData}
                            width={154}
                            placeholder="회원검색"
                            onSelect={(select) => setSelectedMember(select.code)}
                        />
                    </Row>
                </Row>
            )}
            {filteredData && filteredData.length > 0 ? (
                <Table
                    data={filteredData}
                    columns={groupType === 'member' ? columns : columns2}
                    className="mb-[100px]"
                    globalFilter={search}
                    onGlobalFilterChange={setSearch}
                    width={groupType === 'class' ? 1300 : 1238}
                    isPagination
                    customPaginationComponent={({
                        firstPage,
                        nextPage,
                        canNextPage,
                        previousPage,
                        canPreviousPage,
                    }) => (
                        <Row className=" absolute items-center flex right-0 bottom-0 mb-[20px]">
                            <RegularText
                                fontSize={16}
                                lineHeight={23}
                                color="#3D4244"
                                className={`mr-[16px] ${
                                    canPreviousPage ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                                }`}
                                onClick={() => !canPreviousPage && firstPage()}
                            >
                                처음으로
                            </RegularText>
                            <button
                                className="flex flex-row items-center mr-[13px] bg-[#F4F5F7] rounded-[7px] px-[19px] py-[7px] 
                        disabled:opacity-50 disabled:cursor-not-allowed
                        "
                                onClick={previousPage}
                                disabled={canPreviousPage}
                            >
                                <SvgIcon
                                    name={'SvgLeftStrokeArrow'}
                                    size={15}
                                    fill={colors.trans}
                                    stroke={'#70747C'}
                                    strokeWidth={1.2}
                                    className="mr-[7px]"
                                />
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    이전
                                </RegularText>
                            </button>
                            <button
                                className="flex flex-row items-center mr-[13px] bg-[#F4F5F7] rounded-[7px] px-[19px] py-[7px] 
                        disabled:opacity-50 disabled:cursor-not-allowed
                        "
                                onClick={nextPage}
                                disabled={canNextPage}
                            >
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244" className="mr-[7px]">
                                    다음
                                </RegularText>
                                <SvgIcon
                                    name={'SvgRightStrokeArrow'}
                                    size={15}
                                    fill={colors.trans}
                                    stroke={'#70747C'}
                                    strokeWidth={1.2}
                                />
                            </button>
                        </Row>
                    )}
                />
            ) : (
                <Col>
                    {/* {groupType === 'member' ? (
                        <Row className="flex flex-row w-full h-[44px] bg-[#EEF0F2] rounded-[9px] items-center">
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[149px] text-center"
                            >
                                강사
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[145px] text-center"
                            >
                                회원
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[149px] text-center"
                            >
                                완료/노쇼
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[149px] text-center"
                            >
                                남은 수업 수
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[149px] text-center"
                            >
                                총 수업 정산액
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[149px] text-center"
                            >
                                커미션
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[123px] text-center"
                            >
                                수업기록
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[127px] text-center"
                            >
                                수강권
                            </DemiLightText>
                        </Row>
                    ) : (
                        <Row className="flex flex-row w-full h-[44px] bg-[#EEF0F2] rounded-[9px] items-center">
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[149px] text-center"
                            >
                                강사
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[145px] text-center"
                            >
                                회원
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[133px] text-center"
                            >
                                상태
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[219px] text-center"
                            >
                                수업 일시
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[226px] text-center"
                            >
                                수강권
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[129px] text-center"
                            >
                                회차/총 회차
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[135px] text-center"
                            >
                                정산액
                            </DemiLightText>
                            <DemiLightText
                                fontSize={16}
                                lineHeight={24}
                                color="#3D4244"
                                className="w-[129px] text-center"
                            >
                                수업 금액
                            </DemiLightText>
                        </Row>
                    )} */}
                    <Col className="w-full  items-center h-[500px]">
                        <MediumText
                            fontSize={20}
                            lineHeight={29}
                            letterSpacing={-0.3}
                            color="#3D4244"
                            className="mb-[8px] mt-[100px]"
                        >
                            조회 결과가 없어요
                        </MediumText>
                        <RegularText fontSize={16} lineHeight={24} color="#6B7684">
                            입력한 정보가 올바른지 확인해주세요.
                        </RegularText>
                    </Col>
                </Col>
            )}
            {isHover && (
                <Col
                    className=" absolute py-[10px] px-[14px] rounded-[9px]  w-[200px] h-[98px] bg-[#36393E] justify-center"
                    style={{
                        top: `${position.top - 100}px`,
                        left: `${position.left + 75}px`,
                        zIndex: 40,
                    }}
                >
                    <DemiLightText fontSize={11} lineHeight={16} letterSpacing={-0.32} color="white">
                        수강권에 대한 정산 비율을 설정했을
                    </DemiLightText>
                    <Row className="w-full mb-[8px]">
                        <DemiLightText fontSize={11} lineHeight={16} letterSpacing={-0.32} color="white">
                            경우 [수강권 아이콘
                        </DemiLightText>
                        <SvgIcon name="SvgMembershipTicket" size={16} fill="#B4BCC4" className="mx-[4px]" />
                        <DemiLightText fontSize={11} lineHeight={16} letterSpacing={-0.32} color="white">
                            ] 이 표시돼요
                        </DemiLightText>
                    </Row>
                    <DemiLightText fontSize={11} lineHeight={16} letterSpacing={-0.32} color="white">
                        * 표시가 없는 경우는 강사 정산 비율입니다.
                    </DemiLightText>
                </Col>
            )}
        </div>
    );
};

export default ClassPaySettlementPage;

// 회원별 수업별 버튼 Row
const GroupButtonRow = ({
    groupType,
    onGroupTypeChange,
}: {
    groupType: 'class' | 'member';
    onGroupTypeChange: (type: 'class' | 'member') => void;
}) => {
    const group = [
        {
            title: '수강권별',
            value: 'member',
        },
        {
            title: '수업별',
            value: 'class',
        },
    ];

    return (
        <Row className=" mr-[16px]">
            {group.map((item) => {
                const isSelected = item.value === groupType;
                const TextComponent = isSelected ? MediumText : RegularText;
                const bgColor = isSelected ? '#F4F5F7' : 'white';

                return (
                    <Col
                        className="rounded-full w-[101px] h-[52px] justify-center items-center cursor-pointer"
                        key={item.value}
                        style={{ backgroundColor: bgColor }}
                        onClick={() => onGroupTypeChange(item.value as 'class' | 'member')}
                    >
                        <TextComponent fontSize={20} lineHeight={24} color="#3D4244">
                            {item.title}
                        </TextComponent>
                    </Col>
                );
            })}
        </Row>
    );
};

// 헤더 표
const TableHeader = ({
    summaryData,
    date,
    totalRemainingScheduleSession,
}: {
    summaryData: GetClassFeeSettlementSummaryData;
    date: string;
    totalRemainingScheduleSession?: number;
}) => {
    const totalScheduleFee = summaryData?.totalScheduleFee;
    const totalPricePerSchedule = summaryData?.totalPricePerSchedule;
    const totalRemainSession = summaryData?.totalRemainSession;
    const totalAttendanceScheduleCount = summaryData?.totalAttendanceScheduleCount;
    const totalNoshowScheduleCount = summaryData?.totalNoshowScheduleCount;
    const totalScheduleMemberCount = summaryData?.totalScheduleMemberCount;

    return (
        <div className="flex  overflow: visible">
            <table className=" min-w-full bg-white border border-solid border-gray-300 text-center mb-[66px]">
                <thead>
                    <tr className="bg-gray-100 border-solid border-b h-[40px] justify-center items-center">
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    {`${dayjs(date).month() + 1}월 수업 정산액`}
                                </RegularText>
                                {/* TooltipIcon 추가 */}
                                <TooltipIcon
                                    title={`현재까지 완료된 ${
                                        dayjs(date).month() + 1
                                    }월 수업 데이터를 기준으로 계산된 정산 금액`}
                                />
                            </Row>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    총 수업 금액
                                </RegularText>
                                <TooltipIcon
                                    title={`강사의 1회당 수업 금액(서비스표 금액)을 기준으로 이번 달에 등록된\n모든 수업의 총합`}
                                />
                            </Row>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    남은 수업 수
                                </RegularText>
                                <TooltipIcon
                                    title={`회원이 결제 시 등록한 총 수업 수에서 현재까지 완료된 수업 수를 뺀 남은 수업 수`}
                                />
                            </Row>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <RegularText fontSize={16} lineHeight={24} color="#6B7684" letterSpacing={-0.32}>
                                완료 수업/노쇼
                            </RegularText>
                        </th>
                        <th className="w-[228px] h-full justify-center items-center content-center  border border-solid border-[#D9D9D9] bg-[#F0F2F3]">
                            <Row className="items-center justify-center">
                                <RegularText
                                    fontSize={16}
                                    lineHeight={24}
                                    color="#6B7684"
                                    className="mr-[5px]"
                                    letterSpacing={-0.32}
                                >
                                    수업한 회원 수
                                </RegularText>
                                <TooltipIcon
                                    title={`강사가 이번 달 동안 실제로 수업을 진행한 회원의 고유 수`}
                                    subTitle="* 동일 회원이 여러 번 참여했더라도 한 번만 계산됩니다."
                                />
                            </Row>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/* 수업료 */}
                        <td className=" py-[22px] border border-solid border-[#D9D9D9] ">
                            <RegularText fontSize={23} lineHeight={24} color="#3D4244">
                                {convertToKRW(totalScheduleFee)}
                            </RegularText>
                        </td>
                        {/* 총 등록단가 */}
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <RegularText fontSize={23} lineHeight={24} color="#3D4244">
                                {convertToKRW(totalPricePerSchedule)}
                            </RegularText>
                        </td>
                        {/* 남은 수업수 */}
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <RegularText fontSize={23} lineHeight={24} color="#3D4244">
                                {totalRemainingScheduleSession ? totalRemainingScheduleSession : '계산중...'}
                            </RegularText>
                        </td>
                        {/* 완료수업수 / 노쇼 */}
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <RegularText fontSize={23} lineHeight={24} color="#3D4244">
                                {totalAttendanceScheduleCount}/{totalNoshowScheduleCount}
                            </RegularText>
                        </td>
                        {/* 수업한 회원 수 */}
                        <td className="py-[22px] border border-solid border-[#D9D9D9]">
                            <RegularText fontSize={23} lineHeight={24} color="#3D4244">
                                {totalScheduleMemberCount}
                            </RegularText>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
