import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getItemWithExpiration } from '../lib/utils';
import {
    createTempMember,
    CreateTempMemberParamsType,
    deleteTempMember,
    DeleteTempMemberParamsType,
} from '../api/member';
import { MemberProfile } from './useMemberQueryService';

type Props = {
    onCreateTempMemberSuccess?: (data: MemberProfile) => void;
    onDeleteTempMemberSuccess?: () => void;
};

const useMemberMutationService = ({ onCreateTempMemberSuccess, onDeleteTempMemberSuccess }: Props) => {
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [authorization] = useState(getItemWithExpiration('authorization'));

    // 임시회원 생성
    const createTempMemberMutation = useMutation(
        ['createTempMemberMutation'],
        async (params: CreateTempMemberParamsType) => createTempMember({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status, data }) => {
                if (status === 201) {
                    const member: MemberProfile = data.member;
                    onCreateTempMemberSuccess && onCreateTempMemberSuccess(member);
                } else if (status === 413) {
                    alert('이미 존재하는 회원입니다.');
                } else if (status === 414) {
                    alert(
                        '이미 회원님이 회원가입하셨고 연결 대기상태이십니다. 회원님이 직접 강사코드를 통해 연결하게 해주세요.'
                    );
                }
            },
        }
    );
    // 임시회원 삭제
    const deleteTempMemberMutation = useMutation(
        ['deleteTempMemberMutation'],
        async (params: DeleteTempMemberParamsType) => deleteTempMember({ ...params }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 204) {
                    onDeleteTempMemberSuccess && onDeleteTempMemberSuccess();
                }
            },
        }
    );

    return { createTempMemberMutation, deleteTempMemberMutation };
};

export default useMemberMutationService;
