import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../modal/Modal';
import { Col, Row } from '../../lib/utils';
import { BoldText, MediumText, RegularText } from '../../lib/Text';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onClickDelete: () => void;
};
const ModalDeleteAlert = ({ setIsVisible, onClickDelete }: Props) => {
    return (
        <Modal setIsVisible={setIsVisible} whiteViewWidth={685} borderRadius={25} isCloseOutside>
            <Col className="p-[35px]">
                <BoldText fontSize={28} lineHeight={38} color="#3D4244" className="mb-[20px]">
                    해당 수강권을 삭제하시겠습니까?
                </BoldText>
                <Row className="flex flex-row  items-center">
                    <Col className="w-[4px] h-[4px] bg-black rounded-full mr-[8px]" />
                    <RegularText fontSize={22} lineHeight={38} color="#3D4244">
                        매출 관련 목록에서 해당 수강권 정보가 삭제됩니다.
                    </RegularText>
                </Row>
                <Row className="flex flex-row  items-center mb-[111px]">
                    <Col className="w-[4px] h-[4px] bg-black rounded-full mr-[8px]" />
                    <RegularText fontSize={22} lineHeight={38} color="#3D4244">
                        매출 및 수업료 금액에 영향을 줄 수 있으니 주의해주세요.
                    </RegularText>
                </Row>

                <Row className="self-end">
                    <Col
                        className=" justify-center items-center bg-[#F2F4F6] w-[119px] h-[51px] rounded-[11px] cursor-pointer mr-[20px] "
                        onClick={() => setIsVisible(false)}
                    >
                        <RegularText fontSize={20} lineHeight={28} letterSpacing={-0.32} color="#3D4244">
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className=" justify-center items-center bg-primary w-[119px] h-[51px] rounded-[11px] cursor-pointer self-end"
                        onClick={() => {
                            onClickDelete && onClickDelete();
                            setIsVisible(false);
                        }}
                    >
                        <RegularText fontSize={20} lineHeight={28} letterSpacing={-0.32} color="white">
                            확인
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalDeleteAlert;
