import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../modal/Modal';
import { Col } from '../../lib/utils';
import { BoldText, MediumText, RegularText } from '../../lib/Text';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
};
const ModalUpdateAlert = ({ setIsVisible }: Props) => {
    return (
        <Modal setIsVisible={setIsVisible} borderRadius={25} isCloseOutside>
            <Col className="p-[35px]">
                <BoldText fontSize={28} lineHeight={38} color="#3D4244" className="mb-[20px]">
                    [수강권 정보 변경 시 주의]
                </BoldText>
                <MediumText fontSize={22} lineHeight={38} color="#3D4244">
                    1. 회당가격
                </MediumText>
                <MediumText fontSize={22} lineHeight={38} color="#3D4244">
                    2. 판매 금액
                </MediumText>
                <MediumText fontSize={22} lineHeight={38} color="#3D4244" className="mb-[18px]">
                    3. 총 회차
                </MediumText>
                <RegularText fontSize={22} lineHeight={38} color="#3D4244" className="mb-[40px]">
                    해당 정보 변경 시 매출 정산 데이터가 변경 될 수 있으니 주의해주세요.
                </RegularText>
                <Col
                    className=" justify-center items-center bg-primary w-[119px] h-[51px] rounded-[11px] cursor-pointer self-end"
                    onClick={() => setIsVisible(false)}
                >
                    <RegularText fontSize={20} lineHeight={28} letterSpacing={-0.32} color="white">
                        확인
                    </RegularText>
                </Col>
            </Col>
        </Modal>
    );
};

export default ModalUpdateAlert;
