import React, { useCallback, useMemo } from 'react';
import { NavigateAction } from '../types/types';
import dayjs, { Dayjs } from 'dayjs';

interface ToolbarProps {
    date: Date | string | Dayjs; // 현재 보이는 달력의 날짜
    onNavigate: (action: NavigateAction) => void; // 네비게이션 핸들러
    onView: (view: string) => void; // 뷰 전환 핸들러
    views: string[]; // 사용할 수 있는 뷰의 배열
    view: string; // 현재 활성화된 뷰

    //추가
    monthTooltipCustomComponent?: (
        onNavigate: (action: NavigateAction) => void,
        date: Dayjs | string
    ) => React.ReactNode;
}

const Toolbar: React.FC<ToolbarProps> = ({ date, onNavigate, onView, views, view, monthTooltipCustomComponent }) => {
    // 네비게이션 버튼 핸들러
    const handleNavigate = useCallback(
        (action: NavigateAction) => {
            onNavigate(action);
        },
        [onNavigate]
    );

    // 뷰 전환 버튼 핸들러
    const handleViewChange = useCallback(
        (view: string) => {
            onView(view);
        },
        [onView]
    );

    // 네비게이션 버튼 그룹
    const navigationButtons = useMemo(() => {
        return (
            <>
                <button type="button" onClick={() => handleNavigate('TODAY')}>
                    오늘
                </button>
                <button type="button" onClick={() => handleNavigate('PREV')}>
                    이전
                </button>
                <button type="button" onClick={() => handleNavigate('NEXT')}>
                    다음
                </button>
            </>
        );
    }, [handleNavigate]);

    // 뷰 전환 버튼 그룹 생성
    const viewNamesGroup = useMemo(() => {
        if (views.length > 0) {
            return views.map((name) => (
                <button
                    key={name}
                    type="button"
                    className={view === name ? 'active' : ''}
                    onClick={() => handleViewChange(name)}
                >
                    {name === 'month' && '월'}
                    {name === 'week' && '주'}
                    {name === 'day' && '일'}
                    {name === 'agenda' && '목록'}
                </button>
            ));
        }
        return null;
    }, [views, view, handleViewChange]);

    return monthTooltipCustomComponent ? (
        <div>{monthTooltipCustomComponent(onNavigate, dayjs(date))}</div>
    ) : (
        <div className="rbc-toolbar">
            {/* 네비게이션 버튼 그룹 */}
            <span className="rbc-btn-group">{navigationButtons}</span>

            {/* 라벨 */}
            <span className="rbc-toolbar-label">
                {/* {date.toLocaleDateString('ko-KR', { year: 'numeric', month: 'long' })} */}
                {dayjs(date).format('YYYY년 MM월')}
            </span>

            {/* 뷰 전환 버튼 그룹 */}
            <span className="rbc-btn-group">{viewNamesGroup}</span>
        </div>
    );
};

export default Toolbar;
