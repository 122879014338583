import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { BoldText, DemiLightText, MediumText, RegularText } from '../../lib/Text';
import { colors } from '../../lib/colors';
import SvgIcon from '../../components/SvgIcon';
import { MembershipInfo } from '../../services/useMemberQueryService';
import dayjs from 'dayjs';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    membershipInfo: MembershipInfo;
};

const ModalMembershipInfo = ({ setIsVisible, membershipInfo }: Props) => {
    // 강사이름, 회원 이름
    const coachName = membershipInfo?.coachName;
    const memberName = membershipInfo?.memberInfo?.memberName;
    const memberAge = membershipInfo?.memberInfo?.age;
    const isNewSalesText = membershipInfo.memberInfo.isNewSales ? '신규' : '재등록';
    const memberGender = membershipInfo?.memberInfo?.gender
        ? membershipInfo?.memberInfo?.gender === 'male'
            ? '남성'
            : '여성'
        : '미입력';
    // 현재 index

    // const membership = membershipInfo?.memberships
    const [membership, setMembership] = React.useState(membershipInfo.membership);
    const BGColor = membership?.BGColor;
    const isActive = BGColor && BGColor.length > 0;
    const membershipName = membership?.name;
    const startDate = dayjs(membership?.activationDate).format('YYYY-MM-DD');
    const endDate = dayjs(membership?.expirationDate).format('YYYY-MM-DD');
    // 현재회차, 총회차, 회당 가격, 발급일자
    const currentSession = membership.currentSession;
    const totalSession = membership.totalSession;
    const pricePerSchedule = membership.pricePerSchedule;
    const createdAt = dayjs(membership.createdAt).format('YYYY-MM-DD');

    return (
        <Modal
            setIsVisible={setIsVisible}
            whiteViewWidth={585}
            borderRadius={25}
            isCloseButton
            isCloseOutside
            modalAlign="start"
            mt={80}
            heightFull={false}
        >
            <Col className="flex px-[35px] pt-[35px] items-center">
                <Row className=" items-center mb-[27px] self-start">
                    <MediumText fontSize={38} lineHeight={55} color="#3D4244" className="mr-[31px]">
                        수강권 정보
                    </MediumText>
                    <MediumText fontSize={23} lineHeight={33} color="#3D4244" className="mr-[8px]">
                        {coachName} 강사
                    </MediumText>
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={20}
                        fill={colors.trans}
                        stroke="#3D4244"
                        strokeWidth={1.5}
                    />
                    <MediumText fontSize={23} lineHeight={33} color="#3D4244" className="ml-[8px]">
                        {memberName} 회원
                    </MediumText>
                </Row>
                <Col className="flex relative py-[22px] px-[24px] rounded-[16px] border border-solid border-[#E5E5E9] w-[345px] h-[102px] bg-[#F2F4F6] mb-[17px]">
                    <Row>
                        <SvgIcon name={'SvgProfileInWhiteCircle'} size={58} fill={colors.trans} className="mr-[12px]" />
                        <Col>
                            <MediumText fontSize={23} lineHeight={33} color="#3D4244">
                                {memberName}
                            </MediumText>
                            <Row className="items-center">
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {memberAge ? memberAge + '세' : '미입력'}
                                </RegularText>
                                <div className="h-[14px] bg-black w-[1px] mx-[11px]" />
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {memberGender}
                                </RegularText>
                            </Row>
                        </Col>
                    </Row>
                    <Col className=" absolute right-[28px] bg-[#7E7EF5] rounded-[16.5px] w-[54px] h-[33px] items-center justify-center">
                        <MediumText fontSize={14} lineHeight={20} color="white">
                            {isNewSalesText}
                        </MediumText>
                    </Col>
                </Col>
                <Row className=" items-center mb-[35px]">
                    <Col className="w-[345px]">
                        <Col>
                            <Col
                                className="flex flex-col w-full rounded-tr-[12px] rounded-tl-[12px] h-[123px] justify-center items-center "
                                style={{
                                    background: isActive
                                        ? `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`
                                        : colors.GREY_200,
                                    alignItems: 'center',
                                }}
                            >
                                <Col className="flex w-full p-[32px] justify-start items-start">
                                    <BoldText fontSize={18} lineHeight={28} color="white" className="w-full h-[56px]">
                                        {membershipName}
                                    </BoldText>
                                    <Row className=" items-center">
                                        <Col className="bg-white bg-opacity-[34%] rounded-[18px] w-[60px] h-[25px] justify-center items-center mr-[7px] ">
                                            <RegularText fontSize={11} lineHeight={15.93} color="white">
                                                이용 기간
                                            </RegularText>
                                        </Col>
                                        <RegularText
                                            fontSize={15}
                                            lineHeight={21.72}
                                            color="white"
                                            className=" opacity-[0.6]"
                                        >
                                            {`${startDate} ~ ${endDate}`}
                                        </RegularText>
                                    </Row>
                                </Col>
                            </Col>
                            <Col className="w-full h-[183px] border border-solid border-[#D9D9D9] rounded-bl-[21px] rounded-br-[21px] px-[24px] justify-between py-[20px]">
                                <Row className="w-full justify-between">
                                    <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                        현재 회차
                                    </DemiLightText>
                                    <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                        {currentSession + '회'}
                                    </MediumText>
                                </Row>
                                <Row className="w-full justify-between">
                                    <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                        총 회차
                                    </DemiLightText>
                                    <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                        {totalSession + '회'}
                                    </MediumText>
                                </Row>
                                <Row className="w-full justify-between">
                                    <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                        회당 가격
                                    </DemiLightText>
                                    <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                        {convertToKRW(pricePerSchedule ?? 0) + '원'}
                                    </MediumText>
                                </Row>
                                <Row className="w-full justify-between">
                                    <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                        발급 일자
                                    </DemiLightText>
                                    <MediumText fontSize={16} lineHeight={23} color="#3D4244">
                                        {createdAt}
                                    </MediumText>
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalMembershipInfo;
