import { colors } from './colors';

type TextProps = {
    children: React.ReactNode;
    fontSize?: number;
    lineHeight?: number;
    color?: string;
    className?: string; // 추가 클래스
    onClick?: () => void;
    letterSpacing?: number;
    isNoColor?: boolean; // className 으로 color 넣어야할때 사용
};

export const RegularText = ({
    children,
    fontSize = 14,
    color = colors.black,
    className = '',
    lineHeight: _lineHeight,
    letterSpacing = 0,
    onClick,
    isNoColor = false,
}: TextProps) => {
    return (
        <span
            onClick={onClick}
            className={`  ${className} font-noto-regular `}
            style={{
                fontSize: `${fontSize}px`,
                lineHeight: `${_lineHeight}px`,

                color: isNoColor ? undefined : `${color}`,
                letterSpacing: `${letterSpacing}px`,
            }}
        >
            {children}
        </span>
    );
};

export const DemiLightText = ({
    children,
    fontSize = 14,
    color = colors.black,
    className = '',
    lineHeight: _lineHeight,
    onClick,
    letterSpacing = 0,
}: TextProps) => {
    return (
        <span
            onClick={onClick}
            style={{
                fontSize: `${fontSize}px`,
                lineHeight: `${_lineHeight}px`,
                color: `${color}`,
                letterSpacing: `${letterSpacing}px`,
            }}
            className={`  ${className} font-noto-demilight`}
        >
            {children}
        </span>
    );
};
export const MediumText = ({
    children,
    fontSize = 14,
    color = colors.black,
    className = '',
    lineHeight: _lineHeight,
    letterSpacing = 0,
    onClick,
}: TextProps) => {
    return (
        <span
            onClick={onClick}
            style={{
                fontSize: `${fontSize}px`,
                lineHeight: `${_lineHeight}px`,
                color: `${color}`,
                letterSpacing: `${letterSpacing}px`,
            }}
            className={`  ${className} font-noto-medium`}
        >
            {children}
        </span>
    );
};

export const BoldText = ({
    children,
    fontSize = 14,
    color = colors.black,
    className = '',
    lineHeight: _lineHeight,
}: TextProps) => {
    return (
        <span
            className={`  ${className} font-noto-bold`}
            style={{
                fontSize: `${fontSize}px`,
                lineHeight: `${_lineHeight}px`,
                color: `${color}`,
            }}
        >
            {children}
        </span>
    );
};
