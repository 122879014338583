import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../../components/modal/Modal';
import { MemberScheduleDataType } from '../../services/useMembershipQueryService';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { BoldText, MediumText, RegularText } from '../../lib/Text';
import { colors } from '../../lib/colors';
import SvgIcon from '../../components/SvgIcon';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../components/Table/Table';
import dayjs from 'dayjs';
import { ClassFeeSettlementScheduleRecord } from '../../services/useMemberQueryService';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    scheduleRecord?: ClassFeeSettlementScheduleRecord;
};
const columnHelper = createColumnHelper<MemberScheduleDataType>();
// 상태, 수업일시, 수강권, 회차/ 총회차, 수업료, 등록 단가, 커미션
const columns = [
    columnHelper.accessor('attendanceStatus', {
        id: 'attendanceStatus',
        header: '상태',
        cell: (info) => {
            const text = info.getValue() === 'ATTENDANCE' ? '출석' : '노쇼';
            const isAttendance = info.getValue() === 'ATTENDANCE';
            const color = isAttendance ? '#E5F8F3' : '#FFE8E9';
            const textColor = isAttendance ? '#00A793' : '#FF553E';
            return (
                <Col
                    className="flex items-center justify-center w-[57px] h-[32px]"
                    style={{ backgroundColor: color, borderRadius: 5 }}
                >
                    <RegularText fontSize={16} lineHeight={24} color={textColor}>
                        {text}
                    </RegularText>
                </Col>
            );
        },
        size: 133,
        enableSorting: false,
    }),
    columnHelper.accessor('startTime', {
        id: 'startTime',
        header: '수업 일시',
        cell: (info) => dayjs(info.getValue()).format('YYYY-MM-DD(ddd) HH:mm'),
        size: 221,
        enableSorting: false,
    }),
    columnHelper.accessor('membershipName', {
        id: 'membershipName',
        header: '수강권',
        cell: (info) => {
            return (
                <div className="flex w-[200px] overflow-hidden justify-center items-center">
                    <span className="text-[16px] leading-[24px] text-[#3D4244] truncate">
                        {info.getValue() ?? 'wefefwwefwefewfweewfweffwefew'}
                    </span>
                </div>
            );
        },
        size: 224,
        enableSorting: false,
    }),
    columnHelper.accessor('currentSession', {
        id: 'currentSession',
        header: '회차/총회차',
        cell: (info) => info.getValue() + '/' + info.row.original.totalSession,
        size: 129,
        enableSorting: false,
    }),

    columnHelper.accessor('memberScheduleFee', {
        id: 'memberScheduleFee',
        header: '정산액',
        cell: (info) => convertToKRW(info.getValue() ?? 0),
        size: 134,
        enableSorting: false,
    }),
    columnHelper.accessor('pricePerSchedule', {
        id: 'pricePerSchedule',
        header: '수업 금액',
        cell: (info) => convertToKRW(info.getValue() ?? 0),
        size: 131,
        enableSorting: false,
    }),
    columnHelper.accessor('commission', {
        id: 'commission',
        header: '정산 비율',
        cell: (info) =>
            info.row.original.commissionType === 'percentage'
                ? info.getValue() + '%'
                : convertToKRW(info.getValue() ?? 0),
        size: 132,
        enableSorting: false,
    }),
];

const ModalClassHistory = ({ setIsVisible, scheduleRecord }: Props) => {
    // 강사이름, 회원 이름
    const coachName = scheduleRecord?.coachName;
    const memberName = scheduleRecord?.memberName;
    const allSchedules = scheduleRecord?.allSchedules;
    const BGColor = scheduleRecord?.membershipBGColor;
    const membershipName = scheduleRecord?.membershipName;

    return (
        <Modal
            setIsVisible={setIsVisible}
            whiteViewHeight={588}
            borderRadius={25}
            isCloseButton
            isCloseOutside
            modalAlign="start"
            mt={80}
            isWhiteOverFlowScroll={false}
        >
            <Col className="flex px-[35px] pt-[35px] ">
                <Row className=" items-center mb-[30px]">
                    <MediumText fontSize={38} lineHeight={55} color="#3D4244" className="mr-[31px]">
                        수업 기록
                    </MediumText>
                    <Col
                        className="flex flex-col w-[285px] rounded-[8px] h-[43px] justify-center px-[15px] py-[7px] mr-[19px] "
                        style={{
                            background: BGColor
                                ? `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`
                                : colors.GREY_200,

                            alignItems: 'center',
                        }}
                    >
                        <MediumText
                            fontSize={16}
                            lineHeight={28}
                            letterSpacing={-0.32}
                            color="#FFFFFF"
                            className="self-start w-full overflow-hidden whitespace-nowrap text-ellipsis"
                        >
                            {membershipName}
                        </MediumText>
                    </Col>
                    <MediumText fontSize={23} lineHeight={33} color="#3D4244" className="mr-[8px]">
                        {coachName} 강사
                    </MediumText>
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={20}
                        fill={colors.trans}
                        stroke="#3D4244"
                        strokeWidth={1.5}
                    />
                    <MediumText fontSize={23} lineHeight={33} color="#3D4244" className="ml-[8px]">
                        {memberName} 회원
                    </MediumText>
                </Row>
                <Col
                    className="flex flex-col h-[480px] overflow-auto
                scrollbar-thumb-[#B4B4B6] scrollbar-track-gray-200 scrollbar scrollbar-w-[10px]  scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-h-[10px] 
                "
                >
                    {allSchedules && allSchedules.length > 0 ? (
                        <Table data={allSchedules ?? []} columns={columns} height={404} />
                    ) : (
                        <Col className="w-full  items-center h-[500px]">
                            <MediumText
                                fontSize={20}
                                lineHeight={29}
                                letterSpacing={-0.3}
                                color="#3D4244"
                                className="mb-[8px] mt-[100px]"
                            >
                                조회 결과가 없어요
                            </MediumText>
                        </Col>
                    )}
                </Col>
            </Col>
        </Modal>
    );
};

export default ModalClassHistory;
